
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import SurveyHeaderTitle from '@/components/surveys/SurveyHeaderTitle.vue'
import QuestionTitlePill from '@/components/surveys/QuestionTitlePill.vue'
import InitialQuestion from '@/components/surveys/InitialQuestion.vue'
import Question from '@/components/surveys/Question.vue'
import RadioOption from '@/components/surveys/RadioOption.vue'
import TextQuestion from '@/components/surveys/TextQuestion.vue'
import CheckOption from '@/components/surveys/CheckOption.vue'
import SurveyPreview from '@/views/surveys/SurveyPreview.vue'
import SurveyCompleted from '@/components/surveys/SurveyCompleted.vue'
// #endregion

// #region interfaces
import {
  SurveyQuestionInterface,
  SurveyPresentQuestionRawInterface,
  SurveyPresentInterface
} from '@/types/interfaces/survey'
// #endregion

// #region services
import SurveyService from '@/services/survey.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

// Local Interfaces
interface ValidForm {
  isValid: boolean
  question: string
}

// Components injection
@Component({
  components: {
    SurveyHeaderTitle,
    InitialQuestion,
    QuestionTitlePill,
    Question,
    RadioOption,
    TextQuestion,
    CheckOption,
    SurveyPreview,
    SurveyCompleted
  }
})
export default class SurveyPresent extends Vue {
  @Prop() readonly companyName!: string

  initialData = {
    companyName: 'ASDF',
    survey: {
      name: ''
    }
  }

  token = ''
  email = ''
  successfulSent = false

  data = {
    company_id: '',
    company_name: '',
    active: true,
    name: '',
    survey_id: 0,
    survey_questions: [] as Array<SurveyPresentQuestionRawInterface>,
    access_key: '',
    client_email: '',
    booking_info: {
      booking_id: '',
      number_of_interactors: 0,
      date_from: new Date(),
      date_to: new Date(),
      company_name: ''
    }
  } as SurveyPresentInterface

  async created (): Promise<void> {
    this.token = this.$route.params.token

    this.fetchSurveyPresentQuestions()
  }

  async fetchSurveyPresentQuestions (): Promise<void> {
    const result = await SurveyService.getSurveyPresentQuestions(this.token)
    if (result === false) {
      this.$router.push({ name: 'error-no-auth-page' })
    }

    this.data = { ...result }
  }

  getOptions (entry: SurveyQuestionInterface): Array<string> {
    const options = []
    if (entry.options) {
      for (const option of entry.options) {
        if (option.value) {
          options.push(option.value)
        }
      }
    }

    return options
  }

  validateFields (): ValidForm {
    let invalidFlag = false
    const output = {} as ValidForm

    for (let i = 0; i < this.data.survey_questions.length; i++) {
      if (invalidFlag) break
      const question = this.data.survey_questions[i]
      if (
        question.type !== 'nested' &&
        question.required === true &&
        (!question.answer || question.answer === '')
      ) {
        invalidFlag = true
        output.isValid = false
        output.question = question.question
        break
      }

      if (question.type === 'nested' && question.children.length > 0) {
        for (let j = 0; j < question.children.length; j++) {
          const innerQuestion = question.children[j]
          if (invalidFlag) break
          if (
            innerQuestion.required === true &&
            (!innerQuestion.answer || innerQuestion.answer === '')
          ) {
            invalidFlag = true
            output.isValid = false
            output.question = innerQuestion.question
            break
          }
        }
      }
    }

    if (invalidFlag) {
      return output
    }
    output.isValid = true
    return output
  }

  getSubtitle (): string {
    // const ck = `This survey is related to your booking of ${this.data.booking_info.number_of_interactors}
    // persons to ${this.data.booking_info.company_name} between ${this.data.booking_info.date_from} and ${this.data.booking_info.date_to}.
    // Booking ID: ${this.data.booking_info.booking_id}
    // `

    return i18n
      .t('page.surveys.header')
      .toString()
      .replace(
        '{{number_of_interactors}}',
        this.data.booking_info.number_of_interactors.toString()
      )
      .replace('{{company_name}}', this.data.booking_info.company_name)
      .replace(
        '{{date_from}}',
        this.data.booking_info.date_from.toLocaleString()
      )
      .replace('{{date_to}}', this.data.booking_info.date_to.toLocaleString())
      .replace('{{booking_id}}', this.data.booking_info.booking_id)
  }

  async submit (): Promise<void> {
    const validation: ValidForm = this.validateFields()

    if (!validation.isValid) {
      this.$toast.error(
        `${i18n.t('error.fill_survey_question')} ${validation.question}"`
      )
      return
    }

    await SurveyService.createSurveyFeedback(this.data)

    this.successfulSent = true
  }
}
