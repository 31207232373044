
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// Components
import EmptyTable from '@/common/components/ui/table/EmptyTable.vue'
import TableSearch from '@/common/components/ui/table/TableSearch.vue'

// Utils
import { dummyDateConverter } from '@/utils/date.utils'
import { debounce } from 'lodash'
import { format, isBefore } from 'date-fns'

// Services
import bookingService from '@/services/booking.service'
import { BookingInterface } from '@/types/interfaces/booking'

@Component({ components: { EmptyTable, TableSearch }, methods: { format } })
export default class UpcommingBookings extends Vue {
  @Prop({ default: 'hourly' }) readonly scheduleSystem!: string
  @Prop({ default: () => new Date() }) readonly selectedDate!: Date
  @Prop({ default: false, required: true }) drawer!: boolean
  @Prop({ required: true }) readonly eventClick!: (id: string) => void
  @Prop({ required: true }) readonly toggleDrawer!: () => void
  @Prop({ default: 'day', required: false }) readonly viewMode!: string

  events = []
  showSearch = false
  innerDrawerState = false
  debounceTime = 500 // time in ms

  handlePastEvents (event: BookingInterface): string {
    if (isBefore(new Date(event.date_from), new Date())) {
      return 'tw-text-red-500'
    }

    return 'tw-text-gray-500'
  }

  async fetchEvents (phrase: string | null = null): Promise<void> {
    if (!this.drawer) {
      return
    }

    this.events = await bookingService.getCalendarListEvents(
      dummyDateConverter(this.selectedDate),
      this.scheduleSystem.toUpperCase(),
      this.viewMode,
      phrase
    )
  }

  handleSearch = debounce(
    (value: string) => this.fetchEvents(value),
    this.debounceTime
  )

  async created (): Promise<void> {
    await this.fetchEvents()
  }

  @Watch('showSearch')
  async refetch (): Promise<void> {
    if (this.showSearch) {
      return
    }

    await this.fetchEvents()
  }

  @Watch('drawer')
  async onDrawerChange (): Promise<void> {
    this.innerDrawerState = this.drawer
    await this.fetchEvents()
  }

  async handleDrawer (): Promise<void> {
    if (!this.innerDrawerState) {
      this.showSearch = false
      this.toggleDrawer()
    }
  }
}
