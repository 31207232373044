// #region imports

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region servcies
import BaseService from '@/services/base.service'
// #endregion

// #endregion

const { baseUrl } = configs

class ClientService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll () {
    Store.dispatch('app/loading', true)

    const url = `${baseUrl}/clients`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/clients-search?page=${page}`

    const result = await this.axios.post(url, { searchText })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/client/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.client
  }
}

export default new ClientService()
