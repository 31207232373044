interface Config {
  [key: string]: string
}

const config: Config = {
  appTitle: process.env.VUE_APP_TITLE,
  appProductName: process.env.VUE_APP_PRODUCT_NAME,
  appVersion: process.env.VUE_APP_VERSION || '',
  appLanguage: (process.env.VUE_APP_LANGUAGE || 'en-us').toLowerCase(),
  appLanguageFallback: (
    process.env.VUE_APP_FALLBACK_LANGUAGE || 'en-us'
  ).toLowerCase(),
  appDefaultDomain: process.env.VUE_APP_DEMO_DEFAULT_DOMAIN,
  baseUrl: process.env.VUE_APP_API_ENDPOINT,
  appUrl: process.env.VUE_APP_URL,
  defaultTimezone: 'Europe/Oslo',
  defaultFormat: 'dd.MM.yyyy HH:mm',
  defaultDateTimeFormat: 'dd.MM.yyyy HH:mm',
  externalTermsAndConditionsUrl: process.env.VUE_APP_EXTERNAL_TERMS_AND_CONDITIONS_URL,
  externalPrivacyPolicyUrl: process.env.VUE_APP_EXTERNAL_PRIVACY_POLICY_URL,
  externalGettingStartedUrl: process.env.VUE_APP_EXTERNAL_GETTING_STARTED_URL
}

export const configuration = (): Config => {
  if (!config.appTitle) throw new Error('APP Title is missing!')
  if (!config.appProductName) throw new Error('APP Product Name is missing!')
  if (!config.appVersion) throw new Error('APP Version is missing!')
  if (!config.appLanguage) throw new Error('APP Language is missing!')
  if (!config.appLanguageFallback) {
    throw new Error('APP Language Fallback is missing!')
  }
  if (!config.appDefaultDomain) {
    throw new Error('APP Default Domain is missing!')
  }
  if (!config.baseUrl) throw new Error('Base URL is missing!')
  if (!config.appUrl) throw new Error('APP URL is missing!')
  if (!config.defaultTimezone) throw new Error('Timezone config is missing')
  if (!config.defaultTimezone) { throw new Error('Default datetime format config is missing') }
  if (!config.defaultFormat) throw new Error('Default format config is missing')
  if (!config.defaultDateTimeFormat) { throw new Error('Default datetime format config is missing') }
  if (!config.externalTermsAndConditionsUrl) { throw new Error('External terms and conditions url is missing') }
  if (!config.externalPrivacyPolicyUrl) { throw new Error('External privacy policy url is missing') }
  if (!config.externalGettingStartedUrl) { throw new Error('External getting started url is missing') }

  return config
}
