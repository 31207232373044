
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import {
  DailyDurationSettingInteface,
  LastBookingSettingInterface,
  CompanySettingInterface
} from '@/types/interfaces/setting'
// #endregion

// #region helpers & utils
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region data
import { camelCase } from 'lodash'
// #endregion

// #endregion

@Component
export default class LastBookingSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  rules = rules

  data = {
    default: null,
    mondayLastBooking: null,
    tuesdayLastBooking: null,
    wednesdayLastBooking: null,
    thursdayLastBooking: null,
    fridayLastBooking: null,
    saturdayLastBooking: null,
    sundayLastBooking: null
  } as DailyDurationSettingInteface

  expandedLastBookingSettings = false

  saved = false

  async defaultLastBookingSettingSubmit (): Promise<void> {
    await this.lastBookingSettingsSubmit()
    this.saved = true
  }

  async lastBookingSettingsSubmit (): Promise<void> {
    const dataToSubmit = {} as { [key: string]: number | null }
    let hasData = false

    for (const item in this.data) {
      if (this.data[item]) {
        hasData = true
        dataToSubmit[item] = this.data[item]
      }
    }

    if (hasData) {
      const result = await settingService.updateLastBookingSettings(
        dataToSubmit,
        this.companyId
      )
      this.resetLastBookingSettings(result)
    }

    this.expandedLastBookingSettings = false
  }

  @Watch('data.default')
  onDurationInMinutesChange (): void {
    if (this.data.default) {
      this.expandedLastBookingSettings = false
    }
  }

  toggleLastBookingSettings (): void {
    if (this.data.default) {
      this.expandedLastBookingSettings = !this.expandedLastBookingSettings
    }
  }

  resetLastBookingSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): void {
    const settingsToUse = fetchedSettings || this.settings
    const setting = settingsToUse.last_booking_before
    let settings = {} as DailyDurationSettingInteface

    if (setting) {
      const data = parseJson(setting) as LastBookingSettingInterface
      if (data) {
        settings = { ...this.data }
        for (const item in data) {
          const temp = camelCase(item)
          settings[temp] = data[item]
        }
      }
    }

    this.data = settings
  }

  async fetchLastBookingSettings (): Promise<void> {
    const fetchedSettings = await settingService.getCompanySettings(
      this.companyId
    )
    this.resetLastBookingSettings(fetchedSettings)
  }

  async created (): Promise<void> {
    await this.fetchLastBookingSettings()
    this.setDefaultLastBooking()
  }

  setDefaultLastBooking (): void {
    const setting = parseJson(
      this.settings.last_booking_before
    ) as DailyDurationSettingInteface
    if (setting) {
      this.data.default = setting.default
      this.saved = true
    }
  }
}
