import Vue from 'vue'
import VueShortkey from 'vue-shortkey'

/**
 * Easy keyboard shortcuts
 * https://github.com/iFgR/vue-shortkey
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(VueShortkey)

export default VueShortkey
