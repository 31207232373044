var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`tw-my-2 tw-text-center ${
    _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
  }`},[_c('label',[_c('i',{staticClass:"mdi mdi-tag",attrs:{"color":_vm.tagsColor}}),_vm._v(_vm._s(_vm.$t('common.tags')))]),_c('p',{staticClass:"tw-text-sm"},[_vm._v(" "+_vm._s(_vm.tagDescription)+" ")]),_c('v-divider',{class:`mt-2 ${
      _vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''
    }`}),_c('div',{staticClass:"tw-flex tw-gap-2 tw-justify-center tw-flex-wrap tw-py-2"},[(_vm.notClickable)?[_vm._l((_vm._parseJson(_vm.selectedTags.value.toString())),function(item,indx){return [_c('v-chip',{key:indx,staticClass:"my-2 tw-text-white tw-font-bold",attrs:{"small":"","outlined":"","color":`${
            _vm.canIApplyVisualSettings
              ? 'gButtonBackgroundColor gButtonFontColor--text'
              : 'primary'
          }`}},[_vm._v(" "+_vm._s(item)+" ")])]})]:[(_vm.tags)?[_vm._l((_vm.tags),function(item,indx){return [_c('v-chip',{key:indx,staticClass:"my-2 tw-text-white tw-font-bold",attrs:{"outlined":!_vm.hasPropertyTag(item),"color":_vm.tagsColor,"input-value":_vm.hasPropertyTag(item),"small":""},on:{"click":() => {
                if (!_vm.notClickable) {
                  _vm.toggleTag(indx)
                }
              }}},[_vm._v(" "+_vm._s(item)+" ")])]})]:_vm._e(),(!_vm.tags)?[_c('p',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : 'tw-text-stone-500'},[_vm._v(" "+_vm._s(_vm.$t('component.tags_settings.no_tags_yet'))+" ")])]:_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }