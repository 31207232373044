
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import ThankYou from '@/components/bookings/ThankYou.vue'
import BookingInformation from '@/components/bookings/BookingInformation.vue'
// #endregion

// #region containers
import BookCompanyCard from '@/containers/bookings/BookCompanyCard.vue'
import BookDepartmentCard from '@/containers/bookings/BookDepartmentCard.vue'
import BookCategoryCard from '@/containers/bookings/daily/BookDailyPublicCategoryCard.vue'
import BookDateCard from '@/containers/bookings/hourly/BookDateCard.vue'
import BookDailyPublicDateCard from '@/containers/bookings/daily/BookDailyPublicDateCard.vue'
import BookClientInformation from '@/containers/bookings/hourly/BookClientInformation.vue'
import BookSummaryHourly from '@/containers/bookings/hourly/BookSummaryHourly.vue'
import BookSummaryDaily from '@/containers/bookings/daily/BookSummaryDaily.vue'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
import { CompanyInterface } from '@/types/interfaces/company'
import { AddressInterface } from '@/types/interfaces/address'
import { ClientInterface } from '@/types/interfaces/client'
import {
  BookDateCardInterface,
  BookingPropertyInterface,
  Step
} from '@/types/interfaces/booking'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #region utils
import {
  fromISODateStringToDateOnlyString,
  parseJson
} from '@/utils/helper.utils'
import { IMAGE_SOURCES, LINKS, LANGUAGE_LOCALES } from '@/common/constants'
// #endregion

// #region data
import { clone, cloneDeep } from 'lodash'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region services
import bookingService from '@/services/booking.service'
import departmentService from '@/services/department.service'
import rentingEntityService from '@/services/renting-entity.service'
import { PublicBookingCustomVisualSettingsInterface } from '@/types/interfaces/setting'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme'
import settingService from '@/services/setting.service'
// #endregion

// #endregion

@Component({
  components: {
    BookCompanyCard,
    BookDepartmentCard,
    BookCategoryCard,
    BookDateCard,
    BookDailyPublicDateCard,
    BookClientInformation,
    BookSummaryDaily,
    BookSummaryHourly,
    ThankYou,
    BookingInformation
  }
})
export default class Book extends Vue {
  step = 0
  showThankYou = false
  language = this.$i18n.locale

  hasCategories = false
  category: CategoryInterface | null = null
  rentingEntity: RentingEntityInterface | null = null

  IMAGE_SOURCES = IMAGE_SOURCES
  LINKS = LINKS
  LANGUAGE_LOCALES = LANGUAGE_LOCALES

  queryParams = {
    categoryId: this.$route.query.category || '',
    departmentId: this.$route.query.department || '',
    rentingEntityId: this.$route.query['renting-entity'] || ''
  }

  departments: DepartmentInterface[] = []

  // show & hide stuff
  isOnlyOneCompany = false
  isOnlyOneDepartment = false
  // show & hide stuff

  // header logo stuff -- start
  headerLogoState = {
    start: true,
    link: '',
    company_logo: '',
    powered_by: ''
  }

  // header logo stuff -- end

  data = {
    company: {
      id: '',
      name: '',
      country_code: '',
      phone_number: '',
      logo_url: '',
      background_image_path: '',
      settings: {}
    },
    rentingEntity: {
      id: '',
      name: ''
    },
    department: {
      id: '',
      name: '',
      address: {} as AddressInterface | undefined,
      allow_categories: false,
      activity_system: '',
      schedule_system: '',
      checkin: '',
      checkout: ''
    },
    client: {
      name: '',
      country_code: '',
      phone_number: '',
      email: ''
    } as ClientInterface,
    note: '',
    date_from: '',
    date_to: '',
    number_of_interactors: 0,
    properties: [] as BookingPropertyInterface[],
    category_id: '',
    category_name: ''
  }

  allSteps: Step[] = [
    {
      title: 'common.pick_location',
      component: 'BookCompanyCard'
    },
    {
      title: 'common.pick_area',
      component: 'BookDepartmentCard'
    },
    {
      title: 'common.pick_date',
      component: 'BookDailyPublicDateCard',
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'common.pick_date',
      component: 'BookDateCard',
      checkScheduleSystem: 'HOURLY'
    },
    {
      title: 'common.pick_category',
      component: 'BookCategoryCard',
      checkCategory: true,
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'component.book.fill_information_step',
      component: 'BookClientInformation'
    },
    {
      title: 'component.book.summary_step',
      component: 'BookSummaryDaily',
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'component.book.summary_step',
      component: 'BookSummaryHourly',
      checkScheduleSystem: 'HOURLY'
    }
  ]

  steps: Step[] = cloneDeep(this.allSteps)

  defaultObject = cloneDeep(this.data)

  loading = true

  stepName = this.allSteps[0].component

  companyVisualSettings = {} as PublicBookingCustomVisualSettingsInterface

  resetVisualSettingsEventListener (): void {
    // reset company visual settings
    const defaultCopmanyVisualSettings =
      this.$store.getters['app/getDefaultCompanyVisualSettings']

    this.defaultLight = clone(this.defaultLightFilled)
    this.companyVisualSettings = defaultCopmanyVisualSettings
    this.handleVisuals(true)
  }

  // light theme colors
  defaultLight = {} as VuetifyThemeVariant

  defaultDark = {} as VuetifyThemeVariant

  defaultLightFilled = {
    background: '#f2f5f8',
    surface: '#f2f5f8',
    primary: '#0096c7',
    secondary: '#a0b9c8',
    accent: '#048ba8',
    error: '#ef476f',
    info: '#2196F3',
    success: '#06d6a0',
    warning: '#ffd166',
    gMainBackgroundColor: '#ffffff',
    gFontColor: '#000000',
    gCardBackgroundColor: '#ffffff',
    gCardBorderColor: '#123123',
    gButtonBackgroundColor: '#E3F2FD',
    gButtonFontColor: '#01579B',
    gButtonBorderColor: '#ffffff',
    gDatesBackgroundColorPastDates: '#111b27',
    gDatesBackgroundColorPresent: '#111b27',
    gDatesBackgroundColorFutureDates: '#111b27',
    gGeneralElementsBackgroundColor: '#111b27'
  }

  async created (): Promise<void> {
    // Case 1:
    await this.fetchAndSetDepartments()

    // Case 2:
    await this.fetchAndSetRentingEntities()
    this.loading = false

    this.cloneDefualtThemeColors()
    this.handleVisuals(true)
  }

  cloneDefualtThemeColors (): void {
    this.defaultLight = clone(this.$vuetify.theme.themes.light)
    this.defaultDark = clone(this.$vuetify.theme.themes.dark)
  }

  changeLanguage (): void {
    this.$i18n.locale = this.language
    localStorage.setItem('language', this.language)
    window.location.reload()
  }

  async setDepartment (department: DepartmentInterface): Promise<void> {
    if (department.schedule_system !== 'DAILY') {
      return
    }

    if (department.company) {
      // check if company has settings
      if (!department.company.settings) {
        const settings = await settingService.getCompanySettingsPulbic(
          department.company.id
        )

        department.company.settings = settings
        this.data.company.settings = settings
      }

      this.selectedCompany(department.company, false, false)
    }
    this.selectedDepartment(department, false)
  }

  async fetchAndSetDepartments (): Promise<void> {
    if (!this.queryParams.departmentId) {
      return
    }

    const result = await departmentService.getAllPublic()
    const department = result.find(
      (item) => item.id === this.queryParams.departmentId
    )

    if (department) {
      await this.setDepartment(department)
    }

    this.departments = result
  }

  async fetchAndSetRentingEntities (): Promise<void> {
    if (!this.queryParams.rentingEntityId) {
      return
    }

    const result = await rentingEntityService.getAllPublic()

    const rentingEntity = result.find(
      (item) => item.id === this.queryParams.rentingEntityId
    )

    if (rentingEntity) {
      const department = rentingEntity.department

      if (department && department.schedule_system !== 'DAILY') {
        return
      }

      if (department) {
        let query: { [key: string]: string } = {
          'renting-entity': rentingEntity.id as string,
          department: rentingEntity.department_id
        }

        if (rentingEntity.category_id) {
          query = { ...query, category: rentingEntity.category_id }
        }

        this.$router.replace({ query })
        await this.setDepartment(department)
        this.rentingEntity = rentingEntity
        this.data.rentingEntity.id = rentingEntity.id as string
        this.data.rentingEntity.name = rentingEntity.name as string
      }
    }
  }

  get numberOfGuests (): string {
    if (this.data.number_of_interactors) {
      return `- ${this.data.number_of_interactors} ${i18n.t('common.guests')}`
    }
    return ''
  }

  // #region custom visuals
  handleVisuals (reverse = false): void {
    if (!this.companyVisualSettings || !this.companyVisualSettings.active) {
      return
    }
    this.setGeneralColors(reverse)
    this.setFontColor(reverse)
    this.setCardBackgroundColor(reverse)
    this.setCardBorderColor(reverse)
    this.setButtonColors(reverse)
    this.setDatesBackgroundColors(reverse)
    this.setGeneralElementsColor(reverse)
  }

  formatColor (input: string): string {
    if (input) {
      return input.substring(0, 7)
    }

    return ''
  }

  setGeneralColors (reverse = false): void {
    const _formatColor = (i: string) => {
      return this.formatColor(i)
    }

    const _applyCss = (color: string) => {
      const el = document.querySelector('.theme--light.v-application')

      if (el) {
        el.setAttribute('style', `background-color: ${color} !important;`)
      }
    }

    if (!reverse) {
      if (this.companyVisualSettings.main_background_color) {
        this.$vuetify.theme.themes.light.gMainBackgroundColor = _formatColor(
          this.companyVisualSettings.main_background_color.color
        )
      }

      if (this.companyVisualSettings.page_background_color && !this.data.company.background_image_path) {
        this.$vuetify.theme.themes.light.background = _formatColor(
          this.companyVisualSettings.page_background_color.color
        )

        _applyCss(this.companyVisualSettings.page_background_color.color)
      }
    } else {
      this.$vuetify.theme.themes.light.gMainBackgroundColor =
        this.defaultLight.gMainBackgroundColor

      this.$vuetify.theme.themes.light.background = this.defaultLight.background

      _applyCss(this.$vuetify.theme.themes.light.background as string)
    }
  }

  setFontColor (reverse = false): void {
    if (!this.companyVisualSettings.font_color) return

    if (!reverse) {
      this.$vuetify.theme.themes.light.gFontColor = this.formatColor(
        this.companyVisualSettings.font_color.color
      )
    } else {
      this.$vuetify.theme.themes.light.gFontColor = this.defaultLight.gFontColor
    }
  }

  setCardBackgroundColor (reverse = false): void {
    if (!this.companyVisualSettings.cards_background_color) return

    if (!reverse) {
      this.$vuetify.theme.themes.light.gCardBackgroundColor =
        this.companyVisualSettings.cards_background_color.color.substring(0, 7)
    } else {
      this.$vuetify.theme.themes.light.gCardBackgroundColor =
        this.defaultLight.gCardBackgroundColor
    }
  }

  setCardBorderColor (reverse = false): void {
    if (!this.companyVisualSettings.cards_border_color) return
    if (!reverse) {
      this.$vuetify.theme.themes.light.gCardBorderColor =
        this.companyVisualSettings.cards_border_color.color.substring(0, 7)
    } else {
      this.$vuetify.theme.themes.light.gCardBorderColor =
        this.defaultLight.gCardBorderColor
    }
  }

  setButtonColors (reverse = false): void {
    if (!reverse) {
      if (this.companyVisualSettings.buttons_background_color) {
        this.$vuetify.theme.themes.light.gButtonBackgroundColor =
          this.formatColor(
            this.companyVisualSettings.buttons_background_color.color
          )
      }

      if (this.companyVisualSettings.buttons_font_color) {
        this.$vuetify.theme.themes.light.gButtonFontColor = this.formatColor(
          this.companyVisualSettings.buttons_font_color.color
        )
      }

      if (this.companyVisualSettings.buttons_border_color) {
        this.$vuetify.theme.themes.light.gButtonBorderColor = this.formatColor(
          this.companyVisualSettings.buttons_border_color.color
        )
      }
    } else {
      this.$vuetify.theme.themes.light.gButtonBackgroundColor =
        this.defaultLight.gButtonBackgroundColor

      this.$vuetify.theme.themes.light.gButtonFontColor =
        this.defaultLight.gButtonFontColor

      this.$vuetify.theme.themes.light.gButtonBorderColor =
        this.defaultLight.gButtonBorderColor
    }
  }

  // dates - start
  setDatesBackgroundColors (reverse = false): void {
    if (!reverse) {
      if (this.companyVisualSettings.dates_background_color_past) {
        // past dates
        this.$vuetify.theme.themes.light.gDatesBackgroundColorPastDates =
          this.formatColor(
            this.companyVisualSettings.dates_background_color_past.color
          )
      }

      if (this.companyVisualSettings.dates_background_color_present) {
        // present date
        this.$vuetify.theme.themes.light.gDatesBackgroundColorCurrentDate =
          this.formatColor(
            this.companyVisualSettings.dates_background_color_present.color
          )
      }

      if (this.companyVisualSettings.dates_background_color_future) {
        // future dates
        this.$vuetify.theme.themes.light.gDatesBackgroundColorFutureDates =
          this.formatColor(
            this.companyVisualSettings.dates_background_color_future.color
          )
      }
    } else {
      // past dates
      this.$vuetify.theme.themes.light.gDatesBackgroundColorPastDates =
        this.defaultLight.gDatesBackgroundColorPastDates

      // present date
      this.$vuetify.theme.themes.light.gDatesBackgroundColorCurrentDate =
        this.defaultLight.gDatesBackgroundColorCurrentDate

      // future dates
      this.$vuetify.theme.themes.light.gDatesBackgroundColorFutureDates =
        this.defaultLight.gDatesBackgroundColorFutureDates
    }
  }
  // dates - end

  // general elements - start
  setGeneralElementsColor (reverse = false): void {
    if (!reverse) {
      if (this.companyVisualSettings.selectors_border_color) {
        this.$vuetify.theme.themes.light.gGeneralElementsBackgroundColor =
          this.formatColor(
            this.companyVisualSettings.selectors_border_color.color
          )
      }

      if (this.companyVisualSettings.error_color) {
        this.$vuetify.theme.themes.light.error = this.formatColor(
          this.companyVisualSettings.error_color.color
        )
      }
    } else {
      this.$vuetify.theme.themes.light.gGeneralElementsBackgroundColor =
        this.defaultLight.gGeneralElementsBackgroundColor

      this.$vuetify.theme.themes.light.error = this.defaultLight.error
    }
  }

  // general elements - end

  numberOfInteractorsSelectOptionsClickEventListener (reverse = false): void {
    if (!this.companyVisualSettings.active) return
    this.$nextTick(() => {
      const el = document.querySelector('.v-list--dense')

      // set background color and font color
      if (el) {
        if (!reverse) {
          el.setAttribute(
            'style',
            `background-color: ${this.companyVisualSettings.cards_background_color.color.substring(
              0,
              7
            )} !important;`
          )
        } else {
          el.setAttribute(
            'style',
            `background-color: ${this.defaultLight.gCardBackgroundColor} !important;`
          )
        }
      }
    })

    // font
    this.$nextTick(() => {
      const el = document.getElementsByClassName('v-list-item__content')

      if (el) {
        if (!reverse) {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color =
              this.companyVisualSettings.font_color.color.substring(0, 7)
          }
        } else {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color = ''
          }
        }
      }
    })
  }

  selectHourSelectOptionsClickEventListener (reverse = false): void {
    if (!this.companyVisualSettings.active) return
    this.$nextTick(() => {
      const el = document.querySelector('.v-list--dense')

      // set background color and font color
      if (el) {
        if (!reverse) {
          el.setAttribute(
            'style',
            `background-color: ${this.companyVisualSettings.cards_background_color.color.substring(
              0,
              7
            )} !important; color: ${this.companyVisualSettings.font_color.color.substring(
              0,
              7
            )} !important;`
          )
        } else {
          el.setAttribute(
            'style',
            `background-color: ${this.defaultLight.gCardBackgroundColor} !important; color: ${this.defaultLight.gFontColor} !important;`
          )
        }
      }
    })

    // font
    this.$nextTick(() => {
      const el = document.getElementsByClassName('v-list-item__content')

      if (el) {
        if (!reverse) {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color =
              this.companyVisualSettings.font_color.color.substring(0, 7)
          }
        } else {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color = ''
          }
        }
      }
    })
  }

  selectCountryCodeClickEventListener (reverse = false): void {
    if (!this.companyVisualSettings.active) return
    this.$nextTick(() => {
      const el = document.querySelector('.v-list--dense')

      // set background color and font color
      if (el) {
        if (!reverse) {
          el.setAttribute(
            'style',
            `background-color: ${this.companyVisualSettings.cards_background_color.color.substring(
              0,
              7
            )} !important; color: ${this.companyVisualSettings.font_color.color.substring(
              0,
              7
            )} !important;`
          )
        } else {
          el.setAttribute(
            'style',
            `background-color: ${this.defaultLight.gCardBackgroundColor} !important; color: ${this.defaultLight.gFontColor} !important;`
          )
        }
      }
    })

    // font
    this.$nextTick(() => {
      const el = document.getElementsByClassName('v-list-item__content')

      if (el) {
        if (!reverse) {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color =
              this.companyVisualSettings.font_color.color.substring(0, 7)
          }
        } else {
          for (let i = 0; i < el.length; i++) {
            const element = el[i] as HTMLElement
            element.style.color = ''
          }
        }
      }
    })
  }

  // #endregion

  stepperClass (num: number): string {
    const prefix =
      this.step === num && this.$vuetify.breakpoint.mdAndDown
        ? 'b-l-0 ma-0 '
        : ''
    return prefix + 'ml-md-8 px-3 px-md-5'
  }

  updateStep (_step: number): void {
    if (_step === 0) {
      this.handleVisuals(true)
      this.$store.commit('app/setCompanyBackgroundImage', null)
      this.headerLogoState.company_logo = IMAGE_SOURCES.DEFAULT_LOGO
      this.headerLogoState.link = LINKS.FRONTLINE_WEBSITE
      this.headerLogoState.start = true
    } else {
      this.handleVisuals()
    }
    this.step = _step
    this.stepName = this.steps[_step].component
  }

  selectedCompany (
    data: CompanyInterface,
    incrementStep = true,
    cleanQueryParams = true
  ): void {
    this.data.company = {
      id: data.id,
      name: data.name,
      country_code: data.country_code,
      phone_number: data.phone_number,
      logo_url: data.logo_url as string,
      background_image_path: data.background_image_path as string,
      settings: data.settings || {}
    }

    const findResult = data.settings?.find((item) => {
      if (item.key === 'public_booking_custom_visual_settings') {
        return item.value
      }
      return null
    })

    if (findResult) {
      const parseResult = parseJson(findResult.value)

      if (parseResult !== false) {
        this.companyVisualSettings = parseResult
      }
    }

    if (cleanQueryParams) {
      this.cleanQueryParams()
    }

    if (incrementStep) {
      this.updateStep(1)
    }

    this.$store.commit('app/setCompanyBackgroundImage', data.background_image_path)
    this.headerLogoState.company_logo = data.logo_url ? data.logo_url : IMAGE_SOURCES.DEFAULT_LOGO
    this.headerLogoState.link = LINKS.FRONTLINE_WEBSITE
    this.headerLogoState.start = false
  }

  selectedDepartment (data: DepartmentInterface, cleanQueryParams = true): void {
    this.data.department = {
      id: data.id,
      name: data.name,
      address: data.address,
      allow_categories: !!data.allow_categories,
      activity_system: data.activity_system,
      schedule_system: data.schedule_system,
      checkin: data.checkin?.value || '',
      checkout: data.checkout?.value || ''
    }

    if (cleanQueryParams) {
      this.cleanQueryParams()
    }

    this.updateStep(2)
  }

  cleanQueryParams (): void {
    const queryParams = Object.keys(this.$route.query)

    if (queryParams.length) {
      this.$router.replace({ query: {} })
      this.cleanRentingEntity()
    }
  }

  cleanRentingEntity (): void {
    this.rentingEntity = null
    this.data.rentingEntity.id = ''
    this.data.rentingEntity.name = ''
  }

  async setCategory (data: CategoryInterface): Promise<void> {
    // Check if there are any slots for the given data
    const hasSlots = await this.checkAvaialbleBookingSlotsOnCategoryLevel(
      fromISODateStringToDateOnlyString(new Date(this.data.date_from)),
      fromISODateStringToDateOnlyString(new Date(this.data.date_to)),
      this.data.department.id,
      this.data.number_of_interactors,
      data.id // <-- category id
    )

    if (hasSlots === false) {
      this.$toast.warning(
        i18n.t('component.book.daily_no_available_slots').toString()
      )
      this.cleanQueryParams()
      return
    }

    this.category = data
    this.updateStep(this.step + 1)

    this.data.category_id = data.id as string
    this.data.category_name = data.name
  }

  dateInformationAdded (data: BookDateCardInterface): void {
    this.data = { ...this.data, ...data }

    this.updateStep(this.step + 1)
  }

  dateInformationAddedDaily (data: BookDateCardInterface): void {
    this.data = { ...this.data, ...data }

    if (
      this.rentingEntity &&
      this.rentingEntity.number_of_interactors < data.number_of_interactors
    ) {
      this.rentingEntity = null
      this.data.rentingEntity.id = ''
      this.$router.replace({ query: {} })
      this.$toast.warning(
        this.$t(
          'component.book.number_of_guests_greater_than_renting_entity'
        ).toString()
      )
    }

    this.updateStep(++this.step)
  }

  clientInformationAdded (client: ClientInterface): void {
    this.data.properties = []
    this.data.client = client
    if (client.notes) {
      this.data.properties.push({ key: 'notes', value: client.notes })
    }
    if (client.tags) {
      this.data.properties.push(client.tags)
    }
    this.updateStep(this.step + 1)
  }

  setDetail (index: number, key: 'key' | 'value', value: string): void {
    this.data.properties[index][key] = value
  }

  ordered (): void {
    this.showThankYou = true
  }

  newBooking (): void {
    this.step = 0
    this.data = cloneDeep(this.defaultObject)
    this.showThankYou = false
    this.handleVisuals(true)
  }

  resetDate (): void {
    this.data.date_from = ''
    this.data.number_of_interactors = 0
  }

  @Watch('data.department.id')
  onDepartmentChange (): void {
    this.hasCategories = !!this.data.department.allow_categories

    let _steps = [...this.allSteps]

    if (!this.hasCategories) {
      _steps = _steps.filter((item) => !item.checkCategory)
    }

    _steps = _steps.filter(
      (item) =>
        item.checkScheduleSystem === this.data.department.schedule_system ||
        !item.checkScheduleSystem
    )

    this.steps = cloneDeep(_steps)
  }

  showHeader (item: Step, _step: number): boolean {
    const stepCondition = this.step >= _step

    if (item.checkCategory) {
      return stepCondition && this.hasCategories
    }

    if (item.checkScheduleSystem) {
      return (
        stepCondition &&
        this.data.department.schedule_system === item.checkScheduleSystem
      )
    }

    return stepCondition
  }

  showContent (item: Step, _step: number): boolean {
    const stepCondition = this.step === _step

    if (item.checkCategory) {
      return stepCondition && this.hasCategories
    }

    if (item.checkScheduleSystem) {
      return (
        stepCondition &&
        this.data.department.schedule_system === item.checkScheduleSystem
      )
    }

    return stepCondition
  }

  isOnlyOneCompanyEventHandler (isOnlyOneCompany: boolean): void {
    this.isOnlyOneCompany = isOnlyOneCompany
  }

  isOnlyOneDepartmentEventHandler (isOnlyOneDepartment: boolean): void {
    this.isOnlyOneDepartment = isOnlyOneDepartment
  }

  showCompaniesOrDepartments (component: string): boolean {
    if (component === 'BookCompanyCard') {
      return !this.isOnlyOneCompany
    } else if (component === 'BookDepartmentCard') {
      return !this.isOnlyOneDepartment
    }
    return true
  }

  // #region backend interacting methods
  async checkAvaialbleBookingSlotsOnCategoryLevel (
    dateFrom: string,
    dateTo: string,
    departmentId: string,
    numberOfInteractors: number,
    categoryId?: string
  ): Promise<boolean> {
    return await bookingService.checkAvaialbleBookingSlots({
      dateFrom,
      dateTo,
      departmentId,
      numberOfInteractors,
      categoryId,
      rentingEntityId: this.data.rentingEntity.id
    })
  }
  // #endregion
}
