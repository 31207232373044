
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region componentes
import BookDateCard from '@/containers/bookings/hourly/BookDateCard.vue'
import CardButtons from '@/common/components/ui/card/CardButtons.vue'
import CardButton from '@/common/components/ui/card/CardButton.vue'
import BookCompanyCard from '@/containers/bookings/BookCompanyCard.vue'
import BookHourlyDepartmentCard from '@/containers/bookings/hourly/BookHourlyDepartmentCard.vue'
import Tags from '@/components/bookings/Tags.vue'
import ManageGuests from '@/common/components/modals/ManageGuests.vue'
import ChoiceCard from '@/common/components/ui/card/ChoiceCard.vue'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import { CompanyInterface } from '@/types/interfaces/company'

import {
  BookDateCardInterface,
  BookingPropertyInterface,
  CreateBookingInterface
} from '@/types/interfaces/booking'
import { ClientInterface } from '@/types/interfaces/client'
// #endregion

// #region validatons
import rules from '@/utils/rules.utils'
// #endregion

// #region utils
import { format, isAfter, isBefore } from 'date-fns'
import { parseJson, jsonToString } from '@/utils/helper.utils'
import { sanitizePhoneNumber } from '@/utils/sanitation.utils'
// #endregion

// #region assets
import countryPrefixes from '@/assets/CountryPrefix'
// #endregion

// #region services
import bookingService from '@/services/booking.service'
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
import settingService from '@/services/setting.service'
// #endregion

// #region locales
import i18n from '@/i18n'
import { defaultOutgoing, dummyDateConverter } from '@/utils/date.utils'
import { enUS, nb } from 'date-fns/locale'
// #endregion

// Utils
// #endregion

@Component({
  components: {
    BookDateCard,
    CardButtons,
    CardButton,
    BookCompanyCard,
    BookHourlyDepartmentCard,
    Tags,
    ManageGuests,
    ChoiceCard
  },
  methods: {
    parseJson,
    jsonToString,
    sanitizePhoneNumber
  }
})
export default class AdminBookingManagement extends Vue {
  @Prop() dialog!: boolean
  @Prop({ default: '' }) readonly itemId!: string
  @Prop({ default: () => [] as CreateBookingInterface[] })
  readonly items!: CreateBookingInterface[]

  @Prop({ default: 'ADD' }) readonly state!: string
  @Prop({ required: false }) readonly rentingEntityIds!: string[]

  prefixes = countryPrefixes
  deleteDialog = false
  checkoutDialog = false
  step = 1
  loading = true

  valid = false
  rules = rules

  company = {} as CompanyInterface
  department = {} as DepartmentInterface
  tags = []
  interactorSeries = [] as Array<number>

  user = store.getters['auth/getUserData']

  notes = {
    key: 'notes',
    value: ''
  } as BookingPropertyInterface

  selectedTags = {
    key: 'tags',
    value: []
  } as BookingPropertyInterface

  data = {
    company_id: '',
    department_id: '',
    renting_entity_id: '',
    schedule_system: '',
    client: {
      name: '',
      phone_number: '',
      email: '',
      country_code: '+47'
    } as ClientInterface,
    date_from: '',
    expected_arrival: '',
    expected_departure: '',
    number_of_interactors: 0,
    properties: [this.notes, this.selectedTags],
    department: {} as DepartmentInterface,
    editable: true
  } as CreateBookingInterface

  async created (): Promise<void> {
    if (this.itemId) {
      const item = this.items.find(
        (item) => item.id === this.itemId
      ) as CreateBookingInterface

      if (item) {
        const result = await bookingService.getBookingCustom(this.itemId)
        this.data = result
        this.manageGuests.scheduleSystem = result.schedule_system as string

        const notes = result.properties?.find((x) => x.key === 'notes')
        const tags = result.properties?.find((x) => x.key === 'tags')

        this.data.properties = []

        const properties = []
        if (notes) {
          properties.push(notes)
          this.notes = notes
        }

        if (tags) {
          properties.push(tags)
          this.selectedTags = tags
        }

        this.data.properties = properties

        this.generateInteractorsSeries()
      }
    }

    if (this.user.company_id) {
      const company = await companyService.getSingle(this.user.company_id)
      this.selectedCompany(company)
    }

    if (this.user.department_id) {
      const department = await departmentService.getSingle(
        this.user.department_id
      )
      this.selectedDepartment(department)
    }

    this.loading = false
  }

  generateInteractorsSeries (): void {
    const maxNumberOfInteractors =
      this.data.max_number_of_interactors?.max_interactors
    for (let i = 1 as number; i <= maxNumberOfInteractors; i++) {
      this.interactorSeries.push(i)
    }
  }

  view (): void {
    this.$router.push(`/booking/${this.itemId}`)
  }

  async deleteBooking (): Promise<void> {
    this.deleteDialog = false
    await bookingService.delete(this.itemId)
    this.$emit('submit')
  }

  resetDate (): void {
    // console.log('reset date')
  }

  canTouch (): boolean {
    const now = new Date()
    const dateFrom = dummyDateConverter(this.data.date_from)
    const dateTo = dummyDateConverter(this.data.date_to as Date)
    if (isBefore(dateFrom, now) && isBefore(dateTo, now)) {
      return false
    }
    return this.user.role === 'admin' || this.user.role === 'host'
  }

  async selectedCompany (company: CompanyInterface): Promise<void> {
    this.company = company
    this.data.company_id = company.id
    this.updateStep(2)

    // Getting tags
    if (this.company) {
      const companySettings = await settingService.getCompanySettings(
        this.company.id
      )
      this.tags = parseJson(companySettings.tags)
    }

    if (this.data.properties) {
      this.data.properties = this.data.properties.filter(
        (item) => item.key !== 'tags'
      )

      this.data.properties.push({
        key: 'tags',
        value: []
      })
    }
  }

  toggleTag (indx: number): void {
    if (this.data.properties) {
      const tempTag = this.tags[indx]
      const tags = this.data.properties.find((item) => item.key === 'tags')
      const localIndx = tags?.value.indexOf(tempTag)

      const properites = this.data.properties.filter(
        (item) => item.key !== 'tags'
      )

      const updatedTags = this.data.properties.find(
        (item) => item.key === 'tags'
      )

      if (updatedTags) {
        if (localIndx === -1) {
          updatedTags.value.push(tempTag)
        } else {
          updatedTags.value.splice(localIndx, 1)
        }
        properites.push(updatedTags)
      }

      this.data.properties = [...properites]
    }
  }

  hasPropertyTag (item: string): boolean {
    if (this.data.properties) {
      const tag = this.data.properties.find((item) => item.key === 'tags')
      const index = tag ? tag.value.indexOf(item) : -1
      return index > -1
    }

    return false
  }

  selectedDepartment (department: DepartmentInterface): void {
    this.department = department
    this.data.department_id = department.id
    this.data.schedule_system = department.schedule_system
    this.updateStep(3)
  }

  get companyName (): string {
    if (this.company.name) {
      return `- ${this.company.name}`
    }
    return ''
  }

  get departmentName (): string {
    if (this.department.name) {
      return `- ${this.department.name}`
    }
    return ''
  }

  getText (data: { name: string; code: string }): string {
    return `${data.name} (${data.code})`
  }

  stepperClass (num: number): string {
    const prefix =
      this.step === num && this.$vuetify.breakpoint.mdAndDown
        ? 'b-l-0 ma-0 '
        : ''
    return prefix + 'ml-md-8 px-3 px-md-5'
  }

  updateStep (num: number): void {
    this.step = num
  }

  locale = i18n.locale.toLowerCase() === 'en-us' ? enUS : nb

  get formatedSelectedDate (): string {
    if (this.step < 4) {
      return ''
    }

    if (this.data.date_from) {
      const date = dummyDateConverter(this.data.date_from)
      const dateFormat = 'EEEE, MMMM do, yyyy - HH:mm'
      return `- ${format(date, dateFormat, { locale: this.locale })}`
    }
    return ''
  }

  get numberOfGuests (): string {
    if (this.step < 4) {
      return ''
    }

    if (this.data.number_of_interactors) {
      return `- ${this.data.number_of_interactors} ${i18n.t('common.guests')}`
    }
    return ''
  }

  get clientInformation (): string {
    if (this.step < 5) {
      return ''
    }

    // const client = this.data.client
    // return `${client.name}, ${client.email}, (+${client.country_code}) ${client.phone_number}`
    const tmp = this.data
    return `${tmp.client_name}, ${tmp.client_email}, (+${tmp.client_country_code}) ${tmp.client_phone_number}`
  }

  dateInformationAdded (values: BookDateCardInterface): void {
    this.data = { ...this.data, ...values }
    this.updateStep(4)
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      let properties = this.data.properties
        ? (this.data.properties.filter(
            (property: BookingPropertyInterface) => property.value !== ''
          ) as BookingPropertyInterface[])
        : []

      let data = {
        booking: { ...this.data },
        client: this.data.client,
        booking_properties: [] as BookingPropertyInterface[] | undefined,
        department: this.data.department
      }

      if (properties.length) {
        properties = properties.filter((item) => item.value.length > 0)
        properties.forEach((item: BookingPropertyInterface) => {
          if (item.key === 'tags') {
            const tmpBookingProperty = jsonToString(item.value)
            if (tmpBookingProperty) {
              item.value = tmpBookingProperty
            }
          }
        })
        data = { ...data, booking_properties: properties }
      }

      if (!properties.length) {
        delete data.booking_properties
      }

      data.client.name = data.booking.client_name as string
      data.client.email = data.booking.client_email as string
      data.client.phone_number = data.booking.client_phone_number as string
      data.client.country_code = data.booking.client_country_code as string

      // Making a request for booking
      if (this.data.department?.activity_system === 'REQUESTS_ONLY') {
        delete data.department

        await bookingService.createRequest(data)

        this.$toast.success(
          i18n.t('notify.success.booking_request_added').toString()
        )

        this.$emit('submit')

        return
      }

      // Normal booking
      await bookingService.create(data)
      this.$toast.success(i18n.t('notify.success.booking_added').toString())
      this.$emit('submit')
    }
    this.$emit('close')
  }

  async delete (): Promise<void> {
    // DELETE LATER
    // console.log('Delete activated')
  }

  backed = false
  back (num: number): void {
    this.backed = true
    this.step = num
  }

  get showClientArrivedButton (): boolean {
    if (this.state.toLowerCase() === 'add' || this.data.arrived_at) {
      return false
    }
    return this.data.date_to
      ? new Date(this.data.date_to as string).getTime() > Date.now()
      : false
  }

  async clientArrived (): Promise<void> {
    const date = await bookingService.clientPresent(this.data.id)
    this.data.arrived_at = date
    this.$emit('submit')
  }

  async clientNotPresent (): Promise<void> {
    await bookingService.clientNotPresent(this.data.id)
    this.data.arrived_at = undefined
    this.$emit('submit')
  }

  async save (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      if (this.notes.value) {
        const notesIndex =
          this.data.properties?.findIndex((item) => item.key === 'notes') || -1

        if (this.data.properties) {
          if (notesIndex > -1) {
            this.data.properties[notesIndex] = this.notes
          } else {
            this.data.properties.push(this.notes)
          }
        }
      }

      await bookingService.update(this.data, this.itemId)
      this.$emit('submit')
    }
  }

  // #region Manage Guests

  // data
  manageGuests = {
    scheduleSystem: '',
    dialog: false
  }

  // methods
  closeManageGuestsDialog (): void {
    this.manageGuests.dialog = false
  }

  openManageGuestsDialog (): void {
    this.manageGuests.dialog = true
  }

  // #endregion

  // #region checkout
  get canWeCheckOut (): boolean {
    const dateFrom = dummyDateConverter(this.data.date_from as Date)

    const now = dummyDateConverter()

    return isAfter(now, dateFrom)
  }

  async checkout (): Promise<void> {
    if (this.canWeCheckOut) {
      const dateTo = defaultOutgoing(this.data.date_to as Date)

      const bookingId = this.itemId

      await bookingService.checkout(dateTo, bookingId)

      this.$toast.success('Checkout completed')

      this.$emit('closeDialogFromCheckouFunction')
    }
  }
  // #endregion
}
