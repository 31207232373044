
import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/auth.service'
import * as yup from 'yup'
import rules from '@/utils/rules.utils'
import i18n from '@/i18n'

@Component
export default class SigninView extends Vue {
  isLoading = false
  rules = rules

  data = {
    email: '',
    password: ''
  }

  errors = {
    email: '',
    password: ''
  }

  errorMessage = ''

  showPassword = false

  get getForgotPasswordPath (): string {
    let domain = this.$router.currentRoute.params.domain

    if (!domain) domain = 'demo'

    const result = `/${domain}/auth/forgot-password`

    return result
  }

  async created (): Promise<void> {
    await this.directLogin()
  }

  schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required()
  })

  objectValidaty = [
    { validator: 'email', path: 'email' },
    { validator: 'password', path: 'password' }
  ]

  async directLogin (): Promise<void> {
    const query = this.$router.currentRoute.query
    if (Object.keys(query).length > 0) {
      const email = query.email as string
      const password = query.password as string
      this.data.email = email
      this.data.password = password
      // delay 2 seconds to login
      setTimeout(async () => {
        await this.login()
      }, 1000)
    }
  }

  async login (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    let state = false

    if (valid) {
      state = await AuthService.signIn(
        this.data.email,
        this.data.password,
        this.$route.params.domain
      )
    }

    if (!state) {
      this.errorMessage = i18n.t('error.http_errors.401').toString()
      return
    }

    setTimeout(() => {
      this.$router.replace('/')
    }, 500)
  }
}
