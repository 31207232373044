
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
// #endregion

// #region
import reportService from '@/services/report.service'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { CreateReportObjectInterface } from '@/types/interfaces/report'
// #endregion

// #region services
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
import { DepartmentInterface } from '@/types/interfaces/department'
// #endregion

// #region helpers & utils
import { defaultOutgoing } from '@/utils/date.utils'
// #endregion

// #endregion

@Component({
  components: { TableModalForm, TableModalActions }
})
export default class GenerateReport extends Vue {
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  rules = rules
  valid = false
  companies = [] as CompanyInterface[]
  departments = [] as DepartmentInterface[]
  selectedCompany = {} as CompanyInterface

  date = new Date()
  year = this.date.getFullYear()
  month = this.date.getMonth()
  loading = true

  data = {
    range: {
      start: new Date(this.year, this.month, 1),
      end: new Date(this.year, this.month + 1, 0, 23, 59, 59)
    },
    version: 2
  } as CreateReportObjectInterface

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      const reportData = {
        date_from: defaultOutgoing(this.data.range.start),
        date_to: defaultOutgoing(this.data.range.end),
        company_id: this.data.company_id,
        department_id: this.data.department_id || null,
        name: this.data.name,
        version: this.data.department_id ? this.data.version : 1
      }
      await reportService.generate(reportData)
      this.onSubmit()
      this.dialogHandler()
    }
  }

  async created (): Promise<void> {
    this.loading = true

    const companies = await companyService.getActiveWithoutPagination()
    this.companies = companies

    this.loading = false
  }

  // #endregion watchers
  @Watch('data.company_id')
  onCompanyChange (): void {
    const company = this.companies.find(
      (item) => item.id === this.data.company_id
    )

    if (company) {
      this.selectedCompany = company
    }
    this.departments = []
  }

  @Watch('selectedCompany')
  async onCompanySelection (): Promise<void> {
    if (!this.selectedCompany.id) {
      return
    }

    if (this.selectedCompany.daily_departments_count) {
      const departments = await departmentService.getAllCompanyDepartments(
        this.data.company_id,
        'DAILY'
      )
      this.departments = departments
    }
  }

  // #endregion
}
