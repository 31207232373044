// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region servcies
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

class RentingEntityPropertyService extends BaseService {
  error?: ApiCallErrorInterface

  async getPropertyByEntityId (entityId: string | undefined) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity-property/${entityId}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)
    return result.data.property
  }
}

export default new RentingEntityPropertyService()
