
import { Component, Prop, Vue } from 'vue-property-decorator'
import ItemActions from '@/common/components/ui/table/ItemActions.vue'
import ItemAction from '@/common/components/ui/table/ItemAction.vue'

@Component({
  components: {
    ItemActions,
    ItemAction
  }
})
export default class DefaultTableActions extends Vue {
  // eslint-disable-next-line
  @Prop({ required: true }) readonly item!: any
  @Prop({ default: () => [] }) readonly disabledActions!: string[]
  // eslint-disable-next-line
  @Prop({ required: false }) readonly view!: (item: any) => void
  // eslint-disable-next-line
  @Prop({ required: false }) readonly edit!: (item: any) => void
  // eslint-disable-next-line
  @Prop({ required: false }) readonly delete!: (item: any) => void
  // eslint-disable-next-line
  @Prop({ required: false }) readonly reactivate!: (item: any) => void
  // eslint-disable-next-line
  @Prop({ required: false }) readonly customAction!: (item: any) => void
  @Prop({ required: false, default: '' }) readonly customIcon!: string
  @Prop({ default: false }) readonly unarchive!: boolean

  notAllowedActions = [] as string[]

  created (): void {
    this.notAllowedActions = this.disabledActions.map((data: string) =>
      data.toLowerCase()
    )
  }

  allowedAction (action: string): boolean {
    if (this.notAllowedActions.includes(action.toLowerCase())) {
      return false
    }

    return true
  }

  viewItem (): void {
    if (this.allowedAction('view')) {
      this.view(this.item)
    }
  }

  editItem (): void {
    if (this.allowedAction('edit')) {
      this.edit(this.item)
    }
  }

  deleteItem (): void {
    if (this.allowedAction('delete')) {
      this.delete(this.item)
    }
  }

  reactivateItem (): void {
    if (this.allowedAction('reactivate')) {
      this.reactivate(this.item)
    }
  }
}
