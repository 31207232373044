
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CounterCard extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: true }) readonly name!: string
  @Prop({ required: true }) readonly count!: number
  @Prop({ required: false }) readonly date!: string
  @Prop({ required: false }) readonly desciption!: string
  @Prop({ default: false }) readonly info!: string
}
