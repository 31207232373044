import MainPrice from '@/views/price/MainPrice.vue'

const pricesRoutes = [
  {
    path: '/pricing',
    name: 'pricing',
    component: MainPrice,
    meta: {
      layout: 'informative',
      auth: false
    }
  }
]

export default pricesRoutes
