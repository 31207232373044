// #region imports

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region servcies
import BaseService from '@/services/base.service'
// #endregion

// #endregion

class DashboardService extends BaseService {
  public error?: ApiCallErrorInterface

  async getData () {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/dashboard')

    Store.dispatch('app/loading', false)

    return result.data.dashboard
  }
}

export default new DashboardService()
