
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
// #endregion

// #region interfaces
import {
  RentingEntityInterface,
  RentingEntityTableItemInterface
} from '@/types/interfaces/renting-entity'

import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import RentingEntityService from '@/services/renting-entity.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({ components: { Table, DefaultTableActions } })
export default class ArchivedRentingEntities extends Vue {
  @Prop({ required: true }) readonly tab!: string

  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('table.col.type'),
      value: 'custom_render',
      key: 'type',
      render: Vue.component('TypeName', {
        props: ['item'],
        template:
          '<v-chip label outlined color="red" small>{{item.name.toUpperCase()}}</v-chip>'
      })
    },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    {
      text: i18n.t('table.col.status'),
      value: 'custom_render',
      render: Vue.component('Status', {
        template: '<v-chip label outlined color="red">ARCHIVED</v-chip>'
      }),
      sortable: false
    },
    {
      text: i18n.t('input.label.number_of_interactors'),
      value: 'custom_render',
      key: 'number_of_interactors',
      render: Vue.component('NumberOfInteractors', {
        props: ['item'],
        template:
          '<v-chip label outlined color="blue" small class="tw-font-bold">{{item}}</v-chip>'
      }),
      sortable: false
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  rentingEntities: Array<RentingEntityInterface> = []
  pagination = {} as PaginationInterface

  async handleRentingEntitiesFetch (): Promise<void> {
    const result = await RentingEntityService.getAll('archived')
    this.setData(result)
  }

  async created (): Promise<void> {
    this.handleRentingEntitiesFetch()
  }

  dialog = false
  rentingEntity = null

  viewItem (row: RentingEntityTableItemInterface): void {
    this.$router.push(`/renting-entity/${row.item.id}`)
  }

  toggleDialog (): void {
    this.dialog = !this.dialog
  }

  resetItem (): void {
    this.rentingEntity = null
  }

  async reactivateItem (row: RentingEntityTableItemInterface): Promise<void> {
    const id = row.item.id !== undefined ? row.item.id : ''

    const result = await RentingEntityService.reactivate(id)

    this.$toast.success(
      `${i18n.t('notify.success.reactivated_part1')}'${row.item.name}' ${i18n.t(
        'notify.success.reactivated_part2'
      )}
`
    )

    this.handleRentingEntitiesFetch()

    return result
  }

  async nextPage (): Promise<void> {
    const result = await RentingEntityService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await RentingEntityService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await RentingEntityService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'trashedRentingEntites') {
      this.handleRentingEntitiesFetch()
    }
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.rentingEntities = result.entities.data
    delete result.entities.data
    this.pagination = result.entities
  }
}
