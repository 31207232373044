
// #region imports

// #region vue
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// #region components
import DocumentationToolbar from './partials/_DocumentationToolbar.vue'
import DocumentationNavItems from './partials/_DocumentationNavItems.vue'
import GetStartedDocumentation from './partials/_GetStartedDocumentation.vue'
import ApplicationWorkflowDocumentation from './partials/_ApplicationWorkflowDocumentation.vue'
import CalendarDocumentation from './partials/_CalendarDocumentation.vue'
import CompanyDocumentation from './partials/_CompanyDocumentation.vue'
import DepartmentDocumentation from './partials/_DepartmentDocumentation.vue'
import RentingEntityDocumentation from './partials/_RentingEntityDocumentation.vue'
import BookingDocumentation from './partials/_BookingDocumentation.vue'
import CategoryDocumentation from './partials/_CategoryDocumentation.vue'
import SurveyDocumentation from './partials/_SurveyDocumentation.vue'
import ReportDocumentation from './partials/_ReportDocumentation.vue'
import SettingsDocumentation from './partials/_SettingsDocumentation.vue'

// #endregion

// #endregion

// Vue instantiates the component
@Component({
  components: {
    DocumentationToolbar,
    DocumentationNavItems,
    GetStartedDocumentation,
    ApplicationWorkflowDocumentation,
    CalendarDocumentation,
    CompanyDocumentation,
    DepartmentDocumentation,
    RentingEntityDocumentation,
    BookingDocumentation,
    CategoryDocumentation,
    SurveyDocumentation,
    ReportDocumentation,
    SettingsDocumentation
  }
})
export default class DocumentationLayout extends Vue {}
