
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region services
import companyService from '@/services/company.service'
import settingService from '@/services/setting.service'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import ThemeSettings from '@/containers/settings/ThemeSettings.vue'
import PaginationSettings from '@/containers/settings/PaginationSettings.vue'
import BookingSettings from '@/containers/settings/duration/BookingSettings.vue'
import ApplicationSettingsOverview from '@/components/settings/ApplicationSettingsOverview.vue'
import LogoSettings from '@/containers/settings/LogoSettings.vue'
import FirelistSettings from '@/containers/settings/FirelistSettings.vue'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    ThemeSettings,
    PaginationSettings,
    BookingSettings,
    ApplicationSettingsOverview,
    LogoSettings,
    FirelistSettings
  }
})
export default class ApplicationSettings extends Vue {
  rules = rules
  expandedDurationSettings = false
  selectedCompany = ''

  data = {} as CompanySettingInterface

  companies = [] as CompanyInterface[]

  expandDurationSettings (): void {
    if (this.selectedCompany) {
      this.expandedDurationSettings = !this.expandedDurationSettings
    }
  }

  async fetchCompanies (): Promise<void> {
    const result = await companyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchSettings (): Promise<void> {
    const result = await settingService.getCompanySettings(this.selectedCompany)
    this.data = { ...this.data, ...result }
  }

  @Watch('selectedCompany')
  async onSelectedCompanyChange (): Promise<void> {
    if (this.selectedCompany) {
      this.expandedDurationSettings = false
      this.data = {} as CompanySettingInterface
      await this.fetchSettings()
    }
  }

  async created (): Promise<void> {
    await this.fetchCompanies()

    if (this.companies.length === 1) {
      this.selectedCompany = this.companies[0].id
    }
  }

  async setPagination (newValue: number): Promise<void> {
    this.data.pagination = newValue
  }

  async setLogoPath (newValue: string): Promise<void> {
    this.data.logo_path = newValue
  }
}
