var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"tw-flex tw-flex-col justify-center tw-mx-4 tw-my-4",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"label":_vm.$t('input.label.name_require_mark'),"name":"name","dense":"","rules":[_vm.rules.required],"outlined":"","color":_vm.elementColor},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('div',{staticClass:"tw-flex tw-flex-col md:tw-flex-row tw-gap-2"},[_c('v-select',{staticClass:"tw-align-middle",attrs:{"label":_vm.$t('input.label.country_code'),"prepend-icon":"mdi-phone","dense":"","items":_vm.prefixes,"item-text":(data) => _vm.getText(data),"item-value":"code","outlined":"","rules":[_vm.rules.required, _vm.rules.countryPrefix],"color":_vm.elementColor},on:{"click":function($event){return _vm.$emit('selectCountryCodeClickEvent')},"change":_vm.injectCustomCss},scopedSlots:_vm._u([{key:"item",fn:function({ item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('span',{attrs:{"color":`${
                    _vm.canIApplyVisualSettings
                      ? 'gFontColor--text lighten-1'
                      : ''
                  }`}},[_vm._v(_vm._s(item.name))]),_c('v-spacer'),_c('v-chip',{attrs:{"text-color":_vm.canIApplyVisualSettings ? 'gFontColor' : '',"color":`${
                    _vm.canIApplyVisualSettings
                      ? 'gCardBackgroundColor lighten-1'
                      : ''
                  }`,"small":""}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)])],1)],1)]}}]),model:{value:(_vm.data.country_code),callback:function ($$v) {_vm.$set(_vm.data, "country_code", $$v)},expression:"data.country_code"}}),_c('v-text-field',{staticClass:"tw-w-full md:tw-w-3/4",attrs:{"label":_vm.$t('input.label.phone_number_require_mark'),"name":"phone_number","dense":"","prepend-icon":_vm.$vuetify.breakpoint.mdAndUp ? '' : 'mdi-phone',"outlined":"","rules":[_vm.rules.required, _vm.rules.phone2],"hint":_vm.$t('input.hint.phone_no_leading_zeros'),"persistent-hint":"","counter":"15","color":_vm.elementColor},on:{"blur":function($event){_vm.data.phone_number = _vm._sanitizePhoneNumber(_vm.data.phone_number)}},model:{value:(_vm.data.phone_number),callback:function ($$v) {_vm.$set(_vm.data, "phone_number", $$v)},expression:"data.phone_number"}})],1),_c('v-text-field',{attrs:{"label":_vm.$t('input.label.email_require_mark'),"name":"email","dense":"","outlined":"","rules":[_vm.rules.required, _vm.rules.email],"color":_vm.elementColor},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),_c('Tags',{attrs:{"properties":[_vm.selectedTags],"tags":_vm.tags,"toggleTag":_vm.toggleTag,"companySettings":_vm.item.company.settings,"visualSettingsActive":_vm.visualSettings.active}}),_c('v-textarea',{attrs:{"filled":"","label":_vm.$t('input.label.note_optional_mark'),"placeholder":_vm.$t('input.placeholder.note'),"auto-grow":"","hide-details":"","color":_vm.elementColor},on:{"click":_vm.injectCustomCss},model:{value:(_vm.data.notes),callback:function ($$v) {_vm.$set(_vm.data, "notes", $$v)},expression:"data.notes"}}),_c('v-btn',{staticClass:"tw-my-2 tw-ml-auto",attrs:{"color":_vm.buttonColor},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submitButtonTitleComputed)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }