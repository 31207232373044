import BookingRequests from '@/views/booking-requests/BookingRequests.vue'
import BookingRequest from '@/views/booking-requests/BookingRequest.vue'

const bookingRequestsRoutes = [
  {
    path: '/booking-requests',
    name: 'booking-requests',
    component: BookingRequests,
    meta: {
      auth: true
    }
  },
  {
    path: '/booking-request/:id',
    name: 'booking-request',
    component: BookingRequest,
    meta: {
      auth: true
    }
  }
]

export default bookingRequestsRoutes
