
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region utils
import { format } from 'date-fns'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region configs
import { configuration } from '@/config'
// #endregion

// #endregion

@Component
export default class BlockedDateDateView extends Vue {
  @Prop({ required: true }) readonly item!: string | Date
  @Prop({ required: false }) readonly color!: string

  get formatedDate (): string {
    return format(dummyDateConverter(this.item), configuration().defaultFormat)
  }

  get colorClass (): string {
    return this.color ? this.color : 'purple lighten-5'
  }
}
