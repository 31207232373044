
import { Component, Vue, Watch } from 'vue-property-decorator'
import Store from '@/store'
import i18n from '@/i18n'

@Component
export default class ErrorNotification extends Vue {
  get error (): string {
    return Store.getters['app/errorNotificationState']
  }

  @Watch('error')
  updateShow (): void {
    if (this.error) {
      const e = this.error.split('|')[0]
      this.$toast.error(i18n.t(`A-ERROR.${e}`).toString())
      Store.dispatch('app/clearErrorNotification')
    }
  }
}
