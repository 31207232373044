import { render, staticRenderFns } from "./_PendingBookingRequests.vue?vue&type=template&id=1dd81b34&"
import script from "./_PendingBookingRequests.vue?vue&type=script&lang=ts&"
export * from "./_PendingBookingRequests.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports