import { render, staticRenderFns } from "./_ApprovedBookingRequests.vue?vue&type=template&id=743e255c&"
import script from "./_ApprovedBookingRequests.vue?vue&type=script&lang=ts&"
export * from "./_ApprovedBookingRequests.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports