
import { Component, Vue } from 'vue-property-decorator'
import rules from '@/utils/rules.utils'
import { ResetPasswordInterface } from '@/types/interfaces/auth'
import authService from '@/services/auth.service'

@Component
export default class ResetPasswordView extends Vue {
  valid = true
  rules = rules
  showPassword = false
  tokenExpired = true

  get getRoute (): string {
    return `/${this.$router.currentRoute.params.domain}/auth/forgot-password`
  }

  data = {
    password: '',
    password_confirmation: '',
    token: ''
  } as ResetPasswordInterface

  async created (): Promise<void> {
    this.data.token = this.$route.params.token
    await this.checkTokenExpiration()
  }

  async checkTokenExpiration (): Promise<void> {
    const result = await authService.checkTokenExpiration(this.data.token)
    this.tokenExpired = result.info.token_expired
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()
    if (valid) {
      const result = await authService.resetPassword(this.data)

      this.$router.push(`/${result.info.domain}/auth/signin`)
    }
  }
}
