
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Store from '@/store'

@Component({
  computed: { ...mapState('app', ['snackbarVisibility', 'snackbarData']) }
})
export default class SnackbarAlert extends Vue {
  close (): void {
    Store.dispatch('app/closeSnackbar')
  }
}
