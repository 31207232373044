
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import MoreThanOneRentingEntity from '@/components/renting-entities/MoreThanOneRentingEntity.vue'
import Tags from '@/components/bookings/Tags.vue'
// #endregion

// #region utils & helpers
import { differenceInMinutes } from 'date-fns'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
// #endregion

// #region interfaces
import { BookingInterface } from '@/types/interfaces/booking'
// #endregion

// #region helpers & utils
import { defaultIncoming, bookingTimeDuration } from '@/utils/date.utils'
// #endregion

// #region constants
const DATE_FORMAT = 'd - MMMM - yyyy H:mm'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewItems,
    PageOverviewItem,
    MoreThanOneRentingEntity,
    Tags
  }
})
export default class Booking extends Vue {
  booking = {} as BookingInterface

  async handleFetchBooking (): Promise<void> {
    const result = await BookingService.getSingle(this.$route.params.id)
    this.booking = result
  }

  async created (): Promise<void> {
    await this.handleFetchBooking()
  }

  viewParent (): void {
    this.$router.push(`/department/${this.booking.department.id}`)
  }

  get dateFrom (): string {
    return defaultIncoming(this.booking.date_from, DATE_FORMAT)
  }

  get dateTo (): string {
    return defaultIncoming(this.booking.date_to, DATE_FORMAT)
  }

  get dateDiff (): number {
    return differenceInMinutes(
      new Date(this.booking.date_to),
      new Date(this.booking.date_from)
    )
  }

  get formattedBookingSpan (): string {
    const minutes = this.dateDiff

    const formatted = bookingTimeDuration(minutes)

    return formatted
  }
}
