var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{class:`d-flex flex-column ${
    _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
  }`},[_c('v-card',{class:`my-2 d-flex flex-column ${
      _vm.canIApplyVisualSettings ? 'gFontColor--text gMainBackgroundColor' : ''
    } `,attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.reservationSummary.client.name))]),_c('div',{staticClass:"justify-space-between d-flex flex-column flex-md-row"},[_c('div',[_c('v-card-subtitle',{staticClass:"py-0 tw-font-bold"},[_vm._v(" "+_vm._s(_vm.clientPhoneNumber)+" ")]),_c('v-card-subtitle',{staticClass:"py-0 tw-font-bold"},[_vm._v(" "+_vm._s(_vm.reservationSummary.client.email)+" ")])],1),_c('div',{staticClass:"my-auto tw-font-bold"},[_c('v-chip',{class:_vm.canIApplyVisualSettings
              ? 'gCardBackgroundColor gFontColor--text'
              : '',attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.dateRange)+" ")])],1)])],1),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card',{class:`tw-my-2 d-flex flex-column ${
      _vm.canIApplyVisualSettings ? 'gFontColor--text gMainBackgroundColor' : ''
    }`,attrs:{"flat":""}},[_c('v-card-title',{staticClass:"tw-flex tw-justify-between"},[_c('div',[_vm._v(" "+_vm._s(_vm.reservationSummary.company.name)+" ")]),(_vm.reservationSummary.company.logo_url)?_c('v-img',{attrs:{"src":_vm.reservationSummary.company.logo_url,"alt":_vm.reservationSummary.company.name,"lazy-src":_vm.reservationSummary.company.logo_url,"max-width":"150","max-height":"30"}}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-space-between flex-column flex-md-row"},[_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.reservationSummary.department.name)+" ")]),_c('v-card-subtitle',{staticClass:"py-0"},[_vm._v(" "+_vm._s(_vm.departmentAddress)+" ")])],1)],1),_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : ''}),_c('v-card-title',[_vm._v(_vm._s(_vm.$t('card.title.note')))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.notes.value ? _vm.notes.value : 'N/A')+" ")]),(_vm.tags.value)?_c('div',{staticClass:"d-flex flex-row gap-2 tw-my-2 tw-text-end"},[_c('h4',{class:`tw-uppercase tw-font-extrabold tw-underline tw-underline-offset-4 tw-decoration-2 ${
        _vm.canIApplyVisualSettings ? '' : 'tw-decoration-sky-500'
      }`},[_vm._v(" "+_vm._s(_vm.tags.key.toUpperCase())+": ")]),(_vm.parseJson(_vm.tags.value).length)?_vm._l((_vm.parseJson(_vm.tags.value)),function(item,indx){return _c('v-chip',{key:indx,staticClass:"tw-font-bold",attrs:{"color":_vm.canIApplyVisualSettings
            ? 'gCardBackgroundColor gFontColor  --text'
            : 'info',"outlined":!_vm.canIApplyVisualSettings,"small":""}},[_vm._v(" "+_vm._s(item)+" ")])}):[_c('v-card-text',[_vm._v(" N/A ")])]],2):_vm._e(),_c('v-btn',{staticClass:"w-full w-md-auto ml-md-auto mt-2",attrs:{"color":`${
      _vm.canIApplyVisualSettings
        ? 'gButtonBackgroundColor gButtonFontColor--text'
        : 'primary'
    }`},on:{"click":_vm.submitBooking}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-receipt ")]),_vm._v(" "+_vm._s(_vm.$t('button.make_reservation'))+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }