import { render, staticRenderFns } from "./TableModalForm.vue?vue&type=template&id=671c7776&"
import script from "./TableModalForm.vue?vue&type=script&lang=ts&"
export * from "./TableModalForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports