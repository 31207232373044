import Vue from 'vue'
import Vuex from 'vuex'
import AppModule from '@/store/app/app.module'
import AuthModule from '@/store/auth/auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule
  }
})
