var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"w-full"},[_c('v-card',{staticClass:"tw-max-w-[70%] tw-divide-y tw-p-4 tw-mx-auto",attrs:{"flat":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-3"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-w-full"},[_c('div',[_c('SurveyHeaderTitle',{attrs:{"entityTitle":_vm.companyName}})],1),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('QuestionTitlePill',{attrs:{"question":_vm.$t('page.surveys.placeholders.question'),"type":"bordered"}}),_c('InitialQuestion',{attrs:{"question":_vm.survey.name
            ? _vm.survey.name
            : _vm.$t('page.surveys.placeholders.initial_question'),"info":_vm.$t('page.surveys.placeholders.info')}})],1),_c('div',{staticClass:"tw-mt-5 tw-pt-5"},[_vm._l((_vm.survey.survey_questions),function(entry,index){return [(entry.type === 'textual')?[_c('TextQuestion',{key:'textual_question' + index,attrs:{"question":entry.question
                ? entry.question
                : _vm.$t('page.surveys.placeholders.textual_question')}})]:_vm._e(),(entry.type === 'selection')?[(!_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{key:'radio_question_' + index,staticClass:"tw-mt-5 tw-pt-5"},[_c('div',{staticClass:"tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-items-start"},[_c('Question',{attrs:{"question":entry.question
                      ? entry.question
                      : _vm.$t('page.surveys.placeholders.radio_question')}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-1"},[_c('RadioOption',{attrs:{"name":entry.question,"options":_vm.getOptions(entry)}})],1)])])]:[_c('Question',{key:index,attrs:{"question":entry.question
                  ? entry.question
                  : _vm.$t('page.surveys.placeholders.radio_question')}}),_c('v-select',{key:'selection_question_' + index,staticClass:"tw-w-full tw-px-2 tw-pt-2 customSelect",attrs:{"flat":"","solo":"","placeholder":_vm.$t('page.surveys.placeholders.inner_select'),"outlined":"","color":"#3bbd26","item-color":"green darken-2","dense":"","hide-details":"","items":_vm.getOptions(entry)}})]]:_vm._e(),(entry.type === 'nested')?[_c('QuestionTitlePill',{key:'nexted_question_' + index,attrs:{"question":entry.question
                ? entry.question
                : _vm.$t('page.surveys.placeholders.nested_radio_question'),"type":""}}),_vm._l((entry.children),function(innerQuestion,innerIndex){return [_c('div',{key:'nested_question_' + innerQuestion.question,staticClass:"tw-mt-3"},[(innerQuestion.type === 'textual')?[_c('TextQuestion',{key:'nested_textual_question_' + innerIndex,attrs:{"question":innerQuestion.question
                      ? innerQuestion.question
                      : _vm.$t('page.surveys.placeholders.textual_question')}})]:_vm._e(),(innerQuestion.type === 'selection')?[(!_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{key:'neset_radio_question_' + innerIndex,staticClass:"tw-mt-1 tw-pt-1"},[_c('div',{staticClass:"tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-items-start"},[_c('Question',{attrs:{"question":innerQuestion.question
                            ? innerQuestion.question
                            : _vm.$t(
                              'page.surveys.placeholders.nested_radio_question'
                            )}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-1"},[_c('RadioOption',{attrs:{"name":innerQuestion.question,"options":!innerQuestion.parent_options
                              ? _vm.getOptions(innerQuestion)
                              : _vm.getOptions(entry)}})],1)])])]:[_c('Question',{attrs:{"question":innerQuestion.question
                        ? innerQuestion.question
                        : _vm.$t(
                          'page.surveys.placeholders.nested_radio_question'
                        )}}),_c('v-select',{key:'inner_selection_question_' + innerIndex,staticClass:"tw-w-full tw-px-2 tw-pt-2 customSelect",attrs:{"flat":"","solo":"","placeholder":_vm.$t('page.surveys.placeholders.inner_select'),"outlined":"","color":"#3bbd26","item-color":"green darken-2","dense":"","hide-details":"","items":!innerQuestion.parent_options
                        ? _vm.getOptions(innerQuestion)
                        : _vm.getOptions(entry)}})]]:_vm._e()],2)]})]:_vm._e()]})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }