/* eslint-disable @typescript-eslint/no-explicit-any */

// #region imports

// #region utils
import { format as simpleFormat } from 'date-fns'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
// #endregion

// #region configs
import { configuration } from '@/config'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
// #endregion

// #endregion

// #region constants
export const DEFAULT_TIMEZONE = configuration().defaultTimezone

export const DEFAULT_FORMAT = configuration().defaultFormat

let locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

// #endregion

// Formating server response to client view
// Neper tabela, kara pidha etj
export const defaultIncoming = (
  date: Date | string,
  format = DEFAULT_FORMAT,
  timeZone = DEFAULT_TIMEZONE
): string => {
  setLocale()
  const _date = typeof date === 'string' ? date : new Date(date)
  return formatInTimeZone(_date, timeZone, format, { locale })
}

export const defaultIncomingDateObj = (date: string): Date => {
  return new Date(defaultIncoming(date))
}

// Sending date to server (request to api)
export const defaultOutgoing = (
  date: Date,
  timeZone = DEFAULT_TIMEZONE
): string => {
  return zonedTimeToUtc(date, timeZone).toISOString()
}

// Receiving date from server to calendar (response from api)
// Specifikisht per kalendar, per te hyrat e te dhenave ne kalendar t'karit
// newDateInCompanyTimezone
export const calendarHackDate = (date: Date): Date => {
  return new Date(
    new Date(date).toLocaleString('en-CA', {
      timeZone: DEFAULT_TIMEZONE,
      hour12: false
    })
  )
}

export const calendarHackFormat = (
  date: Date,
  format = DEFAULT_FORMAT
): string => {
  const _hacked = calendarHackDate(date)

  return simpleFormat(_hacked, format)
}

/**
 *
 * @param date
 * @returns a date object in DEFAULT_TIMEZONE (Europe/Oslo in our case). If no date is provided, it will create an instance of current time.
 */
export const dummyDateConverter = (date: string | Date = new Date()): Date => {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: DEFAULT_TIMEZONE
    })
  )
}

export const getLocalTimezoneIANA = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const bookingTimeDuration = (minutes: number): string => {
  if (!minutes) return '0'

  const days = Math.floor(minutes / 1440) // 1440 minutes in a day
  const hours = Math.floor((minutes % 1440) / 60)
  const mins = minutes % 60

  let result = ''

  if (days > 0) {
    const d = i18n.t('common.day').toString().toLowerCase()

    const ds = i18n.t('common.days').toString().toLowerCase()

    result += `${days} ${days > 1 ? ds : d} `
  }

  if (hours > 0) {
    const h = i18n.t('common.hour').toString().toLowerCase()

    const hs = i18n.t('common.hours').toString().toLowerCase()

    result += `${hours} ${hours > 1 ? hs : h} `
  }

  if (mins > 0) {
    const m = i18n.t('common.minute').toString().toLowerCase()

    const ms = i18n.t('common.minutes').toString().toLowerCase()

    result += `${mins} ${mins > 1 ? ms : m}`
  }

  return result.trim()
}

const setLocale = () => {
  if (i18n.locale === 'en-us') {
    locale = enUS
    return
  }

  if (i18n.locale === 'no') {
    locale = nb
  }
}
