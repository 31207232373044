
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region services
import companyService from '@/services/company.service'
import settingService from '@/services/setting.service'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import EmailNotificationSettings from '@/containers/settings/notifications/EmailNotificationSettings.vue'
import SmsNotificationSettings from '@/containers/settings/notifications/SmsNotificationSettings.vue'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    EmailNotificationSettings,
    SmsNotificationSettings
  }
})
export default class NotificationSettings extends Vue {
  rules = rules
  selectedCompany = ''
  companies = [] as CompanyInterface[]
  data = { pagination: 20 }
  tab = 'email_notifications'

  items = [
    {
      key: 'email_notifications',
      label: `${i18n.t('common.email')} ${i18n.t('common.notifications')}`
    },
    {
      key: 'sms_notifications',
      label: `${i18n.t('common.sms')} ${i18n.t('common.notifications')}`
    }
  ]

  async created (): Promise<void> {
    await this.fetchCompanies()
    if (this.companies.length === 1) {
      this.selectedCompany = this.companies[0].id
    }
  }

  async fetchCompanies (): Promise<void> {
    const result = await companyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchSettings (): Promise<void> {
    const result = await settingService.getCompanySettings(this.selectedCompany)
    this.data = result
  }

  @Watch('selectedCompany')
  async onSelectedCompanyChange (): Promise<void> {
    if (this.selectedCompany) {
      await this.fetchSettings()
    }
  }

  setData (result: CompanySettingInterface): void {
    this.data = result
  }
}
