import { getDomain } from './../utils/helper.utils'
// #region imports

// #region axios
import axios, { AxiosError, AxiosInstance } from 'axios'
// #endregion

// #region vue
import Store from '@/store'
import router from '@/router'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region interfaces
import { UserInterface } from '@/types/interfaces/user'
// #endregion

// #endregion

const { baseUrl } = configs

class BaseService {
  get user (): UserInterface | null {
    return Store.getters['auth/getUserData']
  }

  get axios (): AxiosInstance {
    const domain = getDomain()
    const api = axios.create({
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
        'COMPANY-DOMAIN-NAME': domain.toLowerCase(),
        Authorization: this.user ? `Bearer ${this.user.access_token}` : ''
      }
    })

    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!error.response) {
          Store.dispatch('auth/logout')
          Store.dispatch('app/loading', false)
          router.push('/login')
          return Promise.reject(error)
        }

        const status = error.response.status
        if ([404, 403, 500].includes(status)) {
          const message =
            status === 500
              ? 'Your request failed, please try again.'
              : error.response.data.message

          Store.dispatch('app/errorPage', message)
          Store.dispatch('app/loading', false)

          const path = this.getPath(status)
          router.push(path)

          throw Error(`Request failed with status: ${status}`)
        } else if (status === 422) {
          Store.dispatch('app/loading', false)
          Store.dispatch('app/errorNotification', error.response.data.message)

          throw Error(`Request failed with status: ${status}`)
        }

        Store.dispatch('app/loading', false)
        let message = error.message

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message
        }

        Store.dispatch('app/error', {
          message,
          status: status || 401
        })

        throw Error(`Request failed with status: ${status}`)
      }
    )

    return api
  }

  // eslint-disable-next-line
  async goToPage(url: string) {
    Store.dispatch('app/loading', true)
    try {
      const result = await this.axios(url)

      Store.dispatch('app/loading', false)
      return result.data
    } catch (e) {
      const error = e as AxiosError

      Store.dispatch('app/loading', false)

      Store.dispatch('app/error', {
        message: error.message,
        status: error.response?.status || 401
      })
    }
  }

  getPath (status: number): string {
    const routeName = router.currentRoute.name as string
    const params = router.currentRoute.params

    const authRoutes = {
      'auth-signup': 'auth-signup',
      'auth-signin': 'auth-signin',
      'auth-forgot-password': 'auth-forgot-password',
      'company-auth-signin': 'company-auth-signin'
    } as { [key: string]: string }

    if (authRoutes[routeName]) {
      return router.currentRoute.path
    }

    const domain = params.domain

    switch (status) {
      case 404:
        return `${domain ? `/${domain}` : ''}/error/not-found`
      case 422:
        return `${domain ? `/${domain}` : ''}/error/unprocessable-etnry`
      case 403:
        return `${domain ? `/${domain}` : ''}/error/access-denied`
      default:
        return `${domain ? `/${domain}` : ''}/error-page`
    }
  }
}

export default BaseService
