import Clients from '@/views/clients/Clients.vue'
import Client from '@/views/clients/Client.vue'

const clientRoutes = [
  {
    path: '/clients',
    name: 'clients',
    component: Clients,
    meta: {
      auth: true,
      role: ['admin', 'host']
    }
  },
  {
    path: '/client/:id',
    name: 'client',
    component: Client,
    meta: {
      auth: true,
      role: ['admin', 'host']
    }
  }
]

export default clientRoutes
