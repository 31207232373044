
// #region Imports

// vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import DepartmentReservationClientInformation from '@/containers/bookings/DepartmentReservationClientInformation.vue'
import SimpleChipWithTitle from '@/common/components/ui/chips/SimpleChipWithTitle.vue'
import DateFromTo from '@/components/bookings/DateFromTo.vue'

// interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  BookingPropertyInterface,
  ReservationInterface
} from '@/types/interfaces/booking'
import { ClientInterface } from '@/types/interfaces/client'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
import { CreateDepartmentReservationInterface } from '@/types/interfaces/department-reservation'

// helpers & utils
import rules from '@/utils/rules.utils'
import subMinutes from 'date-fns/subMinutes'
import addMinutes from 'date-fns/addMinutes'
import { clone } from 'lodash'
import { defaultOutgoing, dummyDateConverter } from '@/utils/date.utils'
import { addDays, isAfter, isBefore, isEqual } from 'date-fns'

// services
import _companyService from '@/services/company.service'
import _departmentService from '@/services/department.service'
import _departmentReservationService from '@/services/department-reservation.service'

// configs
import { configuration } from '@/config'

// logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
import { enUS, nb } from 'date-fns/locale'

// #endregion

@Component({
  components: {
    DepartmentReservationClientInformation,
    SimpleChipWithTitle,
    DateFromTo
  },
  methods: {
    configuration
  }
})
export default class ReserveBookingHourly extends Vue {
  @Prop() dialog!: boolean

  // data - start
  data = {
    companyId: '',
    departmentId: '',
    date: {
      date: new Date(),
      dateFrom: subMinutes(new Date(), 60),
      dateTo: addMinutes(new Date(), 60),
      datePickerKey: 0
    },
    availableSlots: {
      avaialble: false,
      checked: false
    }
  }

  reservation = {
    company: {
      id: '',
      name: '',
      logo_url: ''
    },
    department: {
      id: '',
      name: '',
      activity_system: '',
      schedule_system: '',
      checkin: '',
      checkout: ''
    },
    rentingEntity: {
      id: ''
    },
    client: {
      id: '',
      name: '',
      email: '',
      phone_number: '',
      country_code: ''
    },
    date_from: new Date(),
    date_to: new Date(),
    note: '',
    number_of_interactors: 0,
    category_id: ''
  } as ReservationInterface

  defaultDataDate = {
    date: new Date(),
    dateFrom: subMinutes(new Date(), 60),
    dateTo: addMinutes(new Date(), 60),
    datePickerKey: 0
  }

  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>

  selectedCompany = {} as CompanyInterface
  selectedDepartment = {} as DepartmentInterface

  rules = rules
  loading = false

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  blockedDates = {
    blockedDatesAttributes: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }

  // data - end

  _config () {
    return configuration()
  }

  // hooks - start
  async created (): Promise<void> {
    this.toggleLoading()
    await this.fetchCompanies()
    this.toggleLoading()
  }
  // hooks - end

  // methods - start
  closeReserveBooking (): void {
    this.$emit('closeReserveBooking')
  }

  toggleLoading (): void {
    this.loading = !this.loading
  }

  setSelectedCompany (): void {
    if (this.companies.length > 0) {
      this.selectedCompany = this.companies.find(
        (x) => x.id === this.data.companyId
      ) as CompanyInterface
    }
  }

  setSelectedDepartment (): void {
    if (this.departments.length > 0) {
      this.selectedDepartment = this.departments.find(
        (x) => x.id === this.data.departmentId
      ) as DepartmentInterface
    }
  }

  onSelectedDateChange (): void {
    const year = this.data.date.date.getFullYear()
    const month = this.data.date.date.getMonth()
    const date = this.data.date.date.getDate()

    this.data.date.dateFrom.setFullYear(year)
    this.data.date.dateFrom.setMonth(month)
    this.data.date.dateFrom.setDate(date)

    this.data.date.dateTo.setFullYear(year)
    this.data.date.dateTo.setMonth(month)
    this.data.date.dateTo.setDate(date)

    this.data.date.datePickerKey++
  }

  ensureDefaultDateNotBlocked (): void {
    if (this.blockedDates.disabledDates.length === 0) {
      this.regulateDates()
      return
    }

    const disableDates = clone(this.blockedDates.disabledDates)

    disableDates.forEach((item) => {
      const tmpStart = dummyDateConverter(item.start)
      const tmpEnd = dummyDateConverter(item.end)
      // CHECKING THE FOLLOWING
      /*
        1. tmpStart-----dateFrom--------------------------------------tmpEnd-------dateTo
        2. dateFrom-----tmpStart---------------------------dateTo------------------tmpEnd
        3. dateFrom-----tmpStart---------------------------tmpEnd------------------dateTo
        4. tmpStart---------------------------dateFrom------dateTo-----------------tmpEnd
      */
      if (
        (this.data.date.dateFrom >= tmpStart &&
          this.data.date.dateFrom <= tmpEnd) ||
        (this.data.date.dateTo >= tmpStart &&
          this.data.date.dateTo <= tmpEnd) ||
        (this.data.date.dateFrom <= tmpStart &&
          this.data.date.dateTo >= tmpEnd) ||
        (this.data.date.dateFrom >= tmpStart && this.data.date.dateTo <= tmpEnd)
      ) {
        this.data.date.date = addDays(this.data.date.date, 1)
        this.data.date.dateFrom = addDays(this.data.date.dateFrom, 1)
        this.data.date.dateTo = addDays(this.data.date.dateTo, 1)
        this.ensureDefaultDateNotBlocked() // <-- recursively calling and adding one day until we find a date that is not blocked
      }
    })
  }

  regulateDates () {
    this.data.date = this.defaultDataDate
  }

  setRelevantData (): void {
    this.reservation.company.id = this.data.companyId
  }

  async checkAndNavigateToClientForm (): Promise<void> {
    const avaialbleDepartment =
      await _departmentReservationService.checkDepartmentAvaialability(
        this.data.departmentId,
        defaultOutgoing(this.data.date.dateFrom),
        defaultOutgoing(this.data.date.dateTo),
        'HOURLY'
      )

    this.data.availableSlots.checked = true
    this.data.availableSlots.avaialble = avaialbleDepartment.result

    if (
      this.data.availableSlots.avaialble &&
      this.data.availableSlots.checked
    ) {
      const checkRenderAndScroll = () => {
        this.$nextTick(() => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const el = this.$refs.simpleChipWithTitleComponentRef as any // this is a vue component element
          if (el) {
            el.$el.scrollIntoView({ behavior: 'smooth' })
          } else {
            setTimeout(checkRenderAndScroll, 100) // Check every 100ms
          }
        })
      }

      setTimeout(checkRenderAndScroll, 400)
    }
  }
  // methods - end

  // fetchings from backend - start
  async fetchCompanies (): Promise<void> {
    this.toggleLoading()
    const result = await _companyService.getActiveWithoutPagination()
    this.companies = result
    this.toggleLoading()
  }

  async fetchDepartments (): Promise<void> {
    this.toggleLoading()
    const result = await _departmentService.getAllCompanyDepartments(
      this.data.companyId,
      'HOURLY'
    )
    this.departments = result
    this.toggleLoading()
  }

  async fetchBlockedDates (blockedDateId: string | null = null): Promise<void> {
    this.blockedDates.blockedDatesAttributes = []
    this.blockedDates.disabledDates = []
    const result = (await getBlockedDatesAsync(
      this.data.companyId,
      this.data.departmentId,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.blockedDates.blockedDatesAttributes = [
      ...this.blockedDates.blockedDatesAttributes,
      ...result.blockedDates
    ]

    this.blockedDates.disabledDates = [
      ...this.blockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  async submit (data: ClientInterface): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const isValid = form.validate()

    if (isValid) {
      const payload = {} as CreateDepartmentReservationInterface

      payload.booking = {
        company_id: this.data.companyId,
        department_id: this.data.departmentId,
        date_from: defaultOutgoing(this.data.date.dateFrom),
        date_to: defaultOutgoing(this.data.date.dateTo),
        number_of_interactors: data.number_of_interactors as number,
        schedule_system: 'HOURLY'
      }

      payload.client = {
        name: data.name,
        email: data.email as string,
        phone_number: data.phone_number,
        country_code: data.country_code
      }

      const bookingProperties = [] as BookingPropertyInterface[] | undefined

      if (data.notes) {
        bookingProperties?.push({
          key: 'notes',
          value: data.notes
        })
      }

      if (data.tags) {
        bookingProperties?.push({
          key: 'tags',
          value: data.tags.value
        })
      }

      payload.booking_properties =
        bookingProperties as BookingPropertyInterface[]

      _departmentReservationService.create(payload)

      this.$toast.success('Cka po di une bravo a!')

      this.$emit('closeReserveBooking')
      this.$emit('submit')
    }
  }
  // fetchings from backend - end

  // watchers - start
  @Watch('data.companyId')
  async onCompanyIdChange (): Promise<void> {
    this.setSelectedCompany()
    this.data.departmentId = ''
    this.data.availableSlots.avaialble = false
    await this.fetchDepartments()
  }

  @Watch('data.departmentId')
  async onDepartmentIdChange (): Promise<void> {
    this.toggleLoading()
    this.setSelectedDepartment()
    this.data.availableSlots.avaialble = false
    await this.fetchBlockedDates()
    this.ensureDefaultDateNotBlocked()
    this.toggleLoading()
  }

  @Watch('data.date.dateFrom')
  onDateFromChange () {
    this.data.availableSlots.avaialble = false
    this.data.availableSlots.checked = false
    if (isEqual(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }

    if (isAfter(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }
  }

  @Watch('data.date.dateTo')
  onDateToChange () {
    this.data.availableSlots.avaialble = false
    this.data.availableSlots.checked = false
    if (isEqual(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }

    if (isBefore(this.data.date.dateTo, this.data.date.dateFrom)) {
      this.data.date.dateFrom = subMinutes(this.data.date.dateTo, 30)
    }
  }
  // watchers - end

  // getters - start
  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // getters - end

  // emmiters - start

  // emmiters - end
}
