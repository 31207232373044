
// #region Imports

// vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import DepartmentReservationClientInformation from '@/containers/bookings/DepartmentReservationClientInformation.vue'
import SimpleChipWithTitle from '@/common/components/ui/chips/SimpleChipWithTitle.vue'
import DateFromTo from '@/components/bookings/DateFromTo.vue'
import DateInfo from '@/common/components/ui/textual/date-info/DateInfo.vue'

// interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  BookingPropertyInterface,
  ReservationInterface
} from '@/types/interfaces/booking'
import { ClientInterface } from '@/types/interfaces/client'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface,
  VCalendarModelConfigInterface
} from '@/types/interfaces/calendar'
import { CreateDepartmentReservationInterface } from '@/types/interfaces/department-reservation'

// helpers & utils
import rules from '@/utils/rules.utils'
import subMinutes from 'date-fns/subMinutes'
import addMinutes from 'date-fns/addMinutes'
import { clone } from 'lodash'
import { defaultOutgoing, dummyDateConverter } from '@/utils/date.utils'
import {
  addDays,
  addHours,
  differenceInDays,
  format,
  isSameDay
} from 'date-fns'

// services
import _companyService from '@/services/company.service'
import _departmentService from '@/services/department.service'
import _departmentReservationService from '@/services/department-reservation.service'

// configs
import { configuration } from '@/config'

// logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
import { enUS, nb } from 'date-fns/locale'

// locales
import i18n from '@/i18n'

// #endregion

@Component({
  components: {
    DepartmentReservationClientInformation,
    SimpleChipWithTitle,
    DateFromTo,
    DateInfo
  },
  methods: {
    configuration
  }
})
export default class ReserveBookingDaily extends Vue {
  @Prop() dialog!: boolean

  // data - start
  data = {
    companyId: '',
    departmentId: '',
    date: {
      range: {
        start: new Date(),
        end: addHours(new Date(), 24)
      },
      masks: {
        input: 'YYYY-MM-DD HH:mm Z'
      }
    },
    availableSlots: {
      avaialble: false,
      checked: false
    },
    expected_arrival: '',
    expected_departure: ''
  }

  dailyDates = {
    range: {
      start: new Date(),
      end: new Date()
    },
    modelConfig: {
      start: { timeAdjust: '12:00:00' },
      end: { timeAdjust: '10:00:00' }
    }
  } as VCalendarModelConfigInterface

  reservation = {
    company: {
      id: '',
      name: '',
      logo_url: ''
    },
    department: {
      id: '',
      name: '',
      activity_system: '',
      schedule_system: '',
      checkin: '',
      checkout: ''
    },
    rentingEntity: {
      id: ''
    },
    client: {
      id: '',
      name: '',
      email: '',
      phone_number: '',
      country_code: ''
    },
    date_from: new Date(),
    date_to: new Date(),
    note: '',
    number_of_interactors: 0,
    category_id: ''
  } as ReservationInterface

  defaultDataDate = {
    date: new Date(),
    dateFrom: subMinutes(new Date(), 60),
    dateTo: addMinutes(new Date(), 60),
    datePickerKey: 0
  }

  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>

  selectedCompany = {} as CompanyInterface
  selectedDepartment = {} as DepartmentInterface

  rules = rules
  loading = false
  readonly dateInfoFormat = 'PPP / H:mm'

  // locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb
  locale = this.currentLanguage === 'en-us' ? enUS : nb
  checkAvailableSlotsDisabled = false
  showClientInformation = false

  blockedDates = {
    blockedDatesAttributes: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }

  // data - end

  _config () {
    return configuration()
  }

  // hooks - start
  async created (): Promise<void> {
    this.toggleLoading()
    await this.fetchCompanies()
    this.toggleLoading()
  }
  // hooks - end

  // methods - start
  closeReserveBooking (): void {
    this.$emit('closeReserveBooking')
  }

  toggleLoading (): void {
    this.loading = !this.loading
  }

  setSelectedCompany (): void {
    if (this.companies.length > 0) {
      this.selectedCompany = this.companies.find(
        (x) => x.id === this.data.companyId
      ) as CompanyInterface
    }
  }

  setSelectedDepartment (): void {
    if (this.departments.length > 0) {
      this.selectedDepartment = this.departments.find(
        (x) => x.id === this.data.departmentId
      ) as DepartmentInterface
    }
  }

  ensureDefaultDateNotBlocked (): void {
    if (this.blockedDates.disabledDates.length === 0) {
      return
    }

    const disableDates = clone(this.blockedDates.disabledDates)

    disableDates.forEach((item) => {
      const tmpStart = dummyDateConverter(item.start)
      const tmpEnd = dummyDateConverter(item.end)
      // CHECKING THE FOLLOWING
      /*
        1. tmpStart-----dateFrom--------------------------------------tmpEnd-------dateTo
        2. dateFrom-----tmpStart---------------------------dateTo------------------tmpEnd
        3. dateFrom-----tmpStart---------------------------tmpEnd------------------dateTo
        4. tmpStart---------------------------dateFrom------dateTo-----------------tmpEnd
      */
      if (
        (this.data.date.range.start >= tmpStart &&
          this.data.date.range.end <= tmpEnd) ||
        (this.data.date.range.end >= tmpStart &&
          this.data.date.range.end <= tmpEnd) ||
        (this.data.date.range.start <= tmpStart &&
          this.data.date.range.end >= tmpEnd) ||
        (this.data.date.range.start >= tmpStart &&
          this.data.date.range.end <= tmpEnd)
      ) {
        this.data.date.range.start = addDays(this.data.date.range.start, 1)
        this.data.date.range.end = addDays(this.data.date.range.end, 1)
        this.ensureDefaultDateNotBlocked() // <-- recursively calling and adding one day until we find a date that is not blocked
      }
    })
  }

  setRelevantData (): void {
    this.reservation.company.id = this.data.companyId
  }

  formatDateInfo (date: Date | string): string {
    return format(dummyDateConverter(date), this.dateInfoFormat, {
      locale: this.locale
    })
  }

  scrollToElement (elementRef: string): void {
    const checkRenderAndScroll = () => {
      this.$nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const el = this.$refs[elementRef] as any // this is a vue component element
        if (el) {
          el.$el.scrollIntoView({ behavior: 'smooth' })
        } else {
          setTimeout(checkRenderAndScroll, 100)
        }
      })
    }
    setTimeout(checkRenderAndScroll, 400)
  }

  resetDailyDates (): void {
    this.dailyDates.range = {
      ...this.dailyDates.range,
      start: new Date(),
      end: new Date()
    }
  }
  // methods - end

  // fetchings from backend - start
  async fetchCompanies (): Promise<void> {
    this.toggleLoading()
    const result = await _companyService.getActiveWithoutPagination()
    this.companies = result
    this.toggleLoading()
  }

  async fetchDepartments (): Promise<void> {
    this.toggleLoading()
    const result = await _departmentService.getAllCompanyDepartments(
      this.data.companyId,
      'DAILY'
    )
    this.departments = result
    this.toggleLoading()
  }

  async checkAndNavigateToClientForm (): Promise<void> {
    const avaialbleDepartment =
      await _departmentReservationService.checkDepartmentAvaialability(
        this.data.departmentId,
        defaultOutgoing(this.data.date.range.start),
        defaultOutgoing(this.data.date.range.end),
        'DAILY'
      )

    this.data.availableSlots.checked = true
    this.data.availableSlots.avaialble = avaialbleDepartment.result

    if (
      this.data.availableSlots.avaialble &&
      this.data.availableSlots.checked
    ) {
      this.scrollToElement('4_client_information')
      this.checkAvailableSlotsDisabled = true
      this.showClientInformation = true
    }
  }

  async fetchBlockedDates (blockedDateId: string | null = null): Promise<void> {
    this.blockedDates.blockedDatesAttributes = []
    this.blockedDates.disabledDates = []
    const result = (await getBlockedDatesAsync(
      this.data.companyId,
      this.data.departmentId,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.blockedDates.blockedDatesAttributes = [
      ...this.blockedDates.blockedDatesAttributes,
      ...result.blockedDates
    ]

    this.blockedDates.disabledDates = [
      ...this.blockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  async submit (data: ClientInterface): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const isValid = form.validate()

    if (isValid) {
      const payload = {} as CreateDepartmentReservationInterface

      payload.booking = {
        company_id: this.data.companyId,
        department_id: this.data.departmentId,
        date_from: defaultOutgoing(this.data.date.range.start),
        date_to: defaultOutgoing(this.data.date.range.end),
        number_of_interactors: data.number_of_interactors as number,
        schedule_system: 'DAILY'
      }

      payload.client = {
        name: data.name,
        email: data.email as string,
        phone_number: data.phone_number,
        country_code: data.country_code
      }

      const bookingProperties = [] as BookingPropertyInterface[] | undefined

      if (data.notes) {
        bookingProperties?.push({
          key: 'notes',
          value: data.notes
        })
      }

      if (data.tags) {
        bookingProperties?.push({
          key: 'tags',
          value: data.tags.value
        })
      }

      payload.booking_properties =
        bookingProperties as BookingPropertyInterface[]

      _departmentReservationService.create(payload)

      this.$emit('closeReserveBooking')
      this.$emit('submit')
    }
  }
  // fetchings from backend - end

  // watchers - start
  @Watch('data.companyId')
  async onCompanyIdChange (): Promise<void> {
    this.setSelectedCompany()
    this.data.departmentId = ''
    this.data.availableSlots.avaialble = false
    this.resetDailyDates()
    await this.fetchDepartments()
  }

  @Watch('data.departmentId')
  async onDepartmentIdChange (): Promise<void> {
    this.toggleLoading()
    this.setSelectedDepartment()
    this.dailyDates.modelConfig.start.timeAdjust = this.checkinTime
    this.dailyDates.modelConfig.end.timeAdjust = this.checkoutTime
    this.data.availableSlots.avaialble = false
    this.resetDailyDates()
    await this.fetchBlockedDates()
    this.ensureDefaultDateNotBlocked()
    this.scrollToElement('3_pick_date_time')
    this.toggleLoading()
  }

  @Watch('dailyDates.range')
  onDailyDatesRangeChanged () {
    if (
      isSameDay(
        this.dailyDates.range.start as Date,
        this.dailyDates.range.end as Date
      )
    ) {
      this.dailyDates.range.end = addDays(this.dailyDates.range.end as Date, 1)
    }

    this.data.date.range.start = this.dailyDates.range.start as Date
    this.data.date.range.end = this.dailyDates.range.end as Date
    this.checkAvailableSlotsDisabled = false
    this.showClientInformation = false
  }
  // watchers - end

  // getters - start
  get currentLanguage (): string {
    return this.$i18n.locale
  }

  get checkinTime (): string {
    if (this.selectedDepartment && this.selectedDepartment.checkin) {
      if (typeof this.selectedDepartment.checkin === 'string') {
        return this.selectedDepartment.checkin
      } else {
        return this.selectedDepartment.checkin.value
      }
    } else {
      return this.data.expected_arrival
    }
  }

  get checkoutTime (): string {
    if (this.selectedDepartment && this.selectedDepartment.checkout) {
      if (typeof this.selectedDepartment.checkout === 'string') {
        return this.selectedDepartment.checkout
      } else {
        return this.selectedDepartment.checkout.value
      }
    } else {
      return this.data.expected_departure
    }
  }

  get deltaDays (): number {
    return (
      Math.abs(
        differenceInDays(
          this.dailyDates.range.start as Date,
          this.dailyDates.range.end as Date
        )
      ) + 1
    )
  }

  get formattedTotalDays (): string {
    return this.deltaDays > 1
      ? this.deltaDays + ' ' + i18n.t('common.days')
      : this.deltaDays + ' ' + i18n.t('common.day')
  }

  // getters - end

  // emmiters - start

  // emmiters - end
}
