
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #endregion

@Component
export default class MoreThanOneCompany extends Vue {
  @Prop({ required: true }) readonly items!: CompanyInterface[]
}
