// #region imports

// #region interfaces
import {
  ResetPasswordInterface,
  AuthErrorInterface,
  DemoRequestInterface
} from '@/types/interfaces/auth'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

const { baseUrl } = configs

class AuthService extends BaseService {
  public error?: AuthErrorInterface
  public basepath = baseUrl

  async signIn (email: string, password: string, company = 'demo') {
    Store.dispatch('app/loading', true)

    try {
      const result = await this.axios.post(
        '/login',
        {
          data: {
            email,
            password
          }
        },
        { headers: { 'COMPANY-DOMAIN-NAME': company.toLowerCase() } }
      )

      Store.dispatch('auth/login', result.data.data)
      Store.dispatch('app/loading', false)
      return true
    } catch (error) {
      Store.dispatch('app/loading', false)
      return false
    }
  }

  async forgotPassword (email: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.post('/forgot-password', {
      data: {
        email
      }
    })

    Store.dispatch('app/loading', false)
    return result.data
  }

  async checkTokenExpiration (token: string) {
    Store.dispatch('app/loading', true)

    const url = '/check-token-expiration'

    const result = await this.axios.post(url, {
      data: {
        token
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async resetPassword (data: ResetPasswordInterface) {
    Store.dispatch('app/loading', true)

    const url = 'reset-password'

    const result = await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async demoRequest (data: DemoRequestInterface) {
    Store.dispatch('app/loading', true)

    await this.axios.post('/demo-request', {
      data
    })

    Store.dispatch('app/loading', false)
  }

  async checkDomain (slug: string) {
    Store.dispatch('app/loading', true)

    await this.axios.post('/check-domain', {
      slug
    })

    Store.dispatch('app/loading', false)
  }
}

export default new AuthService()
