
// #region imports

// #region vue
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
// #endregion

// #region components
import DailyBookingDurationSettings from '@/containers/settings/duration/DailyBookingDurationSettings.vue'
import HourlyBookingDurationSettings from '@/containers/settings/duration/HourlyBookingDurationSettings.vue'
// #endregion

// #region interfaces
import {
  BookingDurationSettingInterface,
  CompanySettingInterface
} from '@/types/interfaces/setting'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #endregion

@Component({
  components: { DailyBookingDurationSettings, HourlyBookingDurationSettings }
})
export default class BookingDurationSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  rules = rules

  saved = false

  async bookingDurationSettingSubmit (): Promise<void> {
    this.saved = true
    const defaultValue = this.bookingDefaultDuration as number
    await settingService.updateDefaultBookingDurations(
      +defaultValue,
      this.companyId
    )
  }

  @Watch('bookingDefaultDuration')
  onBookingDefaultDurationChange (): void {
    if (this.bookingDefaultDuration) {
      this.expandedBookingDurationSettings = false
    }
  }

  expandedBookingDurationSettings = false

  bookingDefaultDuration = null as null | number

  toggleBookingDurationSettings (): void {
    if (this.bookingDefaultDuration) {
      this.expandedBookingDurationSettings =
        !this.expandedBookingDurationSettings
    }
  }

  created (): void {
    this.setDefaultBookingDuration()
  }

  onChange (): void {
    this.saved = false
  }

  setDefaultBookingDuration (): void {
    const setting = parseJson(
      this.settings.booking_duration
    ) as BookingDurationSettingInterface
    if (setting) {
      this.bookingDefaultDuration = setting.default
      this.saved = true
    }
  }
}
