
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import MoreThanOneCompany from '@/components/companies/MoreThanOneCompany.vue'
// #endregion

// #region interfaces
import {
  ClientInterface,
  ClientTableItemInterface
} from '@/types/interfaces/client'

import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import ClientService from '@/services/client.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region utils
import { getAppTitle } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component({
  components: { Table, DefaultTableActions }
})
export default class Clients extends Vue {
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('common.email'),
      value: 'custom_render',
      key: 'email',
      render: Vue.component('Email', {
        props: ['item'],
        template: '<span class="tw-text-red-400">{{item}}</span>'
      })
    },
    {
      text: i18n.t('table.col.phone_number'),
      keys: ['country_code', 'phone_number'],
      value: 'custom_render',
      render: Vue.component('PhoneNumber', {
        props: ['items'],
        template:
          '<span class="tw-text-red-400">({{items[0]}}) {{items[1]}}</span>'
      })
    },
    {
      text: i18n.t('table.col.company'),
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'companies',
      render: MoreThanOneCompany
    },
    { text: i18n.t('table.title.bookings'), value: 'bookings_count' },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  clients: Array<ClientInterface> = []
  pagination = {} as PaginationInterface
  searching = ''

  async handleClientsFetch (): Promise<void> {
    const result = await ClientService.getAll()
    this.setData(result)
  }

  async handleClientsSearchFetch (
    searchString: string,
    page = 1
  ): Promise<void> {
    const result = await ClientService.filter(searchString, page)
    this.setData(result)
  }

  async created (): Promise<void> {
    document.title = getAppTitle('Clients')
    await this.handleClientsFetch()
  }

  dialog = false
  client: ClientInterface | null = null

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleClientsFetch()
      return
    }

    this.searching = query
    this.handleClientsSearchFetch(query)
  }

  viewItem (row: ClientTableItemInterface): void {
    this.$router.push(`client/${row.item.id}`)
  }

  toggleDialog (): void {
    this.dialog = !this.dialog
  }

  resetItem (): void {
    this.client = null
  }

  async nextPage (): Promise<void> {
    if (this.searching) {
      await this.handleClientsSearchFetch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await ClientService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (this.searching) {
      await this.handleClientsSearchFetch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await ClientService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (this.searching) {
      await this.handleClientsSearchFetch(this.searching, number)
      return
    }

    const result = await ClientService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.clients = result.clients.data
    delete result.clients.data
    this.pagination = result.clients
  }
}
