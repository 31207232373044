
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import CardButtons from '@/common/components/ui/card/CardButtons.vue'
import CardButton from '@/common/components/ui/card/CardButton.vue'
import BookCompanyCard from '@/containers/bookings/BookCompanyCard.vue'
import BookDepartmentCard from '@/containers/bookings/BookDepartmentCard.vue'
import AutoBookingOverview from '@/components/bookings/AutoBookingOverview.vue'
import Tags from '@/components/bookings/Tags.vue'
import DateInfo from '@/common/components/ui/textual/date-info/DateInfo.vue'
import DateFromTo from '@/components/bookings/DateFromTo.vue'
import ManualModeHourly from '@/containers/bookings/hourly/ManualModeHourly.vue'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'

import {
  BookingPropertyInterface,
  AutoBookingTransferDataInterface,
  CreateBookingInterface
} from '@/types/interfaces/booking'

import { ClientInterface } from '@/types/interfaces/client'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
import {
  defaultIncoming,
  defaultIncomingDateObj,
  dummyDateConverter
} from '@/utils/date.utils'
// #endregion

// #region utils
import {
  addMinutes,
  format,
  differenceInDays,
  addDays,
  isAfter,
  isBefore,
  subMinutes,
  isEqual
} from 'date-fns'
import moment from 'moment'
import { jsonToString } from '@/utils/helper.utils'
import { sanitizePhoneNumber } from '@/utils/sanitation.utils'
// #endregion

// #region assets
import countryPrefixes from '@/assets/CountryPrefix'
// #endregion

// #region services
import bookingService from '@/services/booking.service'
import settingService from '@/services/setting.service'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
// #endregion

// #region configs
import { configuration } from '@/config'
// #endregion

// #region logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
// #endregion

// #endregion

@Component({
  components: {
    CardButtons,
    CardButton,
    BookCompanyCard,
    BookDepartmentCard,
    AutoBookingOverview,
    Tags,
    DateInfo,
    DateFromTo,
    ManualModeHourly
  },
  methods: {
    format,
    differenceInDays,
    defaultIncoming,
    dummyDateConverter,
    configuration,
    sanitizePhoneNumber
  }
})
export default class AdminBookingManagementAuto extends Vue {
  @Prop() readonly dialog!: boolean
  @Prop({ default: '' }) readonly itemId!: string

  @Prop({ default: '', required: true }) readonly rentingEntityId!: string
  @Prop({ required: true }) readonly dateFrom!: string

  @Prop({ default: 'ADD' }) readonly state!: string

  prefixes = countryPrefixes
  deleteDialog = false
  step = 1
  readonly dateInfoFormat = 'PPP / H:mm'

  valid = false
  rules = rules

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  company = {} as CompanyInterface
  department = {} as DepartmentInterface
  rentingEntity = {} as RentingEntityInterface
  availableHOurs = []
  maxNumberOfInteractors = 0
  interactorSeries = [] as Array<number>
  disableSubmit = false
  tags = []

  daily = {
    date: moment(new Date()),
    selectedDateRange: {} as { start: Date; end: Date },
    disabledDates: [] as DateRangeInterface[],
    disabledDatesAttribute: [] as VCalendarAttributeInterface[],
    modelConfig: {
      start: { timeAdjust: '12:00:00' },
      end: { timeAdjust: '10:00:00' }
    },
    renderMiniCalendar: false
  }

  notes = { key: 'notes', value: '' }
  selectedTags = { key: 'tags', value: [] }

  data = {
    company_id: '',
    company_name: '',
    department_id: '',
    department_name: '',
    renting_entity_id: '',
    renting_entity_name: '',
    number_of_interactors: 0,
    date_from: '',
    date_to: '',
    schedule_system: '',
    client: {
      name: '',
      phone_number: '',
      email: '',
      country_code: '+47'
    } as ClientInterface,
    properties: [this.notes, this.selectedTags],
    expected_arrival: '',
    expected_departure: '',
    manualSelectedDate: {
      date: new Date(),
      from: addMinutes(new Date(), 30),
      to: addMinutes(new Date(), 60),
      datePickerKey: 0
    }
  } as CreateBookingInterface

  showModelData = {
    company_id: '',
    company: {
      name: ''
    },
    department_id: '',
    department: {
      name: '',
      schedule_system: '',
      activity_system: '',
      checkin: {
        value: ''
      },
      checkout: {
        value: ''
      }
    },
    renting_entity_id: '',
    date_from: 0,
    date_to: 0,
    name: '',
    number_of_interactors: 0
  } as AutoBookingTransferDataInterface

  async created (): Promise<void> {
    this.setLocale()
    await this.fetchRentingEntityRelatedData()
    await this.fetchTags()
    this.fillData()

    this.regulateManualSelectedDate()

    if (this.showModelData.department.schedule_system === 'HOURLY') {
      await this.fetchAvailableHours()
    } else {
      this.fetchAvaialableDates()
      await this.fetchDisabledDates()
    }
  }

  async fetchRentingEntityRelatedData (): Promise<void> {
    const result = await bookingService.getRentingEntityRelatedData(
      this.rentingEntityId
    )
    this.showModelData = { ...this.showModelData, ...result }
  }

  async fetchTags (): Promise<void> {
    const result = await settingService.getValuesPublic(
      this.showModelData.company_id,
      'tags'
    )
    this.tags = result.value
  }

  async fetchAvailableHours (): Promise<void> {
    if (
      this.showModelData.department.activity_system === 'REQUESTS_ONLY' &&
      this.data.manualSelectedDate
    ) {
      const result = await bookingService.getAvaialbleHoursForARentingEntity(
        this.rentingEntityId,
        this.data.manualSelectedDate.from.toISOString(),
        this.data.manualSelectedDate.to.toISOString()
      )

      if (result.data.available_hours === null) {
        this.data.manualSelectedDate.info = 'NO_SLOTS_AVAILABLE'
        this.disableSubmit = true
      } else {
        this.data.manualSelectedDate.info = ''
        this.disableSubmit = false
      }
    }

    if (this.showModelData.department.activity_system === 'BOOKING') {
      const result = await bookingService.getAvaialbleHoursForARentingEntity(
        this.rentingEntityId,
        this.data.date_from
      )

      if (result.data.available_hours !== null) {
        this.data.date_to = result.data.available_hours
      } else {
        this.disableSubmit = true
        // this.data.date_to = i18n.t('error.no_slots').toString()
      }
    }
  }

  regulateManualSelectedDate (): void {
    this.data.manualSelectedDate = {
      ...this.data.manualSelectedDate,
      datePickerKey: 0,
      date: defaultIncomingDateObj(this.dateFrom),
      from: new Date(this.dateFrom),
      to: addMinutes(new Date(this.dateFrom), 60)
    }
  }

  fetchAvaialableDates (): void {
    this.daily.selectedDateRange.start = new Date()
  }

  async fetchDisabledDates (): Promise<void> {
    const result = await bookingService.getDisabledDatesForARentingEntity(
      this.rentingEntityId
    )

    this.daily.disabledDates = [...result.disabled_dates]
    this.setDisabledDatesAttribute()
    this.fetchAndSetBlockedDates()
    this.daily.renderMiniCalendar = true
  }

  // This sets the attributes for dates that already have bookings
  setDisabledDatesAttribute = (): void => {
    const tmpAttribute = {
      highlight: {
        color: 'red',
        fillMode: 'solid'
      },
      dates: this.daily.disabledDates,
      popover: {
        label: i18n.t('error.no_slots').toString()
      }
    } as VCalendarAttributeInterface

    this.daily.disabledDatesAttribute.push(tmpAttribute)
  }

  async fetchAndSetBlockedDates (): Promise<void> {
    const result = (await getBlockedDatesAsync(
      this.showModelData.company_id as string,
      this.showModelData.department_id as string,
      null,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.daily.disabledDatesAttribute = [
      ...this.daily.disabledDatesAttribute,
      ...result.blockedDates
    ]

    this.daily.disabledDates = [
      ...this.daily.disabledDates,
      ...result.disabledDates
    ]
  }

  toggleTag (indx: number): void {
    if (this.data.properties) {
      const tempTag = this.tags[indx]
      const tags = this.data.properties.find((item) => item.key === 'tags')
      const localIndx = tags?.value.indexOf(tempTag)

      const properites = this.data.properties.filter(
        (item) => item.key !== 'tags'
      )

      const updatedTags = this.data.properties.find(
        (item) => item.key === 'tags'
      )

      if (updatedTags) {
        if (localIndx === -1) {
          updatedTags.value.push(tempTag)
        } else {
          updatedTags.value.splice(localIndx, 1)
        }
        properites.push(updatedTags)
      }

      this.data.properties = [...properites]
    }
  }

  fillData (): void {
    this.data = {
      ...this.data,
      company_id: this.showModelData.company_id,
      company_name: this.showModelData.company.name,
      department_id: this.showModelData.department_id as string,
      department_name: this.showModelData.department.name,
      renting_entity_id: this.rentingEntityId,
      renting_entity_name: this.showModelData.name,
      date_from: this.dateFrom,
      number_of_interactors: this.showModelData.number_of_interactors,
      schedule_system: this.showModelData.department.schedule_system
    }

    this.daily.modelConfig = {
      ...this.daily.modelConfig,
      start: {
        timeAdjust: this.showModelData.department.checkin?.value as string
      },
      end: {
        timeAdjust: this.showModelData.department.checkout?.value as string
      }
    }

    this.maxNumberOfInteractors = this.data.number_of_interactors
    this.generateInteractorsSeries()
  }

  generateInteractorsSeries (): void {
    for (let i = 1 as number; i <= this.maxNumberOfInteractors; i++) {
      this.interactorSeries.push(i)
    }
    this.data.number_of_interactors = 1
  }

  getText (data: { name: string; code: string }): string {
    return `${data.name} (${data.code})`
  }

  pickedDate (date: string): void {
    this.daily.date = moment(date)
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      if (
        this.showModelData.department.schedule_system === 'DAILY' &&
        this.data.date_to === ''
      ) {
        this.$toast.warning(
          i18n.t('component.book.select_a_date_range').toString()
        )
        return
      }

      const properties = this.data.properties
        ? (this.data.properties.filter(
            (property: BookingPropertyInterface) => property.value !== ''
          ) as BookingPropertyInterface[])
        : []

      let payload = {
        booking: { ...this.data },
        client: this.data.client,
        booking_properties: [] as BookingPropertyInterface[] | undefined
      }

      if (properties.length > 0) {
        properties.forEach((item: BookingPropertyInterface) => {
          if (item.key === 'tags') {
            const tmpBookingProperty = jsonToString(item.value)
            if (tmpBookingProperty !== false) {
              item.value = tmpBookingProperty
            }
          }
        })
        payload = { ...payload, booking_properties: properties }
      } else {
        delete payload.booking_properties
      }

      if (this.showModelData.department.activity_system === 'BOOKING') {
        await bookingService.create(payload)
        this.$toast.success(
          i18n.t('notify.success.booking_added_successfully').toString()
        )
        this.$emit('submit')
        return
      }

      if (this.showModelData.department.activity_system === 'REQUESTS_ONLY') {
        payload.booking.renting_entity_id = ''
        delete payload.booking.renting_entity

        if (this.showModelData.department.schedule_system === 'HOURLY') {
          if (this.data.manualSelectedDate) {
            payload.booking.date_from =
              this.data.manualSelectedDate.from.toISOString()
            payload.booking.date_to =
              this.data.manualSelectedDate.to.toISOString()
          }
          await bookingService.createRequest(payload)
          this.$toast.success(
            i18n.t('notify.success.booking_added_successfully').toString()
          )
          this.$emit('submit')
        }

        if (this.showModelData.department.schedule_system === 'DAILY') {
          await bookingService.createRequest(payload)
          this.$toast.success(
            i18n.t('notify.success.booking_added_successfully').toString()
          )
          this.$emit('submit')
        }
      }
    }
    this.$emit('close')
  }

  // #region Watchers
  @Watch('daily.selectedDateRange')
  setBookingRange (): void {
    if (
      this.daily.selectedDateRange.start.toLocaleDateString() ===
      this.daily.selectedDateRange.end.toLocaleDateString()
    ) {
      this.daily.selectedDateRange.end = addDays(
        this.daily.selectedDateRange.end,
        1
      )
    }
    this.data = {
      ...this.data,
      date_from: this.daily.selectedDateRange.start.toISOString(),
      date_to: this.daily.selectedDateRange.end.toISOString()
    }
  }

  @Watch('data.manualSelectedDate.from')
  async onManualSelectedDateFromChange (): Promise<void> {
    if (
      this.data.manualSelectedDate &&
      this.showModelData.department.activity_system === 'REQUESTS_ONLY'
    ) {
      if (
        isEqual(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }

      if (
        isAfter(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }
      await this.fetchAvailableHours()
    }
  }

  @Watch('data.manualSelectedDate.to')
  async onManualSelectedDateToChange (): Promise<void> {
    if (
      this.data.manualSelectedDate &&
      this.showModelData.department.activity_system === 'REQUESTS_ONLY'
    ) {
      if (
        isEqual(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }

      if (
        isBefore(
          this.data.manualSelectedDate.to,
          this.data.manualSelectedDate.from
        )
      ) {
        this.data.manualSelectedDate.from = subMinutes(
          this.data.manualSelectedDate.to,
          10
        )
      }
      await this.fetchAvailableHours()
    }
  }
  // #endregion

  // #region computed propertis
  get deltaDays (): number {
    return (
      Math.abs(
        differenceInDays(
          this.daily.selectedDateRange.start,
          this.daily.selectedDateRange.end
        )
      ) + 1
    )
  }

  get formattedTotalDays (): string {
    return this.deltaDays > 1
      ? this.deltaDays + ' ' + i18n.t('common.days')
      : this.deltaDays + ' ' + i18n.t('common.day')
  }

  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // #endregion

  // #region setters
  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }
  // #endregion
}
