
// #region imports
import { Vue, Component, Prop } from 'vue-property-decorator'

// #endregion
@Component
export default class SimpleChipWithTitle extends Vue {
  @Prop({ default: 'Title' }) readonly title!: string
  @Prop({ default: false }) readonly divider!: boolean
  @Prop({ default: 1 }) readonly number!: number
  @Prop({ default: false }) readonly fullfilled!: boolean

  // computed - start
  get color (): string {
    return this.fullfilled ? 'info' : ''
  }
  // computed - end
}
