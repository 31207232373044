
import i18n from '@/i18n'
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class QuestionTitlePill extends Vue {
  @Prop({
    required: true,
    default: () => i18n.t('page.surveys.labels.question')
  })
  readonly question!: string

  @Prop({ default: 'normal' }) readonly type!: 'normal' | 'grey' | 'bordered'
}
