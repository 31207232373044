
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'
// #endregion

// #region interfaces
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #region servcies
import RentingEntityService from '@/services/renting-entity.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard
  }
})
export default class RentingEntity extends Vue {
  rentingEntity = {} as RentingEntityInterface

  async fetchRentingEntity (): Promise<RentingEntityInterface> {
    return await RentingEntityService.getSingle(this.$route.params.id)
  }

  created (): void {
    this.fetchRentingEntity().then((entity) => {
      this.rentingEntity = entity
      this.handleChartData(entity)
    })
  }

  handleChartData (entity: RentingEntityInterface): void {
    this.series = [
      {
        name: i18n.t('table.title.bookings').toString(),
        data: entity.bookings_count_by_day || []
      }
    ]

    this.chartOptions.title.text = entity.bookings_count || 1
  }

  series = [
    {
      name: i18n.t('table.title.bookings').toString(),
      data: this.rentingEntity.bookings_count_by_day || []
    }
  ]

  get bookingCount (): number {
    return this.rentingEntity.bookings_count || 0
  }

  chartOptions = {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      type: 'datetime'
    },
    colors: ['#DCE6EC'],
    title: {
      text: this.bookingCount,
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: i18n.t('table.title.bookings'),
      offsetX: 0,
      style: {
        fontSize: '14px'
      }
    }
  }

  viewGrandParent (): void {
    this.$router.push(`/company/${this.rentingEntity.company_id}`)
  }

  viewParent (): void {
    this.$router.push(`/department/${this.rentingEntity.department_id}`)
  }
}
