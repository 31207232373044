
// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import ChoiceCard from '@/common/components/ui/card/ChoiceCard.vue'

// enums
import { ReservationTypes } from '@/types/enums/booking'

// interfaces
import { ChoiceCardPropogateClickInterface } from '@/types/interfaces/booking'

@Component({
  components: {
    ChoiceCard
  }
})
export default class UberDialogHourly extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean

  ReservationTypes = ReservationTypes
  readonly translationKey = 'page.bookings.reservation_type_choices'

  public close () {
    this.$emit('UberDialogCloseEvent', false)
  }

  cardClickedEventListener (
    propogationTransferObject: ChoiceCardPropogateClickInterface
  ): void {
    this.$emit('ReservationChoiceMadeEvent', propogationTransferObject)
  }
}
