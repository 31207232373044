import Bookings from '@/views/bookings/Bookings.vue'
import Booking from '@/views/bookings/Booking.vue'
import Book from '@/views/bookings/Book.vue'
import BookTest from '@/views/bookings/BookTest.vue'

const bookingRoutes = [
  {
    path: '/bookings',
    name: 'bookings',
    component: Bookings,
    meta: {
      auth: true
    }
  },
  {
    path: '/booking/:id',
    name: 'booking',
    component: Booking,
    meta: {
      auth: true
    }
  },
  {
    path: '/:domain/book',
    name: 'book',
    component: Book,
    meta: {
      layout: 'empty',
      auth: false
    }
  },
  {
    path: '/:domain/book-test',
    name: 'book-test',
    component: BookTest,
    meta: {
      layout: 'empty',
      auth: false
    }
  }
]

export default bookingRoutes
