export const IMAGE_SOURCES = {
  FLAG_NORWEGIAN: require('@/assets/images/flags/no.png'),
  FLAG_ENGLISH: require('@/assets/images/flags/en.png'),
  DEFAULT_LOGO: require('@/assets/frontline_logo.png')
}

export const LINKS = {
  FRONTLINE_WEBSITE: 'https://www.frontlinepos.no/'
}

export const LANGUAGE_LOCALES = {
  ENGLISH_US: 'en-us',
  NORWEGIAN: 'no'
}
