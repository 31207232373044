// #region imports

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
import { InteractorInterface } from '@/types/interfaces/interactors'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

class InteractorsService extends BaseService {
  public error?: ApiCallErrorInterface

  async getInteractors (bookingId: string) {
    Store.dispatch('app/loading', true)

    const URL = `/interactors/${bookingId}`

    const RESULT = await this.axios(URL)

    Store.dispatch('app/loading', false)

    return RESULT.data.interactors
  }

  async getPaginatedInteractors (bookingId: string) {
    Store.dispatch('app/loading', true)

    const URL = `/interactors-paginated/${bookingId}`

    const RESULT = await this.axios(URL)

    Store.dispatch('app/loading', false)

    return RESULT.data
  }

  async deleteInteractor (bookingId: string, interactorId: string) {
    Store.dispatch('app/loading', true)

    const URL = `/interactors/${bookingId}/${interactorId}`

    const RESULT = await this.axios.delete(URL)

    Store.dispatch('app/loading', false)

    return RESULT.data
  }

  async create (bookingId: string, payload: InteractorInterface) {
    Store.dispatch('app/loading', true)

    const URL = `/interactors/${bookingId}`

    const RESULT = await this.axios.post(URL, {
      data: payload
    })

    Store.dispatch('app/loading', false)

    return RESULT.data
  }

  async update (
    bookingId: string,
    interactorId: string,
    payload: InteractorInterface
  ) {
    Store.dispatch('app/loading', true)

    const URL = `/interactors/${bookingId}/${interactorId}`

    const RESULT = await this.axios.put(URL, {
      data: payload
    })

    Store.dispatch('app/loading', false)

    return RESULT.data
  }
}

export default new InteractorsService()
