
// vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// interfaces
import { CreateBookingInterface } from '@/types/interfaces/booking'
import {
  AvaialbleRentingEntitiesAtDateRangeResponseInterface,
  AvaialbleRentingEntityInterface,
  SendThroughDataInterface
} from '@/types/interfaces/manual-booking'

// components
import AutoBookingOverview from '@/components/bookings/AutoBookingOverview.vue'

// configs
import { configuration } from '@/config'

// utils
import { addMinutes, isAfter, isEqual, subMinutes } from 'date-fns'

// services
import _manualBookingService from '@/services/manual-booking-service'

@Component({
  name: 'ManualModeHourly',
  components: {
    AutoBookingOverview
  },
  methods: {
    configuration
  }
})
export default class ManualModeHourly extends Vue {
  @Prop({ required: true }) readonly rentingEntityId!: string
  @Prop({ required: true }) readonly item!: CreateBookingInterface

  data = {
    date: {
      date_from: subMinutes(new Date(), 30),
      date_to: addMinutes(new Date(), 30)
    }
  }

  avaialbleRentingEntitiesAtDateRange =
    {} as AvaialbleRentingEntitiesAtDateRangeResponseInterface

  selectedRentingEntities = {
    list: [] as AvaialbleRentingEntityInterface[],
    sum: 0
  }

  avaialbleRentingEntities = {
    avaialble: false
  }

  _config () {
    return configuration()
  }

  // #region backend methods
  async getAvaialableRentingEntitiesAtDateRange (): Promise<void> {
    const result =
      await _manualBookingService.getAvaialableRentingEntitiesAtDateRange(
        this.item.department_id,
        this.item.schedule_system as string,
        this.data.date.date_from,
        this.data.date.date_to
      )

    this.avaialbleRentingEntitiesAtDateRange = result.result

    await this.scrollToElement(result.result)
  }
  // #endregion

  // #region methods
  async scrollToElement (
    input: AvaialbleRentingEntitiesAtDateRangeResponseInterface
  ): Promise<void> {
    this.avaialbleRentingEntities.avaialble =
      input.total_number_of_interactors > 0

    if (this.avaialbleRentingEntities.avaialble) {
      await this.$nextTick()
      const element = document.getElementById('g-renting-entities')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  formatRentingEntityName (
    rentingEntity: AvaialbleRentingEntityInterface
  ): string {
    return `${rentingEntity.name} (${rentingEntity.number_of_interactors})`
  }

  clearData (): void {
    this.selectedRentingEntities.list = []
    this.avaialbleRentingEntitiesAtDateRange =
      {} as AvaialbleRentingEntitiesAtDateRangeResponseInterface
    this.avaialbleRentingEntities.avaialble = false
  }
  // #endregion

  // #region Watchers
  @Watch('data.date.date_from')
  onDateFromChanged () {
    if (isAfter(this.data.date.date_from, this.data.date.date_to)) {
      this.data.date.date_to = addMinutes(this.data.date.date_from, 30)
    }

    if (isEqual(this.data.date.date_from, this.data.date.date_to)) {
      this.data.date.date_to = addMinutes(this.data.date.date_to, 15)
      this.data.date.date_from = subMinutes(this.data.date.date_from, 15)
    }

    this.clearData()
  }

  @Watch('data.date.date_to')
  onDateToChanged () {
    if (isAfter(this.data.date.date_from, this.data.date.date_to)) {
      this.data.date.date_from = subMinutes(this.data.date.date_to, 30)
    }

    if (isEqual(this.data.date.date_from, this.data.date.date_to)) {
      this.data.date.date_to = addMinutes(this.data.date.date_to, 15)
      this.data.date.date_from = subMinutes(this.data.date.date_from, 15)
    }

    this.clearData()
  }

  @Watch('selectedRentingEntities.list')
  onSelectedRentingEntitiesChanged () {
    const sum = this.selectedRentingEntities.list.reduce(
      (sum, item) => sum + item.number_of_interactors,
      0
    )
    this.selectedRentingEntities.sum = sum

    // emti event
    this.$emit('rentingEntitiesSelectedEvent', {
      rentingEntities: this.selectedRentingEntities.list.map((item) => {
        return item.id as string
      }),
      numberOfInteractors: this.selectedRentingEntities.sum,
      dates: this.data.date
    } as SendThroughDataInterface)
  }
  // #endregion

  // #region
  get progressColorGradient (): [string, number] {
    const sum = this.selectedRentingEntities.sum
    const total =
      this.avaialbleRentingEntitiesAtDateRange.total_number_of_interactors

    const percentage = (sum * 100) / total

    switch (true) {
      case percentage > 0 && percentage < 20:
        return ['teal lighten-1', percentage]
      case percentage >= 20 && percentage < 40:
        return ['teal darken-1', percentage]
      case percentage >= 40 && percentage < 60:
        return ['teal darken-2', percentage]
      case percentage >= 60 && percentage < 80:
        return ['teal darken-3', percentage]
      case percentage >= 80 && percentage <= 100:
        return ['teal darken-4', percentage]
      default:
        return ['grey', percentage]
    }
  }

  get interactorRatio (): string {
    return `${this.selectedRentingEntities.sum}/${this.avaialbleRentingEntitiesAtDateRange.total_number_of_interactors}`
  }

  // #endregion
}
