// #region imports

// services start
import _blockedDateService from '@/services/blocked-dates.service'
// services end

// interfaces start
import {
  BlockedDatesInterface,
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
// interfaces end

// #endregion

// #region constants
const LANGUAGE_EN = 'en-us'
// #endregion

/***
 * @description get blocked dates from backend and return them in a format that can be used by v-calendar
 * @param companyId
 * @param departmentId
 * @param blockedDateId
 * @param currentLanguage
 * @return {Promise<BlockedDatesSeriesInterface>}
 */
export const getBlockedDatesAsync = async (
  companyId: string,
  departmentId: string,
  blockedDateId: string | null = null,
  currentLanguage: string
): Promise<BlockedDatesSeriesInterface> => {
  const output = {
    blockedDates: [] as Array<VCalendarAttributeInterface>,
    disabledDates: [] as Array<DateRangeInterface>
  } as BlockedDatesSeriesInterface

  // interacting with backend
  const result = await _blockedDateService.getBlockedDatesSeries(
    companyId,
    departmentId,
    blockedDateId
  )

  output.blockedDates = result.blocked_dates.map(
    (item: BlockedDatesInterface) => {
      return {
        highlight: item.highlight,
        dates: item.dates,
        popover: {
          label:
            currentLanguage === LANGUAGE_EN
              ? item.description_en
              : item.description_no
        }
      }
    }
  )

  output.blockedDates.forEach((item: VCalendarAttributeInterface) => {
    output.disabledDates = [
      ...output.disabledDates,
      ...(item.dates as DateRangeInterface[])
    ]
  })

  return output
}
