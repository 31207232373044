
import i18n from '@/i18n'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageOverview extends Vue {
  @Prop({ default: () => i18n.t('page.overview.title') })
  readonly title!: string

  @Prop() readonly date!: string
  @Prop({ default: 'my-1' }) readonly className!: string
}
