
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #region

// #region components
import DeleteDialog from '@/common/components/modals/DeleteDialog.vue'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #endregion

@Component({
  components: { DeleteDialog }
})
export default class DeleteCompany extends Vue {
  @Prop({ required: true }) readonly entityTitle!: string
  @Prop({ default: null }) readonly item!: CompanyInterface | null
  @Prop({ required: true }) readonly dialogHanlder!: () => void
}
