
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region i18n
import i18n from '@/i18n'
// #endregion

// #region utils
import { clone } from 'lodash'
import rules from '@/utils/rules.utils'
import { ColorPickerPropagationInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component({ name: 'ColorPicker' })
export default class ColorPicker extends Vue {
  @Prop({ required: true, default: 'Title' }) readonly title!: string
  @Prop({ required: false, default: 'Description' })
  readonly description!: string

  @Prop({ required: true })
  readonly settingsKey!:
    | 'main_background_color'
    | 'cards_background_color'
    | 'cards_border_color'
    | 'font_color'
    | 'selectors_border_color'
    | 'dates_background_color_past'
    | 'dates_background_color_present'
    | 'dates_background_color_future'
    | 'buttons_background_color'
    | 'buttons_font_color'
    | 'buttons_border_color'

  @Prop({ required: true }) readonly color!: string

  data = {
    color: '',
    defaultColor: '#ffffffff',
    enabled: false
  }

  rules = rules

  created (): void {
    this.data.color = clone(this.color)
  }

  resetColorToDefault (): void {
    this.data.color = this.data.defaultColor
  }

  toggleEnabled (): void {
    this.data.enabled = !this.data.enabled
  }

  // #region getters
  get chipData (): string {
    return this.data.enabled
      ? i18n.t('common.enabled').toString().toUpperCase()
      : i18n.t('common.disabled').toString().toUpperCase()
  }

  // #endregion

  // backend
  submit (): void {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      const colorPickerPropagationObject: ColorPickerPropagationInterface = {
        color: this.data.color,
        settings_key: this.settingsKey
      }
      this.$emit('colorPickerSubmitEvent', colorPickerPropagationObject)
    }
  }
}
