
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import AddEditDepartment from '@/containers/departments/AddEditDepartment.vue'
import ScheduleSystem from '@/components/departments/ScheduleSystem.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import { AddressInterface } from '@/types/interfaces/address'
import {
  DepartmentInterface,
  DepartmentTableItemInterface
} from '@/types/interfaces/department'
// #endregion

// #region services
import DepartmentService from '@/services/department.service'
import CompanyService from '@/services/company.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { Table, AddEditDepartment, DefaultTableActions, ScheduleSystem }
})
export default class ActiveDepartments extends Vue {
  @Prop({ required: true }) readonly tab!: string

  dialog = false
  deleteDialog = false
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      value: 'custom_render',
      render: Vue.component('Name', {
        props: ['item'],
        template: `
        <h5 v-if="item.activity_system === 'REQUESTS_ONLY'">
          {{item.name}}  
          <i class="mdi mdi-bookmark-off tw-text-md"></i>
        </h5> 
        <h5 v-else>
          {{item.name}}  
          <i class="mdi mdi-bookmark tw-text-md"></i>
        </h5>
          `
      })
    },
    {
      text: i18n.t('table.col.description'),
      value: 'description'
    },
    {
      text: i18n.t('table.col.company'),
      value: 'company.name'
    },
    { text: i18n.t('table.col.address'), value: 'address.address' },
    {
      text: i18n.t('table.col.renting_entities'),
      value: 'custom_render',
      key: 'renting_entities_count',
      render: Vue.component('RentingEntitiesCount', {
        props: ['item'],
        template: `
            <v-chip label outlined color="blue darken-1" small v-if="item > 0" >{{item}}</v-chip>
          <v-chip label outlined color="deep-orange lighten-3" small v-else>{{item}}</v-chip>
          `
      }),
      sortable: false
    },
    {
      text: i18n.t('table.col.schedule_system'),
      value: 'custom_render',
      key: 'schedule_system',
      render: ScheduleSystem
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  departments: Array<DepartmentInterface> = []
  pagination = {} as PaginationInterface
  addresses: Array<AddressInterface> = []
  loading = false

  searching = ''

  async handleDepartmentsFetch (): Promise<void> {
    const result = await DepartmentService.getAll()
    this.setData(result)
  }

  async handleDepartmentsSearchFetch (
    searchText: string,
    page = 1
  ): Promise<void> {
    const result = await DepartmentService.filter(searchText, page)
    this.setData(result)
  }

  async handleAddressesFetch (): Promise<void> {
    const result = await CompanyService.getAddresses()
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleDepartmentsFetch()
  }

  department: DepartmentInterface | null = null

  editItem (row: DepartmentTableItemInterface): void {
    this.department = this.departments[row.index]
    this.toggleDialog()
  }

  async deleteItem (): Promise<void> {
    const departmentId = this.department !== null ? this.department.id : ''

    await DepartmentService.delete(departmentId)

    this.$toast.success(i18n.t('notify.success.departament_deleted').toString())

    this.handleDepartmentsFetch()

    this.deleteDialog = false
  }

  viewItem (row: DepartmentTableItemInterface): void {
    this.$router.push(`department/${row.item.id}`)
  }

  initDeleteDepartment (row: DepartmentTableItemInterface): void {
    this.deleteDialog = true
    this.department = this.departments[row.index]
  }

  cancelDialog (): void {
    this.deleteDialog = false
    this.department = null
  }

  @Watch('dialog')
  onDialogChange (): void {
    if (!this.dialog) {
      this.department = null
    }
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'activeDepartments') {
      this.handleDepartmentsFetch()
    }
  }

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.department = null
    }
  }

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleDepartmentsFetch()
      return
    }

    this.searching = query
    this.handleDepartmentsSearchFetch(query)
  }

  async nextPage (): Promise<void> {
    if (this.searching) {
      await this.handleDepartmentsSearchFetch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await DepartmentService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (this.searching) {
      await this.handleDepartmentsSearchFetch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await DepartmentService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (this.searching) {
      await this.handleDepartmentsSearchFetch(this.searching, number)
      return
    }

    const result = await DepartmentService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.loading = true

    this.departments = result.departments.data
    delete result.departments.data
    this.pagination = result.departments

    this.loading = false
  }
}
