
// #region imports
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// Vue instantiates the component
@Component
export default class DocumentationNavItems extends Vue {
  items = [
    {
      title: 'Get Started',
      icon: 'mdi-format-letter-starts-with',
      to: '#get-started'
    },
    {
      title: 'Application workflow',
      icon: 'mdi-rocket-launch',
      to: '#application-workflow'
    },
    { title: 'Calendar', icon: 'mdi-calendar', to: '#calendar' },
    { title: 'Company', icon: 'mdi-book', to: '#company' },
    { title: 'Department', icon: 'mdi-store', to: '#department' },
    {
      title: 'Renting Entity',
      icon: 'mdi-ceiling-light',
      to: '#renting-entity'
    },
    { title: 'Booking', icon: 'mdi-receipt', to: '#booking' },
    { title: 'Category', icon: 'mdi-comma-box', to: '#category' },
    { title: 'Survey', icon: 'mdi-test-tube', to: '#survey' },
    { title: 'Report', icon: 'mdi-file-chart', to: '#report' },
    { title: 'Settings', icon: 'mdi-cog', to: '#settings' }
  ]
}
