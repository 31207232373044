
// #region imports

// #region vue
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

// Vue instantiates the component
@Component
export default class DocTemplateCard extends Vue {
  @Prop({ required: true, default: 'Section Title' }) title!: string
}
