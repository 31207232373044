
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableModalActions extends Vue {
  @Prop({ required: false, default: true }) readonly defaultAction!: boolean
  @Prop({ required: false, default: false }) readonly disabled!: boolean

  onCancel (): void {
    this.$emit('dialogHandler')
  }

  onSubmit (): void {
    this.$emit('submit')
    if (this.defaultAction) {
      this.onCancel()
    }
  }
}
