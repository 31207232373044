var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`tw-mt-2 tw-py-4 tw-justify-items-end tw-font-bold ${
    _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
  }`},[(_vm.chip)?[_c('OneLinerChip',{attrs:{"title":_vm.checkinTitle,"subtitle":_vm.checkinDate,"icon":"mdi-account-arrow-down","color":_vm.canIApplyVisualSettings
          ? 'gCardBackgroundColor gFontColor--text'
          : 'blue lighten-4'}}),_c('OneLinerChip',{attrs:{"title":_vm.checkoutTitle,"subtitle":_vm.checkoutDate,"icon":"mdi-account-arrow-up","color":_vm.canIApplyVisualSettings
          ? 'gCardBackgroundColor gFontColor--text'
          : 'orange lighten-4'}}),_c('OneLinerChip',{attrs:{"title":_vm.totalDays,"separator":"","color":_vm.canIApplyVisualSettings ? 'gCardBackgroundColor gFontColor--text' : 'primary'}})]:[_c('OneLiner',{attrs:{"title":_vm.checkinTitle,"subtitle":_vm.checkinDate}}),_c('OneLiner',{attrs:{"title":_vm.checkoutTitle,"subtitle":_vm.checkoutDate}}),_c('OneLiner',{attrs:{"title":_vm.totalDays,"separator":""}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }