
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import SurveyStatus from '@/components/surveys/SurveyStatus.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'

import {
  SurveyInterface,
  SurveyTableItemInterface
} from '@/types/interfaces/survey'
// #endregion

// #region services
import SurveyService from '@/services/survey.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { Table }
})
export default class ActiveSurveys extends Vue {
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('table.col.status'),
      value: 'custom_render',
      render: SurveyStatus
    },
    { text: i18n.t('table.col.company'), value: 'company.name' },
    {
      text: i18n.t('page.surveys.table.total_questions'),
      value: 'questions_count'
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  surveys: Array<SurveyInterface> = []
  pagination = {} as PaginationInterface

  async handleDepartmentsFetch (): Promise<void> {
    const result = await SurveyService.getAll()
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleDepartmentsFetch()
  }

  viewItem (row: SurveyTableItemInterface): void {
    this.$router.push(`survey/${row.item.id}`)
  }

  createSurvey (): void {
    this.$router.push('/survey-create')
  }

  async nextPage (): Promise<void> {
    const result = await SurveyService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await SurveyService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await SurveyService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.surveys = result.surveys.data
    delete result.surveys.data
    this.pagination = result.surveys
  }

  async deactivate (row: SurveyTableItemInterface): Promise<void> {
    const surveyId = row.item.id

    await SurveyService.deactivateSurvey(surveyId as number)

    this.$toast.success(i18n.t('notify.success.survey_deleted').toString())

    this.handleDepartmentsFetch()
  }
}
