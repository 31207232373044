/**
 * Remove all whitespace characters (including spaces, tabs, and line breaks)
 * @param value string
 * @returns sanitized value in string
 */
export const sanitizePhoneNumber = (value: string): string => {
  if (!value) return ''

  const sanitizedValue = value.replace(/\s/g, '')

  return sanitizedValue
}
