// #region imports

// #region interfaces
import {
  StaffMemberInterface,
  CreateOrUpdateStaffMember,
  StaffMemberCreateResponseInterface
} from '@/types/interfaces/staff'

import { ProfileInterface, RoleInterface } from '@/types/interfaces/user'

import {
  ApiCallErrorInterface,
  PasswordChangeInterface
} from '@/types/interfaces/setting'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

const { baseUrl } = configs

class StaffService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll () {
    Store.dispatch('app/loading', true)

    const url = `${baseUrl}/users`
    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/users-search?page=${page}`

    const result = await this.axios(url, { params: { searchText } })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string): Promise<StaffMemberInterface> {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/user/${id}`)

    Store.dispatch('app/loading', false)

    return result.data?.user
  }

  // Create Staff Member
  async createStaffMember (
    data: CreateOrUpdateStaffMember
  ): Promise<StaffMemberCreateResponseInterface> {
    Store.dispatch('app/loading', true)

    // data.role = data.role_name

    const result = await this.axios.post('/user', { data: { user: data } })

    Store.dispatch('app/loading', false)

    return result.data.user
  }

  async updateStaffMember (data: CreateOrUpdateStaffMember): Promise<void> {
    Store.dispatch('app/loading', true)

    const { id } = data

    const result = await this.axios.put(`/user/${id}`, { data: { user: data } })

    Store.dispatch('app/loading', false)

    return result.data.user
  }

  async delete (id: string | undefined) {
    Store.dispatch('app/loading', true)

    const url = `/user/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result
  }

  // Fetch Roles
  async getRoles (): Promise<Array<RoleInterface>> {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/roles/get-all-unpaginated')

    Store.dispatch('app/loading', false)

    return result.data.roles
  }

  async getProfile (): Promise<ProfileInterface> {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/profile')

    Store.dispatch('app/loading', false)

    return result.data.profile
  }

  async updateProfile (data: ProfileInterface): Promise<void> {
    Store.dispatch('app/loading', true)

    const { id } = data

    const result = await this.axios.put(`/user/${id}`, { data: { user: data } })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async changePassword (data: PasswordChangeInterface): Promise<void> {
    Store.dispatch('app/loading', true)

    const result = await this.axios.patch('/user/change-password', { data })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getUserByDepartmentAndRole (
    departmentId: string,
    role: string,
    userId: string
  ) {
    Store.dispatch('app/loading', true)

    const url = '/users-by-department-and-role/'

    const result = await this.axios(url, {
      params: { departmentId, role, userId }
    })

    Store.dispatch('app/loading', false)

    return result.data.users
  }
}

export default new StaffService()
