
// #region imports

// #region vue
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { FireListResponseInterface } from '@/types/interfaces/report'
// #endregion

// #region components
// #endregion

// #region locales
// import i18n from '@/i18n'
// #endregion

// #region utils
import { defaultIncoming } from '@/utils/date.utils'
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  name: 'FilteredPrintableBookings',
  methods: {
    defaultIncoming
  }
})
export default class FirelistPrintableInteractors extends Vue {
  // #region hooks
  created (): void {
    const firelistString = localStorage.getItem('firelist')

    if (firelistString) {
      const firelist = JSON.parse(firelistString)
      this.data.firelist = firelist

      setTimeout(() => {
        window.print()
      }, 500)
    }
  }

  // #endregion

  data = {
    firelist: [] as FireListResponseInterface[]
  }

  headers = [
    '#',
    i18n.t('table.col.full_name').toString().toUpperCase(),
    i18n.t('table.col.renting_entity_name').toString().toUpperCase(),
    i18n.t('table.col.checkin_time').toString().toUpperCase(),
    i18n.t('table.col.department_name').toString().toUpperCase(),
    i18n.t('table.col.department_address').toString().toUpperCase(),
    i18n.t('table.col.country_of_residence').toString().toUpperCase()
  ]
}
