
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageOverviewItem extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ default: false }) readonly clickable!: boolean
  @Prop() readonly clicked!: () => void

  click (): void {
    if (this.clickable) {
      this.clicked?.()
    }
  }
}
