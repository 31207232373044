
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
import departmentService from '@/services/department.service'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region utils & helpers
import moment from 'moment'
import { format, differenceInDays, addDays } from 'date-fns'
import {
  fromISODateStringToDateOnlyString,
  parseJson
} from '@/utils/helper.utils'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region components
import DateInfo from '@/common/components/ui/textual/date-info/DateInfo.vue'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
import {
  KeyValuSettingsInterface,
  OverallBookingInformationInterface,
  PublicBookingCustomVisualSettingsInterface
} from '@/types/interfaces/setting'
// #endregion

// #endregion

interface DateRange {
  start: Date
  end: Date
}

@Component({
  methods: { format },
  components: {
    DateInfo
  }
})
export default class BookDailyPublicDateCard extends Vue {
  @Prop({ required: true }) readonly company!: CompanyInterface
  @Prop({ required: true }) readonly departmentId!: string
  @Prop({ required: true }) readonly selectedDepartment!: DepartmentInterface
  @Prop({ required: false, default: '' }) readonly rentingEntityId!: string
  @Prop({ default: i18n.t('common.next') })
  readonly nextButtonText!: string

  @Prop({ default: false })
  readonly visualSettings!: PublicBookingCustomVisualSettingsInterface

  // #region fields

  rules = rules

  selectedDate = new Date()
  dailyBookingData: DateRange = {} as DateRange

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  disabledDates: DateRange[] = []
  availableHours = [] as Array<string>
  valid = false
  department = {} as DepartmentInterface
  moreThanEightDialog = {
    dialog: false,
    initalState: true
  }

  userRole = ''

  data = {
    number_of_interactors: 1,
    time: '',
    date: moment(new Date()),
    formattedDates: {
      dateFrom: '',
      dateTo: ''
    },
    expected_departure: '10:00',
    expected_arrival: '12:00'
  }

  dailyBlockedDates = {
    blockedDates: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }
  // #endregion

  // #region methods
  injectInputsCss (): void {
    // v-input__slot
    const vInput = document.querySelectorAll('div.v-input__slot')
    for (let i = 0; i < vInput.length; i++) {
      const element = vInput[i] as HTMLElement

      // set elements color style and make it important:
      element.style.background = 'var(--v-gCardBackgroundColor-base)'
      element.style.stroke = 'var(--v-gGeneralElementsBackgroundColor-base)'
    }

    const vInputNumberPart = document.querySelectorAll('input')
    for (let i = 0; i < vInputNumberPart.length; i++) {
      const element = vInputNumberPart[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    const vInputIconPart = document.querySelectorAll('i')
    for (let i = 0; i < vInputIconPart.length; i++) {
      const element = vInputIconPart[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    // after selection
    const labelSelected = document.querySelectorAll('div.v-select__selections')
    for (let i = 0; i < labelSelected.length; i++) {
      const element = labelSelected[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    // after selection
    const hint = document.querySelectorAll('div.v-select__selections')
    for (let i = 0; i < hint.length; i++) {
      const element = hint[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    // after selection
    const label = document.querySelectorAll('label')
    for (let i = 0; i < label.length; i++) {
      const element = label[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }
  }

  injectCustomCss (): void {
    if (this.canIApplyVisualSettings) {
      this.injectInputsCss()
    }
  }

  // #region vue hooks
  async created (): Promise<void> {
    this.setLocale()
    await this.fetchDepartment()
    await this.fetchDailyBlockedDates()
    this.setUserRole()

    this.$nextTick(() => {
      this.injectCustomCss()
    })
  }
  // #endregion

  // #region backend interaction methods

  async fetchDepartment (): Promise<void> {
    this.department = await departmentService.getSinglePublic(this.departmentId)
  }

  /**
   * Checks if there are avaialble booking slots for the given data
   * @var
   */
  async checkAvaialbleBookingSlots (): Promise<boolean> {
    return await BookingService.checkAvaialbleBookingSlots({
      dateFrom: fromISODateStringToDateOnlyString(this.dailyBookingData.start),
      dateTo: fromISODateStringToDateOnlyString(this.dailyBookingData.end),
      departmentId: this.departmentId,
      numberOfInteractors: this.data.number_of_interactors,
      rentingEntityId: this.rentingEntityId
    })
  }

  async fetchDailyBlockedDates (): Promise<void> {
    const result = (await getBlockedDatesAsync(
      this.company.id,
      this.departmentId,
      null,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.dailyBlockedDates.blockedDates = [
      ...this.dailyBlockedDates.blockedDates,
      ...result.blockedDates
    ]

    this.dailyBlockedDates.disabledDates = [
      ...this.dailyBlockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      if (!this.dailyBookingData.start && !this.dailyBookingData.start) {
        this.$toast.warning(
          i18n.t('component.book.select_a_date_range').toString()
        )
        return
      }

      // check if there are any avaialble slots for the given date & number of interactors
      if (this.department.allow_categories === false) {
        const avaialbleSlots: boolean = await this.checkAvaialbleBookingSlots()

        if (avaialbleSlots === false) {
          this.$toast.warning(
            i18n.t('component.book.daily_no_available_slots').toString()
          )
          return
        }
      }

      if (this.department.activity_system === 'BOOKING') {
        this.$emit('dateInformationAddedDaily', {
          formattedDates: {
            dateFrom: format(
              this.dailyBookingData.start,
              'EEEE, MMMM do, yyyy'
            ),
            dateTo: format(this.dailyBookingData.end, 'EEEE, MMMM do, yyyy')
          },
          number_of_interactors: this.data.number_of_interactors,
          date_from: this.dailyBookingData.start,
          date_to: this.dailyBookingData.end,
          department: this.department,
          expected_departure: this.data.expected_departure,
          expected_arrival: this.data.expected_arrival,
          date_info: {
            total: this.formattedTotalDays
          }
        })
        return
      }

      if (this.department.activity_system === 'REQUESTS_ONLY') {
        this.$emit('dateInformationAddedDaily', {
          formattedDates: {
            dateFrom: format(
              this.dailyBookingData.start,
              'EEEE, MMMM do, yyyy'
            ),
            dateTo: format(this.dailyBookingData.end, 'EEEE, MMMM do, yyyy')
          },
          number_of_interactors: this.data.number_of_interactors,
          date_from: this.dailyBookingData.start,
          date_to: this.dailyBookingData.end,
          department: this.department,
          expected_departure: this.data.expected_departure,
          expected_arrival: this.data.expected_arrival,
          date_info: {
            total: this.formattedTotalDays
          }
        })
      }
    }
  }
  // #endregion

  // #region local methods
  setUserRole (): void {
    const userData = this.$store.state.auth.user
    if (userData) {
      this.userRole = userData.role
    }
  }

  resetTime (): void {
    this.data.time = ''
  }

  pickedDate (date: string): void {
    this.data.date = moment(date)
  }

  @Watch('dailyBookingData')
  setBookingRange (): void {
    if (
      this.dailyBookingData.start.toLocaleDateString() ===
      this.dailyBookingData.end.toLocaleDateString()
    ) {
      this.dailyBookingData.end = addDays(this.dailyBookingData.end, 1)
    }
  }
  // #endregion

  // #endregion

  // #region computed properties
  get showMoreThanEightDialog (): boolean {
    if (!this.userRole && this.data.number_of_interactors) {
      return true
    }

    return false
  }

  get deltaDays (): number {
    return Math.abs(
      differenceInDays(this.dailyBookingData.start, this.dailyBookingData.end)
    )
  }

  get formattedTotalDays (): string {
    return this.deltaDays > 1
      ? this.deltaDays + ' ' + i18n.t('common.days')
      : this.deltaDays + ' ' + i18n.t('common.day')
  }

  get currentLanguage (): string {
    return this.$i18n.locale
  }

  get overallBookingInformation (): string {
    const overallBookingInformationSetting = this.company.settings?.find(
      (item: KeyValuSettingsInterface) =>
        item.key === 'overall_booking_information'
    )

    if (overallBookingInformationSetting) {
      const parsed: OverallBookingInformationInterface = parseJson(
        overallBookingInformationSetting.value
      )
      return this.currentLanguage === 'no'
        ? parsed.overall_booking_information_no
        : parsed.overall_booking_information_en
    }

    return ''
  }

  get checkinTime (): string {
    if (this.selectedDepartment && this.selectedDepartment.checkin) {
      if (typeof this.selectedDepartment.checkin === 'string') {
        return this.selectedDepartment.checkin
      } else {
        return this.selectedDepartment.checkin.value
      }
    } else {
      return this.data.expected_arrival
    }
  }

  get checkoutTime (): string {
    if (this.selectedDepartment && this.selectedDepartment.checkout) {
      if (typeof this.selectedDepartment.checkout === 'string') {
        return this.selectedDepartment.checkout
      } else {
        return this.selectedDepartment.checkout.value
      }
    } else {
      return this.data.expected_departure
    }
  }

  get currentUser (): string {
    return this.$store.state.auth.user?.id
  }

  get isLoggedIn (): boolean {
    return !!this.$store.state.auth.user.name
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettings.active && !this.isLoggedIn
  }
  // #endregion

  // #region setters
  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }
  // #endregion
}
