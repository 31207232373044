
import { Component, Vue } from 'vue-property-decorator'
import InformationCard from '@/common/components/ui/card/InformationCard.vue'
import { mapState } from 'vuex'
import companyService from '@/services/company.service'
import store from '@/store'

@Component({
  components: { InformationCard },
  computed: { ...mapState('auth', ['user']) }
})
export default class EnableAccount extends Vue {
  user = store.getters['auth/getUserData']
  accountState = store.getters['auth/getAccountState']

  data = {
    companies: 0,
    departments: 0,
    rentingEntities: 0,
    bookings: 0,
    clients: 0,
    staff: 0,
    surveys: 0
  }

  async created (): Promise<void> {
    this.data = await companyService.getEnableAccountCounters()
  }

  async approve (): Promise<void> {
    await companyService.enableAccount(this.user.domain)

    this.accountState.isDemo = false
    store.commit('auth/setAccountState', this.accountState)

    this.$router.push('/')
  }
}
