
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewDescription from '@/common/components/pages/PageOverviewDescription.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'
// #endregion

// #region interfaces
import { StaffMemberInterface } from '@/types/interfaces/staff'
// #endregion

// #region services
import StaffService from '@/services/staff.service'
// #endregion
// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewDescription,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard
  }
})
export default class StaffMember extends Vue {
  staffMember = {} as StaffMemberInterface

  async handleStaffMemeberFetch (): Promise<StaffMemberInterface> {
    return await StaffService.getSingle(this.$route.params.id)
  }

  async created (): Promise<void> {
    const result = await this.handleStaffMemeberFetch()
    this.staffMember = result
  }

  toUpperCase (data: string): string {
    return data ? data.toUpperCase() : data
  }

  get phone (): string {
    return this.staffMember.phone_number
      ? `${this.staffMember.country_code} ${this.staffMember.phone_number}`
      : 'N/A'
  }
}
