
// #region imports

// region vue
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  name: 'MainPrice'
})
export default class MainPrice extends Vue {
  // region hooks

  // #endregion
  hover = 'hover:tw-bg-zinc-900 hover:tw-text-sky-700 tw-transition-all tw-duration-300'
  table = [
    {
      name: i18n.t('page.pricing.table.data.package1.name'),
      price: i18n.t('page.pricing.table.data.package1.price'),
      description: i18n.t('page.pricing.table.data.package1.description'),
      color: `tw-text-zinc-500 ${this.hover}`
    },
    {
      name: i18n.t('page.pricing.table.data.package2.name'),
      price: i18n.t('page.pricing.table.data.package2.price'),
      description: i18n.t('page.pricing.table.data.package2.description'),
      color: `tw-text-zinc-600 ${this.hover}`
    },
    {
      name: i18n.t('page.pricing.table.data.package3.name'),
      price: i18n.t('page.pricing.table.data.package3.price'),
      description: i18n.t('page.pricing.table.data.package3.description'),
      color: `tw-text-zinc-700 ${this.hover}`
    }
  ]

  // #region methods

  // #endregion
}
