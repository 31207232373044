
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
import {
  ReportInterface,
  ReportTableItemInterface
} from '@/types/interfaces/report'

import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import GenerateReport from '@/containers/reports/GenerateReport.vue'
import ReportTimeFrame from '@/components/reports/ReportTimeFrame.vue'
import SimpleChipForTables from '@/common/components/ui/chips/SimpleChipForTables.vue'
// #endregion

// #region services
import ReportService from '@/services/report.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region utils
import { getAppTitle } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component({ components: { Table, GenerateReport, SimpleChipForTables } })
export default class Reports extends Vue {
  dialog = false
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('common.timeframe'),
      key: 'start',
      value: 'custom_render',
      keys: ['date_from', 'date_to'],
      render: ReportTimeFrame,
      sortable: false
    },
    {
      text: i18n.t('common.version'),
      value: 'custom_render',
      keys: 'version',
      sortable: false,
      render: SimpleChipForTables,
      align: 'center'
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  reports: Array<ReportInterface> = []
  pagination = {} as PaginationInterface
  searching = ''

  async handleReportsFetch (): Promise<void> {
    const result = await ReportService.getAll()
    this.setData(result)
  }

  async handleReportsSearchFetch (searchText: string, page = 1): Promise<void> {
    const result = await ReportService.filter(searchText, page)
    this.setData(result)
  }

  async created (): Promise<void> {
    document.title = getAppTitle('Reports')
    await this.handleReportsFetch()
  }

  downloadItem (row: ReportInterface, type: string): void {
    ReportService.downloadItem(row, type)
  }

  async deleteItem (row: ReportTableItemInterface): Promise<void> {
    await ReportService.delete(row.id)
    this.reports = this.reports.filter((item) => item.id !== row.id)
  }

  toggleDialog (): void {
    this.dialog = !this.dialog
  }

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleReportsFetch()
      return
    }

    this.searching = query
    this.handleReportsSearchFetch(query)
  }

  async nextPage (): Promise<void> {
    const result = await ReportService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await ReportService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await ReportService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.reports = result.reports.data
    delete result.reports.data
    this.pagination = result.reports
  }
}
