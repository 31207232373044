
import { Component, Vue } from 'vue-property-decorator'
import ActiveDepartments from '@/views/departments/_ActiveDepartments.vue'
import ArchivedDepartments from '@/views/departments/_ArchivedDepartments.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({ components: { ActiveDepartments, ArchivedDepartments } })
export default class Departments extends Vue {
  tab = 'activeDepartments'

  items = [
    { key: 'activeDepartments', label: i18n.t('page.departaments.active') },
    { key: 'archivedDepartments', label: i18n.t('page.departaments.archived') }
  ]

  created (): void {
    document.title = getAppTitle('Departments')
  }
}
