
// #region imports

// #region vue
import { Prop, Vue, Component } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class TooltipPills extends Vue {
  @Prop({ required: true }) readonly name!: string
  @Prop({ required: true }) readonly items!: unknown
  @Prop({ required: false }) readonly pillColor!: string
}
