
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { BookingPropertyInterface } from '@/types/interfaces/booking'
// #endregion

// #region utils
import { parseJson } from '@/utils/helper.utils'
import { SettingsInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component({ methods: { parseJson } })
export default class Tags extends Vue {
  @Prop({ required: true }) readonly tags!: BookingPropertyInterface
  @Prop({ required: true }) readonly properties!: BookingPropertyInterface[]
  @Prop({ default: false }) readonly notClickable!: boolean
  @Prop({ default: '', required: false })
  readonly companySettings!: SettingsInterface[]

  @Prop({ default: () => [] }) readonly selectedTags!: BookingPropertyInterface
  @Prop({ required: false }) readonly toggleTag!: () => void
  @Prop({ required: false, default: false })
  readonly visualSettingsActive!: boolean

  hasPropertyTag (item: string): boolean {
    if (this.properties) {
      const tag = this.properties.find((item) => item.key === 'tags')
      const index =
        tag && Array.isArray(tag.value)
          ? tag.value.findIndex((i) => i === item)
          : -1
      return index > -1
    }

    return false
  }

  tagDescription = ''

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _parseJson (value: string): any {
    return parseJson(value)
  }

  created (): void {
    if (!Array.isArray(this.companySettings)) {
      return
    }

    const data = this.companySettings.find(
      (item) => item.key === 'tags_description'
    )

    if (data) {
      const langKey =
        this.$i18n.locale === 'en-us'
          ? 'tag_description_en'
          : 'tag_description_no'

      const tagDescriptions = JSON.parse(data.value)

      this.tagDescription = tagDescriptions[langKey]
    }
  }

  // #region computed
  get isLoggedIn (): boolean {
    const userData = this.$store.state.auth.user

    if (userData.name) {
      return true
    }

    return false
  }

  get tagsColor (): string {
    return !this.isLoggedIn && this.visualSettingsActive
      ? 'gButtonBackgroundColor gButtonFontColor--text'
      : 'primary'
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettingsActive && !this.isLoggedIn
  }
  // #endregion
}
