
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
import { BookingInterface } from '@/types/interfaces/booking'
import { PublicBookingCustomVisualSettingsInterface } from '@/types/interfaces/setting'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region services
import categoryService from '@/services/category.service'
// #endregion

// #endregion

@Component
export default class BookDailyPublicCategoryCard extends Vue {
  @Prop({ required: true }) readonly departmentId!: string
  @Prop({ required: true }) readonly setCategory!: (
    category: CategoryInterface
  ) => void

  @Prop({ required: false, default: '' }) readonly selectedCategory!: string
  @Prop({ required: false, default: 1 }) readonly hasRentingEntities!: number
  @Prop({ required: false, default: false }) readonly allowTitle!: boolean
  @Prop({ required: false, default: {} }) readonly item!: BookingInterface
  @Prop({ required: false })
  readonly visualSettings!: PublicBookingCustomVisualSettingsInterface

  rules = rules

  locale = i18n.locale

  valid = false
  categories: CategoryInterface[] = []

  queryParams = {
    categoryId: this.$route.query.category || null
  }

  async created (): Promise<void> {
    await this.fetchCategories()
  }

  async fetchCategories (): Promise<void> {
    const result = await categoryService.getAvailableCategories({
      departmentId: this.departmentId,
      hasRentingEntities: this.hasRentingEntities,
      numberOfInteractors: this.item.number_of_interactors || null,
      dateFrom: this.item.date_from || null,
      dateTo: this.item.date_to || null
    })

    this.categories = result

    if (this.queryParams.categoryId) {
      const category = result.find(
        (item) => item.id === this.queryParams.categoryId
      )

      if (category) {
        this.setCategory(category)
      }
    }
  }

  // #region getters
  get enableAvaialble (): boolean {
    return this.item.department.activity_system === 'BOOKING'
  }

  get isLoggedIn (): boolean {
    return this.$store.getters['auth/getUser']
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettings.active && !this.isLoggedIn
  }

  // #endregion
}
