// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces

// #endregion

// #region utils & helpers
// import { format } from 'date-fns'
// #endregion

// #endregion

class ManualBookingService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAvaialableRentingEntitiesAtDateRange (
    departmentId: string,
    scheduleSystem: string,
    dateFrom: string | Date,
    dateTo: string | Date
  ) {
    Store.dispatch('app/loading', true)

    const URL = 'get-available-renting-entities-at-date-range'

    const result = await this.axios.get(URL, {
      params: {
        departmentId,
        scheduleSystem,
        dateFrom,
        dateTo
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async create (data: any) {
    Store.dispatch('app/loading', true)

    const URL = 'manual-booking'

    const result = await this.axios.post(URL, {
      data
    })

    Store.dispatch('app/loading', false)

    return result.data
  }
}

export default new ManualBookingService()
