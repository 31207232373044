import router from '@/router'
import store from '@/store'

export const getPhoneNumber = (data: {
  name: string
  code: string
}): string => {
  return `${data.name} (${data.code})`
}

export const disabledFieldOnEdit = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  item: any,
  fields: string[],
  name: string
): boolean => {
  return item && fields.includes(name)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseJson = (value: string): any => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return false
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonToString = (value: string | any): any => {
  try {
    return JSON.stringify(value)
  } catch (error) {
    return false
  }
}

export const extractNumbersStart = (value: string): string => {
  let output = ''
  let exit = false
  for (let i = 0; i < value.length; i++) {
    if (exit === true) break

    const testChar = value.charAt(i)

    switch (testChar) {
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        output += testChar
        break
      default:
        exit = true
        break
    }
  }
  return output
}

export const extractNumbersEnd = (value: string): string => {
  let output = ''
  let exit = false
  for (let i = value.length - 1; i >= 0; i--) {
    if (exit === true) break

    const testChar = value.charAt(i)

    switch (testChar) {
      case '0': // DOES NOTHING
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        output += testChar
        break
      default:
        exit = true
        break
    }
  }
  return output.split('').reverse().join('')
}

// If == 0, if > 1, if < -1,  else 99
/// If anej knej

/**
 * Compares input date with now.
 *
 * @param date
 * @returns 0 if equal, 1 if greater, -1 if less
 */
export const compareDatesWithNow = (date: Date, mode = 'time'): number => {
  const now = new Date()

  if (mode === 'time') {
    if (date.getTime() === now.getTime()) return 0
    if (date.getTime() > now.getTime()) return 1
    if (date.getTime() < now.getTime()) return -1
  }

  if (mode === 'day') {
    date.setHours(0, 0, 0, 0)
    now.setHours(0, 0, 0, 0)

    if (date === now) return 0
    if (date > now) return 1
    if (date < now) return -1
  }

  return 99
}

export const getDomain = (): string => {
  const loggedIn = store.getters['auth/getUser']
  const user = store.getters['auth/getUserData']

  const routeDomain = router.currentRoute.params.domain || 'demo'

  const domain = loggedIn ? user.domain : routeDomain

  return domain
}

export const getAppTitle = (prefix: string): string => {
  return `${prefix} - ${process.env.VUE_APP_TITLE}`
}

export const copyLink = (link: string): void => {
  navigator.clipboard.writeText(link)
}

export const fromISODateStringToDateOnlyString = (date: Date): string => {
  return date.toISOString().split('T')[0]
}

export const getErrorOrWarningCode = (
  message: string,
  delimiter = '|'
): string => {
  if (!message) return ''

  if (!message.includes(delimiter)) return message

  return message.split(delimiter)[0]
}

export const getErrorOrWarningMessage = (
  message: string,
  delimiter = '|'
): string => {
  if (!message) return ''

  if (!message.includes(delimiter)) return message

  return message.split(delimiter)[1]
}

export const mapNumbers = (
  num1: number,
  num2: number,
  mapMin: number,
  mapMax: number
): number => {
  // Ensure num1 and num2 are within the range [0, 1]
  num1 = Math.min(1, Math.max(0, num1))
  num2 = Math.min(1, Math.max(0, num2))

  // Map num1 and num2 to the range [mapMin, mapMax]
  const mappedValue = mapMin + ((mapMax - mapMin) * (num1 + num2)) / 2

  return mappedValue
}
