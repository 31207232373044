
import { Component, Vue } from 'vue-property-decorator'
import ActiveRentingEntites from '@/views/renting-entities/_ActiveRentingEntities.vue'
import ArchivedRentingEntites from '@/views/renting-entities/_ArchivedRentingEntities.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({
  components: {
    ActiveRentingEntites,
    ArchivedRentingEntites
  }
})
export default class RentingEntities extends Vue {
  tab = 'activeRentingEntites'

  items = [
    {
      key: 'activeRentingEntites',
      label: i18n.t('page.renting_entities.active')
    },
    {
      key: 'trashedRentingEntites',
      label: i18n.t('page.renting_entities.archived')
    }
  ]

  created (): void {
    document.title = getAppTitle('Renting Entities')
  }
}
