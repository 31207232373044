
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import MoreThanOneRentingEntity from '@/components/renting-entities/MoreThanOneRentingEntity.vue'
import BookingsDate from '@/components/bookings/BookingsDate.vue'
import BookingClientName from '@/components/bookings/BookingClientName.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import {
  BookingInterface,
  BookingSearchBundle,
  BookingTableItemInterface
} from '@/types/interfaces/booking'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion
@Component({
  components: { Table }
})
export default class CanceledBookings extends Vue {
  headers = [
    {
      text: i18n.t('table.col.rented'),
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'renting_entities',
      render: MoreThanOneRentingEntity
    },
    {
      text: i18n.t('table.col.client'),
      value: 'custom_render',
      render: BookingClientName
    },
    {
      text: i18n.t('table.col.department'),
      value: 'department.name'
    },
    {
      text: i18n.t('table.col.company'),
      value: 'department.company.name'
    },
    {
      text: i18n.t('table.col.interactors'),
      value: 'number_of_interactors',
      key: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.status'),
      value: 'custom_render',
      render: Vue.component('Status', {
        props: [],
        template: '<v-chip label outlined color="red">CANCELED</v-chip>'
      }),
      sortable: false
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  bookings: Array<BookingInterface> = []
  pagination = {} as PaginationInterface

  async handleBookingsFetch (): Promise<void> {
    const result = await BookingService.getAll('archived')
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleBookingsFetch()
  }

  async searchHandler (query: string): Promise<void> {
    await this.handleBookingsSearch(query)
  }

  async handleBookingsSearch (searchText: string): Promise<void> {
    const requestPayload = {
      search: {
        text: searchText
      }
    } as BookingSearchBundle
    const result = await BookingService.search(requestPayload, 'archived')

    this.setData(result)
  }

  viewItem (row: BookingTableItemInterface): void {
    this.$router.push(`/booking/${row.item.id}`)
  }

  async nextPage (): Promise<void> {
    const result = await BookingService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await BookingService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await BookingService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.bookings = result.bookings.data
    delete result.bookings.data
    this.pagination = result.bookings
  }
}
