
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'

// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
// #endregion

// #region components
import BookingDurationSettings from '@/containers/settings/BookingDurationSettings.vue'
import WorkingHoursSettings from '@/containers/settings/WorkingHoursSettings.vue'
import LastBookingSettings from '@/containers/settings/LastBookingSettings.vue'
import TagsSettings from '@/containers/settings/TagsSettings.vue'
import ReminderHoursSettings from '@/containers/settings/ReminderHoursSettings.vue'
import BlockedDatesSettings from '../BlockedDatesSettings.vue'
import OverallBookingInformationSetting from '../OverallBookingInformationSetting.vue'
import PublicBookingVisualSettings from '../PublicBookingVisualSettings.vue'
// #endregion

// #endregion

@Component({
  components: {
    WorkingHoursSettings,
    BookingDurationSettings,
    LastBookingSettings,
    ReminderHoursSettings,
    TagsSettings,
    BlockedDatesSettings,
    OverallBookingInformationSetting,
    PublicBookingVisualSettings
  }
})
export default class BookingSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  @Prop({ required: true }) readonly expandDurationSettings!: () => void
  @Prop({ required: true, default: false })
  readonly expandedDurationSettings!: boolean
}
