
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
// #endregion

// #region interfaces
import {
  BookingPropertyInterface,
  ReservationInterface
} from '@/types/interfaces/booking'
import { PublicBookingCustomVisualSettingsInterface } from '@/types/interfaces/setting'
// #endregion

// #region utils & helpers
import { format } from 'date-fns'
import { parseJson } from '@/utils/helper.utils'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region locales
import { enUS, nb } from 'date-fns/locale'
// #endregion

// #region constants
// const DATE_FORMAT = 'dd.MM.yyyy / HH:mm'
const DATE_FORMAT_WITH_LOACLES = 'PP / HH:mm'
// #endregion

// #endregion

@Component({
  methods: { format, parseJson }
})
export default class BookSummaryHourly extends Vue {
  @Prop() readonly reservationSummary!: ReservationInterface
  @Prop({ default: false })
  readonly visualSettings!: PublicBookingCustomVisualSettingsInterface

  notes = {
    key: 'notes',
    value: '' as string
  } as BookingPropertyInterface

  tags = {
    key: 'tags',
    value: [] as string[]
  } as BookingPropertyInterface

  created (): void {
    this.setLocale()
    this.notes.value = this.reservationSummary.properties.find(
      (x) => x.key === 'notes'
    )?.value as string
    this.tags.value = this.reservationSummary.properties.find(
      (x) => x.key === 'tags'
    )?.value as string[]
  }

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  async submitBooking (): Promise<void> {
    const properties = this.reservationSummary.properties.filter(
      (property: BookingPropertyInterface) => property.value !== ''
    ) as BookingPropertyInterface[]

    const data = {
      booking: {
        company_id: this.reservationSummary.company.id,
        department_id: this.reservationSummary.department.id,
        date_from: this.reservationSummary.date_from,
        date_to: this.reservationSummary.date_to,
        number_of_interactors: this.reservationSummary.number_of_interactors,
        renting_entity_id: this.reservationSummary.rentingEntity?.id,
        expected_departure: this.reservationSummary.expected_departure,
        expected_arrival: this.reservationSummary.expected_arrival,
        category_id: this.reservationSummary.category_id || null
      },
      client: this.reservationSummary.client,
      booking_properties: [] as BookingPropertyInterface[] | undefined
    }

    if (properties.length > 0) {
      data.booking_properties = properties
    } else {
      delete data.booking_properties
    }

    if (
      this.reservationSummary.department.activity_system?.toUpperCase() ===
      'REQUESTS_ONLY'
    ) {
      await BookingService.createRequest(data, 'PUBLIC')
      this.$emit('ordered')

      return
    }

    await BookingService.createPublic(data)
    this.$emit('ordered')
  }

  // #region computed stuff
  get departmentAddress (): string {
    return this.reservationSummary.department.address
      ? `${this.reservationSummary.department.address.address}, 
          ${this.reservationSummary.department.address.city},
          ${this.reservationSummary.department.address.zip}`
      : ''
  }

  get clientPhoneNumber (): string {
    return `(${this.reservationSummary.client.country_code}) ${this.reservationSummary.client.phone_number}`
  }

  get dateRange (): string {
    let range = format(
      dummyDateConverter(this.reservationSummary.date_from),
      DATE_FORMAT_WITH_LOACLES,
      { locale: this.locale }
    )

    if (
      this.reservationSummary.department.activity_system === 'REQUESTS_ONLY'
    ) {
      range += ` - ${format(
        dummyDateConverter(this.reservationSummary.date_to),
        DATE_FORMAT_WITH_LOACLES,
        { locale: this.locale }
      )}`
    }

    return range
  }

  get isLoggedIn (): boolean {
    if (this.$store.state.auth.user.name) {
      return true
    }

    return false
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettings.active && !this.isLoggedIn
  }
  // #endregion

  // #region setters
  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }
  // #endregion
}
