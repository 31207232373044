
import { Component, Vue } from 'vue-property-decorator'

import { mapState } from 'vuex'

// navigation menu configurations
import config from '@/configs'

// #region services
import companyService from '@/services/company.service'
// #endregion

// #region utils
import { IMAGE_SOURCES } from '@/common/constants'
// #endregion

import MainMenu from '@/components/navigation/MainMenu.vue'

@Component({
  components: {
    MainMenu
  },
  computed: {
    ...mapState('app', [
      'product',
      'isContentBoxed',
      'menuTheme',
      'toolbarTheme',
      'isToolbarDetached'
    ]),
    ...mapState('auth', ['user', 'status'])
  }
})
export default class DefaultLayout extends Vue {
  drawer = null
  showSearch = false
  navigation = config.navigation
  Search = ''
  name = ''
  language = this.$i18n.locale
  IMAGE_SOURCES = IMAGE_SOURCES

  get companyLogoPath (): string {
    const logo = this.$store.getters['app/getCompanyLogoPath']
    return logo
  }

  onKeyup (): void {
    (this.$refs.search as HTMLElement).focus()
  }

  changeLanguage (): void {
    this.$i18n.locale = this.language
    localStorage.setItem('language', this.language)
    window.location.reload()
  }

  async created (): Promise<void> {
    this.$i18n.locale = this.language
    await this.getCompanyLogo()
  }

  get drawerActivity (): boolean {
    return this.$store.state.app.isSideBarActive
  }

  async getCompanyLogo (): Promise<string> {
    const companyId = this.$store.getters['auth/getUserData'].company_id
    if (companyId) {
      const logoResult = await companyService.getCompanyLogo(companyId)

      if (logoResult.filePath) {
        this.$store.dispatch('app/updateCompanyLogoPath', logoResult.filePath)
      }
    }
    return 'img/frontline_logo.png'
  }

  toggleSideBarActivity (): void {
    this.$store.dispatch('app/toggleSideBarActivity')
  }
}
