
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewDescription from '@/common/components/pages/PageOverviewDescription.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
// #endregion

// #region services
import DepartmentService from '@/services/department.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewDescription,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard
  }
})
export default class Department extends Vue {
  department = {} as DepartmentInterface

  async fetchDepartment (): Promise<DepartmentInterface> {
    return await DepartmentService.getSingle(this.$route.params.id)
  }

  async created (): Promise<void> {
    const result = await this.fetchDepartment()
    this.department = result
    this.handleChartData()
  }

  handleChartData (): void {
    this.series = [
      {
        name: i18n.t('table.title.bookings').toString(),
        data: this.department.bookings_count_by_day
      }
    ]

    this.chartOptions.title.text = this.department.bookings_count
  }

  series = [
    {
      name: i18n.t('table.title.bookings').toString(),
      data: this.department.bookings_count_by_day
    }
  ]

  chartOptions = {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      type: 'datetime'
    },
    colors: ['#DCE6EC'],
    title: {
      text: this.department.bookings_count,
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: i18n.t('table.title.bookings'),
      offsetX: 0,
      style: {
        fontSize: '14px'
      }
    }
  }

  viewParent (): void {
    this.$router.push(`/company/${this.department.company_id}`)
  }
}
