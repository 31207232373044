var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"tw-flex tw-flex-col justify-center tw-mx-4 tw-my-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.overallBookingInformation && !_vm.currentUser)?_c('v-card',{class:`tw-mb-5 ${
      _vm.canIApplyVisualSettings ? 'gFontColor--text gCardBackgroundColor' : ''
    }`},[_c('v-card-title',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : ''},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('component.overall_booking_information.card_title') .toString() .toUpperCase())+":")])]),_c('v-card-text',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : ''},[_vm._v(" "+_vm._s(_vm.overallBookingInformation)+" ")])],1):_vm._e(),_c('div',{key:"gica"},[(!_vm.isLoggedIn && _vm.department.schedule_system === 'HOURLY')?_c('v-select',{attrs:{"outlined":"","color":_vm.canIApplyVisualSettings
          ? 'gGeneralElementsBackgroundColor gFontColor--text'
          : '',"dense":"","label":_vm.$t('input.label.number_of_guests'),"name":"guests","rules":[_vm.rules.required],"items":[1, 2, 3, 4, 5, 6, 7, 8]},on:{"blur":_vm.onInteractorsChange,"click":_vm.numberOfInteractorsSelectOptionsClicked},model:{value:(_vm.data.number_of_interactors),callback:function ($$v) {_vm.$set(_vm.data, "number_of_interactors", $$v)},expression:"data.number_of_interactors"}}):_vm._e()],1),(
      _vm.department.schedule_system === 'HOURLY' &&
        !(!_vm.userRole || _vm.userRole === '')
    )?_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('input.label.number_of_guests'),"type":"number","outlined":"","dense":"","min":"1","required":""},on:{"blur":_vm.onInteractorsChange},model:{value:(_vm.data.number_of_interactors),callback:function ($$v) {_vm.$set(_vm.data, "number_of_interactors", $$v)},expression:"data.number_of_interactors"}}):_vm._e(),(_vm.showMoreThanEightDialog)?_c('v-alert',{attrs:{"dense":"","filled":"","color":`${
      _vm.canIApplyVisualSettings
        ? 'gFontColor--text gCardBackgroundColor'
        : 'info'
    }`}},[_vm._v(" "+_vm._s(_vm.$t('component.book.more_than_8'))+" "+_vm._s(`(${_vm.company.country_code}) ${_vm.company.phone_number}`)+" ")]):_vm._e(),(_vm.department.schedule_system === 'HOURLY')?_c('div',[(
        _vm.department.activity_system === 'BOOKING' ||
          _vm.department.activity_system === ''
      )?[_c('vc-date-picker',{attrs:{"is-expanded":"","mode":"single","is-inline":"","title-position":"left","rules":[_vm.rules.required],"disabled-dates":_vm.hourlyBlockedDates.disabledDates,"attributes":_vm.hourlyBlockedDates.blockedDates,"min-date":new Date(),"locale":_vm.currentLanguage,"timezone":_vm._config().defaultTimezone,"color":_vm.canIApplyVisualSettings ? _vm.visualSettings.calendar.color : 'blue',"is-dark":_vm.canIApplyVisualSettings ? _vm.visualSettings.calendar.dark_mode : false},on:{"input":_vm.pickedDate},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}),(!_vm.selectedDate)?[_c('v-alert',{class:`mt-1 ${
            _vm.canIApplyVisualSettings
              ? 'gFontColor--text gCardBackgroundColor'
              : ''
          }`,attrs:{"dense":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('error.reservation_date'))+" ")])]:_vm._e(),_c('v-select',{staticClass:"pt-3",attrs:{"outlined":"","label":_vm.$t('input.label.time'),"name":"time","dataPath":"time","rules":[_vm.rules.required],"dense":"","color":_vm.canIApplyVisualSettings
            ? 'gGeneralElementsBackgroundColor gFontColor--text'
            : 'blue',"items":_vm.availableHours,"disabled":!_vm.data.number_of_interactors || !_vm.selectedDate},on:{"click":_vm.selectHourSelectOptionsClicked},model:{value:(_vm.data.time),callback:function ($$v) {_vm.$set(_vm.data, "time", $$v)},expression:"data.time"}})]:[_c('div',[_c('vc-date-picker',{attrs:{"is-expanded":"","mode":"single","is-inline":"","title-position":"left","rules":[_vm.rules.required],"disabled-dates":_vm.hourlyBlockedDates.disabledDates,"attributes":_vm.hourlyBlockedDates.blockedDates,"min-date":new Date(),"locale":_vm.currentLanguage,"timezone":_vm._config().defaultTimezone,"color":_vm.canIApplyVisualSettings ? _vm.visualSettings.calendar.color : 'blue',"is-dark":_vm.canIApplyVisualSettings
              ? _vm.visualSettings.calendar.dark_mode
              : false},on:{"input":_vm.onManualSelectedDateChange},model:{value:(_vm.data.manualSelectedDate.date),callback:function ($$v) {_vm.$set(_vm.data.manualSelectedDate, "date", $$v)},expression:"data.manualSelectedDate.date"}}),(!_vm.data.manualSelectedDate.date)?[_c('v-alert',{class:`mt-1 ${
              _vm.canIApplyVisualSettings
                ? 'gFontColor--text gCardBackgroundColor'
                : ''
            }`,attrs:{"dense":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('error.reservation_date'))+" ")])]:_vm._e()],2),_c('div',{class:`tw-flex tw-flex-wrap tw-flex-grow tw-gap-0 tw-my-10 ${
          _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
        }`},[_c('div',{staticClass:"tw-mx-auto"},[_c('p',{staticClass:"tw-font-bold tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('common.from').toString().toUpperCase())+" ")]),_c('vc-date-picker',{key:_vm.data.manualSelectedDate.datePickerKey,class:`g-blank mx-auto ${
              _vm.canIApplyVisualSettings ? 'gCardBackgroundColor' : ''
            }`,attrs:{"is-expanded":"","mode":"time","is-inline":"","title-position":"left","rules":[_vm.rules.required],"disabled-dates":_vm.disabledDates,"min-date":new Date(),"locale":_vm.currentLanguage,"is24hr":"","timezone":_vm._config().defaultTimezone},on:{"input":_vm.injectTimePickersCss},model:{value:(_vm.data.manualSelectedDate.from),callback:function ($$v) {_vm.$set(_vm.data.manualSelectedDate, "from", $$v)},expression:"data.manualSelectedDate.from"}})],1),_c('div',[_c('v-divider',{class:_vm.canIApplyVisualSettings ? 'gGeneralElementsBackgroundColor' : '',attrs:{"vertical":""}})],1),_c('div',{staticClass:"tw-mx-auto"},[_c('p',{staticClass:"tw-font-bold tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('common.to').toString().toUpperCase())+" ")]),_c('vc-date-picker',{key:_vm.data.manualSelectedDate.datePickerKey,class:`g-blank mx-auto ${
              _vm.canIApplyVisualSettings
                ? 'gCardBackgroundColor gFontColor--text'
                : ''
            }`,attrs:{"is-expanded":"","mode":"time","is-inline":"","title-position":"left","rules":[_vm.rules.required],"disabled-dates":_vm.disabledDates,"min-date":new Date(),"locale":_vm.currentLanguage,"is24hr":"","timezone":_vm._config().defaultTimezone},on:{"input":_vm.injectTimePickersCss},model:{value:(_vm.data.manualSelectedDate.to),callback:function ($$v) {_vm.$set(_vm.data.manualSelectedDate, "to", $$v)},expression:"data.manualSelectedDate.to"}})],1)]),_c('DateFrom',{key:_vm.data.manualSelectedDate.datePickerKey,attrs:{"title":true,"from":_vm.data.manualSelectedDate.from,"to":_vm.data.manualSelectedDate.to,"doCalculation":true,"visualSettingsActive":_vm.visualSettings.active}})]],2):_vm._e(),_vm._t("default"),_c('v-btn',{staticClass:"tw-my-2 tw-ml-auto",attrs:{"color":`${
      _vm.canIApplyVisualSettings
        ? 'gButtonBackgroundColor gButtonFontColor--text'
        : 'primary'
    }`,"disabled":!_vm.selectedDate},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.nextButtonText)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }