
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import MoreThanOneRentingEntity from '@/components/renting-entities/MoreThanOneRentingEntity.vue'
import BookingsDate from '@/components/bookings/BookingsDate.vue'
import BookingClientName from '@/components/bookings/BookingClientName.vue'
import MoreThanOneTag from '@/components/bookings/MoreThanOneTag.vue'
import Interactor from '@/components/bookings/Interactor.vue'
import FirelistDialog from '@/containers/bookings/FirelistDialog.vue'
// #endregion

// #endregion interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface,
  FilterByDateInterface
} from '@/types/interfaces/setting'
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
import {
  BookingInterface,
  BookingFilterBundle,
  BookingSearchBundle,
  BookingTableItemInterface
} from '@/types/interfaces/booking'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
import RentingEntityService from '@/services/renting-entity.service'
import CompanyService from '@/services/company.service'
import DepartmentService from '@/services/department.service'

// #endregion

// #region helpers & utils
import { dummyDateConverter } from '@/utils/date.utils'
import { startOfDay, endOfDay } from 'date-fns'
// #endregion

// #region locales
import i18n from '@/i18n'
import { configuration } from '@/config'
// #endregion

// #endregion

@Component({
  components: { Table, DefaultTableActions, FirelistDialog },
  computed: { ...mapState('app', ['isLoading']) },
  methods: {
    configuration
  }
})
export default class Bookings extends Vue {
  get isLoading (): boolean {
    return this.$store.state.app.isLoading
  }

  tableHeaders = [
    {
      text: i18n.t('table.col.rented'),
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'renting_entities',
      render: MoreThanOneRentingEntity
    },
    {
      text: i18n.t('table.col.client'),
      value: 'custom_render',
      render: BookingClientName
    },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    { text: i18n.t('table.col.company'), value: 'department.company.name' },
    {
      text: i18n.t('table.col.interactors'),
      value: 'number_of_interactors',
      key: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    },
    {
      text: 'TAGS',
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'properties',
      render: MoreThanOneTag
    }
  ]

  tableInteractors = [
    {
      text: i18n.t('table.col.interactors'),
      sortable: false,
      value: 'custom_render',
      keys: 'interactor',
      render: Interactor
    }
  ]

  tableActions = [
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  headers = [...this.tableHeaders, ...this.tableActions]

  bookings: Array<BookingInterface> = []
  pagination = {} as PaginationInterface
  searchable = true
  firelistDialog = false

  filterBundle = {
    clean_state: true,
    start_date: '',
    end_date: '',
    disabled_filter_button: true
  } as FilterByDateInterface

  dialog = false
  booking: BookingInterface | null = null

  departments = [] as Array<DepartmentInterface>
  selectedDepartments = []

  companies = [] as Array<CompanyInterface>
  selectedCompanies = []

  rentingEntities = [] as Array<RentingEntityInterface>
  selectedRentingEntities = []

  _config () {
    return configuration()
  }

  searching = ''

  print (): void {
    localStorage.setItem('bookings', JSON.stringify(this.bookings))

    const route = this.$router.resolve({ path: '/printable/bookings' })

    window.open(route.href, '_blank')
  }

  async handleBookingsFetch (): Promise<void> {
    const result = await BookingService.getAll('active')
    this.setData(result)
  }

  async handleBookingsSearch (searchText: string, page = 1): Promise<void> {
    const requestPayload = {
      search: {
        text: searchText,
        page
      }
    } as BookingSearchBundle
    const result = await BookingService.search(requestPayload, 'active')
    this.setData(result)
  }

  async handleFetchFilters (): Promise<void> {
    await this.fetchDepartments()
    await this.fetchCompanies()
    await this.fetchRentingEntities()
  }

  async handleFilter (page = 1): Promise<void> {
    const filterBundleRequest = {
      filters: {
        start_date:
          typeof this.filterBundle.start_date === 'string'
            ? dummyDateConverter(this.filterBundle.start_date)
            : dummyDateConverter(this.filterBundle.start_date as Date),
        end_date:
          typeof this.filterBundle.end_date === 'string'
            ? dummyDateConverter(this.filterBundle.end_date)
            : dummyDateConverter(this.filterBundle.end_date as Date),
        renting_entities: this.selectedRentingEntities,
        departments: this.selectedDepartments,
        companies: this.selectedCompanies,
        page
      }
    } as BookingFilterBundle

    if (
      this.filterBundle.start_date !== undefined &&
      this.filterBundle.end_date !== undefined &&
      this.filterBundle.start_date <= this.filterBundle.end_date
    ) {
      const result = await BookingService.filter(filterBundleRequest, 'active')
      this.setData(result)
      this.filterBundle.clean_state = false
    } else {
      this.$toast.error(
        i18n.t('error.start_date_greater_than_end_date').toString()
      )
    }
  }

  async clearFilter (): Promise<void> {
    await this.handleBookingsFetch()
    this.filterBundle.clean_state = true
    this.selectedRentingEntities = []
    this.selectedDepartments = []
    this.selectedCompanies = []

    this.setupDefaultDateFilters()
  }

  async created (): Promise<void> {
    await this.handleBookingsFetch()
    await this.handleFetchFilters()
    this.setupDefaultDateFilters()
  }

  async searchHanlder (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleBookingsFetch()
      return
    }

    this.searching = query
    await this.handleBookingsSearch(query)
  }

  toggleDialog (): void {
    this.dialog = !this.dialog
  }

  resetItem (): void {
    this.booking = null
  }

  editItem (row: BookingTableItemInterface): void {
    this.booking = this.bookings[row.index]
    this.toggleDialog()
  }

  viewItem (row: BookingTableItemInterface): void {
    this.$router.push(`/booking/${row.item.id}`)
  }

  @Watch('dialog')
  onDialogChange (): void {
    if (!this.dialog) {
      this.booking = null
    }
  }

  async fetchDepartments (): Promise<void> {
    const result = await DepartmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async fetchCompanies (): Promise<void> {
    const result = await CompanyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchRentingEntities (): Promise<void> {
    const result = await RentingEntityService.getAllUnpaginated()
    this.rentingEntities = result.entities
  }

  @Watch('filterBundle.start_date')
  @Watch('filterBundle.end_date')
  @Watch('selectedRentingEntities')
  @Watch('selectedDepartments')
  @Watch('selectedCompanies')
  toggleBundle (): void {
    const otherFiltersEmpty = this.checkFilterArrayLengths()
    const dateFiltresEmpty = this.checkFilterDateLengths()
    if (dateFiltresEmpty && otherFiltersEmpty) {
      this.filterBundle.disabled_filter_button = true
      this.searchable = true

      if (this.filterBundle.clean_state === false) {
        this.filterBundle.clean_state = true
        this.handleBookingsFetch()
        this.handleFetchFilters()
      }

      return
    }

    this.filterBundle.disabled_filter_button = false
    this.searchable = false
  }

  checkFilterArrayLengths (): boolean {
    return (
      this.selectedRentingEntities.length < 1 &&
      this.selectedDepartments.length < 1 &&
      this.selectedCompanies.length < 1
    )
  }

  checkFilterDateLengths (): boolean {
    return (
      this.filterBundle.start_date === '' || this.filterBundle.end_date === ''
    )
  }

  async nextPage (): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(this.pagination.current_page + 1)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await BookingService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(this.pagination.current_page - 1)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await BookingService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(number)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(this.searching, number)
      return
    }

    const result = await BookingService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    const bookings = result.bookings.data

    this.bookings = bookings

    this.showRentingEnitity(bookings)

    delete result.bookings.data

    this.pagination = result.bookings
  }

  setupDefaultDateFilters (): void {
    const _today = new Date()
    const _startOfDay = startOfDay(_today)
    const _endOfDay = endOfDay(_today)

    this.filterBundle.start_date = _startOfDay
    this.filterBundle.end_date = _endOfDay
  }

  showRentingEnitity (bookings: BookingInterface[]): void {
    for (let i = 0; i < bookings.length; i++) {
      if (bookings[i].request_department) {
        this.headers = [
          ...this.tableHeaders,
          ...this.tableInteractors,
          ...this.tableActions
        ]
        break
      }
    }
  }

  close (): void {
    this.dialog = false
  }

  showFirelistDialog () {
    this.firelistDialog = true
  }

  get currentUserId (): string {
    return this.$store.state.auth.user?.id as string
  }

  async submit (): Promise<void> {
    this.close()
    await this.handleBookingsFetch()
  }
}
