
// Vue
import { Component, Vue, Prop } from 'vue-property-decorator'

// Interfaces
import { EmailNotifications } from '@/types/interfaces/setting'

// Services
import SettingsService from '@/services/setting.service'

// Validations Rules
import rules from '@/utils/rules.utils'

// Translation
import i18n from '@/i18n'

// Utils
import { cloneDeep } from 'lodash'

@Component
export default class EmailReminder extends Vue {
  @Prop({ required: true }) companyId!: string

  valid = false
  rules = rules
  state = ''
  data = {
    cc: '',
    bcc: '',
    subject: '',
    header: '',
    body: '',
    footer:
      // eslint-disable-next-line no-template-curly-in-string
      `
      <td class="esd-block-text es-p15t es-p10b es-p15r es-p15l" align="center">
    <p><strong>Du kan nå vår support via:</strong></p>
    <p><strong>E-post:</strong> {{frontline_email}}</p>
    <p><strong>Telefon:</strong> {{frontline_phone}}</p>
    <p>Drevet av Frontline©</p>
</td>
      `,
    description:
      'This is is used as a template for email reminders to the clients when the booking is created or updated.',
    company_id: this.companyId,
    trigger_name: 'email_reminder'
  } as EmailNotifications

  defaultData = {
    cc: '',
    bcc: '',
    subject: '',
    header: `${i18n.t(
      'templates.notifications.email.reminder.header_to_save'
    )}`,
    body: '',
    footer:
      // eslint-disable-next-line no-template-curly-in-string
      `
      <td class="esd-block-text es-p15t es-p10b es-p15r es-p15l" align="center">
    <p><strong>Du kan nå vår support via:</strong></p>
    <p><strong>E-post:</strong> {{frontline_email}}</p>
    <p><strong>Telefon:</strong> {{frontline_phone}}</p>
    <p>Drevet av Frontline©</p>
</td>
      `,
    description:
      'This is is used as a template for email reminders to the clients when the booking is created or updated.',
    company_id: this.companyId,
    trigger_name: 'email_reminder'
  } as EmailNotifications

  currentCc = ''
  currentBcc = ''
  ccs: Array<string> = []
  bccs: Array<string> = []

  dynamicVariables = [
    '{{client_name}}',
    '{{department_name}}',
    '{{date_from}}',
    '{{date_to}}',
    '{{department_address}}'
  ]

  async created (): Promise<void> {
    this.data.company_id = this.companyId
    await this.fetchEmailNotification()
    this.fillCcsAndBcc()
  }

  async fetchEmailNotification (): Promise<void> {
    const result = await SettingsService.getEmailNotificationsByCompanyId(
      this.companyId,
      this.data.trigger_name
    )
    if (result !== false) {
      this.state = 'edit'
      this.data = cloneDeep(result)
      this.data.footer = this.defaultData.footer
    } else {
      this.state = 'add'
    }
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const payload = { ...this.data }
      if (this.state === 'add') {
        await SettingsService.emailNotifications(payload)
        this.$toast.success(
          i18n.t('notify.success.email_notifications.email_reminder').toString()
        )
      } else {
        delete payload.trigger_id
        delete payload.company_id

        await SettingsService.updateEmailNotifications(payload, payload.id)
        this.$toast.success(
          i18n.t('notify.success.email_notifications.email_reminder').toString()
        )
      }
      await this.fetchEmailNotification()
    }
  }

  async remove (): Promise<void> {
    const payload = { ...this.data }

    await SettingsService.removeEmailNotification(payload.id)

    this.$toast.success(
      i18n
        .t('notify.success.email_notifications.email_reminder_remove')
        .toString()
    )

    this.data = { ...this.defaultData, company_id: this.companyId }

    await this.fetchEmailNotification()
  }

  async addEmail (target: string): Promise<void> {
    if (!target || target === '') return

    if (target === 'cc' && this.currentCc !== '') {
      if (!this.isEmailLocal(this.currentCc)) {
        this.$toast.error(i18n.t('error.invalid_email').toString())
        return
      }
      this.ccs.push(this.currentCc)
      this.currentCc = ''
      await this.compactEmailField(this.ccs, target)
      return
    }

    if (target === 'bcc' && this.currentBcc !== '') {
      if (!this.isEmailLocal(this.currentBcc)) {
        this.$toast.error(i18n.t('error.invalid_email').toString())
        return
      }
      this.bccs.push(this.currentBcc)
      this.currentBcc = ''
      await this.compactEmailField(this.bccs, target)
    }
  }

  async compactEmailField (
    emails: Array<string>,
    target: string
  ): Promise<void> {
    // if (emails && emails.length < 1) return

    if (target === 'cc') {
      this.data.cc = ''
      emails.forEach((e: string, index: number) => {
        this.data.cc += index === 0 ? e : ';' + e
      })
    }

    if (target === 'bcc') {
      this.data.bcc = ''
      emails.forEach((e: string, index: number) => {
        this.data.bcc += index === 0 ? e : ';' + e
      })
    }
  }

  async removeEmail (target: string): Promise<void> {
    if (target === 'cc') {
      this.ccs.splice(-1)
      await this.compactEmailField(this.ccs, target)
      return
    }

    if (target === 'bcc') {
      this.bccs.splice(-1)
      await this.compactEmailField(this.bccs, target)
    }
  }

  async fillCcsAndBcc (): Promise<void> {
    if (this.data.cc && this.data.cc?.length > 0) {
      this.ccs = this.data.cc.split(';')
    }

    if (this.data.bcc && this.data.bcc?.length > 0) {
      this.bccs = this.data.bcc.split(';')
    }
  }

  isEmailLocal (email: string): boolean {
    return !!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  }

  onSubmit (): void {
    this.$emit('submit')
  }

  onRemove (): void {
    this.$emit('remove')
  }
}
