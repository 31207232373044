
// #region imports
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

@Component
export default class DocTemplateImg extends Vue {
  @Prop({ required: false }) src!: string
  @Prop({ required: false, default: 'Image Caption' }) caption!: string
  @Prop({ required: false }) maxWidth!: string
  @Prop({ required: false }) maxHeight!: string

  get imageSrc (): unknown {
    if (this.src) {
      return require(`@/assets/images/quick-start-guide/${this.src}`)
    } else {
      return require('@/assets/images/quick-start-guide/default.jpg')
    }
  }
}
