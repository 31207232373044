
import { Component, Vue } from 'vue-property-decorator'

// Components
import PendingBookingRequests from '@/views/booking-requests/_PendingBookingRequests.vue'
import ApprovedBookingRequests from '@/views/booking-requests/_ApprovedBookingRequests.vue'
import DeclinedBookingRequests from '@/views/booking-requests/_DeclinedBookingRequests.vue'

import i18n from '@/i18n'

import { getAppTitle } from '@/utils/helper.utils'

@Component({
  components: {
    PendingBookingRequests,
    ApprovedBookingRequests,
    DeclinedBookingRequests
  }
})
export default class BookingRequests extends Vue {
  tab = 'pendingRequests'

  items = [
    { key: 'pendingRequests', label: i18n.t('input.label.pending_requests') },
    { key: 'approvedRequests', label: i18n.t('input.label.approved_requests') },
    { key: 'declinedRequests', label: i18n.t('input.label.declined_requests') }
  ]

  created (): void {
    document.title = getAppTitle('Bookings')
  }
}
