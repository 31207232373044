
// #region imports

// #region vue
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region interfaces
import {
  CompanySettingInterface,
  ReminderHoursInterface
} from '@/types/interfaces/setting'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component
export default class ReminderHoursSettings extends Vue {
  // #region Props
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  // #endregion

  // #region Injections
  rules = rules
  // #endregion

  // #region Local Variables

  valid = false
  expandReminderHours = false

  // #endregion

  // #region Models
  data = {
    from: '',
    to: '',
    hours_before: 0
  } as ReminderHoursInterface

  async created (): Promise<void> {
    await this.fetchCompanySettings()
  }

  // #endregion

  // #region Frontend Interaction Methods

  toggleReminderHours (): void {
    this.expandReminderHours = !this.expandReminderHours
  }

  async resetReminderHoursSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): Promise<void> {
    const settingsToUse = fetchedSettings || this.settings
    const reminderHoursSettings = settingsToUse.reminder_hours

    if (reminderHoursSettings) {
      this.data = parseJson(
        reminderHoursSettings.toString()
      ) as ReminderHoursInterface
    } else {
      this.data = {} as ReminderHoursInterface
    }
  }
  // #endregion

  // #region Backend Interaction Methods
  async submit (): Promise<void> {
    const form = this.$refs.inputsForm as HTMLFormElement
    const valid = await form.validate()

    if (valid) {
      await settingService.upsertReminderHours(this.companyId, this.data)
    }
  }

  async fetchCompanySettings (): Promise<void> {
    const settings = await settingService.getCompanySettings(this.companyId)
    await this.resetReminderHoursSettings(settings)
  }

  async deleteReminderHours (): Promise<void> {
    await settingService.deleteCompanySettingByKey(
      this.companyId,
      'reminder_hours'
    )
    await this.fetchCompanySettings()
  }
  // #endregion

  // #endregion

  // #region Computed Properties
  get canDisable (): boolean {
    return !this.data.hours_before || !this.data.from || !this.data.to
  }

  // #endregion
}
