/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */

// #region locales
import i18n from '@/i18n'
// #endregion

class Rules {
  email (v: any) {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(v)
      ? true
      : i18n.t('validation_rules_messages.email').toString()
  }

  phone (v: any) {
    if (v == null) return false
    v = v.replace(' ', '')
    return /^\d{8}|\+47\d{8}$/.test(v)
      ? true
      : i18n.t('validation_rules_messages.phone').toString()
  }

  phone2 (v: any) {
    if (!v) return false

    return /^[1-9][0-9\s]*$/.test(v)
      ? true
      : i18n.t('validation_rules_messages.phone').toString()
  }

  countryPrefix (v: any) {
    if (v == null) return false
    v = v.replace(' ', '')

    return /^\+(\d{1}\\-)?(\d{1,3})$/.test(v)
      ? true
      : i18n.t('validation_rules_messages.country_prefix').toString()
  }

  required (v: any) {
    return v != null && v != undefined && v != ''
      ? true
      : i18n.t('validation_rules_messages.required').toString()
  }

  range (v: any, r: number, required = false) {
    if (!required && !v) return true
    return (
      (v && v.length <= r) ||
      i18n
        .t('validation_rules_messages.range')
        .toString()
        .replace('{{r}}', r.toString())
    )
  }

  password (v: any) {
    if (v == null) {
      return false
    }

    v = v.replace(' ', '')

    return v.length > 7
      ? true
      : i18n.t('validation_rules_messages.password').toString()
  }

  number (value: number | string) {
    return !isNaN(parseInt(`${value}`))
      ? true
      : i18n.t('validation_rules_messages.number').toString()
  }

  max (value: number | string, max: number) {
    return parseInt(`${value}`) <= max
      ? true
      : i18n
        .t('validation_rules_messages.max')
        .toString()
        .replace('{{max}}', max.toString())
  }

  min (value: number | string, min: number) {
    return parseInt(`${value}`) >= min
      ? true
      : i18n
        .t('validation_rules_messages.min')
        .toString()
        .replace('{{min}}', min.toString())
  }

  confirmPassword (value: string, password: string) {
    return value === password
      ? true
      : i18n.t('validation_rules_messages.confirm_password')
  }

  hour (value: string | null) {
    if (value == null) return false
    value = value.replace(' ', '')

    return /^([0-1]?[0-9]|2?[0-4]|[0-9])[:\-/]([0-5][0-9]|[0-9])$/.test(value)
      ? true
      : i18n.t('validation_rules_messages.hour')
  }

  hour2 (value: string | null) {
    if (!value) return true // return true when input value is null or empty
    value = value.replace(/\s/g, '') // remove all whitespace characters

    // regular expression pattern to match the hour format in the range of 00:00 to 23:59 with leading zeros
    const pattern = /^([01]\d|2[0-3]):([0-5]\d)$/

    return pattern.test(value)
      ? true
      : i18n.t('validation_rules_messages.hour2')
  }

  maxTime (value: string, maxTime: string) {
    if (this.hour2(value)) {
      return true
    }
    const [hour, minute] = value.split(':')
    const [maxHour, maxMinute] = maxTime.split(':')

    if (hour > maxHour) {
      return `max: ${maxTime}`
    }

    if (hour === maxHour && minute > maxMinute) {
      return `max: ${maxTime}`
    }

    return true
  }

  minTime (value: string, minTime: string) {
    if (this.hour2(value)) {
      return true
    }
    const [hour, minute] = value.split(':')
    const [minHour, minMinute] = minTime.split(':')

    if (hour < minHour) {
      return `min: ${minTime}`
    }

    if (hour === minHour && minute < minMinute) {
      return `min: ${minTime}`
    }

    return true
  }

  domain (value: string) {
    if (value == null) {
      return i18n.t('validation_rules_messages.domain').toString()
    }

    const v = value.match(/^[A-Za-z0-9_]+$/i)

    if (!v) {
      return i18n.t('validation_rules_messages.domain').toString()
    }

    return true
  }

  domainNumber (value: string) {
    if (value == null) {
      return i18n.t('validation_rules_messages.domain').toString()
    }

    const test = value.match(/^\d/i)

    if (test) {
      return i18n.t('validation_rules_messages.domain_number').toString()
    }

    return true
  }

  imageSize (value: any, maxSize: number) {
    return (
      !value ||
      value.size < 10_485_760 ||
      i18n
        .t('validation_rules_messages.image_size')
        .toString()
        .replace('{{maxSize}}', maxSize.toString())
    )
  }

  hexaColor (value: string) {
    if (value == null) {
      return false
    }

    const v = value.match(/^#([0-9a-f]{3}){1,2}$/i)

    if (!v) {
      return i18n.t('validation_rules_messages.hexa_color').toString()
    }

    return true
  }
}

export default new Rules()
