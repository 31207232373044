// #region imports

// #region interfaces
import { AvailableRentingEntityForBookingRequest } from '@/types/interfaces/renting-entity'
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region servcies
import BaseService from '@/services/base.service'
// #endregion

// #endregion

class BookingRequestService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url =
      type.length === 0 ? '/booking-requests' : `/booking-requests/${type}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/booking-request/${id}/department`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.booking_requests
  }

  // eslint-disable-next-line
  async create(data: any) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/booking-request', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async createPublic(data: any) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/booking-request-public', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async update(data: any, id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.put(`/booking-request/${id}`, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async approve (id: string, rentingEntity?: string): Promise<string> {
    Store.dispatch('app/loading', true)

    let url = `/booking-request-approve/${id}/${rentingEntity}`

    const user = Store.getters['auth/getUserData']

    if (user.role === 'observer') {
      url = `/booking-request-observer-approve/${id}`
    }

    const result = await this.axios(url, {
      method: 'PATCH'
    })

    Store.dispatch('app/loading', false)

    return result.data.arrived_at
  }

  async decline (id: string) {
    Store.dispatch('app/loading', true)

    let url = `/booking-request-decline/${id}`

    const user = Store.getters['auth/getUserData']

    if (user.role === 'observer') {
      url = `/booking-request-observer-decline/${id}`
    }

    const result = await this.axios(url, {
      method: 'DELETE'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async getAvailableRentingEntities (
    id: string
  ): Promise<AvailableRentingEntityForBookingRequest[]> {
    Store.dispatch('app/loading', true)

    const result = await this.axios(
      `/booking-request-available-renting-entities/${id}`,
      {
        method: 'GET'
      }
    )

    Store.dispatch('app/loading', false)

    return result.data.renting_entities
  }
}

export default new BookingRequestService()
