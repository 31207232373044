
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
// #endregion

// #region interfaces
import { BookingRequestInterface } from '@/types/interfaces/booking-request'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region services
import bookingRequestService from '@/services/booking-request.service'
// #endregion

// #region utils & helpers
import isBefore from 'date-fns/isBefore'
import { isSameDay, isAfter, addDays, subDays } from 'date-fns'
import { dummyDateConverter } from '@/utils/date.utils'
// import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region data
import { clone } from 'lodash'
// #endregion

// #region configs
import { configuration } from '@/config'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
// #endregion

// #endregion

@Component({
  components: { TableModalForm, TableModalActions },
  methods: {
    dummyDateConverter,
    configuration
  }
})
export default class EditBookingRequest extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop() readonly item!: BookingRequestInterface
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  user = store.getters['auth/getUserData']
  rules = rules
  loading = true
  linkToStaff = false

  dateFrom = new Date()
  dateTo = new Date()

  data = {
    company_id: '',
    department_id: '',
    number_of_interactors: 1
  } as BookingRequestInterface

  valid = false

  blockedDates = {
    blockedDatesAttributes: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }

  async created (): Promise<void> {
    this.loading = true
    await this.fetchBlockedDates()
    this.data = this.item
    this.dateFrom = new Date(this.item.date_from)
    this.dateTo = new Date(this.item.date_to)
    this.loading = false
  }

  @Watch('dateFrom')
  onDateFromChange (): void {
    const dateFrom = dummyDateConverter(this.dateFrom)
    const dateTo = dummyDateConverter(this.dateTo)

    if (this.item.schedule_system === 'HOURLY') {
      if (!isSameDay(dateTo, dateFrom) || isBefore(dateTo, dateFrom)) {
        const hours = dateTo.getHours()
        const minutes = dateTo.getMinutes()
        const dateFromHour = dateFrom.getHours()
        const dateFromMinutes = dateFrom.getMinutes()

        this.dateTo = clone(dateFrom)

        if (hours < dateFromHour && minutes < dateFromMinutes) {
          this.dateTo.setHours(dateFromHour)
          this.dateTo.setMinutes(dateFromMinutes)
          return
        }

        if (hours < dateFromHour) {
          this.dateTo.setHours(dateFromHour)
          this.dateTo.setMinutes(minutes)
          return
        }

        if (minutes < dateFromMinutes) {
          this.dateTo.setHours(hours)
          this.dateTo.setMinutes(dateFromMinutes)
          return
        }

        this.dateTo.setHours(hours)
        this.dateTo.setMinutes(minutes)
      }
    }

    if (this.item.schedule_system === 'DAILY') {
      if (isAfter(dateFrom, dateTo)) {
        this.dateTo = addDays(clone(dateFrom), 1)
      }

      if (isSameDay(dateFrom, dateTo)) {
        this.dateTo = addDays(clone(dateFrom), 1)
      }
    }
  }

  @Watch('dateTo')
  onDateToChange (): void {
    const dateFrom = dummyDateConverter(this.dateFrom)
    const dateTo = dummyDateConverter(this.dateTo)

    if (this.item.schedule_system === 'HOURLY') {
      if (!isSameDay(dateTo, dateFrom)) {
        const hours = dateFrom.getHours()
        const minutes = dateFrom.getMinutes()

        this.dateFrom = clone(dateTo)

        this.dateFrom.setHours(hours)
        this.dateFrom.setMinutes(minutes)
      }
    }

    if (this.item.schedule_system === 'DAILY') {
      if (isBefore(dateTo, dateFrom)) {
        this.dateFrom = subDays(clone(dateTo), 1)
      }
      if (isSameDay(dateFrom, dateTo)) {
        this.dateFrom = subDays(clone(dateTo), 1)
      }
    }
  }

  async fetchBlockedDates (blockedDateId: string | null = null): Promise<void> {
    const result = (await getBlockedDatesAsync(
      this.item.company_id,
      this.item.department_id,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.blockedDates.blockedDatesAttributes = [
      ...this.blockedDates.blockedDatesAttributes,
      ...result.blockedDates
    ]

    this.blockedDates.disabledDates = [
      ...this.blockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  async submit (): Promise<void> {
    const submitData = {
      number_of_interactors: this.data.number_of_interactors,
      date_from: this.dateFrom,
      date_to: this.dateTo
    }

    await bookingRequestService.update(submitData, this.item.id)

    await this.fetchBlockedDates()

    this.onSubmit()
  }

  // #region computed
  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // #endregion
}
