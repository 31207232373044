
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItemAction extends Vue {
  @Prop({ required: true }) readonly action!: () => void
  @Prop({ required: false }) readonly buttonAction!: () => void
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: false, default: 'primary' }) readonly color!: string
  @Prop({ required: false, default: false })
  readonly confirmationRequired!: boolean

  @Prop({ required: false, default: true })
  readonly defaultConfirmationActions!: boolean

  @Prop({
    required: false,
    default: 'Are you sure you want to continue with this action?'
  })
  readonly confirmationMessage!: string

  menu = false

  click (): void {
    this.menu = false
    this.action()
  }

  menuAction (): void {
    this.buttonAction?.()
  }

  closeMenu (): void {
    this.menu = false
  }
}
