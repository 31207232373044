
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
// #endregion

// #region interfaces
import {
  CompanyInterface,
  CompanyTableItemInterface
} from '@/types/interfaces/company'
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import CompanyService from '@/services/company.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { Table, DefaultTableActions }
})
export default class ArchivedCompanies extends Vue {
  @Prop({ required: true }) readonly tab!: string

  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('table.col.domain'),
      value: 'domain'
    },
    {
      text: i18n.t('table.col.organization_number'),
      value: 'organization_number'
    },
    {
      text: i18n.t('table.col.owner'),
      value: 'owner.name'
    },
    {
      text: i18n.t('table.col.status'),
      value: 'custom_render',
      render: Vue.component('Status', {
        props: [],
        template: '<v-chip label outlined color="red">ARCHIVED</v-chip>'
      }),
      sortable: false
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  companies: Array<CompanyInterface> = []
  pagination = {} as PaginationInterface

  async handleCompaniesFetch (): Promise<void> {
    const result = await CompanyService.getAll('archived')
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleCompaniesFetch()
  }

  viewItem (row: CompanyTableItemInterface): void {
    this.$router.push(`/company/${row.item.id}?archived=true`)
  }

  async reactivateItem (row: CompanyTableItemInterface): Promise<void> {
    const id = row.item.id

    const result = await CompanyService.reactivate(id)

    this.$toast.success(i18n.t('notify.success.company_reactivated').toString())

    this.handleCompaniesFetch()

    return result
  }

  async nextPage (): Promise<void> {
    const result = await CompanyService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await CompanyService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await CompanyService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'archivedCompanies') {
      this.handleCompaniesFetch()
    }
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.companies = result.companies.data

    delete result.companies.data

    this.pagination = result.companies
  }
}
