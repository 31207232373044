
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { WizardInterface } from '@/types/interfaces/setting'
// #endregion

// #region data
import { set, get } from 'lodash'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #endregion

@Component({ methods: { set, get } })
export default class DefaultSettings extends Vue {
  @Prop() data!: WizardInterface

  rules = rules
  formValidity = false

  goToOverview (): void {
    this.validateInputs()
    if (this.formValidity) {
      this.$emit('changeStep')
    }
  }

  validateInputs (): void {
    return (this.$refs.form as HTMLFormElement).validate()
  }

  setToggle (value: boolean | undefined, path: string): void {
    if (value === undefined) {
      const current = get(this.data, path, false)
      value = !current
    }
    set(this.data, path, value)
  }
}
