
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region interfaces
import {
  SurveyQuestionInterface,
  SurveyInterface
} from '@/types/interfaces/survey'
// #endregion

// #region components
import SurveyHeaderTitle from '@/components/surveys/SurveyHeaderTitle.vue'
import InitialQuestion from '@/components/surveys/InitialQuestion.vue'
import QuestionTitlePill from '@/components/surveys/QuestionTitlePill.vue'
import Question from '@/components/surveys/Question.vue'
import RadioOption from '@/components/surveys/RadioOption.vue'
import TextQuestion from '@/components/surveys/TextQuestion.vue'
import CheckOption from '@/components/surveys/CheckOption.vue'
// #endregion

// #endregion

@Component({
  components: {
    SurveyHeaderTitle,
    InitialQuestion,
    QuestionTitlePill,
    Question,
    RadioOption,
    TextQuestion,
    CheckOption
  }
})
export default class SurveyPreview extends Vue {
  @Prop() readonly survey!: SurveyInterface
  @Prop() readonly companyName!: string

  getOptions (entry: SurveyQuestionInterface): Array<string> {
    const options = []
    if (entry.options) {
      for (const option of entry.options) {
        if (option.value) {
          options.push(option.value)
        }
      }
    }

    return options
  }
}
