
// #region imports

// #region vue
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region interfaces
import {
  CompanySettingInterface,
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  BlockedDateTableItemInterface,
  CreateBlockedDateInterface
} from '@/types/interfaces/blocked-dates'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region components
import ExpandableContainer from '@/containers/settings/ExpandableContainer.vue'
import Table from '@/common/components/ui/table/Table.vue'
import AddEditBlockedDate from '../blocked-dates/AddEditBlockedDate.vue'
import ItemAction from '@/common/components/ui/table/ItemAction.vue'
import ItemActions from '@/common/components/ui/table/ItemActions.vue'
import BlockedDateDescription from './BlockedDateDescription.vue'
import BlockedDateDateView from './BlockedDateDateView.vue'
// #endregion

// #region services
import departmentService from '@/services/department.service'
import _blockedDateService from '@/services/blocked-dates.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    ExpandableContainer,
    Table,
    ItemActions,
    ItemAction,
    AddEditBlockedDate
  }
})
export default class BlockedDatesSettings extends Vue {
  // #region Props
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  // #endregion

  // #region data
  rules = rules
  selectedDepartment = ''
  dialog = false
  deleteDialog = false

  departments = [] as Array<DepartmentInterface>
  blockedDate: CreateBlockedDateInterface | null = null
  blockedDates: Array<CreateBlockedDateInterface> = []
  pagination = {} as PaginationInterface
  // #endregion

  tableHeaders = [
    { text: 'Department', alilgn: 'start', value: 'department.name' },
    {
      text: 'Start Date',
      alilgn: 'start',
      value: 'custom_render',
      key: 'start_date',
      render: BlockedDateDateView
    },
    {
      text: 'End Date',
      alilgn: 'start',
      value: ['custom_render'],
      key: 'end_date',
      render: BlockedDateDateView
    },
    {
      text: 'Description En',
      align: 'start',
      value: 'custom_render',
      key:
        this.currentLanguage === 'en-us' ? 'description_en' : 'description_no',
      render: BlockedDateDescription
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.blockedDate = null
    }
  }

  // #region others
  editItem (row: BlockedDateTableItemInterface): void {
    this.blockedDate = this.blockedDates[row.index]
    this.toggleDialog()
  }

  async deleteItem (): Promise<void> {
    const id = this.blockedDate !== null ? this.blockedDate.id : ''

    await _blockedDateService.deleteBlockedDate(id)

    this.$toast.success(
      i18n.t('notify.success.blocked_date_removed').toString()
    )

    this.handleBlockedDatesFetch()

    this.deleteDialog = false
    this.blockedDate = null
  }

  cancelDeleteDialog (): void {
    this.deleteDialog = false
    this.blockedDate = null
  }

  initDeleteRentingEntity (row: BlockedDateTableItemInterface): void {
    this.deleteDialog = true
    this.blockedDate = this.blockedDates[row.index]
  }
  // #endregion

  // #region hooks
  async created (): Promise<void> {
    this.fetchDepartments()
  }
  // #endregion

  // #region backend
  async fetchDepartments (): Promise<void> {
    const result = await departmentService.getAllCompanyDepartments(
      this.companyId
    )
    this.departments = result
  }

  async handleBlockedDatesFetch (): Promise<void> {
    const result = await _blockedDateService.getAllPaginated(
      this.companyId,
      this.selectedDepartment
    )
    this.setData(result)
  }

  async nextPage (): Promise<void> {
    const result = await _blockedDateService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await _blockedDateService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (page: number): Promise<void> {
    const result = await _blockedDateService.goToPage(
      this.pagination.path + '?page=' + page
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.blockedDates = result.blocked_dates.data
    delete result.blocked_dates.data
    this.pagination = result.blocked_dates
  }

  @Watch('company_id')
  async getDepartments (): Promise<void> {
    await this.fetchDepartments()
  }

  @Watch('selectedDepartment')
  async getBlockedDates (): Promise<void> {
    this.blockedDates = []
    await this.handleBlockedDatesFetch()
  }
  // #endregion

  // #region computed
  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // #endregion
}
