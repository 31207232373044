
// #region Imports

// Vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// Components
import Tags from '@/components/bookings/Tags.vue'

// Interfaces
import { ClientInterface } from '@/types/interfaces/client'

import {
  BookingPropertyInterface,
  ReservationInterface
} from '@/types/interfaces/booking'

// Assets
import countryPrefixes from '@/assets/CountryPrefix'

// Utils & Validation
import rules from '@/utils/rules.utils'
import { sanitizePhoneNumber } from '@/utils/sanitation.utils'

// Services
import settingService from '@/services/setting.service'
import { jsonToString } from '@/utils/helper.utils'

// #endregion

@Component({ components: { Tags }, methods: { sanitizePhoneNumber } })
export default class DepartmentReservationClientInformation extends Vue {
  @Prop({ default: null }) readonly item!: ReservationInterface
  @Prop({ default: null }) readonly companyId!: string
  @Prop({ default: null, required: false }) readonly submitButtonTitle!: string

  prefixes = countryPrefixes
  rules = rules

  data = {
    country_code: '+47',
    number_of_interactors: 10
  } as ClientInterface

  tags = []
  selectedTags = { key: 'tags', value: [] } as BookingPropertyInterface

  valid = false

  async created (): Promise<void> {
    await this.getTags()
  }

  getText (data: { name: string; code: string }): string {
    return `${data.name} (${data.code})`
  }

  toggleTag (indx: number): void {
    const tempTag = this.tags[indx]
    const localIndx = this.selectedTags.value.indexOf(tempTag)

    if (Array.isArray(this.selectedTags.value)) {
      if (localIndx === -1) {
        this.selectedTags.value.push(tempTag)
        return
      }

      this.selectedTags.value.splice(localIndx, 1)
    }
  }

  async getTags (): Promise<void> {
    const tags = await settingService.getValuesPublic(this.companyId, 'tags')
    this.tags = tags.value
  }

  submit (): void {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (this.selectedTags.value.length > 0) {
      this.data.tags = {
        ...this.selectedTags,
        value: jsonToString(this.selectedTags.value)
      }
    }

    if (valid) {
      this.$emit('clientInformationAdded', this.data)
    }
  }

  // #region computed properties
  get submitButtonTitleComputed (): string {
    return this.submitButtonTitle || this.$t('common.next').toString()
  }
  // #endregion
}
