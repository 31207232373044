import Vue from 'vue'
import Vuetify from 'vuetify'
import config from '@/configs'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: config.theme.globalTheme === 'dark',
    options: {
      customProperties: true
    },
    themes: {
      dark: config.theme.dark,
      light: config.theme.light
    }
  }
})
