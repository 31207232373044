var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"tw-flex tw-flex-col justify-center tw-mx-4 tw-my-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.categories && _vm.categories.length > 0)?[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-flex-row tw-p-3 tw-gap-2 tw-uppercase tw-text-center"},_vm._l((_vm.categories),function(category){return _c('v-card',{key:category.id,class:`tw-flex align-center tw-flex-auto flex-row justify-space-between ${
          _vm.canIApplyVisualSettings ? 'gCardBackgroundColor' : ''
        }`,on:{"click":() => _vm.setCategory(category)}},[_c('v-card-text',{class:`tw-flex tw-flex-col tw-justify-center ${
            _vm.canIApplyVisualSettings ? 'gFontColor--text' : ''
          } `},[_c('span',{staticClass:"tw-text-center tw-font-bold"},[_vm._v(_vm._s(category.name))]),_c('div',{staticClass:"tw-flex tw-gap-2 tw-justify-center"},[(category.company)?_c('span',{staticClass:"tw-text-center tw-font tw-text-xs"},[_vm._v(_vm._s(category.company.name))]):_vm._e(),(category.department)?_c('span',{staticClass:"tw-text-center tw-font tw-text-xs"},[_vm._v(_vm._s(category.department.name))]):_vm._e()]),(_vm.enableAvaialble)?[(_vm.hasRentingEntities === 1)?_c('div',{staticClass:"tw-font-bold"},[(
                  category.renting_entities_count &&
                    category.renting_entities_count > 0
                )?_c('span',{class:_vm.canIApplyVisualSettings
                    ? 'gFontColor--text'
                    : 'tw-text-green-600'},[_vm._v(" - "+_vm._s(_vm.$t('component.categories.available'))+" ")]):_c('span',{class:_vm.canIApplyVisualSettings ? 'error--text' : 'tw-text-red-600'},[_vm._v("- "+_vm._s(_vm.$t('component.categories.not_available')))])]):_vm._e()]:_vm._e()],2)],1)}),1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }