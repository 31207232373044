
// #region imports

// vue
import { Vue, Component, Prop } from 'vue-property-decorator'
//

// Locales
import i18n from '@/i18n'
//

// Configurations
import { configuration } from '@/config'
//

// Helpers & Utils
import { format, differenceInMinutes } from 'date-fns'
import { dummyDateConverter, bookingTimeDuration } from '@/utils/date.utils'
//

// #endregion

@Component({ methods: { format, configuration, dummyDateConverter } })
export default class DateFromTo extends Vue {
  @Prop({ required: false, default: false }) readonly title!: boolean
  @Prop({ required: true }) readonly from!: Date
  @Prop({ required: true }) readonly to!: Date
  @Prop({ required: false }) readonly dateFormat!: string
  @Prop({ required: false }) readonly addMinute!: boolean
  @Prop({ required: false }) readonly messageForFrom!: string
  @Prop({ required: false }) readonly messageForTo!: string
  @Prop({ required: false }) readonly doCalculation!: boolean
  @Prop({ required: false, default: false }) readonly visualSettingsActive!: boolean

  locale = i18n.locale
  noCalculation = false

  _dummyDateConverter (date: Date | string): Date {
    return dummyDateConverter(date)
  }

  _configuration () {
    return configuration()
  }

  get deltaTimeInMinutes (): string {
    const deltaMin = Math.abs(differenceInMinutes(this.from, this.to))

    const result = bookingTimeDuration(this.addMinute ? deltaMin + 1 : deltaMin)

    return result
  }

  get color (): string {
    if (this.isLoggedIn) {
      return this.doCalculation ? 'tw-text-green-600' : 'tw-text-red-600'
    }

    return this.visualSettingsActive ? 'gFontColor--text' : 'tw-text-gray-600'
  }

  get isLoggedIn (): boolean {
    if (this.$store.state.auth.user.name) {
      return true
    }

    return false
  }
}
