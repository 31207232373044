// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

class RentingEntityTypeService extends BaseService {
  error?: ApiCallErrorInterface

  async getAllUnpaginated (departmentTypeName: string | undefined) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity-types-unpaginated/${departmentTypeName}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.renting_entity_types
  }

  async getRentingEntityTeplatesByTypeId (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity-type-templates/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.renting_entity_templates
  }
}

export default new RentingEntityTypeService()
