
// #region imports

// vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import SimpleChipWithTitle from '@/common/components/ui/chips/SimpleChipWithTitle.vue'

// interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import { FirelistRequestInterface } from '@/types/interfaces/report'

// services
import _departmentService from '@/services/department.service'
import _reportService from '@/services/report.service'
import _companyService from '@/services/company.service'

// utils
import rules from '@/utils/rules.utils'
import { defaultOutgoing } from '@/utils/date.utils'
import { SimpleCompanyInterface } from '@/types/interfaces/company'

// #endregion

@Component({
  name: 'FirelistDialog',
  components: {
    SimpleChipWithTitle
  }
})
export default class FirelistDialog extends Vue {
  @Prop({ required: true }) dialog!: boolean
  @Prop({ required: true }) userId!: string

  departments = [] as Array<DepartmentInterface>
  companies = [] as Array<SimpleCompanyInterface>
  selectedDepartments: string[] = []
  selectedCompany: string | null = null
  all = false

  // injections:
  rules = rules

  // vue hooks - start
  async created (): Promise<void> {
    await this.fetchCompaneis()
    this.onAllChanged(this.all)
  }
  // vue hooks - end

  // #region methods
  close (): void {
    this.$emit('FirelistDialogCloseEvent', false)
  }

  async fetchDepartments (companyId: string): Promise<void> {
    const result = await _departmentService.getAllCompanyDepartments(
      companyId,
      'DAILY'
    )
    this.departments = result
  }

  async fetchCompaneis (): Promise<void> {
    const result = await _companyService.getCompaniesByUserId()
    this.companies = result

    if (result.length === 1) {
      this.selectedCompany = result[0].company_id
    }
  }

  async print (): Promise<void> {
    const payload = {
      company_id: this.selectedCompany,
      department_ids: this.selectedDepartments,
      date: defaultOutgoing(new Date())
    } as FirelistRequestInterface

    try {
      await _reportService.getFirelistDocument(payload)
    } catch (error) {
      this.$toast.error(this.$t('A-ERROR.REPORT-1406').toString())
    }

    // WAIT FOR 1 SEC
    await new Promise((resolve) => setTimeout(resolve, 1000))

    try {
      await _reportService.getFirelistGuestsList(payload)
    } catch (error) {
      this.$toast.error(this.$t('A-ERROR.REPORT-1405').toString())
    }
    this.close()
  }

  // #endregion

  // #region watchers
  @Watch('all')
  onAllChanged (value: boolean): void {
    if (value) {
      this.selectedDepartments = this.departments.map(
        (department) => department.id
      )
    } else {
      this.selectedDepartments = []
    }
  }

  @Watch('selectedCompany')
  async onSelectedCompanyChanged (value: string): Promise<void> {
    if (value) {
      await this.fetchDepartments(value)
      return
    }

    this.departments = []
    this.selectedDepartments = []
    this.all = false
  }
  // #endregion
}
