
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import DateFrom from '@/components/bookings/DateFromTo.vue'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
import CompanyService from '@/services/company.service'
import departmentService from '@/services/department.service'
import _blockedDatesService from '@/services/blocked-dates.service'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region utils & helpers
import moment from 'moment'
import {
  subMinutes,
  addMinutes,
  format,
  isEqual,
  isAfter,
  isBefore,
  addDays
} from 'date-fns'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region locales
import i18n from '@/i18n'
import rentingEntityService from '@/services/renting-entity.service'
// #endregion

// #region configs
import { configuration } from '@/config'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
import { clone } from 'lodash'
import {
  KeyValuSettingsInterface,
  OverallBookingInformationInterface,
  PublicBookingCustomVisualSettingsInterface
} from '@/types/interfaces/setting'
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region constants
const DATE_FORMAT = 'EEEE, MMMM do, yyyy - HH:mm'
// #endregion

// #endregion

interface DateRange {
  start: Date
  end: Date
}

@Component({
  methods: { format, dummyDateConverter, configuration },
  components: {
    DateFrom
  }
})
export default class BookDateCard extends Vue {
  @Prop({ required: true }) readonly company!: CompanyInterface
  @Prop({ required: true }) readonly departmentId!: string
  @Prop({ required: true }) readonly selectedDepartment!: DepartmentInterface
  @Prop({ required: false }) readonly rentingEntityId!: string
  @Prop({ default: i18n.t('common.next') })
  readonly nextButtonText!: string

  @Prop({ default: false })
  readonly visualSettings!: PublicBookingCustomVisualSettingsInterface

  rules = rules

  selectedDate = new Date()
  dailyBookingData: DateRange = {} as DateRange

  locale = i18n.locale

  disabledDates: DateRange[] = []
  availableHours = [] as Array<string>
  valid = false
  department = {} as DepartmentInterface
  moreThanEightDialog = {
    dialog: false,
    initalState: true
  }

  _config () {
    return configuration()
  }

  hourlyBlockedDates = {
    blockedDates: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }

  userRole = ''

  data = {
    number_of_interactors: this.isLoggedIn ? 1 : 0,
    time: '',
    date: moment(new Date()).tz(configuration().defaultTimezone),
    manualSelectedDate: {
      date: new Date(),
      from: subMinutes(new Date(), 15),
      to: addMinutes(new Date(), 15),
      datePickerKey: 0
    },
    formattedDates: {
      dateFrom: '',
      dateTo: ''
    }
  }

  numberOfInteractorsSelectOptionsClicked (): void {
    this.$emit('numberOfInteractorsSelectOptionsClickEvent')
  }

  selectHourSelectOptionsClicked (): void {
    this.$emit('selectHourSelectOptionsClickEvent')
  }

  async handleFetchDisabledDates (): Promise<void> {
    if (this.selectedDepartment.schedule_system === 'DAILY') {
      const result = await rentingEntityService.getDisabledDates(
        this.rentingEntityId
      )
      this.setDisabledDate(result)
      return
    }

    const result = await CompanyService.getDisabledDates()
    this.setDisabledDate(result.dates)
  }

  setDisabledDate (data: string[]): void {
    this.disabledDates = data.map((item: string) => {
      const date = new Date(item)
      return { start: date, end: date }
    })
  }

  async handleFetchAvailableHours (
    department: string,
    guests: number,
    date: Date
  ): Promise<void> {
    const hours = await BookingService.getAvailableHours(
      department,
      guests,
      date
    )

    this.availableHours = hours
    this.resetTime()
  }

  injectCustomCss (): void {
    if (this.canIApplyVisualSettings) {
      // this.injectDatesCss()
      this.injectTimePickersCss()
      this.injectInputsCss()
    }
  }

  // #endregion custom css for vc-date-picker
  // injectDatesCss (): void {
  //   // requests only date pickers
  //   // #region future elements
  //   const futureElements = document.querySelectorAll(
  //     'div.vc-weeks > div > span.vc-day-content.vc-focusable'
  //   )

  //   for (let i = 0; i < futureElements.length; i++) {
  //     const element = futureElements[i] as HTMLElement
  //     const color = this.$vuetify.theme.themes.light
  //       .gDatesBackgroundColorFutureDates as string

  //     element.style.color = color
  //   }
  //   // #endregion

  //   // #region present element
  //   const presentElements = document.querySelectorAll(
  //     'div.vc-weeks > div > span.vc-day-content.vc-focusable.is-today'
  //   )

  //   for (let i = 0; i < presentElements.length; i++) {
  //     const element = presentElements[i] as HTMLElement
  //     const color = this.$vuetify.theme.themes.light
  //       .gDatesBackgroundColorPresentDate as string

  //     element.style.color = color
  //   }

  //   // #endregion

  //   // #region past elements
  //   const elemnets = document.querySelectorAll(
  //     'div.vc-weeks > div > span.vc-day-content.vc-focusable.is-disabled'
  //   )

  //   for (let i = 0; i < elemnets.length; i++) {
  //     const element = elemnets[i] as HTMLElement
  //     const color = this.$vuetify.theme.themes.light
  //       .gDatesBackgroundColorPastDates as string

  //     element.style.color = color
  //   }

  //   // #endregion

  //   // #region weekdays
  //   const weekdays = document.querySelectorAll('div.vc-weeks > div')

  //   for (let i = 0; i < weekdays.length; i++) {
  //     const element = weekdays[i] as HTMLElement
  //     const color = this.$vuetify.theme.themes.light.gFontColor as string

  //     element.style.color = color
  //   }

  //   // #endregion

  //   // #region header title
  //   const headerTitle = document.querySelector('div.vc-title')

  //   if (headerTitle) {
  //     const element = headerTitle as HTMLElement
  //     const color = this.$vuetify.theme.themes.light.gFontColor as string

  //     element.style.color = color
  //     element.style.fontWeight = '400' // light: 300
  //   }

  //   // #endregion

  //   // #region selected present

  //   const style = document.createElement('style')

  //   const color = this.$vuetify.theme.themes.light
  //     .gDatesBackgroundColorCurrentDate as string

  //   // Set the innerHTML of the style element to your CSS
  //   style.innerHTML = `
  //                   .vc-container {
  //                     --zhgl-100: ${color};
  //                     --zhgl-200: ${color};
  //                     --zhgl-300: ${color};
  //                     --zhgl-400: ${color};
  //                     --zhgl-500: ${color};
  //                     --zhgl-600: ${color};
  //                     --zhgl-700: ${color};
  //                     --zhgl-800: ${color};
  //                     --zhgl-900: ${color};

  //                     &.vc-zhgl {
  //                       --accent-100: var(--zhgl-100);
  //                       --accent-200: var(--zhgl-200);
  //                       --accent-300: var(--zhgl-300);
  //                       --accent-400: var(--zhgl-400);
  //                       --accent-500: var(--zhgl-500);
  //                       --accent-600: var(--zhgl-600);
  //                       --accent-700: var(--zhgl-700);
  //                       --accent-800: var(--zhgl-800);
  //                       --accent-900: var(--zhgl-900);
  //                     }
  //                   }`

  //   // Append the style element to the document head
  //   document.head.appendChild(style)
  //   // #endregion
  // }
  // #endregion

  injectTimePickersCss (): void {
    const vcDate = document.querySelectorAll('span.vc-weekday')
    for (let i = 0; i < vcDate.length; i++) {
      const element = vcDate[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    const vcMonth = document.querySelectorAll('span.vc-month')
    for (let i = 0; i < vcMonth.length; i++) {
      const element = vcMonth[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    const vcDay = document.querySelectorAll('span.vc-day')
    for (let i = 0; i < vcDay.length; i++) {
      const element = vcDay[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    const vcYear = document.querySelectorAll('span.vc-year')
    for (let i = 0; i < vcYear.length; i++) {
      const element = vcYear[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    const vcIcon = document.querySelectorAll('svg.vc-time-icon')
    for (let i = 0; i < vcIcon.length; i++) {
      const element = vcIcon[i] as HTMLElement

      // set elements color style and make it important:
      element.style.stroke = 'var(--v-gFontColor-base)'
    }

    const vcSelect = document.querySelectorAll('div.vc-select')
    for (let i = 0; i < vcSelect.length; i++) {
      const element = vcSelect[i] as HTMLElement

      // set elements color style and make it important:
      element.style.stroke = 'var(--v-gCardBackgroundColor-base)'
    }
  }

  injectInputsCss (): void {
    // v-input__slot
    const vInput = document.querySelectorAll('div.v-input__slot')
    for (let i = 0; i < vInput.length; i++) {
      const element = vInput[i] as HTMLElement

      // set elements color style and make it important:
      element.style.background = 'var(--v-gCardBackgroundColor-base)'
      element.style.stroke = 'var(--v-gGeneralElementsBackgroundColor-base)'
    }

    // after selection
    const labelSelected = document.querySelectorAll('div.v-select__selections')
    for (let i = 0; i < labelSelected.length; i++) {
      const element = labelSelected[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }

    // after selection
    const label = document.querySelectorAll('label')
    for (let i = 0; i < label.length; i++) {
      const element = label[i] as HTMLElement

      // set elements color style and make it important:
      element.style.color = 'var(--v-gFontColor-base)'
    }
  }

  async created (): Promise<void> {
    await this.handleFetchDisabledDates()
    await this.fetchDepartment()
    await this.fetchHourlyBlockedDates()
    this.ensureDefaultDateNotBlocked()
    if (this.department.activity_system === 'BOOKING') {
      await this.handleFetchAvailableHours(
        this.departmentId,
        this.data.number_of_interactors,
        this.selectedDate
      )
      this.$emit('resetDate')
    }
    this.setUserRole()

    // inject css
    this.injectCustomCss()
  }

  ensureDefaultDateNotBlocked (): void {
    if (!this.hourlyBlockedDates.disabledDates) return
    const disableDates = clone(this.hourlyBlockedDates.disabledDates)

    disableDates.forEach((item) => {
      const tmpStart = dummyDateConverter(item.start)
      const tmpEnd = dummyDateConverter(item.end)
      // CHECKING THE FOLLOWING
      /*
        1. tmpStart-----from--------------------------------------tmpEnd
        2. tmpStart---------------------------to------------------tmpEnd
        3. from-----tmpStart---------------------------tmpEnd-----to
        4. tmpStart---------------------------from------to--------tmpEnd
      */
      if (
        (this.data.manualSelectedDate.from >= tmpStart &&
          this.data.manualSelectedDate.from <= tmpEnd) ||
        (this.data.manualSelectedDate.to >= tmpStart &&
          this.data.manualSelectedDate.to <= tmpEnd) ||
        (this.data.manualSelectedDate.from <= tmpStart &&
          this.data.manualSelectedDate.to >= tmpEnd) ||
        (this.data.manualSelectedDate.from >= tmpStart &&
          this.data.manualSelectedDate.to <= tmpEnd)
      ) {
        this.data.manualSelectedDate.date = addDays(
          this.data.manualSelectedDate.date,
          1
        )
        this.onManualSelectedDateChange()
        this.ensureDefaultDateNotBlocked() // <-- recursively calling and adding one day until we find a date that is not blocked
      }
    })
  }

  async fetchDepartment (): Promise<void> {
    this.department = await departmentService.getSinglePublic(this.departmentId)
  }

  async onInteractorsChange (): Promise<void> {
    if (!this.selectedDate) return
    if (this.department.activity_system === 'BOOKING') {
      await this.handleFetchAvailableHours(
        this.departmentId,
        this.data.number_of_interactors,
        this.selectedDate
      )
    }
  }

  async fetchHourlyBlockedDates (blockedDateId: string | null = null) {
    const result = (await getBlockedDatesAsync(
      this.company.id,
      this.departmentId,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.hourlyBlockedDates.blockedDates = [
      ...this.hourlyBlockedDates.blockedDates,
      ...result.blockedDates
    ]

    this.hourlyBlockedDates.disabledDates = [
      ...this.hourlyBlockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  setUserRole (): void {
    const userData = this.$store.state.auth.user
    if (userData) {
      this.userRole = userData.role
    }
  }

  get showMoreThanEightDialog (): boolean {
    if (!this.userRole && this.data.number_of_interactors) {
      return true
    }

    return false
  }

  async handleFetchAvailableDays (rentingEntityId: string): Promise<void> {
    const result = await BookingService.getDisabledDatesForARentingEntity(
      rentingEntityId
    )

    this.disabledDates = result.disabled_dates.map((item: string) => {
      const date = new Date(item)
      return { start: date, end: date }
    })
  }

  onManualSelectedDateChange (): void {
    const date = this.data.manualSelectedDate.date.getDate()
    const month = this.data.manualSelectedDate.date.getMonth()
    const year = this.data.manualSelectedDate.date.getFullYear()

    this.data.manualSelectedDate.from.setFullYear(year)
    this.data.manualSelectedDate.from.setMonth(month)
    this.data.manualSelectedDate.from.setDate(date)

    this.data.manualSelectedDate.to.setFullYear(year)
    this.data.manualSelectedDate.to.setMonth(month)
    this.data.manualSelectedDate.to.setDate(date)

    this.data.manualSelectedDate.datePickerKey++
  }

  resetTime (): void {
    this.data.time = ''
  }

  pickedDate (date: string): void {
    this.data.date = moment(date)
    // this.injectDatesCss()
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      if (this.department.activity_system === 'BOOKING') {
        const reservationTime = moment(this.data.time, 'HH:mm')

        this.data.date = this.data.date.set({
          hour: reservationTime.get('hour'),
          minute: reservationTime.get('minute'),
          second: reservationTime.get('second')
        })

        const date = this.data.date.toISOString()

        const isDateAvailableForDepartment =
          await this.isDateAvailableForDepartment(date, this.department.id)

        if (isDateAvailableForDepartment) {
          this.$toast.warning(
            this.$t(
              'notify.failure.public_booking.date_range_unavaialble_due_to_blocked_dates'
            ).toString()
          )
          return
        }

        this.$emit('dateInformationAdded', {
          number_of_interactors: this.data.number_of_interactors,
          date_from: date, // <- skipless but has clashes
          formattedDates: {
            dateFrom: format(this.data.date.toDate(), DATE_FORMAT)
          }
        })

        return
      }

      if (this.department.activity_system === 'REQUESTS_ONLY') {
        const dateFrom = this.data.manualSelectedDate.from.toISOString()
        const dateTo = this.data.manualSelectedDate.to.toISOString()

        const isDateRangeUnavailableForDepartment =
          await this.isDateRangeUnavailableForDepartment(
            dateFrom,
            dateTo,
            this.department.id
          )

        if (isDateRangeUnavailableForDepartment) {
          this.$toast.warning(
            this.$t(
              'notify.failure.public_booking.date_range_unavaialble_due_to_blocked_dates'
            ).toString()
          )
          return
        }

        this.$emit('dateInformationAdded', {
          number_of_interactors: this.data.number_of_interactors,
          date_from: dateFrom,
          date_to: dateTo,
          formattedDates: {
            dateFrom: format(
              new Date(this.data.manualSelectedDate.from),
              DATE_FORMAT
            ),
            dateTo: format(
              new Date(this.data.manualSelectedDate.to),
              DATE_FORMAT
            )
          },
          department: this.department
        })
      }
    }
  }

  private async isDateAvailableForDepartment (
    date: string,
    departmentId: string
  ) {
    const test = await _blockedDatesService.isDateUnavailableForDepartment(
      date,
      departmentId
    )

    return test.result
  }

  private async isDateRangeUnavailableForDepartment (
    dateFrom: string,
    dateTo: string,
    departmentId: string
  ) {
    const isDateRangeUnavailableForDepartment =
      await _blockedDatesService.isDateRangeUnavailableForDepartment(
        dateFrom,
        dateTo,
        departmentId
      )

    return isDateRangeUnavailableForDepartment.result
  }

  // #region Watchers
  @Watch('selectedDate')
  async onSelectedDateChange (date: Date | DateRange): Promise<void> {
    if (date) {
      if (this.department.schedule_system.toUpperCase() !== 'DAILY') {
        await this.handleFetchAvailableHours(
          this.departmentId,
          this.data.number_of_interactors,
          date as Date
        )

        return
      }

      await this.handleFetchAvailableDays(this.rentingEntityId)
    }
  }

  @Watch('data.manualSelectedDate.from')
  onManualSelectedDateFromChange (): void {
    if (this.data.manualSelectedDate) {
      if (
        isEqual(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }

      if (
        isAfter(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }
    }
  }

  @Watch('data.manualSelectedDate.to')
  onManualSelectedDateToChange (): void {
    if (this.data.manualSelectedDate) {
      if (
        isEqual(
          this.data.manualSelectedDate.from,
          this.data.manualSelectedDate.to
        )
      ) {
        this.data.manualSelectedDate.to = addMinutes(
          this.data.manualSelectedDate.from,
          10
        )
      }

      if (
        isBefore(
          this.data.manualSelectedDate.to,
          this.data.manualSelectedDate.from
        )
      ) {
        this.data.manualSelectedDate.from = subMinutes(
          this.data.manualSelectedDate.to,
          10
        )
      }
    }
  }

  @Watch('data.manualSelectedDate.date')
  onManualSelectedDateDateChange (): void {
    // wait 10ms to make sure the date is updated
    if (this.canIApplyVisualSettings) {
      setTimeout(() => {
        this.injectTimePickersCss()
      }, 10)
    }
  }
  // #endregion

  // #region Getters
  get currentLanguage (): string {
    return this.$i18n.locale
  }

  get currentUser (): string {
    return this.$store.state.auth.user?.role
  }

  get overallBookingInformation (): string {
    const overallBookingInformationSetting = this.company.settings?.find(
      (item: KeyValuSettingsInterface) =>
        item.key === 'overall_booking_information'
    )

    if (overallBookingInformationSetting) {
      const parsed: OverallBookingInformationInterface = parseJson(
        overallBookingInformationSetting.value
      )
      return this.currentLanguage === 'no'
        ? parsed.overall_booking_information_no
        : parsed.overall_booking_information_en
    }

    return ''
  }

  get notLoggedIn (): boolean {
    return (
      (!this.userRole || this.userRole === '') && this.visualSettings.active
    )
  }

  get isLoggedIn (): boolean {
    if (this.$store.state.auth.user.name) {
      return true
    }

    return false
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettings.active && !this.isLoggedIn
  }
  // #endregion
}
