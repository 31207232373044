
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import SurveyPreview from '@/views/surveys/SurveyPreview.vue'
import CardButtons from '@/common/components/ui/card/CardButtons.vue'
import CardButton from '@/common/components/ui/card/CardButton.vue'
import SurveyElement from '@/components/surveys/SurveyElement.vue'
import SurveyHeaderCard from '@/components/surveys/SurveyHeaderCard.vue'
import SurveyInitialQuestionCard from '@/components/surveys/SurveyInitialQuestionCard.vue'
// #endregion

// #region services
import SurveyService from '@/services/survey.service'
import CompanyService from '@/services/company.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #endregion

interface SurveyObject {
  active: boolean
  // eslint-disable-next-line
  company_id: string
  name: string
  // eslint-disable-next-line
  survey_questions: any[]
}

@Component({
  components: {
    SurveyPreview,
    SurveyHeaderCard,
    SurveyInitialQuestionCard,
    CardButtons,
    CardButton,
    SurveyElement
  }
})
export default class CreateSurvey extends Vue {
  dialog = false
  companyId = ''
  companyName = ''
  companies = [] as Array<CompanyInterface>
  rules = rules

  async created (): Promise<void> {
    await this.fetchCompanies()
  }

  preview (): void {
    this.dialog = true
  }

  survey: SurveyObject = {
    active: true,
    company_id: '',
    name: '',
    survey_questions: []
  }

  addTextualQuestion (): void {
    this.survey.survey_questions.push({
      question: '',
      required: true,
      type: 'textual'
    })
  }

  addRadioQuestion (): void {
    this.survey.survey_questions.push({
      question: '',
      options: [
        {
          value: '',
          type: 'option'
        },
        {
          value: '',
          type: 'option'
        }
      ],
      required: true,
      type: 'selection'
    })
  }

  addNestedQuestion (): void {
    this.survey.survey_questions.push({
      question: '',
      children: [],
      options: [],
      required: true,
      type: 'nested'
    })
  }

  addTextualQuestionToNestedQuestion (index: number): void {
    const items = this.survey.survey_questions
    items[index].children.push({
      question: '',
      required: true,
      type: 'textual'
    })
    this.survey.survey_questions = items
  }

  addRadioQuestionToNestedQuestion (index: number): void {
    const items = this.survey.survey_questions
    items[index].children.push({
      question: '',
      required: true,
      parent_options: true,
      options: [],
      type: 'selection'
    })

    this.survey.survey_questions = items
  }

  addParentalOptionsToNestedQuestion (index: number): void {
    const items = this.survey.survey_questions
    items[index].options.push({
      question: '',
      type: 'parent_options'
    })
    this.survey.survey_questions = items
  }

  addOptionToRadioQuestion (questionIndex: number): void {
    const items = this.survey.survey_questions
    items[questionIndex].options.push({
      question: '',
      type: 'option'
    })
    this.survey.survey_questions = items
  }

  addOptionToNestedRadioQuestion (
    questionIndex: number,
    nestedIndex: number
  ): void {
    const items = this.survey.survey_questions
    items[questionIndex].children[nestedIndex].options.push({
      value: '',
      type: 'nested_option'
    })
    this.survey.survey_questions = items
  }

  removeOptionFromRadioQuestion (
    questionIndex: number,
    optionIndex: number
  ): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options = this.survey.survey_questions[questionIndex].options as any[]
    const mutatedOptions = options.filter((_, index) => index !== optionIndex)

    const items = this.survey.survey_questions
    items[questionIndex].options = mutatedOptions
    this.survey.survey_questions = items
  }

  removeOptionFromNestedRadioQuestion (
    nestedIndex: number,
    questionIndex: number,
    optionIndex: number
  ): void {
    const item =
      this.survey.survey_questions[nestedIndex].children[questionIndex]

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options = item.options as any[]
    const mutatedOptions = options.filter((_, index) => index !== optionIndex)

    const items = this.survey.survey_questions
    items[nestedIndex].children[questionIndex].options = mutatedOptions
    this.survey.survey_questions = items
  }

  deleteQuestion (index: number): void {
    const items = this.survey.survey_questions.filter((_, i) => i !== index)
    this.survey.survey_questions = items
  }

  deleteNestedQuestion (nestedIndex: number, questionIndex: number): void {
    const items = this.survey.survey_questions
    items[nestedIndex].children = items[nestedIndex].children.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_: any, index: number) => index !== questionIndex
    )
    this.survey.survey_questions = items
  }

  deleteParentOption (nestedIndex: number, optionIndex: number): void {
    const items = this.survey.survey_questions
    items[nestedIndex].options = items[nestedIndex].options.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_: any, index: number) => index !== optionIndex
    )
    this.survey.survey_questions = items
  }

  switchOptions (index: number, innerIndex: number): void {
    const items = this.survey.survey_questions
    const item = items[index].children[innerIndex]
    item.parent_options = !item.parent_options
    items[index].children[innerIndex].parent_options = item.parent_options

    if (!item.parent_options && item.options.length === 0) {
      items[index].children[innerIndex].options.push({
        value: '',
        type: 'nested_option'
      })
    }

    this.survey.survey_questions = items
  }

  requiredRule = [(v: string): boolean | string => !!v || 'Field is required.']

  formValidity = false
  objectErrorMessage = ''

  validateInputs (): void {
    return (this.$refs.form as HTMLFormElement).validate()
  }

  validateObject (): void {
    this.formValidity = this.survey.survey_questions.length > 0
    if (!this.formValidity) {
      this.objectErrorMessage =
        'You should at least have one question present in the survey you are trying to create.'
    }
  }

  @Watch('survey.survey_questions')
  surveyQuestions (): void {
    if (this.survey.survey_questions.length > 0 && this.objectErrorMessage) {
      this.objectErrorMessage = ''
    }
  }

  @Watch('companyId')
  setCompanyId (): void {
    this.survey.company_id = this.companyId
    const companyName = this.companies.find(
      (company: CompanyInterface) => company.id === this.companyId
    )
    this.companyName = companyName !== undefined ? companyName.name : ''
  }

  async save (): Promise<void> {
    this.validateInputs()
    this.validateObject()

    await SurveyService.create(this.survey)

    this.$toast.success(i18n.t('notify.success.surver_added').toString())

    this.$router.push('surveys')
  }

  async fetchCompanies (): Promise<void> {
    const result = await CompanyService.getActiveWithoutPagination()
    this.companies = result
  }
}
