
// #region imports

// #region vue
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class ExpandableContainer extends Vue {
  @Prop({ default: 'title' }) title!: string

  // #region data
  expanded = false
  // #endregion

  toggleExpansion (): void {
    this.expanded = !this.expanded
  }
}
