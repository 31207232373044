// #region imports

// #region vue
import router from '@/router'
import { Commit } from 'vuex'
// #endregion

// #region interfaces
import { AccountStateInterface } from '@/types/interfaces/setting'
import { UserInterface } from '@/types/interfaces/user'
// #endregion

// #endregion

const user = JSON.parse(localStorage.getItem('user') || '{}')

const initialState = user
  ? {
      status: { loggedIn: true },
      user,
      accountState: {
        isDemo: user.is_demo,
        parentDomain: user.parent_domain
      }
    }
  : { status: { loggedIn: false }, user: null, accountState: null }
export interface AuthState {
  status: {
    loggedIn: boolean
  }
  user: UserInterface | null
  accountState: AccountStateInterface | null
}

export default {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }: { commit: Commit }, user: UserInterface): void {
      commit('loginSuccess', user)
      commit('setAccountState', {
        isDemo: user.is_demo,
        parentDomain: user.parent_domain
      })
    },
    logout ({ commit }: { commit: Commit }): void {
      commit('logoutUser')
    },
    domainWizardCompleted ({ commit }: { commit: Commit }): void {
      commit('domainWizardCompleted')
    }
  },
  getters: {
    getUser (state: AuthState): boolean {
      return !!(state.user && state.user.access_token)
    },
    getUserData (state: AuthState): UserInterface | null {
      return state.user
    },
    getAccountState (state: AuthState): AccountStateInterface | null {
      return state.accountState
    }
  },
  mutations: {
    loginSuccess (state: AuthState, user: UserInterface): void {
      state.status.loggedIn = true
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    logoutUser (state: AuthState): void {
      const user = state.user
      state.status.loggedIn = false
      state.user = null
      state.accountState = null

      localStorage.removeItem('user')

      const path = getPath(user)
      router.push(path)
    },
    domainWizardCompleted (state: AuthState): void {
      const user = state.user
      if (user !== null) {
        user.full_domain.wizard_completed = true
        localStorage.setItem('user', JSON.stringify(user))
      }
    },
    setAccountState (state: AuthState, payload: AccountStateInterface): void {
      state.accountState = { ...payload }
      if (state.user) {
        state.user.is_demo = state.accountState.isDemo
        localStorage.setItem('user', JSON.stringify(state.user))
      }
    }
  }
}

const getPath = (user: UserInterface | null): string => {
  if (user && user.domain) {
    return `/${user.domain}/auth/signin`
  }

  return '/login'
}
