
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region data
import { get } from 'lodash'
// #endregion

// #region interfaces
import { TableHeaderInterface } from '@/types/interfaces/ui'
// #endregion

// #endregion

@Component
export default class CustomTableRender extends Vue {
  // eslint-disable-next-line
  @Prop({ required: true }) readonly item!: any
  // eslint-disable-next-line
  @Prop({ required: true }) readonly header!: TableHeaderInterface

  // eslint-disable-next-line
  getValue(item: any, path: string, header: any): any {
    if (!header.key) {
      return item
    }

    return get(item, path)
  }

  // eslint-disable-next-line
  getValues(
    // eslint-disable-next-line
    item: any,
    keys: Array<string> | string = [],
    // eslint-disable-next-line
    header: any
    // eslint-disable-next-line
  ): Array<any> {
    if (!header.keys) {
      return item
    }

    const data = []
    if (Array.isArray(keys)) {
      for (let i = 0; i < keys.length; i++) {
        data.push(item[keys[i]])
      }
      return data
    }

    return item[keys]
  }
}
