
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class InformationCard extends Vue {
  @Prop() readonly icon!: string
  @Prop() readonly title!: string
  @Prop() readonly description!: string
  @Prop({ default: 'white' }) readonly cardColor!: string
  @Prop({ default: 'black' }) readonly textColor!: string
  @Prop({ default: 'default' }) readonly iconColor!: string
  @Prop({ default: false }) readonly clickable!: boolean
  @Prop({ default: '' }) readonly cardClass!: string
}
