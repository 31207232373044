
// # region imports
import { Component, Prop, Vue } from 'vue-property-decorator'

// #region components
import ExpandableContainer from '@/containers/settings/ExpandableContainer.vue'
// # endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import {
  CompanySettingInterface,
  OverallBookingInformationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import _settingService from '@/services/setting.service'
// #endregion

// #region utils
import { parseJson } from '@/utils/helper.utils'
import { IMAGE_SOURCES } from '@/common/constants'
// #endregion

// #endregion

@Component({
  name: 'OverallBookingInformationSetting',
  components: {
    ExpandableContainer
  }
})
export default class OverallBookingInformationSetting extends Vue {
  // #region props
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  // #endregion

  // #region injections
  rules = rules
  IMAGE_SOURCES = IMAGE_SOURCES
  // #endregion

  // #region data
  data = {
    overall_booking_information_no: '',
    overall_booking_information_en: ''
  } as OverallBookingInformationInterface
  // #endregion

  // #region hooks
  async created (): Promise<void> {
    await this.fetchOverralBookingInformation()
  }
  //   #endregion

  // #region methods
  async resetOverallBookingInformationSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): Promise<void> {
    const settingsToUse = fetchedSettings || this.settings
    const overallBookingInformationSettings =
      settingsToUse.overall_booking_information

    if (overallBookingInformationSettings) {
      this.data = parseJson(overallBookingInformationSettings.toString())
    } else {
      this.resetAll()
    }
  }

  resetForm (formName: string): void {
    const form = this.$refs[formName] as HTMLFormElement
    if (form) {
      form.reset()
      form.resetValidation()
    }
  }

  resetData (): void {
    this.data = {} as OverallBookingInformationInterface
  }

  resetAll (): void {
    this.resetForm('overallBookingInformationForm')
    this.resetData()
  }
  // #endregion

  //   #region backend
  async submitOverallBookingInformation (): Promise<void> {
    const form = this.$refs.overallBookingInformationForm as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      await _settingService.upsertOverallBookingInformation(
        this.companyId,
        this.data
      )

      this.$toast.success(
        this.$t(
          'component.overall_booking_information.saved_successfully'
        ).toString()
      )
    }
  }

  async deleteOverallBookingInformation (): Promise<void> {
    await _settingService.deleteCompanySettingByKey(
      this.companyId,
      'overall_booking_information'
    )

    this.$toast.success(
      this.$t(
        'component.overall_booking_information.deleted_successfully'
      ).toString()
    )

    this.resetAll()
  }

  async fetchOverralBookingInformation (): Promise<void> {
    const fetchedSettings = await _settingService.getCompanySettings(
      this.companyId
    )

    await this.$nextTick()

    await this.resetOverallBookingInformationSettings(fetchedSettings)
  }

  //   #endregion
}
