
import { Vue, Component, Prop } from 'vue-property-decorator'
import Question from '@/components/surveys/Question.vue'
import rules from '@/utils/rules.utils'
import i18n from '@/i18n'

@Component({
  components: {
    Question
  }
})
export default class TextQuestion extends Vue {
  @Prop({
    required: false,
    default: () => i18n.t('page.surveys.text_question_default_text')
  })
  readonly text!: string

  @Prop({ required: true }) readonly question!: string
  @Prop({ required: false }) readonly answer!: string
  @Prop({ required: false }) readonly required!: boolean

  rules = rules
}
