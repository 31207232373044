
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import SurveyPreview from '@/views/surveys/SurveyPreview.vue'
// #endregion

// #region interfaces
import {
  SurveyChartInterface,
  SurveyViewInterface
} from '@/types/interfaces/survey'
// #endregion
import SurveyService from '@/services/survey.service'

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewItems,
    PageOverviewItem,
    SurveyPreview
  }
})
export default class Survey extends Vue {
  survey = {} as SurveyViewInterface

  async handleDepartmentsFetch (): Promise<SurveyViewInterface> {
    return await SurveyService.getSingle(this.$route.params.id)
  }

  async created (): Promise<void> {
    const result = await this.handleDepartmentsFetch()
    this.setData(result)
    this.formatSelection()
  }

  setData (result: SurveyViewInterface): void {
    this.survey = result
  }

  formatSelection (): void {
    this.survey.feedback.selection.forEach((item: SurveyChartInterface) => {
      item.chart = this.chart
      item.title = {
        text: item.question,
        offsetX: 0,
        style: {
          fontSize: '14px'
        }
      }
    })
    this.questions = this.survey.feedback.selection
  }

  dialog = false

  viewParent (): void {
    this.dialog = !this.dialog
  }

  chart = {
    type: 'donut'
  }

  title = {
    text: '',
    offsetX: 0,
    style: {
      fontSize: '14px'
    }
  }

  questions: SurveyChartInterface[] = []
  // questions = [
  //   {
  //     question: 'How was your visit to out Restaurant?',
  //     children: [
  //       {
  //         question: 'Food',
  //         chart: this.chart,
  //         series: [50, 50],
  //         labels: ['Excellent', 'Good', 'Bad', 'Whatever', 'Idk'],
  //         title: { ...this.title, text: 'Food' }
  //       },
  //       {
  //         question: 'Service',
  //         chart: this.chart,
  //         series: [43, 37, 20],
  //         labels: ['Excellent', 'Good', 'Bad'],
  //         title: { ...this.title, text: 'Service' }
  //       },
  //       {
  //         question: 'Value for money',
  //         chart: this.chart,
  //         series: [70, 16, 14],
  //         labels: ['Excellent', 'Good', 'Bad'],
  //         title: { ...this.title, text: 'Value for money' }
  //       },
  //       {
  //         question: 'Atmosphere',
  //         chart: this.chart,
  //         series: [0, 37, 63],
  //         labels: ['Excellent', 'Good', 'Bad'],
  //         title: { ...this.title, text: 'Atmosphere' }
  //       }
  //     ]
  //   },
  //   {
  //     question: 'Would you recommend us to your friends?',
  //     chart: this.chart,
  //     series: [87, 13],
  //     labels: ['YES', 'NO'],
  //     title: { ...this.title }
  //   },
  //   {
  //     question: 'TEST with 3 questions?',
  //     chart: this.chart,
  //     series: [87, 13],
  //     labels: ['YES', 'NO'],
  //     title: { ...this.title }
  //   }
  // ]

  questions2: SurveyChartInterface[] = []
}
