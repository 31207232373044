
// #region imports

// #region vue
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { BookingInterface } from '@/types/interfaces/booking'
// #endregion

// #region components
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region utils
import { defaultIncoming } from '@/utils/date.utils'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #endregion

@Component({
  name: 'FilteredPrintableBookings',
  methods: {
    defaultIncoming
  }
})
export default class FilteredPrintableBookings extends Vue {
  created (): void {
    const bookingsString = localStorage.getItem('bookings')
    if (bookingsString) {
      const bookings = JSON.parse(bookingsString)
      this.data.bookings = bookings

      setTimeout(() => {
        window.print()
      }, 1000)
    }
  }

  // injections - start
  _defaultIncoming (date: string | Date): string {
    return defaultIncoming(date, 'PP HH:mm')
  }
  // injections - end

  // methods - start
  composeRentingEntityNames (
    rentingEntities: RentingEntityInterface[] | undefined
  ): string {
    if (!rentingEntities) return ''
    return rentingEntities
      .map((rentingEntity: RentingEntityInterface) => rentingEntity.name)
      .join(', ')
  }

  extractTags (bookings: BookingInterface | undefined): string {
    if (!bookings) return ''

    if (bookings.properties_count === 0) return ''

    const tags = bookings.properties?.find(
      (property) => property.key === 'tags'
    )

    if (!tags) return ''

    // check if tags is an array
    if (Array.isArray(tags.value)) {
      return tags.value.join(', ')
    } else {
      const _tags = JSON.parse(tags.value as string)
      if (Array.isArray(_tags)) {
        return _tags.join(', ')
      }
    }

    return ''
  }
  // methods - end

  data = {
    bookings: [] as BookingInterface[]
  }

  headers = [
    '#',
    i18n.t('table.col.rented').toString().toUpperCase(),
    i18n.t('table.col.client').toString().toUpperCase(),
    i18n.t('table.col.department').toString().toUpperCase(),
    i18n.t('table.col.company').toString().toUpperCase(),
    i18n.t('table.col.interactors').toString().toUpperCase(),
    i18n.t('table.col.from').toString().toUpperCase(),
    i18n.t('table.col.to').toString().toUpperCase(),
    'TAGS'
  ]
}
