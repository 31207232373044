
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RoleInterface, ProfileInterface } from '@/types/interfaces/user'
// #endregion

// #region services
import staffService from '@/services/staff.service'
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

const passwordInitialdata = () => ({
  old_password: '',
  password: '',
  password_confirmation: ''
})

@Component({
  computed: { ...mapState('auth', ['user']) }
})
export default class AccountSettings extends Vue {
  showChangePassword = false
  showCurrentPassword = false
  showPassword = false
  rules = rules

  profile = {} as ProfileInterface
  data = {} as ProfileInterface

  passwordChange = passwordInitialdata()

  assignedCompany (): string {
    return this.data.company.name
  }

  get assignedDepartment (): string {
    return this.data.department.name
  }

  roles = [] as RoleInterface[]
  companies = [] as CompanyInterface[]
  departments = [] as DepartmentInterface[]

  async fetchRoles (): Promise<void> {
    const roles = await staffService.getRoles()
    this.roles = roles
  }

  async fetchCompanies (): Promise<void> {
    const result = await companyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchDepartments (): Promise<void> {
    const result = await departmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async fetchProfile (): Promise<void> {
    const profile = await staffService.getProfile()
    this.data = profile
    this.profile = { ...profile }
  }

  async created (): Promise<void> {
    await this.fetchRoles()
    await this.fetchCompanies()
    await this.fetchDepartments()
    await this.fetchProfile()
  }

  profileFormValidaty = false

  async submitProfileInfo (): Promise<void> {
    const form = this.$refs.profileForm as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      await staffService.updateProfile(this.data)
      await this.fetchProfile()
      this.$toast.success(i18n.t('notify.success.profile_updated').toString())
    }
  }

  passwordChangeFormValidaty = false

  async submitPasswordChange (): Promise<void> {
    const form = this.$refs.passwordChangeForm as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      await staffService.changePassword({
        id: this.data.id,
        ...this.passwordChange
      })
      this.$toast.success(i18n.t('notify.success.password_changed').toString())
      this.passwordChange = passwordInitialdata()
      this.showChangePassword = false
    }
  }
}
