
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region services
import CompanyService from '@/services/company.service'
// #endregion

// #region utils & helpers
import { getDomain } from '@/utils/helper.utils'
import { AddressInterface } from '@/types/interfaces/address'
// #endregion

// #endregion

@Component
export default class Book extends Vue {
  @Prop({ default: false }) readonly row!: boolean
  @Prop({ required: false }) readonly backed!: boolean
  @Prop({ default: true }) readonly arrowRight!: boolean

  companies = [] as Array<CompanyInterface>

  async fetchAllCompanies (): Promise<void> {
    const domain = getDomain()

    const result = await CompanyService.getActiveWithoutPaginationPublic(domain)

    this.companies = result
  }

  @Watch('companies')
  async onCompaniesChange (): Promise<void> {
    if (this.companies.length === 1 && !this.backed) {
      this.$emit('selectedCompany', this.companies[0])
    }
    this.$emit('isOnlyOneCompanyEvent', this.isOnlyOneCompany)
  }

  async created (): Promise<void> {
    await this.fetchAllCompanies()
    this.$emit('resetVisualSettingsEvent')
  }

  get isOnlyOneCompany (): boolean {
    return this.companies.length === 1
  }

  formatFullAddress (address: AddressInterface | undefined): string {
    if (!address) return ''
    return `${address.address}, ${address.city}, ${address.zip}`
  }
}
