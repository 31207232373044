
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewDescription from '@/common/components/pages/PageOverviewDescription.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'
import NonInteractableTable from '@/common/components/ui/table/NonInteractableTable.vue'
import MoreThanOneRentingEntity from '@/components/renting-entities/MoreThanOneRentingEntity.vue'
import BookingsDate from '@/components/bookings/BookingsDate.vue'
// #endregion

// #region interfaces
import { ClientInterface } from '@/types/interfaces/client'
// #endregion

// #region services
import ClientService from '@/services/client.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewDescription,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard,
    NonInteractableTable
  }
})
export default class Client extends Vue {
  client = {} as ClientInterface

  async handleClientFetch (): Promise<void> {
    const result = (await ClientService.getSingle(
      this.$route.params.id
    )) as ClientInterface
    this.client = result
  }

  async created (): Promise<void> {
    await this.handleClientFetch()
  }

  headers = [
    {
      text: i18n.t('table.col.rented'),
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'renting_entities',
      render: MoreThanOneRentingEntity
    },
    {
      text: i18n.t('table.col.department'),
      value: 'department.name'
    },
    {
      text: i18n.t('table.col.department'),
      value: 'company.name'
    },
    {
      text: i18n.t('common.guests'),
      value: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    }
  ]
}
