import SigninView from '@/views/auth/SigninView.vue'
import DomainSignInView from '@/views/auth/DomainSignInView.vue'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import SignupView from '@/views/auth/SignupView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'

const authRoutes = [
  {
    path: '/login',
    name: 'domain-signin',
    component: DomainSignInView,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/:domain/auth/signin',
    name: 'company-auth-signin',
    component: SigninView,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/:domain/auth/forgot-password',
    name: 'auth-forgot-password',
    component: ForgotPasswordView,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/auth/signup',
    name: 'auth-signup',
    component: SignupView,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/:domain/auth/reset-password/:token',
    name: 'reset-password',
    component: ResetPasswordView,
    meta: {
      layout: 'auth'
    }
  }
]

export default authRoutes
