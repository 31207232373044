
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: {
    ...mapState('app', ['product'])
  }
})
export default class InformativeLayout extends Vue {
  language = this.$i18n.locale
  languageTitle = ''

  created (): void {
    this.$i18n.locale = this.language
    if (this.language === 'en-us') {
      this.languageTitle = 'Bytt til norsk'
    } else {
      this.languageTitle = 'Switch to English'
    }
  }

  toggleLanguage (): void {
    if (this.language === 'en-us') {
      this.language = 'no'
    } else {
      this.language = 'en-us'
    }
    localStorage.setItem('language', this.language)
    window.location.reload()
  }
}
