
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { UserInterface } from '@/types/interfaces/user'
// #endregion

// #endregion

@Component
export default class Interactor extends Vue {
  @Prop({ required: true })
  readonly item!: UserInterface
}
