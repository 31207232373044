import FullDocumentation from '@/views/documentation/FullDocumentation.vue'

const documentationRoutes = [
  {
    path: '/v1/quick-start-guide',
    name: 'quick-start-guide',
    component: FullDocumentation,
    meta: {
      layout: 'default',
      auth: false
    }
  }
]

export default documentationRoutes
