
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Store from '@/store'
// #endregion

// #endregion

@Component({ computed: { ...mapState('auth', ['user']) } })
export default class DisabledAccount extends Vue {
  user = this.$store.state.user
  show = false

  @Watch('user')
  updateShow (): void {
    if (this.user) {
      this.show = this.user && !this.user.is_usable
    }
  }

  logout (): void {
    Store.dispatch('auth/logout')
    this.show = false
    this.$router.replace('/login')
  }
}
