
import i18n from '@/i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InitialQuestion extends Vue {
  @Prop({
    required: true,
    default: () => i18n.t('page.surveys.initial_question')
  })
  readonly question!: string

  @Prop({
    required: true,
    default: () => i18n.t('page.survyes.intial_question_info')
  })
  readonly info!: string
}
