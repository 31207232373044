
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region services
import categoryService from '@/services/category.service'
import { BookingInterface } from '@/types/interfaces/booking'
// #endregion

// #endregion

@Component
export default class BookCategoryCard extends Vue {
  @Prop({ required: true }) readonly departmentId!: string
  @Prop({ required: true }) readonly setCategory!: () => void
  @Prop({ required: false, default: '' }) readonly selectedCategory!: string
  @Prop({ required: false, default: 1 }) readonly hasRentingEntities!: number
  @Prop({ required: false, default: false }) readonly allowTitle!: boolean
  @Prop({ required: false, default: {} }) readonly item!: BookingInterface

  rules = rules

  locale = i18n.locale

  valid = false
  categories: CategoryInterface[] = []

  async created (): Promise<void> {
    await this.fetchCategories()
  }

  async fetchCategories (): Promise<void> {
    const result = await categoryService.getAvailableCategories({
      departmentId: this.departmentId,
      hasRentingEntities: this.hasRentingEntities,
      numberOfInteractors: this.item.number_of_interactors || null,
      dateFrom: this.item.date_from || null,
      dateTo: this.item.date_to || null
    })
    this.categories = result
  }

  // #region computed properties
  isSelectedCategoryEqual (category: CategoryInterface): boolean {
    return this.selectedCategory === category.id
  }
  // #endregion
}
