
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import SmsReminder from '@/containers/settings/notifications/SmsReminder.vue'
import SmsSubscription from '@/containers/settings/notifications/SmsSubscription.vue'
import SmsUpdate from '@/containers/settings/notifications/SmsUpdate.vue'
import SmsConfirmation from '@/containers/settings/notifications/SmsConfirmation.vue'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    SmsReminder,
    SmsSubscription,
    SmsUpdate,
    SmsConfirmation
  }
})
export default class NotificationSettings extends Vue {
  @Prop({ required: true }) readonly selectedCompany!: string
  @Prop({ required: true }) readonly data!: CompanySettingInterface
  @Prop({ required: true }) readonly setData!: (
    data: CompanySettingInterface
  ) => void

  rules = rules

  tab = 'sms_reminder'

  items = [
    {
      key: 'sms_reminder',
      label: `${i18n.t('common.sms')} ${i18n.t('common.reminder')}`
    },
    {
      key: 'sms_update',
      label: `${i18n.t('common.sms')} ${i18n.t('settings.update')}`
    },
    {
      key: 'sms_confirmation',
      label: `${i18n.t('common.sms')} ${i18n.t('settings.confirmation')}`
    }
  ]

  async fetchSettings (): Promise<void> {
    const result = await settingService.getCompanySettings(this.selectedCompany)
    this.setData(result)
  }

  async toggleNotificationSettings (setting: string): Promise<void> {
    await settingService.toggleNotificationSetting(
      this.selectedCompany,
      setting
    )

    await this.fetchSettings()
  }
}
