
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class TravelDocumentType extends Vue {
  @Prop({ required: true }) readonly item!: string

  get color (): string {
    switch (this.item) {
      case 'passport':
        return 'blue lighten-4'
      case 'id':
        return 'yellow lighten-4'
      case 'drivers_license':
        return 'orange lighten-4'
      default:
        return ''
    }
  }
}
