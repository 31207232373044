import i18n from '@/i18n'
export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi-view-dashboard',
          key: 'dashboard',
          text: i18n.t('table.title.dashboard'),
          link: '/'
        },
        {
          text: i18n.t('table.title.calendar'),
          key: 'calendar',
          icon: 'mdi-calendar',
          items: [
            {
              icon: 'mdi-clock-digital',
              key: 'calendar-view',
              text: i18n.t('table.title.calendar-hourly'),
              link: '/calendaric-view',
              meta: {
                role: ['admin', 'host', 'employee']
              }
            },
            {
              icon: 'mdi-hours-24',
              key: 'calendar-view-daily',
              text: i18n.t('table.title.calendar-daily'),
              link: '/calendaric-view-daily',
              meta: {
                role: ['admin', 'host', 'employee']
              }
            }
          ]
        },
        {
          icon: 'mdi-book',
          key: 'companies',
          text: i18n.t('table.title.companies'),
          link: '/companies',
          meta: {
            role: ['admin'],
            shouldNotHave: ['company_id']
          }
        },
        {
          icon: 'mdi-store',
          key: 'departments',
          text: i18n.t('table.title.departments'),
          link: '/departments',
          meta: {
            role: ['admin'],
            shouldNotHave: ['department_id']
          }
        },
        {
          icon: 'mdi-ceiling-light',
          key: 'renting-entities',
          text: i18n.t('table.title.renting_entities'),
          link: '/renting-entities',
          meta: {
            role: ['admin']
          }
        },
        {
          icon: 'mdi-comma-box',
          key: 'categories',
          text: i18n.t('table.title.categories'),
          link: '/categories',
          meta: {
            role: ['admin', 'host'],
            shouldNotHave: ['department_id']
          }
        },
        {
          icon: 'mdi-receipt',
          key: 'booking',
          text: i18n.t('table.title.bookings'),
          link: '/bookings'
        },
        {
          icon: 'mdi-file-alert',
          key: 'booking_requests',
          text: i18n.t('table.title.booking_requests'),
          link: '/booking-requests',
          meta: {
            role: ['admin', 'host', 'observer']
          }
        },
        {
          icon: 'mdi-account-supervisor-circle',
          key: 'staff',
          text: i18n.t('table.title.staff'),
          link: '/staff-members',
          meta: {
            role: ['admin']
          }
        },
        {
          icon: 'mdi-account-group',
          key: 'clients',
          text: i18n.t('table.title.clients'),
          link: '/clients',
          meta: {
            role: ['admin', 'host']
          }
        },
        {
          icon: 'mdi-test-tube',
          key: 'surveys',
          text: i18n.t('card.subtitle.surveys'),
          link: '/surveys',
          meta: {
            role: ['admin']
          }
        },
        {
          icon: 'mdi-file-chart',
          key: 'reports',
          text: i18n.t('table.title.reports'),
          link: '/reports',
          meta: {
            role: ['admin']
          }
        },
        {
          icon: 'mdi-cog',
          key: 'settings',
          text: i18n.t('settings.title'),
          link: '/settings',
          meta: {
            role: ['admin']
          }
        }
      ]
    }
  ],

  // footer links
  footer: []
}
