
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
// #endregion

// #region services
import categoryService from '@/services/category.service'
// #endregion
// #endregion

@Component({
  components: { Table },
  computed: { ...mapState('app', ['isLoading']) }
})
export default class DeletedCategories extends Vue {
  headers = [
    { text: i18n.t('table.col.name'), value: 'name' },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    { text: i18n.t('table.col.company'), value: 'company.name' }
  ]

  categories: CategoryInterface[] = []

  async handleCategoriesFetch (): Promise<void> {
    const result = await categoryService.getAll('deleted')
    this.categories = result.categories
  }

  async created (): Promise<void> {
    await this.handleCategoriesFetch()
  }
}
