
import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewDescription from '@/common/components/pages/PageOverviewDescription.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewDescription,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard
  }
})
export default class Owner extends Vue {
  owner = {
    id: 'b23804c2-ef98-4eb9-96c5-44d67f8dd307',
    name: 'Agon Aziri',
    email: 'agonn.aa@gmail.com',
    frontline_tenent_id: null,
    created_at: '2021-01-01'
  }
}
