
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CardButton extends Vue {
  @Prop({ default: '' }) readonly icon!: string
  @Prop() readonly title!: string
  @Prop({ default: '' }) readonly subtitle!: string
  @Prop({ default: true }) readonly clickable!: boolean
}
