// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

class CategoryService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url = type.length ? `/categories/${type}` : '/categories'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getAvailableCategories ({
    departmentId = null,
    companyId = null,
    hasRentingEntities = 1,
    numberOfInteractors = null,
    dateFrom = null,
    dateTo = null
  }: {
    departmentId?: string | null
    companyId?: string | null
    hasRentingEntities?: number | null
    numberOfInteractors?: number | null
    dateFrom?: string | null
    dateTo?: string | null
  }): Promise<CategoryInterface[]> {
    const rentingEntities = hasRentingEntities === 1 ? 1 : 0
    Store.dispatch('app/loading', true)

    const url = `/available-categories/${rentingEntities}`

    const result = await this.axios(url, {
      params: {
        companyId,
        departmentId,
        numberOfInteractors,
        dateFrom,
        dateTo
      }
    })

    Store.dispatch('app/loading', false)

    return result.data.categories
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/categories/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.booking
  }

  async create (data: CategoryInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/categories', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async update (data: CategoryInterface, id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.put(`/categories/${id}`, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async delete (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/categories/${id}`, {
      method: 'DELETE'
    })

    Store.dispatch('app/loading', false)

    return result
  }
}

export default new CategoryService()
