
import { Component, Vue, Watch } from 'vue-property-decorator'
import Store from '@/store'

@Component
export default class ErrorModal extends Vue {
  get inLoginRoute (): boolean {
    return ['auth-signin', 'company-auth-signin'].includes(
      this.$route.name || ''
    )
  }

  error = Store.getters['app/getError']
  show = false

  @Watch('error')
  updateShow (): void {
    if (this.error.message.length > 0 && !this.inLoginRoute) {
      this.show = true
    }
  }

  created (): void {
    if (this.error.message.length > 0 && !this.inLoginRoute) {
      this.show = true
    }
  }

  close (): void {
    if (this.error.action === 'logout') {
      this.show = false
      Store.dispatch('auth/logout')
    }
    this.show = false
  }
}
