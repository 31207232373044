
import i18n from '@/i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SurveyHeaderTitle extends Vue {
  @Prop({
    required: true,
    default: () => i18n.t('page.surveys.survey_header_default_title')
  })
  readonly entityTitle!: string
}
