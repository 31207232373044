
import { Component, Vue } from 'vue-property-decorator'

// Components
import ActiveCategories from '@/views/categories/_ActiveCategories.vue'
import DeletedCategories from '@/views/categories/_DeletedCategories.vue'

import i18n from '@/i18n'

import { getAppTitle } from '@/utils/helper.utils'

@Component({
  components: {
    ActiveCategories,
    DeletedCategories
  }
})
export default class Categories extends Vue {
  tab = 'pendingRequests'

  items = [
    { key: 'activeCategories', label: i18n.t('input.label.activeCategories') },
    { key: 'deletedCategories', label: i18n.t('input.label.deletedCategories') }
  ]

  created (): void {
    document.title = getAppTitle('Categories')
  }
}
