
import { debounce } from 'lodash'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class TableSearch extends Vue {
  @Prop({ required: true, default: false }) searchable!: boolean
  @Prop({ required: true, default: false }) debouncedSearch!: boolean
  @Prop({ required: true, default: false }) readonly showCustomFilter!: boolean

  debounceTime = 500 // time in ms

  showSearch = false

  debouncedSearchHandler = debounce(
    (event: string) => this.$emit('searchHandler', event),
    this.debounceTime
  )

  searchHandler = (event: string): void => {
    this.$emit('searchHandler', event)
  }

  @Watch('showSearch')
  refetch (): void {
    if (!this.showSearch && this.searchable) this.$emit('refetch')
  }
}
