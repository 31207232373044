import Departments from '@/views/departments/Departments.vue'
import Department from '@/views/departments/Department.vue'

const departmentRoutes = [
  {
    path: '/departments',
    name: 'departments',
    component: Departments,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/department/:id',
    name: 'department',
    component: Department,
    meta: {
      auth: true,
      role: ['admin']
    }
  }
]

export default departmentRoutes
