var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"w-full"},[(!_vm.successfulSent)?_c('div',[_c('v-card',{staticClass:"tw-max-w-[70%] tw-divide-y tw-p-4 tw-mx-auto",attrs:{"flat":""}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-3"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-w-full"},[_c('div',[_c('SurveyHeaderTitle',{attrs:{"entityTitle":_vm.data.company_name}})],1)]),_c('QuestionTitlePill',{attrs:{"question":_vm.$t('page.surveys.placeholders.question'),"type":"bordered"}}),_c('InitialQuestion',{attrs:{"question":_vm.data.name
              ? _vm.data.name
              : _vm.$t('page.surveys.placeholders.initial_question'),"info":_vm.getSubtitle()}})],1),_c('div',{staticClass:"tw-mt-5 tw-pt-5"},[_vm._l((_vm.data.survey_questions),function(entry,index){return [(entry.type === 'textual')?[_c('TextQuestion',{key:entry.id,attrs:{"question":entry.question
                  ? entry.question
                  : _vm.$t('page.surveys.placeholders.textual_question'),"required":entry.required},model:{value:(entry.answer),callback:function ($$v) {_vm.$set(entry, "answer", $$v)},expression:"entry.answer"}})]:_vm._e(),(entry.type === 'selection')?[(!_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{key:'radio_question_' + index,staticClass:"tw-mt-5 tw-pt-5"},[_c('div',{staticClass:"tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-items-start"},[_c('Question',{attrs:{"question":entry.question
                        ? entry.question
                        : _vm.$t('page.surveys.placeholders.radio_question'),"required":entry.required}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-1"},[_c('RadioOption',{key:entry.id,attrs:{"name":entry.question,"options":_vm.getOptions(entry),"answer":entry.answer},model:{value:(entry.answer),callback:function ($$v) {_vm.$set(entry, "answer", $$v)},expression:"entry.answer"}})],1)])])]:[_c('Question',{key:index,attrs:{"question":entry.question
                    ? entry.question
                    : _vm.$t('page.surveys.placeholders.radio_question'),"required":entry.required}}),_c('v-select',{key:entry.id,staticClass:"tw-w-full tw-px-2 tw-pt-2 customSelect",attrs:{"flat":"","solo":"","placeholder":_vm.$t('page.surveys.placeholders.inner_select'),"outlined":"","color":"#3bbd26","item-color":"green darken-2","dense":"","hide-details":"","items":_vm.getOptions(entry)},model:{value:(entry.answer),callback:function ($$v) {_vm.$set(entry, "answer", $$v)},expression:"entry.answer"}})]]:_vm._e(),(entry.type === 'nested')?[_c('QuestionTitlePill',{key:'nested_question_' + index,attrs:{"question":entry.question
                  ? entry.question
                  : _vm.$t('page.surveys.placeholders.nested_radio_question'),"type":"normal"}}),_vm._l((entry.children),function(innerQuestion,innerIndex){return [_c('div',{key:'nested_question_' + innerQuestion.question,staticClass:"tw-mt-3"},[(innerQuestion.type === 'textual')?[_c('TextQuestion',{key:innerQuestion.id,attrs:{"question":innerQuestion.question
                        ? innerQuestion.question
                        : _vm.$t('page.surveys.placeholders.textual_question'),"required":innerQuestion.required},model:{value:(innerQuestion.answer),callback:function ($$v) {_vm.$set(innerQuestion, "answer", $$v)},expression:"innerQuestion.answer"}})]:_vm._e(),(innerQuestion.type === 'selection')?[(!_vm.$vuetify.breakpoint.smAndDown)?[_c('div',{key:'nested_radio_question_' + innerIndex,staticClass:"tw-mt-1 tw-pt-1"},[_c('div',{staticClass:"tw-mb-3 tw-flex tw-flex-col tw-gap-3 tw-items-start"},[_c('Question',{attrs:{"question":innerQuestion.question
                              ? innerQuestion.question
                              : _vm.$t(
                                'page.surveys.placeholders.nested_radio_question'
                              ),"required":innerQuestion.required}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-items-start tw-gap-1"},[_c('RadioOption',{key:innerQuestion.id,attrs:{"name":innerQuestion.question,"options":!innerQuestion.parent_options
                                ? _vm.getOptions(innerQuestion)
                                : _vm.getOptions(entry),"answer":innerQuestion.answer},model:{value:(innerQuestion.answer),callback:function ($$v) {_vm.$set(innerQuestion, "answer", $$v)},expression:"innerQuestion.answer"}})],1)])])]:[_c('Question',{attrs:{"question":innerQuestion.question
                          ? innerQuestion.question
                          : _vm.$t(
                            'page.surveys.placeholders.nested_radio_question'
                          ),"required":innerQuestion.required}}),_c('v-select',{key:innerQuestion.id,staticClass:"tw-w-full tw-px-2 tw-pt-2 customSelect",attrs:{"flat":"","solo":"","placeholder":_vm.$t('page.surveys.placeholders.inner_select'),"outlined":"","color":"#3bbd26","item-color":"green darken-2","dense":"","hide-details":"","items":!innerQuestion.parent_options
                          ? _vm.getOptions(innerQuestion)
                          : _vm.getOptions(entry)},model:{value:(innerQuestion.answer),callback:function ($$v) {_vm.$set(innerQuestion, "answer", $$v)},expression:"innerQuestion.answer"}})]]:_vm._e()],2)]})]:_vm._e()]})],2)]),_c('v-card',{staticClass:"tw-max-w-[70%] tw-divide-y tw-p-4 tw-mx-auto tw-mt-5",attrs:{"flat":""}},[_c('div',{staticClass:"tw-grid tw-justify-items-center"},[_c('v-btn',{attrs:{"large":"","color":"primary","outlined":""},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)])],1):_c('SurveyCompleted')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }