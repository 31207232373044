
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
import Store from '@/store'
// #endregion

// #region interfaces
import { MenuItemInterface } from '@/types/interfaces/ui'
// #endregion

// #endregion

@Component({})
export default class NavMenuItem extends Vue {
  @Prop({ type: Object, default: () => [] })
  readonly menuItem!: MenuItemInterface

  @Prop({ default: false }) readonly subgroup!: boolean
  @Prop({ default: false }) readonly small!: boolean

  user = Store.getters['auth/getUserData']

  hasPermission (menuItem: MenuItemInterface): boolean {
    let hasPermission = false

    hasPermission = this.checkPermission(menuItem)

    return hasPermission
  }

  checkPermission (menuItem: MenuItemInterface): boolean {
    let permission = false

    permission = this.checkRole(menuItem)

    if (!permission) {
      return false
    }

    permission = this.checkIfUserContains(menuItem)

    return permission
  }

  checkRole (menuItem: MenuItemInterface): boolean {
    if (
      menuItem.meta &&
      menuItem.meta.role &&
      !menuItem.meta.role.includes(this.user.role)
    ) {
      return false
    }

    return true
  }

  checkIfUserContains (menuItem: MenuItemInterface): boolean {
    if (menuItem.meta && menuItem.meta.shouldNotHave) {
      for (const key of menuItem.meta.shouldNotHave) {
        if (this.user[key]) {
          return false
        }
      }
    }

    return true
  }
}
