
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
import { cloneDeep } from 'lodash'
// #endregion

// #region interfaces
import { RoleInterface } from '@/types/interfaces/user'
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import { CreateOrUpdateStaffMember } from '@/types/interfaces/staff'
// #endregion

// #region services
import staffService from '@/services/staff.service'
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
// #endregion

// #region assets
import countryPrefixes from '@/assets/CountryPrefix'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region helpers & utils
import { sanitizePhoneNumber } from '@/utils/sanitation.utils'
// #endregion

// #endregion

@Component({
  components: { TableModalForm, TableModalActions },
  methods: {
    sanitizePhoneNumber
  }
})
export default class AddEditStaffMember extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop() readonly item!: CreateOrUpdateStaffMember
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  valid = false
  user = store.getters['auth/getUserData']
  rules = rules
  roles = [] as Array<RoleInterface>
  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>
  countryPrefixes = countryPrefixes
  loading = true

  data = {
    id: '',
    name: '',
    email: '',
    role: '',
    phone_number: '',
    country_code: '+47',
    company_id: '',
    department_id: ''
  } as CreateOrUpdateStaffMember

  getText (data: { name: string; code: string }): string {
    return `${data.name} (${data.code})`
  }

  @Watch('data.company_id')
  async companyChanged (): Promise<void> {
    if (this.data.company_id) {
      const departments = await departmentService.getAllCompanyDepartments(
        this.data.company_id
      )
      this.departments = departments
    }
  }

  // roles here
  async fetchRoles (): Promise<void> {
    const roles = await staffService.getRoles()
    this.roles = roles
  }

  async fetchCompanies (): Promise<void> {
    const companies = await companyService.getActiveWithoutPagination()
    this.companies = companies
  }

  async created (): Promise<void> {
    this.loading = true
    await this.fetchRoles()
    await this.fetchCompanies()

    if (this.item) {
      this.data = cloneDeep(this.item)
    }

    if (this.user.company_id) {
      this.data.company_id = this.user.company_id
    }

    if (this.user.department_id) {
      this.data.department_id = this.user.department_id
    }
    this.loading = false
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      if (!this.item) {
        const staffMember = { ...this.data }

        delete staffMember.id

        const member = await staffService.createStaffMember(staffMember)

        store.dispatch('app/showSnackbar', {
          title: i18n.t('component.add_edit_staff.member_added'),
          body: `${i18n.t('component.add_edit_staff.password_is')} ${
            member.user_password
          }`
        })

        this.onSubmit()
        this.dialogHandler()

        return
      }

      const staffMember = { ...this.data }

      await staffService.updateStaffMember(staffMember)

      this.$toast.success(
        i18n.t('notify.success.staff_member_updated').toString()
      )

      this.onSubmit()
      this.dialogHandler()
    }
  }
}
