
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import ThankYou from '@/components/bookings/ThankYou.vue'
import BookingInformation from '@/components/bookings/BookingInformation.vue'
// #endregion

// #region containers
import BookCompanyCard from '@/containers/bookings/BookCompanyCard.vue'
import BookDepartmentCard from '@/containers/bookings/BookDepartmentCard.vue'
import BookCategoryCard from '@/containers/bookings/daily/BookDailyPublicCategoryCard.vue'
import BookDateCard from '@/containers/bookings/hourly/BookDateCard.vue'
import BookDailyPublicDateCard from '@/containers/bookings/daily/BookDailyPublicDateCard.vue'
import BookClientInformation from '@/containers/bookings/hourly/BookClientInformation.vue'
import BookSummaryHourly from '@/containers/bookings/hourly/BookSummaryHourly.vue'
import BookSummaryDaily from '@/containers/bookings/daily/BookSummaryDaily.vue'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
import { CompanyInterface } from '@/types/interfaces/company'
import { AddressInterface } from '@/types/interfaces/address'
import { ClientInterface } from '@/types/interfaces/client'
import {
  BookDateCardInterface,
  BookingPropertyInterface,
  Step
} from '@/types/interfaces/booking'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #region utils
import { fromISODateStringToDateOnlyString } from '@/utils/helper.utils'
// #endregion

// #region data
import { cloneDeep } from 'lodash'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region services
import bookingService from '@/services/booking.service'
import departmentService from '@/services/department.service'
import rentingEntityService from '@/services/renting-entity.service'
// #endregion

// #endregion

@Component({
  components: {
    BookCompanyCard,
    BookDepartmentCard,
    BookCategoryCard,
    BookDateCard,
    BookDailyPublicDateCard,
    BookClientInformation,
    BookSummaryDaily,
    BookSummaryHourly,
    ThankYou,
    BookingInformation
  }
})
export default class Book extends Vue {
  step = 0
  showThankYou = false
  language = this.$i18n.locale

  hasCategories = false
  category: CategoryInterface | null = null
  rentingEntity: RentingEntityInterface | null = null

  queryParams = {
    categoryId: this.$route.query.category || '',
    departmentId: this.$route.query.department || '',
    rentingEntityId: this.$route.query['renting-entity'] || ''
  }

  departments: DepartmentInterface[] = []

  data = {
    company: {
      id: '',
      name: '',
      country_code: '',
      phone_number: ''
    },
    rentingEntity: {
      id: '',
      name: ''
    },
    department: {
      id: '',
      name: '',
      address: {} as AddressInterface | undefined,
      allow_categories: false,
      activity_system: '',
      schedule_system: '',
      checkin: '',
      checkout: ''
    },
    client: {
      name: '',
      country_code: '',
      phone_number: '',
      email: ''
    } as ClientInterface,
    note: '',
    date_from: '',
    date_to: '',
    number_of_interactors: 0,
    properties: [] as BookingPropertyInterface[],
    category_id: '',
    category_name: ''
  }

  allSteps: Step[] = [
    {
      title: 'common.pick_location',
      component: 'BookCompanyCard'
    },
    {
      title: 'common.pick_area',
      component: 'BookDepartmentCard'
    },
    {
      title: 'common.pick_date',
      component: 'BookDailyPublicDateCard',
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'common.pick_date',
      component: 'BookDateCard',
      checkScheduleSystem: 'HOURLY'
    },
    {
      title: 'common.pick_category',
      component: 'BookCategoryCard',
      checkCategory: true,
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'component.book.fill_information_step',
      component: 'BookClientInformation'
    },
    {
      title: 'component.book.summary_step',
      component: 'BookSummaryDaily',
      checkScheduleSystem: 'DAILY'
    },
    {
      title: 'component.book.summary_step',
      component: 'BookSummaryHourly',
      checkScheduleSystem: 'HOURLY'
    }
  ]

  steps: Step[] = cloneDeep(this.allSteps)

  defaultObject = cloneDeep(this.data)

  loading = true

  stepName = this.allSteps[0].component

  async created (): Promise<void> {
    // Case 1:
    await this.fetchAndSetDepartments()

    // Case 2:
    await this.fetchAndSetRentingEntities()
    this.loading = false
  }

  changeLanguage (): void {
    this.$i18n.locale = this.language
    localStorage.setItem('language', this.language)
    window.location.reload()
  }

  setDepartment (department: DepartmentInterface): void {
    if (department.schedule_system !== 'DAILY') {
      return
    }

    if (department.company) {
      this.selectedCompany(department.company, false, false)
    }
    this.selectedDepartment(department, false)
  }

  async fetchAndSetDepartments (): Promise<void> {
    if (!this.queryParams.departmentId) {
      return
    }

    const result = await departmentService.getAllPublic()
    const department = result.find(
      (item) => item.id === this.queryParams.departmentId
    )

    if (department) {
      this.setDepartment(department)
    }

    this.departments = result
  }

  async fetchAndSetRentingEntities (): Promise<void> {
    if (!this.queryParams.rentingEntityId) {
      return
    }

    const result = await rentingEntityService.getAllPublic()

    const rentingEntity = result.find(
      (item) => item.id === this.queryParams.rentingEntityId
    )

    if (rentingEntity) {
      const department = rentingEntity.department

      if (department && department.schedule_system !== 'DAILY') {
        return
      }

      if (department) {
        let query: { [key: string]: string } = {
          'renting-entity': rentingEntity.id as string,
          department: rentingEntity.department_id
        }

        if (rentingEntity.category_id) {
          query = { ...query, category: rentingEntity.category_id }
        }

        this.$router.replace({ query })
        this.setDepartment(department)
        this.rentingEntity = rentingEntity
        this.data.rentingEntity.id = rentingEntity.id as string
        this.data.rentingEntity.name = rentingEntity.name as string
      }
    }
  }

  get numberOfGuests (): string {
    if (this.data.number_of_interactors) {
      return `- ${this.data.number_of_interactors} ${i18n.t('common.guests')}`
    }
    return ''
  }

  stepperClass (num: number): string {
    const prefix =
      this.step === num && this.$vuetify.breakpoint.mdAndDown
        ? 'b-l-0 ma-0 '
        : ''
    return prefix + 'ml-md-8 px-3 px-md-5'
  }

  updateStep (_step: number): void {
    this.step = _step
    this.stepName = this.steps[_step].component
  }

  selectedCompany (
    data: CompanyInterface,
    incrementStep = true,
    cleanQueryParams = true
  ): void {
    this.data.company = {
      id: data.id,
      name: data.name,
      country_code: data.country_code,
      phone_number: data.phone_number
    }

    if (cleanQueryParams) {
      this.cleanQueryParams()
    }

    if (incrementStep) {
      this.updateStep(1)
    }
  }

  selectedDepartment (data: DepartmentInterface, cleanQueryParams = true): void {
    this.data.department = {
      id: data.id,
      name: data.name,
      address: data.address,
      allow_categories: !!data.allow_categories,
      activity_system: data.activity_system,
      schedule_system: data.schedule_system,
      checkin: data.checkin?.value || '',
      checkout: data.checkout?.value || ''
    }

    if (cleanQueryParams) {
      this.cleanQueryParams()
    }

    this.updateStep(2)
  }

  cleanQueryParams (): void {
    const queryParams = Object.keys(this.$route.query)

    if (queryParams.length) {
      this.$router.replace({ query: {} })
      this.cleanRentingEntity()
    }
  }

  cleanRentingEntity (): void {
    this.rentingEntity = null
    this.data.rentingEntity.id = ''
    this.data.rentingEntity.name = ''
  }

  async setCategory (data: CategoryInterface): Promise<void> {
    // Check if there are any slots for the given data
    const hasSlots = await this.checkAvaialbleBookingSlotsOnCategoryLevel(
      fromISODateStringToDateOnlyString(new Date(this.data.date_from)),
      fromISODateStringToDateOnlyString(new Date(this.data.date_to)),
      this.data.department.id,
      this.data.number_of_interactors,
      data.id // <-- category id
    )

    if (hasSlots === false) {
      this.$toast.warning(
        i18n.t('component.book.daily_no_available_slots').toString()
      )
      this.cleanQueryParams()
      return
    }

    this.category = data
    this.updateStep(this.step + 1)

    this.data.category_id = data.id as string
    this.data.category_name = data.name
  }

  dateInformationAdded (data: BookDateCardInterface): void {
    this.data = { ...this.data, ...data }

    this.updateStep(this.step + 1)
  }

  dateInformationAddedDaily (data: BookDateCardInterface): void {
    this.data = { ...this.data, ...data }

    if (
      this.rentingEntity &&
      this.rentingEntity.number_of_interactors < data.number_of_interactors
    ) {
      this.rentingEntity = null
      this.data.rentingEntity.id = ''
      this.$router.replace({ query: {} })
      this.$toast.warning(
        this.$t(
          'component.book.number_of_guests_greater_than_renting_entity'
        ).toString()
      )
    }

    this.updateStep(++this.step)
  }

  clientInformationAdded (client: ClientInterface): void {
    this.data.properties = []
    this.data.client = client
    if (client.notes) {
      this.data.properties.push({ key: 'notes', value: client.notes })
    }
    if (client.tags) {
      this.data.properties.push(client.tags)
    }
    this.updateStep(this.step + 1)
  }

  setDetail (index: number, key: 'key' | 'value', value: string): void {
    this.data.properties[index][key] = value
  }

  ordered (): void {
    this.showThankYou = true
  }

  newBooking (): void {
    this.step = 0
    this.data = cloneDeep(this.defaultObject)
    this.showThankYou = false
  }

  resetDate (): void {
    this.data.date_from = ''
    this.data.number_of_interactors = 0
  }

  @Watch('data.department.id')
  onDepartmentChange (): void {
    this.hasCategories = !!this.data.department.allow_categories

    let _steps = [...this.allSteps]

    if (!this.hasCategories) {
      _steps = _steps.filter((item) => !item.checkCategory)
    }

    _steps = _steps.filter(
      (item) =>
        item.checkScheduleSystem === this.data.department.schedule_system ||
        !item.checkScheduleSystem
    )

    this.steps = cloneDeep(_steps)
  }

  showHeader (item: Step, _step: number): boolean {
    const stepCondition = this.step >= _step

    if (item.checkCategory) {
      return stepCondition && this.hasCategories
    }

    if (item.checkScheduleSystem) {
      return (
        stepCondition &&
        this.data.department.schedule_system === item.checkScheduleSystem
      )
    }

    return stepCondition
  }

  showContent (item: Step, _step: number): boolean {
    const stepCondition = this.step === _step

    if (item.checkCategory) {
      return stepCondition && this.hasCategories
    }

    if (item.checkScheduleSystem) {
      return (
        stepCondition &&
        this.data.department.schedule_system === item.checkScheduleSystem
      )
    }

    return stepCondition
  }

  // #region backend interacting methods
  async checkAvaialbleBookingSlotsOnCategoryLevel (
    dateFrom: string,
    dateTo: string,
    departmentId: string,
    numberOfInteractors: number,
    categoryId?: string
  ): Promise<boolean> {
    return await bookingService.checkAvaialbleBookingSlots({
      dateFrom,
      dateTo,
      departmentId,
      numberOfInteractors,
      categoryId,
      rentingEntityId: this.data.rentingEntity.id
    })
  }
  // #endregion
}
