
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import AddEditCategory from '@/containers/categories/AddEditCategory.vue'
import ItemActions from '@/common/components/ui/table/ItemActions.vue'
import ItemAction from '@/common/components/ui/table/ItemAction.vue'
// #endregion

// #region interfaces
import {
  CategoryInterface,
  CategoryTableItemInterface
} from '@/types/interfaces/category'
// #endregion

// #region utils
import rules from '@/utils/rules.utils'
import { copyLink } from '@/utils/helper.utils'
// #endregion

// #region services
import categoryService from '@/services/category.service'
import departmentService from '@/services/department.service'
import { DepartmentInterface } from '@/types/interfaces/department'
// #endregion

// #endregion

@Component({
  components: { Table, AddEditCategory, ItemActions, ItemAction },
  computed: { ...mapState('app', ['isLoading']) },
  methods: { copyLink }
})
export default class ActiveCategories extends Vue {
  headers = [
    { text: i18n.t('table.col.name'), value: 'name' },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    { text: i18n.t('table.col.company'), value: 'company.name' },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  rules = rules

  categories: CategoryInterface[] = []
  departments: DepartmentInterface[] = []
  category: CategoryInterface | null = null
  dialog = false
  deleteDialog = false
  selectedDepartment = ''
  categoryLink = ''

  async handleCategoriesFetch (): Promise<void> {
    const result = await categoryService.getAll()
    this.categories = result.categories
  }

  async created (): Promise<void> {
    await this.handleCategoriesFetch()
  }

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.category = null
    }
  }

  editItem (row: CategoryTableItemInterface): void {
    this.category = this.categories[row.index]
    this.toggleDialog()
  }

  initDeleteCategory (row: CategoryTableItemInterface): void {
    this.deleteDialog = true
    this.category = this.categories[row.index]
  }

  async deleteItem (): Promise<void> {
    const id = this.category ? this.category.id : ''

    await categoryService.delete(id as string)

    this.$toast.success(i18n.t('notify.success.category_deleted').toString())

    this.handleCategoriesFetch()

    this.deleteDialog = false
    this.category = null
  }

  cancelDeleteDialog (): void {
    this.deleteDialog = false
    this.category = null
  }

  async fetchDepartments (): Promise<void> {
    this.departments =
      await departmentService.getDepartmentsThatAllowCategories()
  }

  async link (row: CategoryTableItemInterface): Promise<void> {
    const item = row.item

    await this.fetchDepartments()
    const user = this.$store.getters['auth/getUserData']

    const domain = user.domain || 'demo'

    if (item.department_id) {
      this.selectedDepartment = item.department_id
    }

    this.categoryLink = `${window.location.origin}/${domain}/book?category=${item.id}`
  }

  @Watch('selectedDepartment')
  departmentSelected (): void {
    this.categoryLink = `${this.categoryLink}&department=${this.selectedDepartment}`
  }

  handleCloseCopyLink (close: () => void): void {
    this.selectedDepartment = ''
    close()
  }
}
