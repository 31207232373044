// #region imports

// #region locales
import i18n from '@/i18n'
// #endregion

// #region interfaces
import {
  ErrorInterface,
  GlobalStateInterface,
  PublicBookingCustomVisualSettingsInterface
} from '@/types/interfaces/setting'
import { SnackbarInterface } from '@/types/interfaces/ui'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region vuetify
import Vuetify from '@/plugins/vuetify'
// #endregion

// #region vue
import { Commit } from 'vuex'
// #endregion

// #endregion

const { product, theme } = configs
const {
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed
} = theme

const companyVisualSettingsDefault = {
  active: true, // <-- this is a hack, don't make it false, else, the visual settings won't be loaded when coming from Categories to Company Selection on public booking.
  key: 'public_booking_custom_visual_settings',
  main_background_color: {
    color: '#ffffff'
  },
  page_background_color: {
    color: '#f2f5f8'
  },
  cards_background_color: {
    color: '#ffffff'
  },
  cards_border_color: {
    color: '#123123'
  },
  font_color: {
    color: '#0096c7'
  },
  selectors_border_color: {
    color: '#ffffff'
  },
  error_color: {
    color: '#ef476f'
  },
  dates_background_color_past: {
    color: '#ffffff'
  },
  dates_background_color_present: {
    color: '#ffffff'
  },
  dates_background_color_future: {
    color: '#ffffff'
  },
  buttons_background_color: {
    color: '#E3F2FD'
  },
  buttons_font_color: {
    color: '#01579B'
  },
  buttons_border_color: {
    color: '#ffffff'
  },
  calendar: {
    color: 'blue',
    dark_mode: false
  }
} as PublicBookingCustomVisualSettingsInterface

// state initial values
const initialState = {
  product,
  globalTheme,
  menuTheme,
  toolbarTheme,
  isToolbarDetached,
  isContentBoxed,
  isLoading: false,
  error: { message: '', action: 'default' },
  pageError: null,
  errorNotification: '',
  snackbarVisibility: false,
  snackbarData: '',
  isSideBarActive: true,
  companyLogo: 'img/frontline_logo.png',
  companyVisualSettingsDefault,
  companyBackgroundImage: null
}

const storeThemeSettings = (key: string, value: string) => {
  const themeSettings = JSON.parse(
    localStorage.getItem('theme_settings') || '{}'
  )
  themeSettings[key] = value
  localStorage.setItem('theme_settings', JSON.stringify(themeSettings))
}

export default {
  namespaced: true,
  state: initialState,
  actions: {
    loading ({ commit }: { commit: Commit }, loading: boolean): void {
      commit('changeLoadingState', loading)
    },
    showSnackbar (
      { commit }: { commit: Commit },
      message: SnackbarInterface
    ): void {
      commit('showSnackbar', message)
    },
    closeSnackbar ({ commit }: { commit: Commit }): void {
      commit('closeSnackbar')
    },
    errorPage: ({ commit }: { commit: Commit }, message: string): void => {
      commit('setPageError', message)
    },
    error (
      { commit }: { commit: Commit },
      error: { status: number; message: string; action?: string }
    ): void {
      error.action = 'default'
      if (error.status === 401) {
        error.message = i18n.t('common.auth_expired') as string
        error.action = 'logout'
      }

      commit('setError', { message: error.message, action: error.action })
    },
    errorNotification ({ commit }: { commit: Commit }, error: string): void {
      commit('setErrorNotification', error)
    },
    clearErrorNotification ({ commit }: { commit: Commit }): void {
      commit('setErrorNotification', '')
    },
    toggleSideBarActivity ({ commit }: { commit: Commit }): void {
      commit('toggleSideBarActivity')
    },
    updateCompanyLogoPath ({ commit }: { commit: Commit }, logo: string): void {
      commit('setCompanyLogoPath', logo)
    },
    updateCompanyBackgroundImage ({ commit }: {commit: Commit}, image: string): void {
      commit('setCompanyBackgroundImage', image)
    }
  },
  mutations: {
    changeLoadingState (state: GlobalStateInterface, loading: boolean): void {
      state.isLoading = loading
    },
    setGlobalTheme (state: GlobalStateInterface, theme: string): void {
      Vuetify.framework.theme.dark = theme === 'dark'
      state.globalTheme = theme
      storeThemeSettings('global', theme)
    },
    setMenuTheme (state: GlobalStateInterface, theme: string): void {
      state.menuTheme = theme
      storeThemeSettings('menu', theme)
    },
    setToolbarTheme (state: GlobalStateInterface, theme: string): void {
      state.toolbarTheme = theme
      storeThemeSettings('toolbar', theme)
    },
    setError (state: GlobalStateInterface, error: ErrorInterface): void {
      state.error = error
    },
    setPageError (state: GlobalStateInterface, message: string): void {
      state.pageError = message
    },
    setErrorNotification (state: GlobalStateInterface, message: string): void {
      state.errorNotification = message
    },
    showSnackbar (
      state: GlobalStateInterface,
      message: SnackbarInterface
    ): void {
      state.snackbarVisibility = true
      state.snackbarData = message
    },
    closeSnackbar (state: GlobalStateInterface): void {
      state.snackbarVisibility = false
      state.snackbarData = {
        title: '',
        body: ''
      }
    },
    toggleSideBarActivity (state: GlobalStateInterface): void {
      state.isSideBarActive = !state.isSideBarActive
    },
    setCompanyLogoPath (state: GlobalStateInterface, logo: string): void {
      state.companyLogo = logo
    },
    setCompanyBackgroundImage (state: GlobalStateInterface, image: string): void {
      // state.companyBackgroundImage = `url(${image})`
      state.companyBackgroundImage = image
    }
  },
  getters: {
    getError (state: GlobalStateInterface): ErrorInterface {
      return state.error
    },
    loadingState (state: GlobalStateInterface): boolean {
      return state.isLoading
    },
    errorState (state: GlobalStateInterface): string {
      return state.error.message
    },
    errorNotificationState (state: GlobalStateInterface): string {
      return state.errorNotification
    },
    getSideBarActivity (state: GlobalStateInterface): boolean {
      return state.isSideBarActive
    },
    getCompanyLogoPath (state: GlobalStateInterface): string {
      return state.companyLogo
    },
    getDefaultCompanyVisualSettings (state: GlobalStateInterface): PublicBookingCustomVisualSettingsInterface {
      return state.companyVisualSettingsDefault
    },
    getCompanyBackgroundImage (state: GlobalStateInterface): string {
      return state.companyBackgroundImage
    }
  }
}
