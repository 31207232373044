
import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/auth.service'
import i18n from '@/i18n'

@Component
export default class ForgotPasswordView extends Vue {
  isLoading = false

  // form
  isFormValid = true
  email = ''

  // form error
  error = false
  errorMessages = ''
  responseError = ''

  // input rules
  rules = {
    required: (value: string): boolean => (value && Boolean(value)) || true
  }

  async submit (): Promise<void> {
    if ((this.$refs.form as HTMLFormElement).validate()) {
      try {
        const result = await AuthService.forgotPassword(this.email)
        this.$toast.success(
          result.message + i18n.t('notify.success.check_email_for_details')
        )
        if (result.success) {
          this.$router.push('/login')
        }
        // eslint-disable-next-line
      } catch (error: any) {
        this.error = true
        this.errorMessages = error.message
      }
    }
  }

  resetErrors (): void {
    this.error = false
    this.errorMessages = ''
  }
}
