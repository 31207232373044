
import { Component, Vue } from 'vue-property-decorator'
import ActiveBookings from '@/views/bookings/_ActiveBookings.vue'
import CanceledBookings from '@/views/bookings/_CanceledBookings.vue'
import AllBookings from '@/views/bookings/_AllBookings.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({ components: { ActiveBookings, CanceledBookings, AllBookings } })
export default class Bookings extends Vue {
  tab = 'activeBookings'

  items = [
    { key: 'activeBookings', label: i18n.t('input.label.active_bookings') },
    { key: 'canceledBookings', label: i18n.t('input.label.cancel_bookings') },
    { key: 'allBookings', label: i18n.t('input.label.all_bookings') }
  ]

  created (): void {
    document.title = getAppTitle('Bookings')
  }
}
