
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
import Store from '@/store'
// #endregion

// #region components
import Information from '@/views/wizard/_Information.vue'
import DefaultSettings from '@/views/wizard/_DefaultSettings.vue'
import WizardOverview from '@/views/wizard/_WizardOverview.vue'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region interfaces
import { WizardInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component({ components: { Information, DefaultSettings, WizardOverview } })
export default class Wizard extends Vue {
  step = 1

  data = {
    pagination: 20,
    notifications: {
      email_subscription: false,
      email_reminder: false,
      email_booking_updates: false,
      email_booking_confirmation: false,
      email_surveys: false,
      email_booking_requests_confirmation: false,
      email_booking_requests_decline: false,
      sms_subscription: false,
      sms_reminder: false,
      sms_booking_updates: false,
      sms_booking_confirmation: false,
      sms_surveys: false,
      sms_booking_requests_confirmation: false,
      sms_booking_requests_decline: false
    },
    workingHours: {
      defaultStart: '8:00',
      defaultEnd: '24:00'
    },
    lastBooking: 120,
    bookingDuration: 120
  } as WizardInterface

  changeStep (s: number): void {
    this.step = s
  }

  async submit (): Promise<void> {
    await settingService.storeWizardData(this.data)

    Store.dispatch('auth/domainWizardCompleted')

    this.$router.replace('/')
  }
}
