import Companies from '@/views/companies/Companies.vue'
import Company from '@/views/companies/Company.vue'

const companyRoutes = [
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/company/:id',
    name: 'company',
    component: Company,
    meta: {
      auth: true,
      role: ['admin']
    }
  }
]

export default companyRoutes
