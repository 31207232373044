import StaffMembers from '@/views/staff/StaffMembers.vue'
import StaffMember from '@/views/staff/StaffMember.vue'
import CalendaricView from '@/views/calendar/CalendaricView.vue'
import CalendaricViewDaily from '@/views/calendar/CalendaricViewDaily.vue'

const staffRoutes = [
  {
    path: '/staff-members',
    name: 'staff-members',
    component: StaffMembers,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/staff-member/:id',
    name: 'staff-member',
    component: StaffMember,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/calendaric-view',
    name: 'calendaric-view',
    component: CalendaricView,
    meta: {
      auth: true,
      role: ['admin', 'host', 'employee']
    }
  },
  {
    path: '/calendaric-view-daily',
    name: 'calendaric-view-daily',
    component: CalendaricViewDaily,
    meta: {
      auth: true,
      role: ['admin', 'host', 'employee']
    }
  }
]

export default staffRoutes
