import Vue from 'vue'
import moment from 'moment'
import VueI18n from 'vue-i18n'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vue-shortkey'
import VueApexCharts from 'vue-apexcharts'
import VCalendar from 'v-calendar'
import VueToast from 'vue-toast-notification'
import i18n from './i18n'

// Styling imports
import './index.css'
import './assets/scss/theme.scss'
import 'animate.css/animate.min.css'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.prototype.moment = moment
Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VueToast)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    'en-US': {
      firstDayOfWeek: 2
    }
  }
})
Vue.use(VueI18n)

Vue.component('Apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
