
import { Component, Vue } from 'vue-property-decorator'
import ActiveCompanies from '@/views/companies/_ActiveCompanies.vue'
import ArchivedCompanies from '@/views/companies/_ArchivedCompanies.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({ components: { ActiveCompanies, ArchivedCompanies } })
export default class Companies extends Vue {
  tab = 'activeCompanies'

  items = [
    { key: 'activeCompanies', label: i18n.t('page.companies.active') },
    { key: 'archivedCompanies', label: i18n.t('page.companies.archived') }
  ]

  created (): void {
    document.title = getAppTitle('Companies')
  }
}
