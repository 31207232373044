
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region components
import InformationCard from '@/common/components/ui/card/InformationCard.vue'
import UpcommingBookings from '@/containers/bookings/UpcommingBookings.vue'
// #endregion

// #region services
import DashboardService from '@/services/dashboard.service'
// #endregion

// #region interfaces
import { DashboardInterface } from '@/types/interfaces/setting'
// #endregion

// #region utils
import { add, sub, format, startOfMonth, endOfMonth } from 'date-fns'
import { getAppTitle } from '@/utils/helper.utils'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
// #endregion

// #endregion

@Component({
  components: { InformationCard, UpcommingBookings },
  computed: { ...mapState('auth', ['user']) }
})
export default class AdminDashboard extends Vue {
  locale: typeof enUS = this.$i18n.locale === 'en-us' ? enUS : nb
  dashboardData = {} as DashboardInterface
  bookingChartsRange = {
    titleDataRange: '',
    annotation: '',
    previousMonth: {
      start: '',
      end: ''
    },
    thisMonth: {
      start: '',
      end: ''
    },
    nextMonth: {
      start: '',
      end: ''
    },
    yaxis: {
      max: 0
    }
  }

  busyHoursChartRange = {
    subtitle: {
      text: ''
    }
  }

  setLocales (): void {
    this.locale = this.$i18n.locale === 'en-us' ? enUS : nb
  }

  async handleDepartmentsFetch (): Promise<DashboardInterface> {
    return await DashboardService.getData()
  }

  async created (): Promise<void> {
    document.title = getAppTitle('Dashboard')

    const dashboardDataResult = await this.handleDepartmentsFetch()

    this.setData(dashboardDataResult)

    this.setLocales()

    this.getBusyHoursDateRange()
  }

  setData (result: DashboardInterface): void {
    this.dashboardData = result
    this.bookingsChartDateRange()
    this.handleChartData(result)
  }

  handleChartData (result: DashboardInterface): void {
    this.bookingsChartSeries = [
      {
        name: i18n.t('table.title.bookings').toString(),
        data: result.bookings_chart
      }
    ]
    this.busyHoursThisMonthSeries = [
      {
        name: i18n.t('common.bookings').toString(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: result.busy_hours.data.map((item: any) => {
          return {
            x: item.x + ':00',
            y: item.y
          }
        })
      }
    ]
  }

  // chart renders -- START --
  bookingsChartOptions = {
    chart: {
      id: 'basic-bar'
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    xaxis: {
      type: 'string',
      title: {
        text: 'Date'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        gradientToColors: ['#fd35ad'],
        shadeIntensity: 2,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100]
      }
    },
    yaxis: {
      type: 'number',
      title: {
        text: i18n.t('table.title.bookings')
      },
      min: 0,
      max: this.bookingChartsRange.yaxis.max
    },
    title: {
      text: i18n.t('table.title.bookings'),
      offsetX: 0,
      style: {
        fontSize: '14px'
      }
    },
    subtitle: {
      text: this.bookingChartsRange.titleDataRange,
      offsetX: 2,
      style: {
        fontSize: '12px',
        fontWeight: 'bold'
      }
    },
    annotations: {
      xaxis: [
        {
          x: this.bookingChartsRange.annotation,
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            style: {
              color: '#fff',
              background: '#4a53ff'
            },
            text: i18n.t('common.today')
          }
        },
        {
          x: this.bookingChartsRange.previousMonth.start,
          x2: this.bookingChartsRange.previousMonth.end,
          fillColor: '#fd35ad',
          opacity: 0.05,
          label: {
            borderColor: '#B3F7CA',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#00E396'
            },
            offsetY: 10
          }
        },
        {
          x: this.bookingChartsRange.previousMonth.start,
          x2: this.bookingChartsRange.previousMonth.end,
          fillColor: '#fff',
          opacity: 0.1,
          label: {
            borderColor: 'none',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#da41b7'
            },
            offsetY: -75,
            text: `${i18n.t('common.prev')} ${i18n.t('common.month')}.`
          }
        },
        {
          x: this.bookingChartsRange.previousMonth.start,
          x2: this.bookingChartsRange.previousMonth.end,
          fillColor: '#2481f0',
          opacity: 0.05,
          label: {
            borderColor: 'none',
            style: {
              fontSize: '10px',
              color: '#fff',
              background: '#0d89f7'
            },
            text: `${i18n.t('common.next')} ${i18n.t('common.month')}.`,
            offsetY: -75,
            offsetX: 14,
            rotate: 90
          }
        }
      ]
    }
  }

  bookingsChartSeries = [
    {
      name: i18n.t('table.title.bookings').toString(),
      data: this.dashboardData.bookings_chart || []
    }
  ]

  busyHours = {
    chart: {
      id: 'basic-bar'
    },
    plotOptions: {
      bar: {
        borderRadius: 5
      }
    },
    xaxis: {
      type: 'string'
    },
    yaxis: {
      type: 'number'
    },
    title: {
      text:
        i18n.t('page.calendar.busy') +
        ` (${i18n.t('page.dashboard.busy_hours.info')})`,
      offsetX: 0,
      style: {
        fontSize: '14px'
      }
    },
    subtitle: {
      text: this.getBusyHoursDateRange() + ` (1 ${i18n.t('common.month')})`,
      offsetX: 2,
      style: {
        fontSize: '12px',
        fontWeight: 'bold'
      }
    }
  }
  // chart renders -- END --

  // SERIES -- START --
  busyHoursThisMonthSeries = [
    {
      name: i18n.t('common.guests'),
      data: this.dashboardData.guests_this_month || []
    }
  ]
  // SERIES -- END --

  // UTILS -- START --
  getBusyHoursDateRange (): string {
    this.setLocales()
    const todayMinus15Days = sub(new Date(), { days: 15 })
    const todayPlus15Days = add(new Date(), { days: 15 })

    return (
      format(todayMinus15Days, 'do MMM', { locale: this.locale }) +
      ' - ' +
      format(todayPlus15Days, 'do MMM', { locale: this.locale })
    )
  }

  bookingsCardTitleDateFormatter (days: number): string {
    // Mon. 14/05
    const result = add(new Date(), { days })

    return format(result, 'iii. dd/MM', {
      locale: this.locale
    })
  }

  bookingsChartFetched = false

  async bookingsChartDateRange (): Promise<void> {
    const today = new Date()

    const previousMonthStart = startOfMonth(sub(today, { months: 1 }))
    const previousMonthEnd = add(endOfMonth(sub(today, { months: 1 })), {
      days: 1
    })

    const thisMonthStart = startOfMonth(today)
    const thisMonthEnd = add(endOfMonth(today), { days: 1 })

    const nextMonthStart = startOfMonth(add(today, { months: 1 }))
    const nextMonthEnd = endOfMonth(add(today, { months: 1 }))

    const todayMinust1Month = startOfMonth(sub(today, { months: 1 }))
    const todayPlus1Month = endOfMonth(add(today, { months: 1 }))

    const result = {
      titleDataRange:
        format(todayMinust1Month, 'do MMM', { locale: this.locale }) +
        ' - ' +
        format(todayPlus1Month, 'do MMM', { locale: this.locale }),
      annotation: format(today, 'dd/MM', { locale: this.locale }),
      previousMonth: {
        start: format(previousMonthStart, 'dd/MM', { locale: this.locale }),
        end: format(previousMonthEnd, 'dd/MM', { locale: this.locale })
      },
      thisMonth: {
        start: format(thisMonthStart, 'dd/MM', { locale: this.locale }),
        end: format(thisMonthEnd, 'dd/MM', { locale: this.locale })
      },
      nextMonth: {
        start: format(nextMonthStart, 'dd/MM', { locale: this.locale }),
        end: format(nextMonthEnd, 'dd/MM', { locale: this.locale })
      },
      yaxis: {
        max: this.dashboardData.bookings_chart_max
      }
    }

    this.bookingsChartFetched = true

    this.bookingChartsRange = result

    this.bookingsChartOptions.subtitle.text =
      result.titleDataRange + ` (3 ${i18n.t('common.month_plural')})`
    this.bookingsChartOptions.annotations.xaxis[0].x = result.annotation

    this.bookingsChartOptions.annotations.xaxis[1].x =
      result.previousMonth.start
    this.bookingsChartOptions.annotations.xaxis[1].x2 = result.previousMonth.end

    this.bookingsChartOptions.annotations.xaxis[2].x = result.thisMonth.start
    this.bookingsChartOptions.annotations.xaxis[2].x2 = result.thisMonth.end

    this.bookingsChartOptions.annotations.xaxis[3].x = result.nextMonth.start
    this.bookingsChartOptions.annotations.xaxis[3].x2 = result.nextMonth.end

    this.bookingsChartOptions.yaxis.max = result.yaxis.max
  }

  // UTILS -- END --

  cardClicked (routeName: string): void {
    this.$router.push({ name: routeName })
  }
}
