
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
import BookCategoryCard from '../bookings/BookCategoryCard.vue'
// #endregion

// #region interfaces

import { CompanyInterface } from '@/types/interfaces/company'
import {
  RentingEntityPropertyTemplateValidatorInterface,
  RentingEntityPropertyTemplateInterface,
  RentingEntityTypeInterface,
  RentingEntityPropertyInterface,
  CreateRentingEntityInterface
} from '@/types/interfaces/renting-entity'
import { UserInterface } from '@/types/interfaces/user'
import { DepartmentInterface } from '@/types/interfaces/department'
import { CategoryInterface } from '@/types/interfaces/category'
// #endregion

// #region services
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
import rentingEntityTypeService from '@/services/renting-entity-type.service'
import rentingEntityPropertyService from '@/services/renting-entity-property.service'
import staffService from '@/services/staff.service'
import rentingEntityService from '@/services/renting-entity.service'
// #endregion

// #region data
import { cloneDeep } from 'lodash'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region helpers & utils
import rules from '@/utils/rules.utils'
import { disabledFieldOnEdit } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component({
  components: { TableModalForm, TableModalActions, BookCategoryCard },
  methods: { disabledFieldOnEdit }
})
export default class AddEditRentingEntity extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop() readonly item!: CreateRentingEntityInterface
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  user = store.getters['auth/getUserData']
  rules = rules
  loading = true
  linkToStaff = false

  data = {
    name: '',
    description: '',
    company_id: '',
    department_id: '',
    type_id: '',
    number_of_interactors: 1,
    can_combine: false,
    properties: [],
    user_id: '',
    category_id: ''
  } as CreateRentingEntityInterface

  valid = false

  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>
  types = [] as Array<RentingEntityTypeInterface>
  templates = [] as Array<RentingEntityPropertyTemplateInterface>
  properties = [] as Array<RentingEntityPropertyInterface>
  users = [] as Array<UserInterface>

  selectedDepartment = {} as DepartmentInterface
  selectedStaff = {} as UserInterface

  async fetchCompanies (): Promise<void> {
    const result = await companyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchUsers (): Promise<void> {
    const userId = this.data.user_id ? this.data.user_id : ''

    const result = await staffService.getUserByDepartmentAndRole(
      this.selectedDepartment.id,
      'observer',
      userId
    )
    this.users = result
  }

  otherDepartmentType = false
  @Watch('data.department_id')
  departmentChanged (): void {
    if (this.data.department_id) {
      const department = this.departments.find(
        (item) => item.id === this.data.department_id
      )

      if (department && department.department_type?.toLowerCase() === 'other') {
        this.otherDepartmentType = true
        return
      }
    }
    this.otherDepartmentType = false
  }

  @Watch('data.company_id')
  async getDepartments (): Promise<void> {
    await this.fetchDepartments()
  }

  async fetchDepartments (): Promise<void> {
    const result = await departmentService.getAllCompanyDepartments(
      this.data.company_id
    )
    this.departments = result
  }

  async fetchRentingEntityTypes (): Promise<void> {
    if (this.departments.length > 0) {
      const department = this.departments.find(
        (item) => item.id === this.data.department_id
      ) as DepartmentInterface
      const result = await rentingEntityTypeService.getAllUnpaginated(
        department.department_type
      )

      this.types = result
    }
  }

  async fetchRentingEntityTemplates (): Promise<void> {
    const result =
      await rentingEntityTypeService.getRentingEntityTeplatesByTypeId(
        this.data.type_id
      )
    this.templates = result
    this.fillProperties()
  }

  async fetchSelectedEntityPropery (): Promise<RentingEntityPropertyInterface> {
    const rentingEntityId = this.data.id
    const selectedProperty =
      await rentingEntityPropertyService.getPropertyByEntityId(rentingEntityId)
    return selectedProperty
  }

  fillProperties (): void {
    const properties = [] as RentingEntityPropertyInterface[]
    this.templates.forEach((item: RentingEntityPropertyTemplateInterface) => {
      if (item.validators) {
        item.validators.forEach(
          async (
            v: RentingEntityPropertyTemplateValidatorInterface,
            index: number
          ) => {
            if (!this.item) {
              properties.push({ key: v.slug, value: '' })
            } else {
              if (this.item.properties !== undefined) {
                const property = this.item.properties[index]
                properties.push({ key: v.slug, value: property.value })
              }
            }
          }
        )
      }
    })
    this.data.properties = properties
  }

  formatRentingEnitityType (name: string): string {
    return i18n
      .t(`renting_entity_types.${name.toLowerCase()}`)
      .toString()
      .toUpperCase()
  }

  @Watch('data.type_id')
  async getRentingEntityTemplate (): Promise<void> {
    if (this.data.type_id) {
      await this.fetchRentingEntityTemplates()
    }
  }

  @Watch('data.department_id')
  requestsOnlyLogic (): void {
    const id = this.data.department_id
    const selectedDepartment = this.departments.find(
      (item: DepartmentInterface) => {
        return item.id === id
      }
    )
    if (selectedDepartment) {
      this.selectedDepartment = selectedDepartment
      if (selectedDepartment.activity_system === 'REQUESTS_ONLY') {
        this.data.can_combine = false
      } else {
        this.data.user_id = ''
        this.linkToStaff = false
      }
    }
  }

  @Watch('data.department_id')
  async getUsers (): Promise<void> {
    if (this.selectedDepartment.activity_system === 'REQUESTS_ONLY') {
      this.fetchUsers()
    }
  }

  @Watch('data.user_id')
  async setRentingEntityName (): Promise<void> {
    const id = this.data.user_id
    const selectedStaff = this.users.find((item: UserInterface) => {
      return item.id === id
    })

    if (selectedStaff) {
      this.data.name = selectedStaff.name
    }
  }

  @Watch('linkToStaff')
  toggleUserId (): void {
    if (this.linkToStaff === false) {
      this.data.user_id = ''
      this.data.name = ''
    }
  }

  showCategoryRequiredAlert = false
  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (this.selectedDepartment.allow_categories && !this.selectedCategoryId) {
      this.showCategoryRequiredAlert = true
      return
    }

    if (valid) {
      if (this.linkToStaff === true && !this.data.user_id) {
        this.$toast.error(
          i18n
            .t('notify.failure.renting_entities.link_to_staff_and_no_user_id')
            .toString()
        )
        return
      }

      if (!this.item) {
        await rentingEntityService.create(this.data)
        this.$toast.success(
          i18n.t('notify.success.renting_entity_added').toString()
        )
        this.onSubmit()
        this.dialogHandler()
        return
      }

      await rentingEntityService.update(this.data, this.data.id)
      this.$toast.success(
        i18n.t('notify.success.renting_entity_updated').toString()
      )
      this.onSubmit()
      this.dialogHandler()
    }
  }

  async created (): Promise<void> {
    this.loading = true

    await this.fetchCompanies()

    if (this.item) {
      this.data = cloneDeep(this.item)

      if (this.item.category_id) {
        this.selectedCategoryId = this.item.category_id
      }

      await this.fetchDepartments()
      await this.fetchRentingEntityTypes()
    }

    if (this.user.company_id) {
      this.data.company_id = this.user.company_id
    }

    if (this.user.department_id) {
      this.data.department_id = this.user.department_id
    }

    this.requestsOnlyLogic()

    if (this.item) {
      if (
        this.selectedDepartment.activity_system === 'REQUESTS_ONLY' &&
        this.data.user_id
      ) {
        this.linkToStaff = true
      }
    }

    if (this.selectedDepartment.activity_system === 'REQUESTS_ONLY') {
      await this.fetchUsers()
    }

    this.selectedCategoryId = this.data.category_id as string

    this.loading = false
  }

  @Watch('data.department_id')
  async getRentingEntityTypes (): Promise<void> {
    if (this.data.department_id) {
      await this.fetchRentingEntityTypes()
    }
  }

  selectedCategoryId = ''
  setCategory (category: CategoryInterface): void {
    this.data.category_id = category.id
    this.selectedCategoryId = category.id as string
    this.data.category_name = category.name
  }
}
