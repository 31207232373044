import ErrorPage from '@/views/error/ErrorPage.vue'

const errorRoutes = [
  {
    path: '*',
    name: 'error-no-auth-page',
    component: ErrorPage,
    meta: {
      layout: 'error',
      auth: false
    }
  },
  {
    path: '*',
    name: 'error-page',
    component: ErrorPage,
    meta: {
      layout: 'error',
      auth: true
    }
  }
]

export default errorRoutes
