
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #endregion

@Component({
  name: 'SimpleChipForTables'
})
export default class SimpleChipForTables extends Vue {
  @Prop({ required: true }) readonly items!: string
}
