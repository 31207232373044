
// #region imports

// #region vue
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import Store from '@/store'
// #endregion

// #region components
import AdminBookingManagement from '@/views/bookings/AdminBookingManagement.vue'
import AdminBookingManagementAuto from '@/views/bookings/AdminBookingManagementAuto.vue'
import TooltipPills from '@/views/calendar/inner_components/TooltipPills.vue'
import UpcommingBookings from '@/containers/bookings/UpcommingBookings.vue'
import ReserveBookingDaily from '@/containers/bookings/daily/ReserveBookingDaily.vue'
import UberDialogDaily from '@/containers/bookings/daily/UberDialogDaily.vue'
import ManualBookingDaily from '@/containers/bookings/daily/ManualBookingDaily.vue'
import FirelistDialog from '@/containers/bookings/FirelistDialog.vue'
// #endregion

// #region fullcalendar
import FullCalendar, {
  EventClickArg,
  EventDropArg,
  DateRange
} from '@fullcalendar/vue'
import resourcePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg
} from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
// #endregion

// #region services
import _bookingService from '@/services/booking.service'
import _departmentService from '@/services/department.service'
import _blockedDateService from '@/services/blocked-dates.service'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  RentingEntityExtendedDescriptionInterface,
  RentingEntityInterface
} from '@/types/interfaces/renting-entity'
import {
  AutoBookingTransferDataInterface,
  ChoiceCardPropogateClickInterface
} from '@/types/interfaces/booking'
import {
  ColorInterface,
  UpdateEventDragOrResize,
  BookingEventInterface
} from '@/types/interfaces/calendar'
import { CreateBlockedDateInterface } from '@/types/interfaces/blocked-dates'

// #endregion

// #region utils
import 'moment-timezone'
import moment from 'moment'
import { format, addDays, subDays, isAfter } from 'date-fns'
import Chroma from 'chroma-js'
// #endregion

// #region helpers
import { compareDatesWithNow, parseJson } from '@/utils/helper.utils'
import { defaultIncoming, dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region others
import { CalendarEvent } from 'vuetify'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
import { configuration } from '@/config'
// #endregion

// #region enums
import { ReservationTypes } from '@/types/enums/booking'
// #endregion

// #endregion

@Component({
  components: {
    FullCalendar,
    AdminBookingManagement,
    AdminBookingManagementAuto,
    TooltipPills,
    UpcommingBookings,
    ReserveBookingDaily,
    ManualBookingDaily,
    UberDialogDaily,
    FirelistDialog
  },
  methods: {
    parseJson,
    defaultIncoming
  }
})
export default class DailyCalendaricView extends Vue {
  @Prop({ default: false, required: true }) readonly show!: boolean
  showFilters = false
  calendarDialog = false
  departments = [] as DepartmentInterface[]
  selectedDepartments = []
  activityFilter = i18n.t('page.calendar.all_renting_entities')
  viewAll = true
  dialog = false
  autoDialog = false
  reserveDialog = false
  manualDialog = false
  rentingEntityId = ''
  dateFrom = ''
  state = 'edit'
  selectedDate = dummyDateConverter(new Date())
  selectedIndex: string | null = null
  autoBookingDataTransfer = {} as AutoBookingTransferDataInterface
  monthView = false
  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb
  userRole = ''
  blockedDates: Array<CreateBlockedDateInterface> = []
  initialRender = true

  colors = {
    default_color: '#2770b1'
  } as ColorInterface

  defaultColors = ['#371B58', '#4C3575', '#5B4B8A'] as Array<string>

  colorIndex = 0
  fetchedData = false
  clearFilterButtonColor = ''
  cleanState = true
  uberDialog = false
  firelistDialog = false
  rentingEntityIds: string[] = []

  reservationChoiceMadeEventListener (
    data: ChoiceCardPropogateClickInterface
  ): void {
    this.handleReservationDialogChoices(data.reservationType)
  }

  get upcomingBookingsViewMode (): string {
    if (this.monthView) {
      return 'month'
    }

    return 'week'
  }

  async fetchDepartments (): Promise<void> {
    const result = await _departmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async handleFilterByDepartments (): Promise<void> {
    if (this.selectedDepartments.length > 0) {
      this.viewAll =
        this.activityFilter === i18n.t('page.calendar.all_renting_entities')
      this.fetchResources()
      this.clearFilterButtonColor = 'tw-text-red-400'
      this.cleanState = false
    }
  }

  async handleFilterByActivity (): Promise<void> {
    this.viewAll =
      this.activityFilter === i18n.t('page.calendar.all_renting_entities')
    this.fetchResources()
    if (!this.viewAll) {
      this.clearFilterButtonColor = 'tw-text-red-400'
      this.cleanState = false
      return
    }

    this.clearFilterButtonColor = ''
    this.cleanState = true
  }

  async clearFilters (): Promise<void> {
    await this.fetchResources()
    await this.fetchEvents()
    this.$forceUpdate()
    this.selectedDepartments = []
    this.clearFilterButtonColor = ''
    this.viewAll = true
    this.activityFilter = i18n.t('page.calendar.all_renting_entities')
    this.cleanState = true
    this.showFilters = false
  }

  async fetchResources (): Promise<void> {
    this.colorIndex = 0
    const rentingEntities =
      await _bookingService.getCalendarViewRentingEntities(
        this.selectedDate,
        this.selectedDepartments,
        this.viewAll,
        'DAILY'
      )
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sorted = rentingEntities.sort((a: any, b: any) =>
      collator.compare(a.name, b.name)
    )

    this.calendarOptions.resources = this.addEntityColors(sorted)
    this.fetchedData = true
  }

  async fetchEvents (): Promise<void> {
    this.colorIndex = 0
    const events = await _bookingService.getCalendarViewEvents(
      dummyDateConverter(this.selectedDate),
      'DAILY'
    )

    this.calendarOptions.events = this.formatEvents(events)
  }

  async fetchBlockedDatesForSelectedDepartments (): Promise<void> {
    const departmentIds = this.calendarOptions.resources
      .map((department) => department.department_id)
      .filter(
        (departmentId, index, currentVal) =>
          currentVal.indexOf(departmentId) === index
      )

    const result = await _blockedDateService.getBlockedDatesSeriesByDepartments(
      departmentIds
    )

    this.blockedDates = result.blocked_dates
    this.formatBlockedDates()
  }

  async handleCalendarViewFetch (): Promise<void> {
    await this.fetchResources()
    await this.fetchEvents()
    await this.fetchBlockedDatesForSelectedDepartments()
  }

  async handleDragOrResizeUpdate (
    info: EventDropArg | EventResizeDoneArg
  ): Promise<void> {
    if (!this.calendarOptions.editable) {
      this.$toast.info(this.$i18n.t('page.calendnar.not_editable').toString())
      info.revert()
      return
    }

    if (info.event.extendedProps.completed_at) {
      info.revert()
      return
    }

    if (info.event.extendedProps.blocking_type) {
      const errMsg = this.$i18n
        .t('page.calendar.blocked_dates.event_modifying_warning')
        .toString()
        .replace(
          '{reason}',
          info.event.extendedProps.blocking_type.toUpperCase()
        )
      this.$toast.error(errMsg)
      info.revert()
      return
    }

    const numberOfInteractors =
      info.oldEvent.extendedProps.number_of_interactors
    const bookingId = info.event._def.publicId

    const title = info.event._def.title

    // const rentingEntityId =
    //   info.event._def.resourceIds !== undefined
    //     ? info.event._def.resourceIds[0]
    //     : ''

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateFrom = info.event.start?.toISOString() as string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateTo = info.event.end?.toISOString() as string

    // const dateFrom = info.event.start && defaultOutgoing(info.event.start)
    // const dateTo = info.event.end && defaultOutgoing(info.event.end)

    // should look into locales and why the event instance is addeding 2 hours?
    // dateFrom = format(subHours(dateFrom, 2), 'y-MM-dd HH:mm:ss')
    // dateTo = format(subHours(dateTo, 2), 'y-MM-dd HH:mm:ss')

    if (info.event.start) {
      if (compareDatesWithNow(info.event.start) === -1) {
        this.$toast.default(
          this.$i18n.t('notify.booking_past_no_modify').toString()
        )
        info.revert()
        return
      }
    }

    if (info.oldEvent.start && info.oldEvent.end) {
      if (
        compareDatesWithNow(info.oldEvent.start) === -1 &&
        compareDatesWithNow(info.oldEvent.end) === -1
      ) {
        this.$toast.default(
          this.$i18n.t('notify.booking_past_no_modify').toString()
        )
        info.revert()
        return
      }
    }

    const payload = {
      booking: {
        renting_entity_id: info.event._def.resourceIds,
        number_of_interactors: numberOfInteractors,
        date_from: dateFrom,
        date_to: dateTo,
        has_blockage: info.event.extendedProps.blocking_type !== undefined
      }
    } as UpdateEventDragOrResize

    try {
      await _bookingService.updateEventDragOrResize(bookingId, payload)
    } catch (error) {
      info.revert()
      await this.handleCalendarViewFetch()
      return
    }

    this.$toast.success(
      `${i18n.t('notify.success.event')} '${title}' ${i18n.t(
        'notify.success.changed'
      )}`
    )
    // this.fetchEvents()
    await this.handleCalendarViewFetch()
  }

  async created (): Promise<void> {
    this.setLocale()
    await this.fetchDepartments()
    await this.handleCalendarViewFetch()
    this.setCalendarEditability()
  }

  // Calendar Events
  dateClicked (info: DateClickArg): void {
    if (this.calendarOptions.editable === false) {
      this.$toast.error(
        this.$i18n
          .t('notify.calendar_no_action_allowed_for_the_role')
          .toString()
          .replace('{role}', this.userRoleComputed.toLocaleUpperCase())
      )
      return
    }

    if (compareDatesWithNow(info.date, 'day') < 0) {
      this.$toast.default(`${i18n.t('notify.booking_no_past')}`)
      return
    }
    if (info.resource !== undefined) {
      this.rentingEntityId = info.resource._resource.id
      this.dateFrom = info.date.toISOString()
    }

    this.showAddModal(info.date, false, 'AUTO')
  }

  eventClicked (info: EventClickArg): void {
    if (info.event.extendedProps.blocking_type) return // <- basically we're checking if the event we're clikcing is virtual blocking event

    info.jsEvent.preventDefault()

    this.rentingEntityIds = info.event._def.resourceIds as string[]
    this.selectedIndex = info.event.id
    this.state = 'edit'
    this.dialog = true
  }

  addEntityColors (
    entities: Array<RentingEntityInterface>
  ): Array<RentingEntityInterface> {
    return entities.map(
      (item: RentingEntityInterface): RentingEntityInterface => {
        const color = this.getColor()
        this.colors[item.id as string] = color

        item.eventClassNames = 'tw-border-0'
        item.eventBackgroundColor = color
        item.title = item.name + ` (Cap. ${item.number_of_interactors})`

        return item
      }
    )
  }

  formatEvents (
    bookings: Array<BookingEventInterface>
  ): BookingEventInterface[] {
    const setColor = (event: BookingEventInterface, color: string) => {
      const t = Chroma(color)
      event.color = t.hex()
    }

    const hasCheckedInGuests = (guestsCount: number, start: Date | string) => {
      return this.hasCheckedInGuests(guestsCount as number, start)
    }

    return bookings.map((event) => {
      const entities = event.resource_ids || []
      delete event.resource_ids

      event.icon =
        event.note !== null || parseJson(event.tags?.value as string).length > 0
      event.resourceIds = entities.map((resource) => resource.renting_entity_id)

      const eventEnd = dummyDateConverter(event.end)
      const eventStart = dummyDateConverter(event.start)
      const now = dummyDateConverter()

      if (eventEnd < now) {
        setColor(
          event,
          hasCheckedInGuests(event.guests_count as number, event.start) && event.has_checked_out
            ? '31b289'
            : 'f7819f'
        )
      } else if (eventStart < now && eventEnd > now) {
        setColor(
          event,
          hasCheckedInGuests(event.guests_count as number, event.start) && event.has_checked_out
            ? '65beff'
            : '9d81f7'
        )
      }

      if (event.whole_department_reservation) {
        setColor(event, 'black')
      }

      return event
    })
  }

  formatBlockedDates (): void {
    if (!this.calendarOptions.resources || !this.blockedDates) return
    const resources = this.calendarOptions.resources

    resources.forEach((resource) => {
      this.blockedDates.forEach((blockedDate) => {
        if (blockedDate.department_id === resource.department_id) {
          const title =
            this.currentLanguage === 'en-us'
              ? blockedDate.description_en
              : blockedDate.description_no

          const event = {
            id: blockedDate.id,
            renting_entity_id: resource.id,
            title: this.getBlockedDateTitle(
              blockedDate.blocking_type as string,
              title
            ),
            start: blockedDate.start_date,
            end: blockedDate.end_date,
            color: Chroma(blockedDate.color as string)
              .darken()
              .hex(),
            resourceId: resource.id,
            extendedProps: {
              blocking_type: blockedDate.blocking_type
            },
            number_of_interactors: 0,
            renting_entity: resource,
            properties: [] as Array<never>,
            client: {} as never,
            completed_at: '',
            editable: false
          } as BookingEventInterface
          this.calendarOptions.events.push(event)
        }
      })
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendar = this.$refs.calendar as any
    calendar.getApi().render()
  }

  getColor (): string {
    const color = this.defaultColors[this.colorIndex]
    this.updateColorIndex()

    return color
  }

  getBlockedDateTitle (
    blockedType: string | undefined,
    content: string
  ): string {
    return (
      i18n
        .t(`page.calendar.blocked_dates.blocking_type.${blockedType}`)
        .toString()
        .toUpperCase() +
      ': ' +
      content
    )
  }

  updateColorIndex (): void {
    if (++this.colorIndex >= this.defaultColors.length) {
      this.colorIndex = 0
    }
  }

  // eslint-disable-next-line
  resourceLabelDidMount(info: any): void {
    info.el.style.backgroundColor = this.colors[info.resource.id]
  }

  formatDate (date: string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss').toString()
  }

  async eventDrop (info: EventDropArg): Promise<void> {
    await this.handleDragOrResizeUpdate(info)
  }

  eventResize (info: EventResizeDoneArg): void {
    this.handleDragOrResizeUpdate(info)
  }

  eventRender (event: BookingEventInterface, element: CalendarEvent): void {
    if (event.icon) {
      element
        .find('.fc-title')
        .prepend("<i class='fa fa-" + event.icon + "'></i>")
    }
  }

  // eslint-disable-next-line
  changeEvenColorOnDrop(resource: any, event: any): void {
    const index = this.calendarOptions.events.findIndex(
      // eslint-disable-next-line
      (item: any) => item.id == event.id
    )

    this.calendarOptions.events[index].resourceId = resource.id
    this.changeEventTime(event, index)
    this.calendarOptions.events[index].color =
      resource.extendedProps.colorSchema
  }

  // eslint-disable-next-line
  changeEventTime(event: any, index: number = -1): void {
    if (index === -1) {
      index = this.calendarOptions.events.findIndex(
        // eslint-disable-next-line
        (item: any) => item.id == event.id
      )
    }

    this.calendarOptions.events[index].start = this.formatDate(event.start)
    this.calendarOptions.events[index].end =
      event.end && this.formatDate(event.end)
  }

  async dateChanged (item: DateRange): Promise<void> {
    if (this.selectedDate.getTime() !== item.start.getTime()) {
      this.selectedDate = item.start
      if (this.initialRender === false) {
        await this.handleCalendarViewFetch()
      }
      this.initialRender = false
    }
  }

  customHeaderHandler (): void {
    this.calendarDialog = true
  }

  listDrawer = false

  toggleListDrawer (): void {
    this.listDrawer = !this.listDrawer
  }

  listEventClicked (id: string): void {
    this.selectedIndex = id
    this.state = 'EDIT'
    this.dialog = true
    this.listDrawer = false
  }

  // Calendar Options
  calendarOptions = {
    height: '100%',
    plugins: [momentTimezonePlugin, resourcePlugin, interactionPlugin],
    initialView: 'weekly',
    resourceLabelClassNames: 'white--text',
    editable: false,
    eventResourceEditable: true,
    eventOverlap: false,
    nowIndicator: true,
    timeZone: configuration().defaultTimezone,
    resourceOrder: '',
    resourceAreaWidth: '20%',
    resourceGroupField: 'department_name',
    locale: this.$i18n.locale,
    resourceAreaColumns: [
      {
        field: 'name',
        headerContent: i18n.t('page.calendar.renting_entities'),
        width: '70%',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        cellContent: (event: any) => {
          const title = event.resource._resource.extendedProps.name
          const extendedDescription: RentingEntityExtendedDescriptionInterface =
            event.resource._resource.extendedProps.extended_description

          const rentingEntityTypeName = i18n.t(
            `renting_entity_types.${extendedDescription.renting_entity_type_name}`
          )

          const output = `${
            extendedDescription.company_name
          } (${rentingEntityTypeName}): ${
            extendedDescription.description ?? 'N/A'
          }`

          return {
            html: `<span title="${output}">${title}</span>`
          }
        }
      },
      {
        field: 'number_of_interactors',
        headerContent: i18n.t('page.calendar.capacity'),
        width: '30%',
        cellClassNames: 'tw-text-center',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        cellContent: (event: any) => {
          const guests = event.fieldValue
          const canCombine = event.resource.extendedProps.can_combine || false
          return canCombine
            ? {
                html: `<div class="tw-flex tw-gap-1 tw-items-center tw-justify-center"><span>${guests}</span><i class="mdi mdi-vector-square-plus tw-text-xs tw-text-stone-500"/></div>`
              }
            : guests
        },
        cellDidMount: (): void => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const calendar = this.$refs.calendar as any
          calendar.getApi().render()
        }
      }
    ],
    weekends: true,
    displayEventTime: true,
    displayEventEnd: true,
    customButtons: {
      firelist: {
        text: this.$t('page.calendar.buttons.firelist'),
        icon: 'mdi mdi mdi-fire-alert',
        click: (): void => {
          this.showFirelistDialog()
        }
      },
      newReservation: {
        text: i18n.t('button.new_reservation'),
        icon: 'mdi mdi mdi-calendar-plus',
        click: (): void => {
          if (!this.calendarOptions.editable) {
            this.$toast.error(
              this.$i18n
                .t('notify.calendar_no_action_allowed_for_the_role')
                .toString()
                .replace('{role}', this.userRoleComputed.toLocaleUpperCase())
            )
            return
          }
          // this.showAddModal(null, true, '')
          this.showUberDialog()
        }
      },
      reloadButton: {
        text: i18n.t('button.reload'),
        icon: 'mdi mdi mdi-reload',
        click: (): void => {
          window.location.reload()
        }
      },
      next: {
        click: (): void => {
          const date = addDays(this.selectedDate, 6)
          date.setDate(date.getDate())

          const calendar = this.$refs.calendar as InstanceType<
            typeof FullCalendar
          >

          const calendarApi = calendar.getApi()
          calendarApi.gotoDate(date)
          this.selectedDate = date
        }
      },
      prev: {
        click: (): void => {
          const date = subDays(this.selectedDate, 6)
          date.setDate(date.getDate())
          const calendar = this.$refs.calendar as InstanceType<
            typeof FullCalendar
          >

          const calendarApi = calendar.getApi()
          calendarApi.gotoDate(date)
          this.selectedDate = date
        }
      },
      today: {
        text: i18n.t('page.calendar.buttons.today'),
        click: (): void => {
          const date = new Date()
          date.setDate(date.getDate())

          const calendar = this.$refs.calendar as InstanceType<
            typeof FullCalendar
          >

          const calendarApi = calendar.getApi()
          calendarApi.gotoDate(date)
          this.selectedDate = date
        }
      },
      listButton: {
        text: i18n.t('page.calendar.client_list'),
        icon: 'mdi mdi mdi-calendar-search',
        click: this.toggleListDrawer
      },
      monthView: {
        text: !this.monthView ? i18n.t('common.month') : i18n.t('common.week'),
        click: (): void => this.changeMonthView()
      },
      fullScreen: {
        click: (): void => this.toggleFullscreen(),
        text: i18n.t('common.fullscreen'),
        icon: 'mdi mdi mdi-fullscreen'
      },
      lockButton: {
        click: (): void => this.showReserveDialog(),
        text: i18n.t('page.calendar.buttons.lock_booking'),
        icon: 'mdi mdi mdi-book-lock'
      },
      customHeader: {
        text: format(this.selectedDate, 'MMMM d, y'),
        click: (): void => this.customHeaderHandler()
      }
    },
    views: {
      weekly: {
        type: 'resourceTimeline',
        duration: { days: 7 },
        slotDuration: '24:00:00',
        slotLabelFormat: [{ day: 'numeric', weekday: 'short' }]
      }
    },
    headerToolbar: {
      left: 'customHeader',
      right: 'firelist newReservation,listButton reloadButton,fullScreen',
      center: 'monthView prev,today,next'
    },
    resources: [] as RentingEntityInterface[],
    events: [] as BookingEventInterface[],
    resourceLabelDidMount: this.resourceLabelDidMount,
    eventDrop: this.eventDrop,
    eventResize: this.eventResize,
    dateClick: this.dateClicked,
    eventClick: this.eventClicked,
    datesSet: this.dateChanged
  }

  showAddModal (
    date: Date | null = null,
    override: boolean,
    type: string
  ): void {
    // eslint-disable-next-line no-mixed-operators
    if (date === null || compareDatesWithNow(date, 'day') < 0) {
      if (!override) {
        this.$toast.default(`${i18n.t('notify.booking_no_past')}`)
        return
      }
    }
    if (type === 'AUTO') {
      this.autoDialog = true
    } else {
      this.dialog = true
    }
    this.state = 'add'

    if (date) {
      this.selectedDate = date
    }
  }

  changeMonthView (): void {
    if (!this.monthView) {
      this.monthView = true

      this.calendarOptions.customButtons.monthView.text = 'Week'

      this.calendarOptions.views.weekly.duration = {
        days: 30
      }

      return
    }

    this.monthView = false

    this.calendarOptions.customButtons.monthView.text = 'Month'

    this.calendarOptions.views.weekly.duration = {
      days: 7
    }
  }

  showReserveDialog () {
    this.reserveDialog = true
  }

  showManualDialog () {
    this.manualDialog = true
  }

  showUberDialog () {
    this.uberDialog = true
  }

  showFirelistDialog () {
    this.firelistDialog = true
  }

  handleReservationDialogChoices (data: ReservationTypes): void {
    switch (data) {
      case ReservationTypes.AUTOMATIC_RESERVATION:
        this.showAddModal(null, true, '')
        break
      case ReservationTypes.MANUAL_RESERVATION:
        this.showManualDialog()
        break
      case ReservationTypes.DEPARTMENT_RESERVATION:
        this.showReserveDialog()
        break
      default:
        throw new Error('Reservation type not found')
    }
    this.uberDialog = false
  }

  toggleFullscreen (): void {
    // const calendarEl = this.$refs.calendar.el as HTMLElement
    const calendar = this.$refs.calendar as InstanceType<typeof FullCalendar>

    const body = document.body as HTMLElement

    const sideBarActive = Store.getters['app/getSideBarActivity']

    if (document.fullscreenElement) {
      // Exiting
      document.exitFullscreen()

      calendar.getApi().render()

      if (sideBarActive === false) {
        Store.dispatch('app/toggleSideBarActivity')
      }
    } else {
      // Entering
      body.requestFullscreen()

      calendar.getApi().render()

      if (sideBarActive === true) {
        Store.dispatch('app/toggleSideBarActivity')
      }
    }
  }

  setCalendarEditability (): void {
    const userData = this.$store.state.auth.user
    if (userData) {
      this.userRole = userData.role
    }

    this.calendarOptions.editable =
      this.userRole === 'admin' || this.userRole === 'host'
  }

  async submit (): Promise<void> {
    this.close()
    await this.handleCalendarViewFetch()
  }

  close (): void {
    this.dialog = false
    this.autoDialog = false
    this.reserveDialog = false
  }

  async closeDialogFromCheckouFunction (): Promise<void> {
    this.close()
    await this.handleCalendarViewFetch()
  }

  calendarDateSelected (pickedDate: string): void {
    const date = new Date(pickedDate)

    const calendar = this.$refs.calendar as InstanceType<typeof FullCalendar>
    const calendarApi = calendar.getApi()
    calendarApi.gotoDate(date)

    this.calendarDialog = false
  }

  hasCheckedInGuests (totalGuests: number, start: Date | string): boolean {
    const now = new Date()
    const _start = dummyDateConverter(start)

    if (totalGuests === 0 && isAfter(now, _start)) return false

    return true
  }

  // #region setters
  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }

  formatEventTitle (
    title: string,
    isWholeDepartmentReservation = false
  ): string {
    if (!isWholeDepartmentReservation) return title

    return this.$t('common.reserved').toString().toUpperCase() + ': ' + title
  }

  // #endregion

  // #region
  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // #endregion

  // #region Watchers
  @Watch('dialog')
  setSelectedIndex (): void {
    if (!this.dialog) {
      this.selectedIndex = null
    }
  }

  @Watch('autoDialog')
  setSelectedIndexAuto (): void {
    if (!this.autoDialog) {
      this.selectedIndex = null
    }
  }

  @Watch('selectedDate')
  @Watch('monthView')
  onSelectedDateChange (): void {
    this.calendarOptions.customButtons.customHeader.text = `
      ${this.headerDateFormatter(this.selectedDate)}
      -
      ${
        !this.monthView
          ? this.headerDateFormatter(addDays(this.selectedDate, 6))
          : this.headerDateFormatter(addDays(this.selectedDate, 30))
      }
    `
  }
  // #endregion

  headerDateFormatter (inputDate: Date): string {
    return format(dummyDateConverter(inputDate), 'PPP', {
      locale: this.locale
    })
  }

  checkArrivalTimeVsCheckin (
    startDate: Date | string,
    expectedArrival: Date | string
  ): boolean {
    return expectedArrival
      ? format(startDate as Date, 'HH:mm') !== expectedArrival
      : false
  }

  // #region getters
  get userRoleComputed (): string {
    return this.$store.state.auth.user?.role as string
  }

  get currentUserId (): string {
    return this.$store.state.auth.user?.id as string
  }
  // #endregion
}
