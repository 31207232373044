
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region components
import EmptyTable from '@/common/components/ui/table/EmptyTable.vue'
import CustomTableRender from '@/common/components/ui/table/CustomTableRender.vue'
// #endregion

// #region interfaces
import { TableHeaderInterface } from '@/types/interfaces/ui'
// #endregion
// #endregion

@Component({
  components: {
    EmptyTable,
    CustomTableRender
  }
})
export default class Table extends Vue {
  // eslint-disable-next-line
  @Prop({ required: true }) items!: Array<any>
  @Prop({ required: true }) readonly title!: string
  @Prop({ default: false }) readonly hideHint!: boolean
  @Prop({ required: true }) readonly headers!: Array<TableHeaderInterface>
}
