
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TablePagination extends Vue {
  totalPages = 1

  @Prop({ default: 1 }) readonly currentPage!: number | boolean
  @Prop({ default: 1 }) readonly totalElements!: number
  @Prop({ default: 1 }) readonly perPage!: number

  @Watch('currentPage')
  @Watch('totalElements')
  @Watch('perPage')
  onCurrentPageChange (): void {
    if (this.currentPage) {
      this.totalPages = Math.ceil(this.totalElements / this.perPage) || 1
    }
  }

  created (): void {
    this.totalPages = Math.ceil(this.totalElements / this.perPage) || 1
  }
}
