
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
import { format } from 'date-fns'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #endregion

// #region constants
const DATE_FORMAT = 'dd.MM.yyyy'
// #endregion

@Component({ methods: { parseJson } })
export default class BookingInformation extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ required: true }) readonly booking!: any
  @Prop({ required: true }) readonly step!: number
  @Prop({ required: true }) readonly stepName!: string
  @Prop({ required: true }) readonly activeVisualSettings!: boolean

  get bookingDate (): string {
    if (!this.booking.date_from) {
      return 'N/A'
    }

    if (
      this.booking.department.schedule_system === 'HOURLY' &&
      this.booking.department.activity_system === 'BOOKING'
    ) {
      const dF = dummyDateConverter(new Date(this.booking.date_from))

      return format(dF, `${DATE_FORMAT} / HH:mm`)
    }

    if (
      this.booking.department.schedule_system === 'HOURLY' &&
      this.booking.department.activity_system === 'REQUESTS_ONLY'
    ) {
      const dF = dummyDateConverter(new Date(this.booking.date_from))

      const dT = dummyDateConverter(new Date(this.booking.date_to))

      return `${format(dF, `${DATE_FORMAT} / HH:mm`)} - ${format(
        dT,
        `${DATE_FORMAT} / HH:mm`
      )}`
    }

    // Daily we need them for both requests & bookings
    const dF = dummyDateConverter(new Date(this.booking.date_from))

    const dT = dummyDateConverter(new Date(this.booking.date_to))

    return `${format(dF, DATE_FORMAT)} - ${format(dT, DATE_FORMAT)}`
  }

  get expectedArrival (): string {
    if (!this.booking.expected_arrival) {
      return 'N/A'
    }

    return `${this.booking.expected_arrival} (24h)`
  }

  get expectedDeparture (): string {
    if (!this.booking.expected_departure) {
      return 'N/A'
    }

    return `${this.booking.expected_departure} (24h)`
  }
}
