
// #region imports

// #region vue
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class DocTemplateCard extends Vue {
  @Prop({ required: true, default: 'Header' }) header!: string
  @Prop({ required: true, default: 'Sub-header' }) subHeader!: string
  @Prop({ required: false }) description!: string
}
