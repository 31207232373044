import navigation from '@/configs/navigation'
import theme from '@/configs/theme'

export default {
  product: {
    name: process.env.VUE_APP_PRODUCT_NAME,
    version: process.env.VUE_APP_VERSION
  },
  navigation,
  theme,
  baseUrl: process.env.VUE_APP_API_ENDPOINT,
  isLoading: false
}
