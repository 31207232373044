
// Vue
import { Component, Vue, Prop } from 'vue-property-decorator'

// Interfaces
import { EmailNotifications } from '@/types/interfaces/setting'

// Services
import SettingsService from '@/services/setting.service'

// Validations Rules
import rules from '@/utils/rules.utils'

// Translation
import i18n from '@/i18n'

// Utils
import { cloneDeep } from 'lodash'

@Component
export default class EmailSubscription extends Vue {
  @Prop({ required: true }) companyId!: string

  // Juggling variables
  valid = false
  rules = rules
  state = ''

  // Model
  data = {
    cc: '',
    bcc: '',
    subject: '',
    header: '',
    body: '',
    footer:
      // eslint-disable-next-line no-template-curly-in-string
      `
      <hr/>
      <p>
        <b>${i18n.t('reminder.email.reach_support')}</b> 
      </p> 
      <p>${i18n.t('common.email')}:</p> 
      <a href="mailto:{{frontline_email}}">{{frontline_email}}</a>
      <p>${i18n.t('common.phone')}:</p> 
      <a href="tel:{{frontline_phone}}">{{frontline_phone}}</a>
      <p>
        <b>${i18n.t('reminder.email.powered_by')}
        </b>
      </p>`,
    description: 'This is is used as a template for email subscription.',
    company_id: this.companyId,
    trigger_name: 'email_subscription'
  } as EmailNotifications

  dynamicVariables = [
    '{{client_name}}',
    '{{department_name}}',
    '{{date_from}}',
    '{{date_to}}',
    '{{department_address}}'
  ]

  async created (): Promise<void> {
    this.data.company_id = this.companyId
    this.fetchEmailNotification()
  }

  async fetchEmailNotification (): Promise<void> {
    const result = await SettingsService.getEmailNotificationsByCompanyId(
      this.companyId,
      this.data.trigger_name
    )
    if (result !== false) {
      this.state = 'edit'
      this.data = cloneDeep(result)
    } else {
      this.state = 'add'
    }
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const emailReminder = { ...this.data }
      if (this.state === 'add') {
        await SettingsService.emailNotifications(emailReminder)
        this.$toast.success(
          i18n.t('notify.success.email_notifications.email_reminder').toString()
        )
      } else {
        delete emailReminder.trigger_id
        delete emailReminder.company_id

        await SettingsService.updateEmailNotifications(
          emailReminder,
          emailReminder.id
        )
        this.$toast.success(
          i18n
            .t('notify.success.email_notifications.email_subscription')
            .toString()
        )
      }
      await this.fetchEmailNotification()
    }
  }

  onSubmit (): void {
    this.$emit('submit')
  }
}
