
// #region imports

// #region vue
import { Vue, Prop, Component } from 'vue-property-decorator'
// #endregion

// #region components
import OneLiner from '../_OneLiner.vue'
import OneLinerChip from '../_OneLinerChip.vue'
// #endregion

// #endregion

@Component({
  components: {
    OneLiner,
    OneLinerChip
  }
})
export default class DateInfo extends Vue {
  @Prop({ required: true }) readonly checkinTitle!: string
  @Prop({ required: true }) readonly checkinDate!: string
  @Prop({ required: true }) readonly checkoutTitle!: string
  @Prop({ required: true }) readonly checkoutDate!: string
  @Prop({ required: true }) readonly totalDays!: string
  @Prop({ required: false, default: false }) readonly chip!: boolean
  @Prop({ required: false, default: false })
  readonly activeVisualSettings!: boolean

  // #region computed
  get isLoggedIn (): boolean {
    return this.$store.getters['auth/getUser']
  }

  get canIApplyVisualSettings (): boolean {
    if (!this.isLoggedIn && this.activeVisualSettings) return true
    return false
  }
  // #endregion
}
