import i18n from '@/i18n'
import jwtDecode from 'jwt-decode'
import store from '@/store'
import { UserInterface } from '@/types/interfaces/user'

interface TokenInterface {
  exp: number
}

export const validateToken = () => {
  let user: string | UserInterface | null = localStorage.getItem('user')

  if (!user) {
    return
  }

  user = JSON.parse(user) as UserInterface

  let decodedToken: null | TokenInterface = null
  if (user.access_token) {
    try {
      decodedToken = jwtDecode(user.access_token)
    } catch (e) {
      dispatchStoreError()
      return
    }
  }

  if (!decodedToken) {
    dispatchStoreError()
    return
  }

  const now = Date.now()
  const expirationDate = +(parseInt(`${decodedToken.exp}`) + '000')
  if (now > expirationDate) {
    dispatchStoreError()
  }
}

const dispatchStoreError = () => {
  store.dispatch('app/error', {
    message: i18n.t('common.auth_expired'),
    status: 401
  })
}
