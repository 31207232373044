import RentingEntities from '@/views/renting-entities/RentingEntities.vue'
import RentingEntity from '@/views/renting-entities/RentingEntity.vue'

const rentingEntityRoutes = [
  {
    path: '/renting-entities',
    name: 'renting-entities',
    component: RentingEntities,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/renting-entity/:id',
    name: '/renting-entity',
    component: RentingEntity,
    meta: {
      auth: true,
      role: ['admin']
    }
  }
]

export default rentingEntityRoutes
