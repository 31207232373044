// #region imports

// #region interfaces
import {
  HourlyDurationSettingInteface,
  DailyDurationSettingInteface,
  WorkingHourSettingInterface,
  TagsSettingsInterface,
  EmailNotifications,
  SmsNotification,
  WizardInterface,
  ApiCallErrorInterface,
  TaggsDescriptionInterface,
  ReminderHoursInterface,
  OverallBookingInformationInterface,
  PublicBookingCustomVisualSettingsInterface
} from '@/types/interfaces/setting'

// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

class SettingService extends BaseService {
  public error?: ApiCallErrorInterface

  async getCompanySettings (companyId: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/company-settings/${companyId}`)

    Store.dispatch('app/loading', false)

    return result.data.settings
  }

  async getCompanySettingsPulbic ($companyId: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/company-settings-public/${$companyId}`)

    Store.dispatch('app/loading', false)

    return result.data.settings
  }

  async updatePagination (companyId: string, value: number) {
    Store.dispatch('app/loading', true)

    const url = `/pagination/${companyId}/${value}`

    const result = await this.axios.put(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async toggleNotificationSetting (companyId: string, setting: string) {
    Store.dispatch('app/loading', true)

    const url = `/toogle-notification/${companyId}`

    const result = await this.axios.put(url, { notification_name: setting })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async updateWorkingHours (
    settings: WorkingHourSettingInterface,
    companyId: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `/working-hours-settings/${companyId}`

    const result = await this.axios.put(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async updateTags (settings: TagsSettingsInterface, companyId: string) {
    Store.dispatch('app/loading', true)

    const url = `/tags-settings/${companyId}`

    const result = await this.axios.put(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async upsertOverallBookingInformation (
    companyId: string,
    settings: OverallBookingInformationInterface
  ) {
    Store.dispatch('app/loading', true)

    const url = `/overall-booking-information/${companyId}`

    const result = await this.axios.post(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async upsertPublicBookingVisualSettings (
    companyId: string,
    settings: PublicBookingCustomVisualSettingsInterface
  ) {
    Store.dispatch('app/loading', true)

    const url = `/public-booking-visual-settings/${companyId}`

    const result = await this.axios.post(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async upsertTagDescription (
    companyId: string,
    settings: TaggsDescriptionInterface
  ) {
    Store.dispatch('app/loading', true)

    const url = `/tags-settings-description/${companyId}`

    const result = await this.axios.post(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async upsertReminderHours (
    companyId: string,
    settings: ReminderHoursInterface
  ) {
    Store.dispatch('app/loading', true)

    const url = `/reminder-hours-settings/${companyId}`

    const result = await this.axios.put(url, { data: settings })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async updateLastBookingSettings (
    setting: DailyDurationSettingInteface,
    companyId: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `/last-booking-settings/${companyId}`

    const result = await this.axios.put(url, { data: setting })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async updateHourlyBookingDurations (
    setting: HourlyDurationSettingInteface,
    companyId: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `/booking-duration-settings/hourly/${companyId}`

    const result = await this.axios.put(url, { data: { hourly: setting } })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async updateDailyBookingDurations (
    setting: DailyDurationSettingInteface,
    companyId: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `/booking-duration-settings/daily/${companyId}`

    const result = await this.axios.put(url, { data: { daily: setting } })

    Store.dispatch('app/loading', false)

    return result.data.setting
  }

  async updateDefaultBookingDurations (defaultValue: number, companyId: string) {
    Store.dispatch('app/loading', true)

    const url = `/booking-duration-settings/default/${companyId}`

    await this.axios.put(url, { data: { default: defaultValue } })

    Store.dispatch('app/loading', false)
  }

  async storeWizardData (data: WizardInterface) {
    Store.dispatch('app/loading', true)

    const url = 'wizard'

    await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)
  }

  async emailNotifications (data: EmailNotifications) {
    Store.dispatch('app/loading', true)

    const url = 'email-notification'

    await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)
  }

  async getEmailNotificationsByCompanyId (
    companyId: string,
    triggerName: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `email-notification-by-company-and-trigger-name/${companyId}/${triggerName}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.email
  }

  async updateEmailNotifications (data: EmailNotifications, id: number) {
    Store.dispatch('app/loading', true)

    const url = `email-notification/${id}`

    await this.axios.put(url, { data })

    Store.dispatch('app/loading', false)
  }

  async getSmsNotificationsByCompanyIdAndTrigger (
    companyId: string,
    triggerName: string
  ) {
    Store.dispatch('app/loading', true)

    const url = `sms-notification-by-company-and-trigger-name/${companyId}/${triggerName}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.sms
  }

  async smsNotification (data: SmsNotification) {
    Store.dispatch('app/loading', true)

    const url = 'sms-notification'

    await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)
  }

  async updateSmsNotification (data: SmsNotification, id: number) {
    Store.dispatch('app/loading', true)

    const URL = `sms-notification/${id}`

    await this.axios.put(URL, { data })

    Store.dispatch('app/loading', false)
  }

  async getValuesPublic (companyId: string, key: string) {
    Store.dispatch('app/loading', true)

    const URL = `get-values-public/${companyId}/${key}`

    const result = await this.axios(URL)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async removeEmailNotification (id: number) {
    Store.dispatch('app/loading', true)

    const URL = `email-notification/${id}`

    const result = await this.axios.delete(URL)

    Store.dispatch('app/loading', false)

    return result
  }

  async removeSmsNotification (id: number) {
    Store.dispatch('app/loading', true)

    const URL = `sms-notification/${id}`

    const result = await this.axios.delete(URL)

    Store.dispatch('app/loading', false)

    return result
  }

  async deleteCompanySettingByKey (companyId: string, key: string) {
    Store.dispatch('app/loading', true)

    const URL = `company-setting-by-key/${companyId}/${key}`

    const result = await this.axios.delete(URL)

    Store.dispatch('app/loading', false)

    return result
  }
}

export default new SettingService()
