
import { Component, Vue } from 'vue-property-decorator'
import authenticatedLayout from '@/layouts/AuthenticatedLayout.vue'
import emptyLayout from '@/layouts/EmptyLayout.vue'
import errorLayout from '@/layouts/ErrorLayout.vue'
import authLayout from '@/layouts/AuthLayout.vue'
import defaultLayout from '@/layouts/DefaultLayout.vue'
import informativeLayout from '@/layouts/InformativeLayout.vue'

import DisabledAccount from '@/components/auth/DisabledAccount.vue'
import ErrorModal from '@/common/components/error/ErrorModal.vue'
import SnackbarAlert from '@/common/components/ui/alert/SnackbarAlert.vue'
import ErrorNotification from '@/common/components/error/ErrorNotification.vue'
import { mapState } from 'vuex'
import { validateToken } from '@/utils/auth.utils'

@Component({
  components: {
    authenticatedLayout,
    errorLayout,
    authLayout,
    emptyLayout,
    defaultLayout,
    informativeLayout,
    DisabledAccount,
    ErrorModal,
    ErrorNotification,
    SnackbarAlert
  },
  computed: { ...mapState('app', ['isLoading']) }
})
export default class App extends Vue {
  get backgroundStyle () {
    const url = this.$store.getters['app/getCompanyBackgroundImage']

    if (url) {
      return {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }
    }
  }

  get isRouterLoaded (): boolean {
    if (this.$route.name !== null) {
      return true
    }
    return false
  }

  get currentLayout (): string {
    if (this.$route.name === 'wizard') {
      return 'errorLayout'
    }

    if (this.$route.name === 'survey-present') {
      return 'errorLayout'
    }

    return (this.$route.meta?.layout || 'authenticated') + 'Layout'
  }

  created (): void {
    document.title = process.env.VUE_APP_TITLE
    validateToken()
  }
}
