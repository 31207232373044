
// #region imports

// #region vue
import { Vue, Prop, Component } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class OneLinerChip extends Vue {
  @Prop({ required: true, default: 'Title' }) readonly title!: string
  @Prop() readonly subtitle!: string
  @Prop({ default: '' }) readonly separator!: string
  @Prop({ required: false, default: '' }) readonly icon!: string
  @Prop({ required: false, default: '' }) readonly color!: string
}
