
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components

import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewDescription from '@/common/components/pages/PageOverviewDescription.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import CounterCard from '@/common/components/analytics/CounterCard.vue'
// #endregion

// #region services
import CompanyService from '@/services/company.service'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewDescription,
    PageOverviewItems,
    PageOverviewItem,
    CounterCard
  }
})
export default class Company extends Vue {
  company = {} as CompanyInterface

  async fetchCompany (): Promise<CompanyInterface> {
    return await CompanyService.getSingle(
      this.$route.params.id,
      !!this.$route.query.archived || false
    )
  }

  public created (): void {
    this.fetchCompany().then((res) => {
      this.company = res
      this.handleChartData()
    })
  }

  handleChartData (): void {
    this.series = [
      {
        name: i18n.t('table.title.bookings'),
        data: this.company.bookings_count_by_day
      }
    ]
    this.chartOptions.title.text = this.company.bookings_count
  }

  series = [
    {
      name: i18n.t('table.title.bookings'),
      data: this.company.bookings_count_by_day
    }
  ]

  chartOptions = {
    chart: {
      type: 'area',
      height: 160,
      sparkline: {
        enabled: true
      }
    },
    stroke: {
      curve: 'smooth'
    },
    fill: {
      opacity: 0.3
    },
    yaxis: {
      min: 0
    },
    xaxis: {
      type: 'datetime'
    },
    colors: ['#DCE6EC'],
    title: {
      text: this.company.bookings_count,
      offsetX: 0,
      style: {
        fontSize: '24px'
      }
    },
    subtitle: {
      text: i18n.t('table.title.bookings'),
      offsetX: 0,
      style: {
        fontSize: '14px'
      }
    }
  }

  viewParent (): void {
    this.$router.push(`/owner/${this.company.owner.id}`)
  }
}
