
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class PurposeOfStay extends Vue {
  @Prop({ required: true }) readonly item!: string

  get color (): string {
    switch (this.item) {
      case 'holiday':
        return 'pink lighten-5'
      case 'business':
        return 'teal lighten-5'
      case 'course_or_conference':
        return 'lime lighten-5'
      default:
        return ''
    }
  }
}
