
import { Component, Vue } from 'vue-property-decorator'

import { configuration } from '@/config'
import rules from '@/utils/rules.utils'
import authService from '@/services/auth.service'

@Component({
  methods: {
    configuration
  }
})
export default class DomainSignInView extends Vue {
  valid = false
  rules = rules

  slug = ''

  async goToAuth (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      try {
        await authService.checkDomain(this.slug)
        this.$router.push(`/${this.slug}/auth/signin`)
      } catch (err) {
        //
      }
    }
  }
}
