import Categories from '@/views/categories/Categories.vue'
import Category from '@/views/categories/Category.vue'

const categoryRoutes = [
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
    meta: {
      auth: true
    }
  },
  {
    path: '/categories/:id',
    name: 'category',
    component: Category,
    meta: {
      auth: true
    }
  }
]

export default categoryRoutes
