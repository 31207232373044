
import i18n from '@/i18n'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Question extends Vue {
  @Prop({
    required: true,
    default: () => i18n.t('page.surveys.labels.question')
  })
  readonly question!: string

  @Prop({ required: false }) readonly required!: boolean
}
