
import { Component, Vue } from 'vue-property-decorator'

// Components
import AdminDashboard from '@/views/dashboard/_AdminDashboard.vue'
import HostDashboard from '@/views/dashboard/_HostDashboard.vue'
import ObserverDashboard from '@/views/dashboard/_ObserverDashboard.vue'

import { mapState } from 'vuex'

@Component({
  components: { AdminDashboard, HostDashboard, ObserverDashboard },
  computed: { ...mapState('auth', ['user']) }
})
export default class Dashboard extends Vue {
  get userRole () {
    return this.$store.state.auth.user?.role as string
  }
}
