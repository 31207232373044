
// #region imports

// #region vue
import { Vue, Component, Prop } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region interfaces
import {
  TagsSettingsInterface,
  CompanySettingInterface,
  TaggsDescriptionInterface
} from '@/types/interfaces/setting'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
import i18n from '@/i18n'
import { IMAGE_SOURCES } from '@/common/constants'
// #endregion

// #endregion

@Component
export default class TagsSettings extends Vue {
  // #region Props
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  // #endregion

  // #region Injections
  rules = rules
  IMAGE_SOURCES = IMAGE_SOURCES
  // #endregion

  // #region Local Variables

  valid = false
  toggleTagsSettings = false
  expandedTags = false

  // #endregion

  // #region Models
  currentTag = ''
  data = {
    tags: []
  } as TagsSettingsInterface

  tagsDescriptionData = {
    tag_description_en: '',
    tag_description_no: ''
  } as TaggsDescriptionInterface

  async created (): Promise<void> {
    await this.fetchTags()
  }

  // #endregion

  // #region Frontend Interaction Methods

  toggleTags (): void {
    this.expandedTags = !this.expandedTags
  }

  async addTagLocal (): Promise<void> {
    if (this.currentTag.length === 0) return

    if (
      this.data.tags.some((item: string) => {
        return item.toLowerCase() === this.currentTag.toLowerCase()
      })
    ) {
      this.$toast.error(
        i18n.t('component.tags_settings.tag_already_exists').toString()
      )
      return
    }

    this.data.tags.push(this.currentTag.toLocaleUpperCase())
    this.currentTag = ''

    await this.submitTags()
  }

  async removeTagLocal (indx: number): Promise<void> {
    try {
      this.data.tags.splice(indx, 1)
    } catch (error) {
      this.$toast.error(
        i18n.t('component.tags_settings.tag_could_not_be_removed').toString()
      )
    }

    await this.submitTags()
  }

  async resetTagsSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): Promise<void> {
    const settingsToUse = fetchedSettings || this.settings
    const setting = settingsToUse.tags
    const tagsDescriptionSetting = settingsToUse.tags_description
    if (setting) {
      const tags = parseJson(setting)
      if (tags) {
        this.data.tags = tags
      }
    }

    if (tagsDescriptionSetting) {
      this.tagsDescriptionData = parseJson(tagsDescriptionSetting.toString())
    } else {
      this.resetAll()
    }
  }

  resetForm (formName: string): void {
    const form = this.$refs[formName] as HTMLFormElement
    if (form) {
      form.reset()
      form.resetValidation()
    }
  }

  resetData (): void {
    this.tagsDescriptionData = {} as TaggsDescriptionInterface
  }

  resetAll (): void {
    this.resetForm('tagDescriptionForm')
    this.resetData()
  }

  // #endregion

  // #region Backend Interaction Methods

  async submitTags (): Promise<void> {
    const form = this.$refs.inputsForm as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      await settingService.updateTags(this.data, this.companyId)
    }
  }

  async submitTagDescription (): Promise<void> {
    const form = this.$refs.tagDescriptionForm as HTMLFormElement
    const valid = form.validate()

    if (valid) {
      await settingService.upsertTagDescription(
        this.companyId,
        this.tagsDescriptionData
      )
      this.$toast.success(
        i18n.t('component.tags_settings.tag_description_saved').toString()
      )
    }
  }

  async fetchTags (): Promise<void> {
    const fetchedSettings = await settingService.getCompanySettings(
      this.companyId
    )
    await this.resetTagsSettings(fetchedSettings)
  }

  async deleteTagDescription (): Promise<void> {
    await settingService.deleteCompanySettingByKey(
      this.companyId,
      'tags_description'
    )
    this.fetchTags()
    this.$toast.success(
      i18n.t('component.tags_settings.tag_description_deleted').toString()
    )
  }

  // #endregion

  // computed properties
  get canDisable (): boolean {
    return (
      !this.tagsDescriptionData.tag_description_en ||
      !this.tagsDescriptionData.tag_description_no
    )
  }
}
