
import { Component, Vue } from 'vue-property-decorator'
import rules from '@/utils/rules.utils'
import { DemoRequestInterface } from '@/types/interfaces/auth'
import authService from '@/services/auth.service'
import countryPrefixes from '@/assets/CountryPrefix'
import { getPhoneNumber } from '@/utils/helper.utils'
import { configuration } from '@/config'

@Component({
  methods: { getPhoneNumber, configuration }
})
export default class SignupView extends Vue {
  valid = true
  rules = rules
  showPassword = false
  prefixes = countryPrefixes
  showThankYouScreen = false

  data = {
    user: {
      name: '',
      email: '',
      password: '',
      phone_number: '',
      country_code: '+47'
    },
    company: {
      name: '',
      organization_number: '',
      domain: '',
      phone_number: '',
      country_code: '+47',
      address: {
        address: '',
        city: '',
        zip: ''
      }
    }
  } as DemoRequestInterface

  _config () {
    return configuration()
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      await authService.demoRequest({
        ...this.data,
        user: {
          ...this.data.user
        }
      })
      this.showThankYouScreen = true
    }
  }
}
