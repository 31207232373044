// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
import {
  CreateRentingEntityInterface,
  GetRentingEntitiesByIdsResponse,
  RentingEntityInterface
} from '@/types/interfaces/renting-entity'
// #endregion

// #endregion

class RentingEntityService extends BaseService {
  error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url =
      type.length === 0 ? '/renting-entities' : '/renting-entities/archived'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getAllPublic (): Promise<RentingEntityInterface[]> {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/renting-entities-public')

    Store.dispatch('app/loading', false)

    return result.data.entities
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entities-search?page=${page}`

    const result = await this.axios(url, { params: { searchText } })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async filterByDepartments (departmentIds: Array<string>, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entities-by-department-ids?page=${page}`

    const result = await this.axios.post(url, { departmentIds })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getAllUnpaginated (type = '') {
    Store.dispatch('app/loading', true)

    const url =
      type.length === 0
        ? '/renting-entities-unpaginated'
        : '/renting-entities-unpaginated/archived'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.entity
  }

  async getAllBasedOnDepartmentAndCategory (
    departmentId: string,
    categoryId: string | null
  ) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.get('/daily/renting-entities', {
      params: {
        departmentId,
        categoryId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data.entities
  }

  async create (data: CreateRentingEntityInterface) {
    Store.dispatch('app/loading', true)

    const url = '/renting-entity/'

    const result = await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)

    return result.data.entity
  }

  async delete (id: string | undefined) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result
  }

  async update (data: CreateRentingEntityInterface, id: string | undefined) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity/${id}`

    const result = await this.axios.put(url, { data })

    Store.dispatch('app/loading', false)

    return result.data.entity
  }

  async reactivate (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/renting-entity-reactivate/${id}`

    const result = await this.axios.patch(url)

    Store.dispatch('app/loading', false)

    return result.data.entity
  }

  async getDisabledDates (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.get(`/booked-dates-for-entity/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.disabled_dates
  }

  async getRentingEntitiesByIds (
    ids: string[]
  ): Promise<GetRentingEntitiesByIdsResponse[]> {
    Store.dispatch('app/loading', true)

    const data = {
      ids
    }

    const result = await this.axios.post('/renting-entities-by-ids', { data })

    Store.dispatch('app/loading', false)

    return result.data.entities
  }
}

export default new RentingEntityService()
