
import { Component, Vue } from 'vue-property-decorator'
import NavMenuItem from '@/components/navigation/NavMenuItem.vue'

@Component({
  components: {
    NavMenuItem
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  }
})
export default class NavMenu extends Vue {}
