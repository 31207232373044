
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component
export default class PaginationSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly setPagination!: (
    pagination: number
  ) => void

  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  paginationFormValidity = false

  pagination = null as null | number

  async updatePagination (): Promise<void> {
    const form = this.$refs.paginationForm as HTMLFormElement
    const valid = form.validate()

    if (valid && this.pagination) {
      await settingService.updatePagination(this.companyId, this.pagination)
      this.setPagination(this.pagination)
    }
  }

  @Watch('settings')
  onSettingsChange (): void {
    this.pagination = this.settings.pagination || null
  }
}
