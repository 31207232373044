
// Vue
import { Component, Vue, Prop } from 'vue-property-decorator'

// Interfaces
import { SmsNotification } from '@/types/interfaces/setting'

// Validations Rules
import rules from '@/utils/rules.utils'

// Seravices
import SettingsService from '@/services/setting.service'

// Translation
import i18n from '@/i18n'

// Utils
import { cloneDeep } from 'lodash'

@Component
export default class SmsUpdate extends Vue {
  @Prop({ required: true }) readonly companyId!: string

  valid = false
  rules = rules
  state = ''

  data = {
    header: '',
    message: '',
    trigger_name: 'sms_booking_updates',
    company_id: ''
  } as SmsNotification

  defaultData = {
    header: `${i18n.t('templates.notifications.sms.update.header_to_save')}`,
    message: '',
    trigger_name: 'sms_booking_updates',
    company_id: ''
  } as SmsNotification

  dynamicVariables = [
    '{{date_from}}',
    '{{date_to}}',
    '{{client_name}}',
    '{{department_name}}'
  ]

  async created (): Promise<void> {
    this.data.company_id = this.companyId
    this.fetchSmsNotification()
  }

  async fetchSmsNotification (): Promise<void> {
    const result =
      await SettingsService.getSmsNotificationsByCompanyIdAndTrigger(
        this.companyId,
        this.data.trigger_name
      )
    if (result !== false) {
      this.state = 'edit'
      this.data = cloneDeep(result)
    } else {
      this.state = 'add'
    }
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const payload = { ...this.data }
      if (this.state === 'add') {
        await SettingsService.smsNotification(payload)
        this.$toast.success(
          i18n.t('notify.success.sms_notifications.sms_reminder').toString()
        )
      } else {
        delete payload.trigger_id
        delete payload.company_id

        await SettingsService.updateSmsNotification(payload, payload.id)
        this.$toast.success(
          i18n.t('notify.success.sms_notifications.sms_reminder').toString()
        )
      }
      await this.fetchSmsNotification()
    }
  }

  async remove (): Promise<void> {
    const payload = { ...this.data }

    await SettingsService.removeSmsNotification(payload.id)

    this.$toast.success(
      i18n.t('notify.success.sms_notifications.sms_update_remove').toString()
    )

    this.data = { ...this.defaultData, company_id: this.companyId }

    await this.fetchSmsNotification()
  }

  onSubmit (): void {
    this.$emit('submit')
  }

  onRemove (): void {
    this.$emit('remove')
  }
}
