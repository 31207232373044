// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region interfaces
import { CreateDepartmentReservationInterface } from '@/types/interfaces/department-reservation'
// #endregion

// #region utils & helpers
// import { format } from 'date-fns'
// #endregion

// #endregion

class DepartmentReservationService extends BaseService {
  public error?: ApiCallErrorInterface

  async checkDepartmentAvaialability (
    departmentId: string,
    dateFrom: Date | string,
    dateTo: Date | string,
    scheduleSystem: 'HOURLY' | 'DAILY'
  ) {
    Store.dispatch('app/loading', true)

    const URL = 'check-department-availability'

    const result = await this.axios.get(URL, {
      params: {
        departmentId,
        dateFrom,
        dateTo,
        scheduleSystem
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async create (data: CreateDepartmentReservationInterface) {
    Store.dispatch('app/loading', true)

    const URL = 'department-reservation'

    const result = await this.axios.post(URL, {
      data
    })

    Store.dispatch('app/loading', false)

    return result.data
  }
}

export default new DepartmentReservationService()
