
// #region imports

// vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'

// components
import ExpandableContainer from './ExpandableContainer.vue'

// validations
import rules from '@/utils/rules.utils'

// services
import _companyService from '@/services/company.service'

// #endregion

@Component({
  name: 'FirelistSettings',
  components: {
    ExpandableContainer
  }
})
export default class FirelistSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  // fields - start
  data = {} as CompanySettingInterface
  saveDisabled = true
  document: File | null = null
  hasUploaded = false
  deleteDialog = false
  // fields - end

  // injections - start
  rules = rules
  // injections - end

  // methods - start
  handleFileSelected (file: File): void {
    this.document = file
    this.saveDisabled = false
  }

  toggleDeleteDialog (): void {
    this.deleteDialog = !this.deleteDialog
  }

  viewFile (): void {
    window.open(this.data.firelist_document_path, '_blank')
  }

  async handleDeleteFirelist (): Promise<void> {
    if (this.hasUploaded) {
      this.deleteDialog = true
    } else {
      this.deleteDialog = false
      this.document = null
      this.saveDisabled = true
    }
  }

  // methods - end

  // vue hooks - start
  async created (): Promise<void> {
    await this.getFirelistDoc()
  }

  // vue hooks - end

  // backend interacting - start
  async getFirelistDoc (): Promise<void> {
    const result = await _companyService.getFirelistDoc(this.companyId)

    if (result.filePath) {
      this.data.firelist_document_path = result.filePath
      this.hasUploaded = true
    } else {
      this.hasUploaded = false
    }
  }

  async upload (): Promise<void> {
    if (!this.document) return

    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    const formData = new FormData()

    if (valid) {
      formData.append('file', this.document)
      formData.append('company_id', this.companyId)

      const result = await _companyService.uploadFirelistDoument(formData)
      this.$toast.success(
        this.$t('settings.firelist.upload_success').toString()
      )
      this.data.firelist_document_path =
        result.result.firelist_document_path.toString()

      this.hasUploaded = true

      this.$emit('fileUploaded')
    } else {
      this.$toast.error(this.$t('settings.firelist.upload_error').toString())
    }
  }

  async deleteFireList (): Promise<void> {
    const deleteResult = await _companyService.deleteFirelistDoc(this.companyId)

    if (deleteResult) {
      this.$toast.success(
        this.$t('settings.firelist.delete_success').toString()
      )
      this.hasUploaded = false
      this.document = null
      this.toggleDeleteDialog()
    } else {
      this.$toast.error(this.$t('settings.firelist.delete_failure').toString())
    }
  }

  // backend interacting - end

  // watchers - start
  @Watch('settings')
  onSettingsChange (): void {
    this.data.firelist_document_path = this.settings.firelist_document_path
    this.document = null
    this.saveDisabled = true
    if (this.data.firelist_document_path) {
      this.hasUploaded = true
    } else {
      this.hasUploaded = false
    }
  }

  // watchers - end

  // getters - start

  // getters - end
}
