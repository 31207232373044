
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region components
// Components
import Table from '@/common/components/ui/table/Table.vue'
// Custom Components
import BookingsDate from '@/components/bookings/BookingsDate.vue'
import BookingClientName from '@/components/bookings/BookingClientName.vue'
import BookingClientPhoneNumber from '@/components/bookings/BookingClientPhoneNumber.vue'
import MoreThanOneTag from '@/components/bookings/MoreThanOneTag.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'

import { BookingInterface } from '@/types/interfaces/booking'
// #endregion

// Services
import bookingRequestService from '@/services/booking-request.service'

@Component({
  components: { Table },
  computed: { ...mapState('app', ['isLoading']) }
})
export default class PendingBookingRequests extends Vue {
  headers = [
    {
      text: i18n.t('table.col.client'),
      value: 'custom_render',
      render: BookingClientName
    },
    {
      text: i18n.t('table.col.phone_number'),
      value: 'custom_render',
      render: BookingClientPhoneNumber
    },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    { text: i18n.t('table.col.company'), value: 'company.name' },
    {
      text: i18n.t('table.col.interactors'),
      value: 'number_of_interactors',
      key: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    },
    {
      text: 'TAGS',
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'properties',
      render: MoreThanOneTag
    }
  ]

  bookings: Array<BookingInterface> = []
  pagination = {} as PaginationInterface

  booking: BookingInterface | null = null

  async handleBookingRequestsFetch (): Promise<void> {
    const result = await bookingRequestService.getAll('approved')
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleBookingRequestsFetch()
  }

  async prevPage (): Promise<void> {
    const result = await bookingRequestService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async nextPage (): Promise<void> {
    const result = await bookingRequestService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await bookingRequestService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.bookings = result.booking_requests.data

    delete result.booking_requests.data

    this.pagination = result.booking_requests
  }
}
