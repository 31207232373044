
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region validation
import { cloneDeep } from 'lodash'
// #endregion

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { AddressInterface } from '@/types/interfaces/address'
// #endregion

import CompanyService from '@/services/company.service'

import i18n from '@/i18n'

// Components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
import countryPrefixes from '@/assets/CountryPrefix'

// Helpers and Utils
import rules from '@/utils/rules.utils'
import { sanitizePhoneNumber } from '@/utils/sanitation.utils'

// #endregion

@Component({
  components: { TableModalActions, TableModalForm },
  methods: {
    sanitizePhoneNumber
  }
})
export default class AddEditCompany extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop({ default: null }) readonly item!: CompanyInterface | null
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  rules = rules
  prefixes = countryPrefixes

  data = {
    name: '',
    domain: '',
    organization_number: '',
    address_id: '',
    phone_number: '',
    country_code: '+47',
    address: {
      address: '',
      city: '',
      zip: ''
    }
  } as CompanyInterface

  loading = true
  valid = false
  addressMode = 'insert'
  addresses = [] as Array<AddressInterface>
  countryPrefixes = countryPrefixes

  async fetchAddress (): Promise<void> {
    const addresses = await CompanyService.getAddresses()
    this.addresses = addresses
  }

  getAddress (data: AddressInterface): string {
    return `${data.address}, ${data.city}, ${data.zip} `
  }

  async created (): Promise<void> {
    this.loading = true
    await this.fetchAddress()

    if (this.item) {
      this.data = cloneDeep(this.item)
    }
    this.loading = false
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      const company = { ...this.data }
      if (this.addressMode === 'select') {
        delete company.address
      } else {
        delete company.address_id
      }

      if (!this.item) {
        await CompanyService.create(company)
        this.$toast.success(i18n.t('notify.success.company_added').toString())
        this.onSubmit()
        this.dialogHandler()
        return
      }

      await CompanyService.update(company)
      this.onSubmit()
      this.dialogHandler()

      this.$toast.success(i18n.t('notify.success.company_updated').toString())
    }
  }

  getText (data: { name: string; code: string }): string {
    return `${data.name} (${data.code})`
  }
}
