
import { Component, Vue } from 'vue-property-decorator'
import ActiveSurveys from '@/views/surveys/_ActiveSurveys.vue'
import ArchivedSurveys from '@/views/surveys/_ArchivedSurveys.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({ components: { ActiveSurveys, ArchivedSurveys } })
export default class Surveys extends Vue {
  tab = 'activeSurveys'

  items = [
    {
      key: 'activeSurveys',
      label: i18n.t('page.surveys.labels.active_surveys')
    },
    {
      key: 'archivedSurveys',
      label: i18n.t('page.surveys.labels.archived_surveys')
    }
  ]

  created (): void {
    document.title = getAppTitle('Surveys')
  }
}
