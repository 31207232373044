
// #region imports

// Vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// Utils and Helpers
import { parseJson } from '@/utils/helper.utils'

// Interfaces
import { BookingPropertyInterface } from '@/types/interfaces/booking'

// #endregion

@Component
export default class MoreThanOneTag extends Vue {
  @Prop({ required: true })
  readonly items!: BookingPropertyInterface[]

  defaultTags = { key: 'tags', value: [] } as BookingPropertyInterface
  tags = this.defaultTags
  created (): void {
    let tag = null

    if (this.items) {
      tag = this.items.find((item) => item.key === 'tags')
    }

    if (!tag) {
      return
    }

    tag.value = parseJson(tag.value as string)
    this.tags = tag
  }
}
