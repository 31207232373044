
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region helpers & utils
import { format } from 'date-fns'
import { defaultIncoming } from '@/utils/date.utils'
// #endregion

// locales - start
import i18n from '@/i18n'
// locales - end

// #endregion

@Component({
  methods: {
    format,
    defaultIncoming
  }
})
export default class BookingsDate extends Vue {
  @Prop({ required: true }) readonly item!: string
  @Prop({ default: false }) readonly checkDate!: boolean

  _defaultIncoming (date: Date | string): string {
    return defaultIncoming(date, 'PP HH:mm')
  }

  get color (): string[] {
    const now = new Date()
    const date = new Date(this.item)

    if (date >= now) {
      return ['teal lighten-4', 'tw-text-emerald-700']
    } else {
      return ['orange lighten-4', 'tw-text-orange-700']
    }
  }

  textColor (): string {
    const now = new Date()
    const date = new Date(this.item)

    if (date >= now) {
      return 'tw-text-black'
    }

    return 'tw-text-red-500'
  }

  get locales (): string {
    return i18n.locale
  }
}
