
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import DepartmentService from '@/services/department.service'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import { AddressInterface } from '@/types/interfaces/address'
// #endregion

// #endregion

@Component
export default class BookHourlyDepartmentCard extends Vue {
  @Prop({ default: false }) readonly row!: boolean
  @Prop({ required: false }) readonly backed!: boolean
  @Prop({ default: true }) readonly arrowRight!: boolean

  @Prop({ required: true }) readonly companyId!: string

  departments = [] as Array<DepartmentInterface>
  readonly SCHEDULE_SYSTEM = 'HOURLY'

  async fetchAllDepartments (): Promise<void> {
    const result = await DepartmentService.getAllCompanyDepartments(
      this.companyId,
      this.SCHEDULE_SYSTEM
    )
    this.departments = result
  }

  @Watch('departments')
  async onDepartmentsChange (): Promise<void> {
    if (this.departments.length === 1 && !this.backed) {
      this.$emit('selectedDepartment', this.departments[0])
    }
  }

  async created (): Promise<void> {
    await this.fetchAllDepartments()
  }

  formatFullAddress (address: AddressInterface | undefined): string {
    if (!address) return ''
    return `${address.address}, ${address.city}, ${address.zip}`
  }
}
