
// #region imports
import { Vue, Component } from 'vue-property-decorator'
// #endregion

// #region components
import DocTemplateCard from '../common/_DocTemplateCard.vue'
import DocTemplateImg from '../common/_DocTemplateImg.vue'
import DocSectionTitle from '../common/_DocSectionTitle.vue'
// #endregion

// Vue instantiates the component
@Component({
  components: {
    DocTemplateCard,
    DocTemplateImg,
    DocSectionTitle
  }
})
export default class CompanyDocumentation extends Vue {
  data = {
    overview: {
      header: 'Overview',
      subHeader: 'Companies',
      description:
        'This section covers the essential aspects of CRUD operations, dependencies, and table functionalities. Explore how to create, read, update, and delete a company. Gain insights into the required dependencies for your application and discover the various functionalities offered by tables.',
      images: {
        img1: {
          src: 'company/nav-companies.png',
          caption: '1. Access to "Companies" throgh the Navbar.'
        }
      }
    },
    activeCompanies: {
      header: 'Active Companies',
      subHeader: 'Companies',
      description:
        'This section covers how to search for companies, how to create, edit and delete a company.',
      sections: {
        search: {
          images: {
            img1: {
              src: 'company/search-company.png',
              caption: '2. Search for companies by typing in the search bar.'
            }
          }
        },
        create: {
          images: {
            img1: {
              src: 'company/company-form.png',
              caption: '3. Add or Edit Company form.'
            },
            img2: {
              src: 'company/add-company.png',
              caption: '4. Add company example.'
            },
            img3: {
              src: 'company/add-company-select-address.png',
              caption: '4. Add company example by selecting an address from the dropdown.'
            }
          }
        }
      }
    }
  }
}
