
// #region imports

// #region vue
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import AddEditCompany from '@/containers/companies/AddEditCompany.vue'
import DeleteCompany from '@/components/companies/DeleteCompany.vue'
// #endregion

// #region interfaces
import {
  CompanyInterface,
  CompanyTableItemInterface
} from '@/types/interfaces/company'
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import CompanyService from '@/services/company.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { Table, AddEditCompany, DeleteCompany, DefaultTableActions },
  computed: { ...mapState('app', ['isLoading']) }
})
export default class ActiveCompanies extends Vue {
  @Prop({ required: true }) readonly tab!: string

  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('table.col.domain'),
      value: 'domain'
    },
    {
      text: i18n.t('table.col.organization_number'),
      value: 'organization_number'
    },
    {
      text: i18n.t('table.col.owner'),
      value: 'owner.name'
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  companies: Array<CompanyInterface> = []
  pagination = {} as PaginationInterface
  showSearch = false

  async handleCompaniesFetch (): Promise<void> {
    const result = await CompanyService.getAll()
    this.setData(result)
  }

  async handleCompaniesSearchFetch (
    searchText: string,
    page = 1
  ): Promise<void> {
    const result = await CompanyService.filter(searchText, page)
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleCompaniesFetch()
  }

  dialog = false
  deleteDialog = false
  company: CompanyInterface | null = null

  searching = ''

  editItem (row: CompanyTableItemInterface): void {
    this.company = this.companies[row.index]
    this.toggleDialog()
  }

  viewItem (row: CompanyTableItemInterface): void {
    this.$router.push(`company/${row.item.id}`)
  }

  async deleteItem (): Promise<void> {
    const id = this.company !== null ? this.company.id : ''

    await CompanyService.delete(id)

    this.$toast.success(i18n.t('notify.success.company_releted').toString())

    await this.handleCompaniesFetch()

    this.deleteDialog = false
  }

  toggleDialog (): void {
    this.dialog = !this.dialog
  }

  initDeleteCompany (row: CompanyTableItemInterface): void {
    this.deleteDialog = true
    this.company = this.companies[row.index]
  }

  cancelDeleteDialog (): void {
    this.deleteDialog = false
    this.company = null
  }

  @Watch('dialog')
  onDialogChange (): void {
    if (!this.dialog) {
      this.company = null
    }
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'activeCompanies') {
      this.handleCompaniesFetch()
    }
  }

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleCompaniesFetch()
      return
    }

    this.searching = query
    this.handleCompaniesSearchFetch(query)
  }

  refetch (): void {
    this.handleCompaniesFetch()
  }

  async nextPage (): Promise<void> {
    if (this.searching) {
      await this.handleCompaniesSearchFetch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await CompanyService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (this.searching) {
      await this.handleCompaniesSearchFetch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await CompanyService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (this.searching) {
      await this.handleCompaniesSearchFetch(this.searching, number)
      return
    }

    const result = await CompanyService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.companies = result.companies.data
    delete result.companies.data
    this.pagination = result.companies
  }
}
