
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SurveyHeaderCard extends Vue {
  @Prop() readonly formValidity!: boolean
  @Prop() readonly previewDisabled!: boolean

  back (): void {
    this.$router.back()
  }
}
