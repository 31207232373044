
// #region imports

// #region vue
import { Vue, Prop, Component } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class OneLiner extends Vue {
  @Prop({ required: true, default: 'Title' }) readonly title!: string
  @Prop() readonly subtitle!: string
  @Prop({ default: ':' }) readonly separator!: string
}
