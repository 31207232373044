
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region interfaces
import {
  DailyDurationSettingInteface,
  BookingDurationSettingInterface,
  CompanySettingInterface
} from '@/types/interfaces/setting'
// #endregion

// #region data
import { camelCase } from 'lodash'
// #endregion

// #region validation
import rules from '@/utils/rules.utils'
// #endregion

// #endregion

@Component
export default class DailyBookingDurationSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly companySettings!: CompanySettingInterface

  rules = rules

  expandedDailyBookingDurationSettings = false

  data = {
    mondayDaily: null,
    tuesdayDaily: null,
    wednesdayDaily: null,
    thursdayDaily: null,
    fridayDaily: null,
    saturdayDaily: null,
    sundayDaily: null
  } as DailyDurationSettingInteface

  resetDailySettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): void {
    const settingsToUse = fetchedSettings || this.companySettings
    const setting = settingsToUse.booking_duration
    let settings = {} as DailyDurationSettingInteface

    if (setting) {
      const data = parseJson(setting) as BookingDurationSettingInterface
      if (data) {
        settings = { ...this.data }
        for (const item in data.daily) {
          const temp = camelCase(item)
          settings[temp] = data.daily[item]
        }
      }
    }

    this.data = settings
  }

  async fetchHourlyBookingDurationSettings (): Promise<void> {
    const fetchedSettings = await settingService.getCompanySettings(
      this.companyId
    )
    this.resetDailySettings(fetchedSettings)
  }

  async created (): Promise<void> {
    await this.fetchHourlyBookingDurationSettings()
  }

  toggleDailyBookingDurationSettings (): void {
    this.expandedDailyBookingDurationSettings =
      !this.expandedDailyBookingDurationSettings
  }

  async submitSettings (): Promise<void> {
    const dataToSubmit = {} as { [key: string]: number | null }
    let hasData = false

    for (const item in this.data) {
      if (this.data[item]) {
        hasData = true
        dataToSubmit[item] = this.data[item]
      }
    }

    if (hasData) {
      const settings = await settingService.updateDailyBookingDurations(
        dataToSubmit,
        this.companyId
      )
      this.resetDailySettings(settings)
    }

    this.expandedDailyBookingDurationSettings = false
  }
}
