
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region utils & helpers
import { cloneDeep } from 'lodash'
import { disabledFieldOnEdit } from '@/utils/helper.utils'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region services
import categoryService from '@/services/category.service'
import companyService from '@/services/company.service'
import departmentService from '@/services/department.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { TableModalForm, TableModalActions },
  methods: { disabledFieldOnEdit }
})
export default class AddEditCategory extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop() readonly item!: CategoryInterface
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  rules = rules
  loading = true

  data = {
    name: '',
    company_id: null,
    department_id: null
  } as unknown as CategoryInterface

  valid = false

  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>

  async fetchCompanies (): Promise<void> {
    const result = await companyService.getActiveWithoutPagination()
    this.companies = result
  }

  @Watch('data.company_id')
  async getDepartments (): Promise<void> {
    await this.fetchDepartments()
  }

  async fetchDepartments (): Promise<void> {
    const result = await departmentService.getAllCompanyDepartments(
      this.data.company_id as string
    )
    this.departments = result
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      if (!this.item) {
        await categoryService.create(this.data)
        this.onSubmit()
        this.dialogHandler()

        this.$toast.success(
          i18n.t('notify.success.category_created').toString()
        )
        return
      }

      await categoryService.update(this.data, this.item.id as string)
      this.onSubmit()
      this.dialogHandler()

      this.$toast.success(i18n.t('notify.success.category_updated').toString())
    }
  }

  async created (): Promise<void> {
    this.loading = true

    await this.fetchCompanies()

    if (this.item) {
      this.data = cloneDeep(this.item)
    }

    this.loading = false
  }
}
