
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region interfaces
import {
  WorkingHourSettingInterface,
  CompanySettingInterface
} from '@/types/interfaces/setting'
// #endregion

import { camelCase } from 'lodash'
import rules from '@/utils/rules.utils'
// #endregion

@Component
export default class WorkingHoursSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface
  rules = rules

  defaultIsValid = false
  inputsValidity = false

  data = {
    defaultStart: '',
    defaultEnd: '',
    mondayStart: '',
    tuesdayStart: '',
    wednesdayStart: '',
    thursdayStart: '',
    fridayStart: '',
    saturdayStart: '',
    sundayStart: '',
    mondayEnd: '',
    tuesdayEnd: '',
    wednesdayEnd: '',
    thursdayEnd: '',
    fridayEnd: '',
    saturdayEnd: '',
    sundayEnd: ''
  } as WorkingHourSettingInterface

  expandedWorkingHours = false

  saved = false

  async defaultWorkingHoursSubmit (): Promise<void> {
    const form = this.$refs.defaultInputsForm as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      await this.submitData()
      this.saved = true
    }
  }

  async workingHoursSettingsSubmit (): Promise<void> {
    const form = this.$refs.inputsForm as HTMLFormElement
    const valid = form.validate()
    if (valid) {
      await this.submitData()
    }
  }

  @Watch('data.defaultStart')
  onStartOnChange (): void {
    if (this.data.defaultStart) {
      this.expandedWorkingHours = false
    }
  }

  @Watch('data.defaultEnd')
  onEndOnChange (): void {
    if (this.data.defaultEnd) {
      this.expandedWorkingHours = false
    }
  }

  toggleWorkingHours (): void {
    if (!!this.data.defaultStart && !!this.data.defaultEnd) {
      this.expandedWorkingHours = !this.expandedWorkingHours
    }
  }

  async submitData (): Promise<void> {
    const dataToSubmit = {} as { [key: string]: string }
    let hasData = false

    for (const item in this.data) {
      if (this.data[item]) {
        hasData = true
        dataToSubmit[item] = this.data[item]
      }
    }

    if (hasData) {
      const settings = (await settingService.updateWorkingHours(
        dataToSubmit,
        this.companyId
      )) as CompanySettingInterface
      this.resetWorkingHoursSettings(settings)
    }

    this.expandedWorkingHours = false
  }

  resetWorkingHoursSettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): void {
    const settingsToUse = fetchedSettings || this.settings
    const setting = settingsToUse.working_hours
    let settings = {} as WorkingHourSettingInterface

    if (setting) {
      const data = parseJson(setting) as WorkingHourSettingInterface
      if (data) {
        settings = { ...this.data }
        for (const item in data) {
          const temp = camelCase(item)
          settings[temp] = data[item]
        }
      }
    }

    this.data = settings
  }

  async fetchWorkingHours (): Promise<void> {
    const fetchedSettings = await settingService.getCompanySettings(
      this.companyId
    )
    this.resetWorkingHoursSettings(fetchedSettings)
  }

  async created (): Promise<void> {
    await this.fetchWorkingHours()
    this.setDefaultWorkingHours()
  }

  @Watch('expandedWorkingHours')
  onExpandedHoursChange (): void {
    if (this.expandedWorkingHours) {
      this.fetchWorkingHours()
    }
  }

  setDefaultWorkingHours (): void {
    const setting = parseJson(
      this.settings.working_hours
    ) as WorkingHourSettingInterface
    if (setting) {
      this.data.defaultStart = setting.defaultStart
      this.data.defaultEnd = setting.defaultEnd
      this.saved = true
    }
  }
}
