
import { Component, Vue } from 'vue-property-decorator'
import AccountSettings from '@/views/settings/_AccountSettings.vue'
import ApplicationSettings from '@/views/settings/_ApplicationSettings.vue'
import NotificationSettings from '@/views/settings/_NotificationSettings.vue'
import store from '@/store'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({
  components: { AccountSettings, ApplicationSettings, NotificationSettings }
})
export default class Settings extends Vue {
  tab = 'account'

  items = [
    { key: 'account', label: i18n.t('settings.acc') },
    { key: 'application', label: i18n.t('settings.app') },
    { key: 'notifications', label: i18n.t('common.notifications') }
  ]

  user = store.getters['auth/getUserData']

  hasPermissions (key: string): boolean {
    if (
      this.user.role !== 'admin' &&
      this.user.department_id &&
      key !== 'account'
    ) {
      return true
    }

    return false
  }

  created (): void {
    document.title = getAppTitle('Settings')
  }
}
