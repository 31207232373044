
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #endregion

@Component
export default class BlockedDateDescription extends Vue {
  @Prop({ required: true }) readonly item!: string

  get clipped (): string {
    return this.item.length > 20
      ? `${this.item.substring(0, 20)}...`
      : this.item
  }
}
