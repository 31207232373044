import {
  DepartmentInterface,
  CreateOrUpdateDepartmentInterface
} from './../types/interfaces/department/index'
// #region imports

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

class DepartmentService extends BaseService {
  error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url = type.length === 0 ? '/departments' : '/departments/archived'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getAllPublic (): Promise<DepartmentInterface[]> {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/departments-public')

    Store.dispatch('app/loading', false)

    return result.data.departments
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/departments-search?page=${page}`

    const result = await this.axios(url, { params: { searchText } })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/department/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.department
  }

  async getSinglePublic (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/department-public/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.department
  }

  async getActiveWithoutPagination () {
    Store.dispatch('app/loading', true)

    const url = '/departments-no-pagination'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.departments
  }

  async getAllCompanyDepartments (companyId: string, type = 'all') {
    Store.dispatch('app/loading', true)

    const url = `/company-departments/${companyId}/${type}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.departments
  }

  async getDepartmentsByUserId () {
    Store.dispatch('app/loading', true)

    const url = '/user-departments'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.departments
  }

  async getAllCompanyDepartmentsPublic (companyId: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-departments-public/${companyId}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.departments
  }

  //  department types
  async getAllDepartmentTypes () {
    Store.dispatch('app/loading', true)

    const url = '/department-types-without-pagination'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.department_types
  }

  async create (data: CreateOrUpdateDepartmentInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/department', {
      method: 'POST',
      data: { data }
    })

    Store.dispatch('app/loading', false)

    return result.data.department
  }

  async update (
    id: string | undefined,
    data: CreateOrUpdateDepartmentInterface
  ) {
    Store.dispatch('app/loading', true)
    const rersult = await this.axios(`/department/${id}`, {
      method: 'PUT',
      data: { data }
    })

    Store.dispatch('app/loading', false)

    return rersult.data.department
  }

  async reactivate (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/department-reactivate/${id}`

    const result = await this.axios.patch(url)

    Store.dispatch('app/loading', false)

    return result.data.department
  }

  async delete (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.delete(`/department/${id}`)

    Store.dispatch('app/loading', false)

    return result
  }

  async getDepartmentsThatAllowCategories () {
    Store.dispatch('app/loading', true)

    const url = '/departments-with-categories'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.departments
  }
}

export default new DepartmentService()
