
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Information extends Vue {
  logout (): void {
    store.dispatch('auth/logout')
  }
}
