
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
import BookCategoryCard from '../bookings/BookCategoryCard.vue'
import DateFromTo from '@/components/bookings/DateFromTo.vue'
// #endregion

// #region interfaces
import { CreateBlockedDateInterface } from '@/types/interfaces/blocked-dates'
import {
  VCalendarAttributeInterface,
  DateRangeInterface,
  BlockedDatesSeriesInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region services
import _blockedDateService from '@/services/blocked-dates.service'
// #endregion

// #region data
import { clone, cloneDeep } from 'lodash'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region helpers & utils
import rules from '@/utils/rules.utils'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { getErrorOrWarningCode } from '@/utils/helper.utils'
import { IMAGE_SOURCES } from '@/common/constants'
// #endregion

// #region configs
import { configuration } from '@/config'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
// #endregion

// #endregion

@Component({
  components: {
    TableModalForm,
    TableModalActions,
    BookCategoryCard,
    DateFromTo
  },
  methods: {
    configuration
  }
})
export default class AddEditBlockedDate extends Vue {
  @Prop() readonly item!: CreateBlockedDateInterface
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly departmentId!: string

  data = {
    id: '',
    company_id: '',
    company_name: '',
    department_id: '',
    department_name: '',
    start_date: new Date(),
    end_date: new Date(),
    description_en: '',
    description_no: '',
    color: '',
    blocking_type: 'blocked'
  } as CreateBlockedDateInterface

  dateRange = {
    start: startOfDay(new Date()),
    end: endOfDay(new Date())
  }

  colors = [
    { label: i18n.t('colors.red'), value: 'red' },
    { label: i18n.t('colors.blue'), value: 'blue' },
    { label: i18n.t('colors.green'), value: 'green' },
    { label: i18n.t('colors.yellow'), value: 'yellow' },
    { label: i18n.t('colors.orange'), value: 'orange' },
    { label: i18n.t('colors.purple'), value: 'purple' },
    { label: i18n.t('colors.pink'), value: 'pink' },
    { label: i18n.t('colors.gray'), value: 'gray' }
  ]

  blockedDatesAttributes = [] as VCalendarAttributeInterface[]
  disabledDates = [] as DateRangeInterface[]

  @Watch('dateRange.start')
  onDateRangeStartChange () {
    this.data.start_date = startOfDay(this.dateRange.start)
  }

  @Watch('dateRange.end')
  onDateRangeEndChange () {
    this.data.end_date = endOfDay(this.dateRange.end)
  }

  async created (): Promise<void> {
    if (this.item) {
      this.data = cloneDeep(this.item)
      await this.fetchBlockedDates(this.data.id) // <- disclude itself from queyring
      this.dateRange = {
        start: this.item.start_date as Date,
        end: this.item.end_date as Date
      }

      this.state = 'EDIT'

      return
    }

    await this.fetchBlockedDates() // <- when we have to create a new one
    this.ensureDefaultDateNotBlocked()
    this.data.company_id = this.companyId
    this.data.department_id = this.departmentId
    this.state = 'ADD'
  }

  resetDates (): void {
    this.dateRange = {
      start: startOfDay(new Date()),
      end: endOfDay(new Date())
    }
  }

  ensureDefaultDateNotBlocked (): void {
    if (this.disabledDates.length === 0) {
      this.dateRange = this.regulateDates(
        this.dateRange.start,
        this.dateRange.end
      )
      return
    }

    const disableDates = clone(this.disabledDates)

    disableDates.forEach((item) => {
      const tmpStart = dummyDateConverter(item.start)
      const tmpEnd = dummyDateConverter(item.end)
      // CHECKING THE FOLLOWING
      /*
        1. tmpStart-----start--------------------------------------tmpEnd-------end
        2. start-----tmpStart---------------------------end---------------------tmpEnd
        3. start-----tmpStart---------------------------tmpEnd------------------end
        4. tmpStart---------------------------start------end--------------------tmpEnd
      */
      if (
        (this.dateRange.start >= tmpStart && this.dateRange.start <= tmpEnd) ||
        (this.dateRange.end >= tmpStart && this.dateRange.end <= tmpEnd) ||
        (this.dateRange.start <= tmpStart && this.dateRange.end >= tmpEnd) ||
        (this.dateRange.start >= tmpStart && this.dateRange.end <= tmpEnd)
      ) {
        this.dateRange.start = startOfDay(addDays(this.dateRange.start, 1))
        this.dateRange.end = endOfDay(addDays(this.dateRange.end, 1))
        this.ensureDefaultDateNotBlocked() // <-- recursively calling and adding one day until we find a date that is not blocked
      } else {
        this.dateRange = this.regulateDates(
          this.dateRange.start,
          this.dateRange.end
        )
      }
    })
  }

  regulateDates (startDate: string | Date, endDate: string | Date) {
    if (!startDate || !endDate) {
      return { start: new Date(), end: new Date() }
    }

    return {
      start:
        typeof startDate === 'string'
          ? startOfDay(dummyDateConverter(startDate))
          : startOfDay(startDate),
      end:
        typeof endDate === 'string'
          ? endOfDay(dummyDateConverter(endDate))
          : endOfDay(endDate)
    }
  }

  // #region data
  rules = rules
  loading = false
  user = store.getters['auth/getUserData']
  state = 'ADD'
  IMAGE_SOURCES = IMAGE_SOURCES
  // #endregion

  @Watch('item')
  onItemChange (): void {
    if (this.item) this.state = 'EDIT'
    else this.state = 'ADD'
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement & {
      validate: () => boolean
    }
    const valid = form.validate()

    if (!valid) return

    if (this.state === 'ADD') {
      const payload = clone(this.data)

      payload.start_date = clone(this.dateRange.start.toISOString())
      payload.end_date = clone(this.dateRange.end.toISOString())

      const createResult = await _blockedDateService.create(payload)
      this.$toast.success(
        i18n.t('notify.success.blocked_date_created').toString()
      )

      if (createResult.result.warning !== false) {
        const warningMsgCode = getErrorOrWarningCode(
          createResult.result.warning
        )
        this.$toast.warning(i18n.t(`W-WARNING.${warningMsgCode}`).toString())
      }

      this.onSubmit()
      this.dialogHandler()
      return
    }

    if (this.state === 'EDIT') {
      const payload = clone(this.data)

      payload.start_date = clone(this.dateRange.start.toISOString())
      payload.end_date = clone(this.dateRange.end.toISOString())

      const updateResult = await _blockedDateService.updateBlockedDate(payload)
      this.$toast.success(
        i18n.t('notify.success.blocked_date_updated').toString()
      )

      if (updateResult.result.warning !== false) {
        const warningMsgCode = getErrorOrWarningCode(
          updateResult.result.warning
        )
        this.$toast.warning(i18n.t(`W-WARNING.${warningMsgCode}`).toString())
      }

      this.onSubmit()
      this.dialogHandler()
    }
  }

  async fetchBlockedDates (blockedDateId: string | null = null): Promise<void> {
    const result = (await getBlockedDatesAsync(
      this.companyId,
      this.departmentId,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.blockedDatesAttributes = [
      ...this.blockedDatesAttributes,
      ...result.blockedDates
    ]

    this.disabledDates = [...this.disabledDates, ...result.disabledDates]
  }

  get currentLanguage (): string {
    return this.$i18n.locale
  }
}
