
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DeleteDialog extends Vue {
  @Prop({ required: true }) readonly dialog!: boolean
  @Prop({ required: true }) readonly entityTitle!: string

  @Prop() readonly confirmDeleteDialog!: () => void
  @Prop() readonly cancelDeleteDialog!: () => void
}
