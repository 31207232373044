import Owner from '@/views/user/Owner.vue'

const userRoutes = [
  {
    path: '/owner/:id',
    name: 'owner',
    component: Owner,
    meta: {
      auth: true,
      role: ['admin']
    }
  }
]

export default userRoutes
