
// vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// enums
import { ReservationTypes } from '@/types/enums/booking'

// interfaces
import { ChoiceCardPropogateClickInterface } from '@/types/interfaces/booking'

@Component
export default class ChoiceCard extends Vue {
  @Prop() readonly title!: string
  @Prop() readonly icon!: string
  @Prop() readonly reservationType!: ReservationTypes

  propogateClick (): void {
    const propogationTransferObject = {
      title: this.title.toLowerCase(),
      clicked: true,
      reservationType: this.reservationType
    } as ChoiceCardPropogateClickInterface

    this.$emit('CardClickedEvent', propogationTransferObject)
  }
}
