import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages: LocaleMessages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const getLanguage = (): string => {
  let language = localStorage.getItem('language')

  if (!language) {
    language = process.env.VUE_APP_LANGUAGE || 'en-us'
  }

  return language as string
}

export default new VueI18n({
  locale: getLanguage(),
  fallbackLocale: process.env.VUE_APP_FALLBACK_LANGUAGE || 'en-us',
  messages: loadLocaleMessages()
})
