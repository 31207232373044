
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import MoreThanOneRentingEntity from '@/components/renting-entities/MoreThanOneRentingEntity.vue'
import BookingsDate from '@/components/bookings/BookingsDate.vue'
import BookingClientName from '@/components/bookings/BookingClientName.vue'
import MoreThanOneTag from '@/components/bookings/MoreThanOneTag.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface,
  FilterByDateInterface
} from '@/types/interfaces/setting'
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
import {
  BookingInterface,
  BookingFilterBundle,
  BookingSearchBundle,
  BookingTableItemInterface
} from '@/types/interfaces/booking'
// #endregion

// #region services
import BookingService from '@/services/booking.service'
import DepartmentService from '@/services/department.service'
import CompanyService from '@/services/company.service'
import RentingEntityService from '@/services/renting-entity.service'
// #endregion

// #region helpers & utils
import { dummyDateConverter } from '@/utils/date.utils'
import { startOfDay, endOfDay } from 'date-fns'
// #endregion

// #region locales
import i18n from '@/i18n'
import { configuration } from '@/config'
// #endregion

// #endregion

@Component({
  components: { Table },
  methods: {
    configuration
  }
})
export default class AllBookings extends Vue {
  headers = [
    {
      text: i18n.t('table.col.rented'),
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'renting_entities',
      render: MoreThanOneRentingEntity
    },
    {
      text: i18n.t('table.col.client'),
      value: 'custom_render',
      render: BookingClientName
    },
    {
      text: i18n.t('table.col.department'),
      value: 'department.name'
    },
    {
      text: i18n.t('table.col.company'),
      value: 'department.company.name'
    },
    {
      text: i18n.t('table.col.interactors'),
      value: 'number_of_interactors',
      key: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    },
    {
      text: 'TAGS',
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'properties',
      render: MoreThanOneTag
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  bookings: Array<BookingInterface> = []
  pagination = {} as PaginationInterface
  searchable = true

  filterBundle = {
    clean_state: true,
    start_date: '',
    end_date: '',
    disabled_filter_button: true
  } as FilterByDateInterface

  departments = [] as Array<DepartmentInterface>
  selectedDepartments = []

  companies = [] as Array<CompanyInterface>
  selectedCompanies = []

  rentingEntities = [] as Array<RentingEntityInterface>
  selectedRentingEntities = []

  searching = ''

  _config () {
    return configuration()
  }

  async handleBookingsFetch (): Promise<void> {
    const result = await BookingService.getAll('all')
    this.setData(result)
  }

  async handleBookingsSearch (searchText: string, page = 1): Promise<void> {
    const requestPayload = {
      search: {
        text: searchText,
        page
      }
    } as BookingSearchBundle
    const result = await BookingService.search(requestPayload, 'all')
    this.setData(result)
  }

  async handleFetchFilters (): Promise<void> {
    await this.fetchDepartments()
    await this.fetchCompanies()
    await this.fetchRentingEntities()
  }

  async handleFilter (page = 1): Promise<void> {
    const filterBundleRequest = {
      filters: {
        start_date: dummyDateConverter(this.filterBundle.start_date as Date),
        end_date: dummyDateConverter(this.filterBundle.end_date as Date),
        renting_entities: this.selectedRentingEntities,
        departments: this.selectedDepartments,
        companies: this.selectedCompanies,
        page
      }
    } as BookingFilterBundle

    if (
      this.filterBundle.start_date !== undefined &&
      this.filterBundle.end_date !== undefined &&
      this.filterBundle.start_date <= this.filterBundle.end_date
    ) {
      const result = await BookingService.filter(filterBundleRequest, 'all')
      this.setData(result)
      this.filterBundle.clean_state = false
    } else {
      this.$toast.error(
        i18n.t('error.start_date_greater_than_end_date').toString()
      )
    }
  }

  async clearFilter (): Promise<void> {
    await this.handleBookingsFetch()
    this.filterBundle.clean_state = true
    this.filterBundle.start_date = ''
    this.filterBundle.end_date = ''
    this.selectedRentingEntities = []
    this.selectedDepartments = []
    this.selectedCompanies = []

    this.setupDefaultDateFilters()
  }

  async created (): Promise<void> {
    await this.handleBookingsFetch()
    await this.handleFetchFilters()
    this.setupDefaultDateFilters()
  }

  async searchHanlder (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleBookingsFetch()
      return
    }

    this.searching = query
    await this.handleBookingsSearch(query)
  }

  async fetchDepartments (): Promise<void> {
    const result = await DepartmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async fetchCompanies (): Promise<void> {
    const result = await CompanyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchRentingEntities (): Promise<void> {
    const result = await RentingEntityService.getAllUnpaginated()
    this.rentingEntities = result.entities
  }

  @Watch('filterBundle.start_date')
  @Watch('filterBundle.end_date')
  @Watch('selectedRentingEntities')
  @Watch('selectedDepartments')
  @Watch('selectedCompanies')
  toggleBundle (): void {
    const otherFiltersEmpty = this.checkFilterArrayLengths()
    const dateFiltresEmpty = this.checkFilterDateLengths()
    if (otherFiltersEmpty && dateFiltresEmpty) {
      this.filterBundle.disabled_filter_button = true
      this.searchable = true

      if (this.filterBundle.clean_state === false) {
        this.filterBundle.clean_state = true
        this.handleBookingsFetch()
        this.handleFetchFilters()
      }

      return
    }

    this.filterBundle.disabled_filter_button = false
    this.searchable = false
  }

  viewItem (row: BookingTableItemInterface): void {
    this.$router.push(`/booking/${row.item.id}`)
  }

  checkFilterArrayLengths (): boolean {
    return (
      this.selectedRentingEntities.length < 1 &&
      this.selectedDepartments.length < 1 &&
      this.selectedCompanies.length < 1
    )
  }

  checkFilterDateLengths (): boolean {
    return (
      this.filterBundle.start_date === '' || this.filterBundle.end_date === ''
    )
  }

  async nextPage (): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(this.pagination.current_page + 1)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await BookingService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(this.pagination.current_page - 1)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await BookingService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (!this.filterBundle.clean_state) {
      await this.handleFilter(number)
      return
    }

    if (this.searching) {
      await this.handleBookingsSearch(this.searching, number)
      return
    }

    const result = await BookingService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.bookings = result.bookings.data
    delete result.bookings.data
    this.pagination = result.bookings
  }

  setupDefaultDateFilters (): void {
    const _today = new Date()
    const _startOfDay = startOfDay(_today)
    const _endOfDay = endOfDay(_today)

    this.filterBundle.start_date = _startOfDay
    this.filterBundle.end_date = _endOfDay
  }
}
