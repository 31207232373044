
import { Component, Vue } from 'vue-property-decorator'
import HourlyCalendarView from '@/views/calendar/HourlyCalendarView.vue'
import DailyCalendarView from '@/views/calendar/DailyCalendarView.vue'
import i18n from '@/i18n'
import { getAppTitle } from '@/utils/helper.utils'

@Component({ components: { HourlyCalendarView, DailyCalendarView } })
export default class CalendarView extends Vue {
  tab = 'hourly'

  items = [{ key: 'hourly', label: i18n.t('page.calendar.hourly') }]

  show (tab: string): boolean {
    return this.tab === tab
  }

  created (): void {
    document.title = getAppTitle('Calendar')
  }
}
