// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
import { CreateBlockedDateInterface } from '@/types/interfaces/blocked-dates'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region utils & helpers
// import { format } from 'date-fns'
// #endregion

// #endregion

class BlockedDatesService extends BaseService {
  public error?: ApiCallErrorInterface

  async create (data: CreateBlockedDateInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.post('/blocked-dates', { data })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getAllPaginated (companyId: string, departmentId: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.get(
      `/blocked-dates/${companyId}/${departmentId}`
    )

    Store.dispatch('app/loading', false)

    return result.data
  }

  async deleteBlockedDate (blockedDateId: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.delete(`/blocked-dates/${blockedDateId}`)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async updateBlockedDate (data: CreateBlockedDateInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.put(`/blocked-dates/${data.id}`, { data })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getBlockedDatesSeries (
    companyId: string,
    departmentId: string,
    blockedDateId: string | null = null
  ) {
    Store.dispatch('app/loading', true)

    const URL = '/blocked-dates-series'
    const result = await this.axios.get(URL, {
      params: {
        companyId,
        departmentId,
        blockedDateId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getBlockedDatesSeriesByCompany (companyId: string) {
    Store.dispatch('app/loading', true)

    const URL = '/blocked-dates-series-by-company'
    const result = await this.axios.get(URL, {
      params: {
        companyId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getBlockedDatesSeriesByDepartments (departments: string[]) {
    Store.dispatch('app/loading', true)

    const URL = '/blocked-dates-series-by-departments'

    const result = await this.axios.post(URL, {
      data: {
        departments
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async isDateRangeUnavailableForDepartment (
    startDate: string,
    endDate: string,
    departmentId: string
  ) {
    Store.dispatch('app/loading', true)

    const URL = '/is-date-range-unavailable-for-department'

    const result = await this.axios.get(URL, {
      params: {
        startDate,
        endDate,
        departmentId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async isDateUnavailableForDepartment (date: string, departmentId: string) {
    Store.dispatch('app/loading', true)

    const URL = '/is-date-unavailable-for-department'

    const result = await this.axios.get(URL, {
      params: {
        date,
        departmentId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data
  }
}

export default new BlockedDatesService()
