
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PageHeader extends Vue {
  @Prop({ required: false, default: false }) readonly clickable!: boolean
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly subtitle!: string
  @Prop({ required: false }) readonly grandparent!: string
  @Prop({ required: false }) readonly parent!: string
  @Prop({ required: false, default: 'chip' }) readonly type!: boolean
  @Prop({ default: 'my-2' }) readonly className!: string
  @Prop({ default: false }) readonly cannotGoBack!: boolean

  back (): void {
    this.$router.back()
  }
}
