
// #region imports

// Vue
import { Component, Prop, Vue } from 'vue-property-decorator'

// Locales
import i18n from '@/i18n'

// #endregion
@Component
export default class ScheduleSystem extends Vue {
  @Prop({ required: true }) readonly item!: string

  get colorScheme () {
    return this.item === 'HOURLY' ? 'blue darken-1' : 'orange darken-1'
  }

  get scheduleSystem () {
    return this.item === 'HOURLY' ? i18n.t('common.hourly') : i18n.t('common.daily')
  }
}
