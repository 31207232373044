
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region utils & helpers
import { differenceInMinutes } from 'date-fns'
import { bookingTimeDuration, defaultIncoming } from '@/utils/date.utils'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import Tags from '@/components/bookings/Tags.vue'
// #endregion

// #region interfaces
import {
  BookingPropertyInterface,
  BookingInterface
} from '@/types/interfaces/booking'
// #endregion

// #region services
import bookingRequestService from '@/services/booking-request.service'
// #endregion

// #region constants
const DATE_FORMAT = 'd - MMMM - yyyy H:mm'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewItems,
    PageOverviewItem,
    Tags
  }
})
export default class BookingRequest extends Vue {
  booking = {} as BookingInterface

  async handleFetchBooking (): Promise<void> {
    const result = await bookingRequestService.getSingle(this.$route.params.id)

    if (result.properties) {
      const notes = result.properties.find((item: BookingPropertyInterface) => {
        return item.key.toLowerCase() === 'notes'
      })

      const tags = result.properties.find((item: BookingPropertyInterface) => {
        return item.key.toLowerCase() === 'tags'
      })

      if (notes) {
        result.note = notes
      }

      if (tags) {
        result.tags = tags
      }
    }

    this.booking = result
  }

  async created (): Promise<void> {
    await this.handleFetchBooking()
  }

  viewBooking (id: string): void {
    this.$router.push(`/booking/${id}`)
  }

  get dateFrom (): string {
    return defaultIncoming(this.booking.date_from, DATE_FORMAT)
  }

  get dateTo (): string {
    return defaultIncoming(this.booking.date_to, DATE_FORMAT)
  }

  get dateDiff (): number {
    return differenceInMinutes(
      new Date(this.booking.date_to),
      new Date(this.booking.date_from)
    )
  }

  get formattedBookingSpan (): string {
    const minutes = this.dateDiff

    const formatted = bookingTimeDuration(minutes)

    return formatted
  }
}
