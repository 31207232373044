
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'
// #endregion

// #region components
import TableModalForm from '@/common/components/ui/table/TableModalForm.vue'
import TableModalActions from '@/common/components/ui/table/TableModalActions.vue'
// #endregion

// #region interfaces
import { AddressInterface } from '@/types/interfaces/address'
import { CompanyInterface } from '@/types/interfaces/company'
import {
  CreateOrUpdateDepartmentInterface,
  DepartmentTypeInterface
} from '@/types/interfaces/department'
// #endregion

import rules from '@/utils/rules.utils'
import CompanyService from '@/services/company.service'
import { cloneDeep } from 'lodash'
import DepartmentService from '@/services/department.service'
import { disabledFieldOnEdit } from '@/utils/helper.utils'
import i18n from '@/i18n'
// #endregion

@Component({
  components: { TableModalForm, TableModalActions },
  methods: { disabledFieldOnEdit }
})
export default class AddEditDepartment extends Vue {
  @Prop() readonly disabledOnEdit!: Array<string>
  @Prop() readonly item!: CreateOrUpdateDepartmentInterface
  @Prop({ required: true }) readonly dialogHandler!: () => void
  @Prop({ required: true }) readonly onSubmit!: () => void

  user = store.getters['auth/getUserData']

  rules = rules
  valid = false
  loading = true
  companyName = ''
  addressMode = 'insert'
  checkinAndCheckoutParity = false

  scheduleSystem = [
    { label: i18n.t('common.hourly'), value: 'HOURLY', hint: '' },
    {
      label: i18n.t('common.daily'),
      value: 'DAILY',
      hint: i18n.t('component.add_edit_department.hint.min_booking_time')
    }
  ]

  activitySystem = [
    { label: i18n.t('common.booking'), value: 'BOOKING', hint: '' },
    {
      label: i18n.t('common.requests_only'),
      value: 'REQUESTS_ONLY',
      hint: i18n.t('component.add_edit_department.hint.activity_system')
    }
  ]

  addresses = [] as Array<AddressInterface>
  companies = [] as Array<CompanyInterface>
  departmentTypes = [] as Array<DepartmentTypeInterface>

  data = {
    id: '',
    name: '',
    description: '',
    organization_number: '',
    company_id: '',
    department_type: 'Restaurant',
    schedule_system: 'HOURLY',
    activity_system: 'BOOKING',
    checkin: { value: '12:00' },
    checkout: { value: '10:00' },
    address_id: '',
    allow_categories: false,
    address: {
      address: '',
      city: '',
      zip: ''
    }
  } as CreateOrUpdateDepartmentInterface

  get state (): string {
    return this.item ? 'edit' : 'add'
  }

  async fetchCompanies (): Promise<void> {
    const result = await CompanyService.getActiveWithoutPagination()
    this.companies = result
  }

  async fetchDepartmentTypes (): Promise<void> {
    const result = await DepartmentService.getAllDepartmentTypes()
    this.departmentTypes = result
  }

  async fetchAddress (): Promise<void> {
    const addresses = await CompanyService.getAddresses()
    this.addresses = addresses
  }

  async created (): Promise<void> {
    this.loading = true

    await this.fetchAddress()
    await this.fetchCompanies()
    await this.fetchDepartmentTypes()

    if (this.item) {
      this.data = cloneDeep(this.item)
    }

    if (this.user.company_id) {
      this.data.company_id = this.user.company_id
    }

    if (!this.data.checkin) {
      this.data.checkin = { value: '' }
    }

    if (!this.data.checkout) {
      this.data.checkout = { value: '' }
    }

    this.loading = false
  }

  getAddress (data: AddressInterface): string {
    return `${data.address}, ${data.city}, ${data.zip}`
  }

  formatDepartmentType (name: string): string {
    return i18n
      .t(`department_types.${name.toLowerCase()}`)
      .toString()
      .toUpperCase()
  }

  async submit (): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    if (valid) {
      const department = { ...this.data }

      if (this.addressMode === 'select') {
        delete department.address
      } else {
        delete department.address_id
      }

      if (!this.item) {
        await DepartmentService.create(department)
        this.$toast.success(
          i18n.t('notify.success.departament_updated').toString()
        )
        this.onSubmit()
        this.dialogHandler()

        return
      }

      await DepartmentService.update(department.id, department)
      this.$toast.success(
        i18n.t('notify.success.departament_updated').toString()
      )
      this.onSubmit()
      this.dialogHandler()
    }
  }

  // #region Watchers

  @Watch('data.checkin.value')
  @Watch('data.checkout.value')
  controllCheckinAndCheckoutParity (): void {
    const checkin = this.data.checkin?.value
    const checkout = this.data.checkout?.value

    if (checkin && checkout) {
      if (checkin === checkout) {
        this.checkinAndCheckoutParity = true
      } else {
        this.checkinAndCheckoutParity = false
      }
    }
  }

  // #endregion
}
