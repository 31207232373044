
import i18n from '@/i18n'
import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({ computed: { ...mapState('app', ['pageError']) } })
export default class ErrorPage extends Vue {
  header = i18n.t('error.how_here')
  message = i18n.t('error.http_errors.404')

  goHome (): void {
    this.$router.push('/')
  }
}
