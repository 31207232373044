var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",staticClass:"tw-flex tw-flex-col justify-center tw-mx-4 tw-my-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.overallBookingInformation && !_vm.currentUser)?_c('v-card',{class:`tw-mb-5 ${
      _vm.canIApplyVisualSettings ? 'gFontColor--text gCardBackgroundColor' : ''
    }`},[_c('v-card-title',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : ''},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('component.overall_booking_information.card_title') .toString() .toUpperCase())+":")])]),_c('v-card-text',{class:_vm.canIApplyVisualSettings ? 'gFontColor--text' : ''},[_vm._v(" "+_vm._s(_vm.overallBookingInformation)+" ")])],1):_vm._e(),_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":_vm.$t('input.label.number_of_guests'),"type":"number","outlined":"","dense":"","min":"1","required":"","color":_vm.canIApplyVisualSettings
        ? 'gGeneralElementsBackgroundColor gFontColor--text'
        : ''},model:{value:(_vm.data.number_of_interactors),callback:function ($$v) {_vm.$set(_vm.data, "number_of_interactors", $$v)},expression:"data.number_of_interactors"}}),_c('div',[_c('vc-date-picker',{staticClass:"mx-auto",attrs:{"is-expanded":"","is-inline":"","is-range":"","title-position":"left","rules":[_vm.rules.required],"disabled-dates":_vm.dailyBlockedDates.disabledDates,"attributes":_vm.dailyBlockedDates.blockedDates,"min-date":new Date(),"columns":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 1,"rows":_vm.$vuetify.breakpoint.mdAndUp ? 1 : 2,"locale":_vm.locale.code,"color":_vm.canIApplyVisualSettings ? _vm.visualSettings.calendar.color : 'blue',"is-dark":_vm.canIApplyVisualSettings ? _vm.visualSettings.calendar.dark_mode : false},model:{value:(_vm.dailyBookingData),callback:function ($$v) {_vm.dailyBookingData=$$v},expression:"dailyBookingData"}}),_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-2 tw-items-center tw-justify-center tw-my-4"},[_c('v-text-field',{staticClass:"tw-px-2",attrs:{"placeholder":"8:00","dense":"","label":_vm.$t('component.book.expected_arrival'),"rules":[
          _vm.rules.required,
          _vm.rules.hour2,
          (value) => _vm.rules.minTime(value, _vm.checkinTime)
        ],"outlined":"","prepend-inner-icon":_vm.$vuetify.breakpoint.mdAndUp
            ? 'mdi-account-arrow-down'
            : 'mdi-clock-outline',"append-icon":_vm.$vuetify.breakpoint.mdAndUp
            ? 'mdi-clock-outline'
            : 'mdi-account-arrow-down',"color":_vm.canIApplyVisualSettings
            ? 'gGeneralElementsBackgroundColor gFontColor--text'
            : ''},model:{value:(_vm.data.expected_arrival),callback:function ($$v) {_vm.$set(_vm.data, "expected_arrival", $$v)},expression:"data.expected_arrival"}}),_c('v-text-field',{staticClass:"tw-px-2",attrs:{"placeholder":"20:00","dense":"","label":_vm.$t('component.book.expected_departure'),"rules":[
          _vm.rules.required,
          _vm.rules.hour2,
          (value) => _vm.rules.maxTime(value, _vm.checkoutTime)
        ],"outlined":"","prepend-inner-icon":_vm.$vuetify.breakpoint.mdAndUp
            ? 'mdi-account-arrow-up'
            : 'mdi-clock-outline',"append-icon":_vm.$vuetify.breakpoint.mdAndUp
            ? 'mdi-clock-outline'
            : 'mdi-account-arrow-up',"reverse":_vm.$vuetify.breakpoint.mdAndUp,"color":_vm.canIApplyVisualSettings
            ? 'gGeneralElementsBackgroundColor gFontColor--text'
            : '',"name":"check-out"},model:{value:(_vm.data.expected_departure),callback:function ($$v) {_vm.$set(_vm.data, "expected_departure", $$v)},expression:"data.expected_departure"}})],1),_c('div',{staticClass:"pa-3 tw-flex tw-flex-col tw-text-center"},[(_vm.dailyBookingData.end)?_c('DateInfo',{attrs:{"checkinTitle":_vm.$t('common.check_in'),"checkinDate":_vm.format(_vm.dailyBookingData.start, 'PPP', { locale: _vm.locale }),"checkoutTitle":_vm.$t('common.check_out'),"checkoutDate":_vm.format(_vm.dailyBookingData.end, 'PPP', { locale: _vm.locale }),"totalDays":_vm.formattedTotalDays,"chip":true,"activeVisualSettings":_vm.visualSettings.active}}):_vm._e()],1)],1),_vm._t("default"),_c('v-btn',{staticClass:"tw-my-2 tw-ml-auto",attrs:{"color":`${
      _vm.canIApplyVisualSettings
        ? 'gButtonBackgroundColor gButtonFontColor--text'
        : 'primary'
    }`,"disabled":!_vm.selectedDate},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.nextButtonText)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }