
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TableModal extends Vue {
  @Prop({ required: true }) readonly dialog!: boolean
  @Prop({ required: true, default: 'ADD' }) readonly state!: string
  @Prop({ required: true }) readonly name!: string
  @Prop({ default: true }) readonly canCreateItem!: string
  @Prop({ default: false }) readonly hideDefault!: string
  @Prop({ default: '500px' }) readonly maxWidth!: string

  get colorBasedOnTheme (): string {
    const theme = this.$store.state.app.globalTheme
    const className = theme === 'dark' ? ' black--text' : ''
    return className
  }
}
