
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { WizardInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component
export default class WizardOverview extends Vue {
  @Prop() readonly data!: WizardInterface
}
