// #region imports

// #region interfaces
import { SurveyPresentInterface } from '@/types/interfaces/survey'

import { ApiCallErrorInterface } from '@/types/interfaces/setting'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

const { baseUrl } = configs

class SurveyService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url =
      type.length === 0 ? `${baseUrl}/surveys` : `${baseUrl}/surveys/archived`
    const result = await this.axios(url)

    Store.dispatch('app/loading', false)
    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/survey/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.survey
  }

  // eslint-disable-next-line
  async create(data: any) {
    Store.dispatch('app/loading', true)

    const url = 'survey'

    const result = await this.axios.post(url, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async getSurveyPresentQuestions (token: string) {
    Store.dispatch('app/loading', true)

    const url = `/survey-token-with-questions/${token}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.survey_token
  }

  async createSurveyFeedback (data: SurveyPresentInterface) {
    Store.dispatch('app/loading', true)

    const url = '/create-survey-feedback'

    const result = await this.axios.post(url, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async deactivateSurvey (id: number) {
    Store.dispatch('app/loading', true)

    const url = `/survey/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', true)

    return result
  }
}

export default new SurveyService()
