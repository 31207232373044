import Surveys from '@/views/surveys/Surveys.vue'
import Survey from '@/views/surveys/Survey.vue'
import CreateSurvey from '@/views/surveys/CreateSurvey.vue'
import SurveyPresent from '@/views/surveys/SurveyPresent.vue'

const surveyRoutes = [
  {
    path: '/surveys',
    name: 'surveys',
    component: Surveys,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/survey/:id',
    name: 'survey',
    component: Survey,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/survey-create',
    name: 'survey-create',
    component: CreateSurvey,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/:domain/survey-present/:token',
    name: 'survey-present',
    component: SurveyPresent,
    meta: {
      auth: false
    }
  }
]

export default surveyRoutes
