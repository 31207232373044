
// #region imports

// #region vue
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { CalendarEvent } from 'vuetify'
import Store from '@/store'
// #endregion

// #region fullcalendar
import FullCalendar, {
  EventClickArg,
  EventDropArg,
  DateRange
} from '@fullcalendar/vue'
import resourcePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg
} from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
// #endregion

// #region utils & helpers
import 'moment-timezone'
import moment from 'moment'
import Chroma from 'chroma-js'
import { compareDatesWithNow, parseJson } from '@/utils/helper.utils'
import { dummyDateConverter } from '@/utils/date.utils'
import { format, subMinutes } from 'date-fns'
// #endregion

// #region components
import AdminBookingManagement from '@/views/bookings/AdminBookingManagement.vue'
import AdminBookingManagementAuto from '@/views/bookings/AdminBookingManagementAuto.vue'
import UpcommingBookings from '@/containers/bookings/UpcommingBookings.vue'
import ReserveBookingHourly from '@/containers/bookings/hourly/ReserveBookingHourly.vue'
import UberDialogHourly from '@/containers/bookings/hourly/UberDialogHourly.vue'
import ManualBookingHourly from '@/containers/bookings/hourly/ManualBookingHourly.vue'
// #endregion

// #region interfaces
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  RentingEntityExtendedDescriptionInterface,
  RentingEntityInterface
} from '@/types/interfaces/renting-entity'
import {
  AutoBookingTransferDataInterface,
  ChoiceCardPropogateClickInterface
} from '@/types/interfaces/booking'
import {
  ColorInterface,
  UpdateEventDragOrResize,
  BookingEventInterface
} from '@/types/interfaces/calendar'
// #endregion

// #region services
import _bookingService from '@/services/booking.service'
import _departmentService from '@/services/department.service'
import _blockedDateService from '@/services/blocked-dates.service'
// #endregion

// #region locales
import i18n from '@/i18n'
import { nb, enUS } from 'date-fns/locale'
import { configuration } from '@/config'
import { CreateBlockedDateInterface } from '@/types/interfaces/blocked-dates'
import { ReservationTypes } from '@/types/enums/booking'
// #endregion

// #region constants
const DATE_FORMAT = 'MMMM d, y'
// #endregion

// #endregion

@Component({
  components: {
    FullCalendar,
    AdminBookingManagement,
    AdminBookingManagementAuto,
    UpcommingBookings,
    ReserveBookingHourly,
    UberDialogHourly,
    ManualBookingHourly
  }
})
export default class HourlyCalendaricView extends Vue {
  @Prop({ default: false, required: true }) readonly show!: boolean
  showFilters = false
  calendarDialog = false
  departments = [] as DepartmentInterface[]
  selectedDepartments = []
  activityFilter = 'All Renting Entities'
  viewAll = true
  dialog = false
  autoDialog = false
  reserveDialog = false
  manualDialog = false
  rentingEntityId = ''
  dateFrom = ''
  state = 'edit'
  selectedDate = dummyDateConverter(new Date())
  selectedIndex: string | null = null
  autoBookingDataTransfer = {} as AutoBookingTransferDataInterface
  scrollTime = null
  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb
  userRole = ''
  blockedDates: Array<CreateBlockedDateInterface> = []
  initialRender = true

  colors = {
    default_color: '#2770b1'
  } as ColorInterface

  // defaultColors = ['#371B58', '#4C3575', '#5B4B8A'] as Array<string> //purple shades
  // defaultColors = ['#701A75', '#86198F', '#A21CAF', '#C026D3'] as Array<string>
  defaultColors = ['#312E81', '#3730A3', '#4338CA', '#4F46E5'] as Array<string>

  colorIndex = 0
  fetchedData = false
  clearFilterButtonColor = ''
  cleanState = true
  uberDialog = false

  reservationChoiceMadeEventListener (
    data: ChoiceCardPropogateClickInterface
  ): void {
    this.handleReservationDialogChoices(data.reservationType)
  }

  async fetchDepartments (): Promise<void> {
    const result = await _departmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async handleFilterByDepartments (): Promise<void> {
    if (this.selectedDepartments.length > 0) {
      this.viewAll = this.activityFilter === 'All Renting Entities'
      this.fetchResources()
      this.clearFilterButtonColor = 'tw-text-red-400'
      this.cleanState = false
      return
    }

    await this.handleCalendarViewFetch()
  }

  async handleFilterByActivity (): Promise<void> {
    this.viewAll = this.activityFilter === 'All Renting Entities'
    this.fetchResources()
    if (!this.viewAll) {
      this.clearFilterButtonColor = 'tw-text-red-400'
      this.cleanState = false
      return
    }

    this.clearFilterButtonColor = ''
    this.cleanState = true
  }

  async clearFilters (): Promise<void> {
    await this.fetchResources()
    await this.fetchEvents()
    this.$forceUpdate()
    this.selectedDepartments = []
    this.clearFilterButtonColor = ''
    this.viewAll = true
    this.activityFilter = 'All Renting Entities'
    this.cleanState = true
    this.showFilters = false
  }

  async fetchResources (): Promise<void> {
    this.colorIndex = 0
    const rentingEntities =
      await _bookingService.getCalendarViewRentingEntities(
        this.selectedDate,
        this.selectedDepartments,
        this.viewAll,
        'HOURLY'
      )
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sorted = rentingEntities.sort((a: any, b: any) =>
      collator.compare(a.name, b.name)
    )

    this.calendarOptions.resources = this.addEntityColors(sorted)
    this.fetchedData = true
  }

  async fetchEvents (): Promise<void> {
    this.colorIndex = 0
    const _selectedDate = dummyDateConverter(this.selectedDate)
    const events = await _bookingService.getCalendarViewEvents(
      _selectedDate,
      'HOURLY'
    )
    const _formatedEvents = this.formatEvents(events)
    this.calendarOptions.events = _formatedEvents
  }

  async fetchBlockedDatesForSelectedDepartments (): Promise<void> {
    const departmentIds = this.calendarOptions.resources
      .map((department) => department.department_id)
      .filter(
        (departmentId, index, currentVal) =>
          currentVal.indexOf(departmentId) === index
      )

    const result = await _blockedDateService.getBlockedDatesSeriesByDepartments(
      departmentIds
    )

    this.blockedDates = result.blocked_dates
    this.formatBlockedDates()
  }

  async handleCalendarViewFetch (): Promise<void> {
    await this.fetchResources()
    await this.fetchEvents()
    await this.fetchBlockedDatesForSelectedDepartments()
  }

  async handleDragOrResizeUpdate (
    info: EventDropArg | EventResizeDoneArg
  ): Promise<void> {
    if (!this.calendarOptions.editable) {
      this.$toast.info(this.$i18n.t('page.calendnar.not_editable').toString())
      info.revert()
      return
    }

    if (info.event.extendedProps.completed_at) {
      info.revert()
      return
    }

    if (info.event.extendedProps.blocking_type) {
      const errMsg = this.$i18n
        .t('page.calendar.blocked_dates.event_modifying_warning')
        .toString()
        .replace(
          '{reason}',
          info.event.extendedProps.blocking_type.toUpperCase()
        )
      this.$toast.error(errMsg)
      info.revert()
      return
    }

    const numberOfInteractors =
      info.oldEvent.extendedProps.number_of_interactors
    const bookingId = info.event._def.publicId
    const title = info.event._def.title

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateFrom = info.event.start?.toISOString() as any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dateTo = info.event.end?.toISOString() as any

    // should look into locales and why the event instance is addeding 2 hours?
    // dateFrom = format(subHours(dateFrom, 0), 'y-MM-dd HH:mm:ss')
    // dateTo = format(subHours(dateTo, 0), 'y-MM-dd HH:mm:ss')

    if (info.event.start) {
      if (compareDatesWithNow(info.event.start) === -1) {
        this.$toast.default(
          this.$i18n.t('notify.booking_past_no_modify').toString()
        )
        info.revert()
        return
      }
    }

    if (info.oldEvent.start && info.oldEvent.end) {
      if (
        compareDatesWithNow(info.oldEvent.start) === -1 &&
        compareDatesWithNow(info.oldEvent.end) === -1
      ) {
        this.$toast.default(
          this.$i18n.t('notify.booking_past_no_modify').toString()
        )
        info.revert()
        return
      }
    }

    const payload = {
      booking: {
        renting_entity_id: info.event._def.resourceIds,
        number_of_interactors: numberOfInteractors,
        date_from: dateFrom,
        date_to: dateTo,
        has_blockage: info.event.extendedProps.blocking_type !== undefined
      }
    } as UpdateEventDragOrResize

    try {
      await _bookingService.updateEventDragOrResize(bookingId, payload)
    } catch (error) {
      info.revert()
      await this.handleCalendarViewFetch()
      return
    }

    this.$toast.success(
      `${i18n.t('notify.success.event')} '${title}' ${i18n.t(
        'notify.success.changed'
      )}`
    )
    // this.fetchEvents()
    await this.handleCalendarViewFetch()
  }

  async created (): Promise<void> {
    this.setLocale()
    await this.fetchDepartments()
    await this.handleCalendarViewFetch()
    this.setCalendarEditability()
  }

  // Calendar Events
  dateClicked (info: DateClickArg): void {
    if (this.calendarOptions.editable === false) {
      this.$toast.error(
        this.$i18n
          .t('notify.calendar_no_action_allowed_for_the_role')
          .toString()
          .replace('{role}', this.userRoleComputed.toLocaleUpperCase())
      )
      return
    }

    // Why is this working?
    if (compareDatesWithNow(new Date(info.dateStr)) === -1) {
      this.$toast.default(i18n.t('notify.booking_no_past').toString())
      return
    }

    if (info.resource !== undefined) {
      this.rentingEntityId = info.resource._resource.id
      this.dateFrom = info.date.toISOString()
    }

    this.showAddModal(info.date, false, 'AUTO')
  }

  eventClicked (info: EventClickArg): void {
    if (info.event.extendedProps.blocking_type) return // <- basically we're checking if the event we're clikcing is virtual blocking event
    info.jsEvent.preventDefault()

    this.selectedIndex = info.event.id
    this.state = 'EDIT'
    this.dialog = true
  }

  addEntityColors (
    entities: Array<RentingEntityInterface>
  ): Array<RentingEntityInterface> {
    return entities.map(
      (item: RentingEntityInterface, index: number): RentingEntityInterface => {
        const color = this.getColor(index)
        this.colors[item.id as string] = color

        item.eventClassNames = 'tw-border-0'
        item.eventBackgroundColor = color
        item.title = item.name + ` (${item.number_of_interactors})`

        return item
      }
    )
  }

  formatEvents (
    bookings: Array<BookingEventInterface>
  ): BookingEventInterface[] {
    const setColor = (event: BookingEventInterface, color: string) => {
      event.color = Chroma(color).hex()
    }

    return bookings.map((event) => {
      const entities = event.resource_ids || []
      delete event.resource_ids

      event.icon =
        event.note !== null || parseJson(event.tags?.value as string).length > 0
      event.resourceIds = entities.map((resource) => resource.renting_entity_id)

      const eventEnd = dummyDateConverter(event.end)
      const eventStart = dummyDateConverter(event.start)
      const now = dummyDateConverter()

      if (eventEnd < now) {
        setColor(event, event.completed_at ? '31b289' : 'E74C3C')
      } else if (eventStart < now && eventEnd > now) {
        setColor(event, 'ff9f0b')
      }

      if (event.whole_department_reservation) {
        event.color = Chroma('black').brighten(1).hex()
      }

      return event
    })
  }

  formatBlockedDates (): void {
    if (!this.calendarOptions.resources || !this.blockedDates) return
    const resources = this.calendarOptions.resources

    resources.forEach((resource) => {
      this.blockedDates.forEach((blockedDate) => {
        if (blockedDate.department_id === resource.department_id) {
          const title =
            this.currentLanguage === 'en-us'
              ? blockedDate.description_en
              : blockedDate.description_no

          const event = {
            id: blockedDate.id,
            renting_entity_id: resource.id,
            title: this.getBlockedDateTitle(
              blockedDate.blocking_type as string,
              title
            ),
            start: blockedDate.start_date,
            end: blockedDate.end_date,
            color: Chroma(blockedDate.color as string)
              .darken()
              .hex(),
            resourceId: resource.id,
            extendedProps: {
              blocking_type: blockedDate.blocking_type
            },
            number_of_interactors: 0,
            renting_entity: resource,
            properties: [] as Array<never>,
            client: {} as never,
            completed_at: '',
            editable: false
          } as BookingEventInterface
          this.calendarOptions.events.push(event)
        }
      })
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendar = this.$refs.calendar as any
    calendar.getApi().render()
  }

  getColor (index: number): string {
    return this.defaultColors[index % this.defaultColors.length]
  }

  getBlockedDateTitle (
    blockedType: string | undefined,
    content: string
  ): string {
    return (
      i18n
        .t(`page.calendar.blocked_dates.blocking_type.${blockedType}`)
        .toString()
        .toUpperCase() +
      ': ' +
      content
    )
  }

  // eslint-disable-next-line
  resourceLabelDidMount(info: any): void {
    info.el.style.backgroundColor = this.colors[info.resource.id]
  }

  formatDate (date: string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss').toString()
  }

  async eventDrop (info: EventDropArg): Promise<void> {
    await this.handleDragOrResizeUpdate(info)
  }

  eventResize (info: EventResizeDoneArg): void {
    this.handleDragOrResizeUpdate(info)
  }

  eventRender (event: BookingEventInterface, element: CalendarEvent): void {
    if (event.icon) {
      element
        .find('.fc-title')
        .prepend("<i class='fa fa-" + event.icon + "'></i>")
    }
  }

  // eslint-disable-next-line
  changeEvenColorOnDrop(resource: any, event: any): void {
    const index = this.calendarOptions.events.findIndex(
      // eslint-disable-next-line
      (item: any) => item.id == event.id
    )

    this.calendarOptions.events[index].resourceId = resource.id
    this.changeEventTime(event, index)
    this.calendarOptions.events[index].color =
      resource.extendedProps.colorSchema
  }

  // eslint-disable-next-line
  changeEventTime(event: any, index: number = -1): void {
    if (index === -1) {
      index = this.calendarOptions.events.findIndex(
        // eslint-disable-next-line
        (item: any) => item.id == event.id
      )
    }

    this.calendarOptions.events[index].start = this.formatDate(event.start)
    this.calendarOptions.events[index].end =
      event.end && this.formatDate(event.end)
  }

  async dateChanged (item: DateRange): Promise<void> {
    if (this.selectedDate.getTime() !== item.start.getTime()) {
      this.selectedDate = item.start
      if (this.initialRender === false) {
        await this.handleCalendarViewFetch()
      }
      this.initialRender = false
    }
  }

  customHeaderHandler (): void {
    this.calendarDialog = true
  }

  threeHourview = false

  rerenderEvents = {
    resourceIndex: 0,
    initialState: true
  }

  listDrawer = false

  toggleListDrawer (): void {
    this.listDrawer = !this.listDrawer
  }

  listEventClicked (id: string): void {
    this.selectedIndex = id
    this.state = 'EDIT'
    this.dialog = true
    this.listDrawer = false
  }

  // Calendar Options
  calendarOptions = {
    height: '100%',
    plugins: [momentTimezonePlugin, resourcePlugin, interactionPlugin],
    initialView: 'hourView',
    resourceLabelClassNames: 'white--text',
    editable: true,
    eventResourceEditable: true,
    eventOverlap: false,
    nowIndicator: true,
    timeZone: configuration().defaultTimezone,
    resourceOrder: '',
    scrollTime: format(subMinutes(dummyDateConverter(new Date()), 90), 'HH:mm'),
    resourceAreaWidth: '20%',
    resourceGroupField: 'department_name',
    locale: this.$i18n.locale,
    resourceAreaColumns: [
      {
        field: 'name',
        headerContent: i18n.t('page.calendar.renting_entities'),
        width: '70%',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        cellContent: (event: any) => {
          const title = event.resource._resource.extendedProps.name
          const extendedDescription: RentingEntityExtendedDescriptionInterface =
            event.resource._resource.extendedProps.extended_description

          const rentingEntityTypeName = i18n.t(
            `renting_entity_types.${extendedDescription.renting_entity_type_name}`
          )

          const output = `${
            extendedDescription.company_name
          } (${rentingEntityTypeName}): ${
            extendedDescription.description ?? 'N/A'
          }`

          return {
            html: `<span title="${output}">${title}</span>`
          }
        }
      },
      {
        field: 'number_of_interactors',
        headerContent: i18n.t('page.calendar.capacity'),
        width: '30%',
        cellClassNames: 'tw-text-center',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
        cellContent: (event: any) => {
          const guests = event.fieldValue
          const canCombine = event.resource.extendedProps.can_combine || false
          return canCombine
            ? {
                html: `<div class="tw-flex tw-gap-1 tw-items-center tw-justify-center"><span>${guests}</span><i class="mdi mdi-vector-square-plus tw-text-xs tw-text-stone-500"/></div>`
              }
            : guests
        },
        cellDidMount: (): void => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const calendar = this.$refs.calendar as any
          calendar.getApi().render()
        }
      }
    ],
    customButtons: {
      newReservation: {
        text: i18n.t('button.new_reservation'),
        icon: 'mdi mdi mdi-calendar-plus',
        click: (): void => {
          if (!this.calendarOptions.editable) {
            this.$toast.error(
              this.$i18n
                .t('notify.calendar_no_action_allowed_for_the_role')
                .toString()
                .replace('{role}', this.userRoleComputed.toLocaleUpperCase())
            )
            return
          }
          // this.showAddModal(null, true, '')
          this.showUberDialog()
        }
      },
      reloadButton: {
        text: i18n.t('button.reload'),
        icon: 'mdi mdi mdi-reload',
        click: (): void => {
          window.location.reload()
        }
      },
      fullScreen: {
        click: (): void => this.toggleFullscreen(),
        text: i18n.t('common.fullscreen'),
        icon: 'mdi mdi mdi-fullscreen'
      },
      lockButton: {
        click: (): void => this.showReserveDialog(),
        text: i18n.t('page.calendar.buttons.lock_booking'),
        icon: 'mdi mdi mdi-book-lock'
      },
      tomorrowButton: {
        text: i18n.t('page.calendar.buttons.tomorrow'),
        click: (): void => {
          const date = this.selectedDate
          date.setDate(date.getDate() + 1)

          const calendar = this.$refs.calendar as InstanceType<
            typeof FullCalendar
          >

          const calendarApi = calendar.getApi()
          calendarApi.gotoDate(date)
          this.selectedDate = date
        }
      },
      customHeader: {
        text: format(this.selectedDate, DATE_FORMAT),
        click: (): void => this.customHeaderHandler()
      },
      hourView: {
        text: !this.threeHourview
          ? i18n.t('page.calendar.buttons.3_hour_timer')
          : i18n.t('page.calendar.buttons.24_hour_timer'),
        click: (): void => this.changeHourView()
      },
      today: {
        text: i18n.t('page.calendar.buttons.today'),
        click: (): void => {
          const date = new Date()
          date.setDate(date.getDate())

          const calendar = this.$refs.calendar as InstanceType<
            typeof FullCalendar
          >

          const calendarApi = calendar.getApi()
          calendarApi.gotoDate(date)
          this.selectedDate = date
        }
      },
      listButton: {
        text: i18n.t('page.calendar.client_list'),
        icon: 'mdi mdi mdi-calendar-search',
        click: this.toggleListDrawer
      }
    },
    views: {
      hourView: {
        type: 'resourceTimeline',
        slotMinTime: '00:00:00',
        slotMaxTime: '23:59:59',
        duration: { days: 1 },
        slotDuration: '00:10:00',
        snapDuration: '00:05:00',
        defaultTimedEventDuration: '02:00:00',
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23'
        }
      }
    },
    headerToolbar: {
      left: 'customHeader',
      right: 'newReservation,listButton reloadButton,fullScreen',
      center: 'hourView prev,today,next'
    },
    resources: [] as RentingEntityInterface[],
    events: [] as BookingEventInterface[],
    resourceLabelDidMount: this.resourceLabelDidMount,
    eventDrop: this.eventDrop,
    eventResize: this.eventResize,
    dateClick: this.dateClicked,
    eventClick: this.eventClicked,
    datesSet: this.dateChanged
  }

  showAddModal (
    date: Date | null = null,
    override: boolean,
    type: string
  ): void {
    type === 'AUTO' ? (this.autoDialog = true) : (this.dialog = true)
    this.state = 'add'

    if (date) {
      this.selectedDate = date
    }
  }

  showReserveDialog () {
    this.reserveDialog = true
  }

  showManualDialog () {
    this.manualDialog = true
  }

  showUberDialog () {
    this.uberDialog = true
  }

  handleReservationDialogChoices (data: ReservationTypes): void {
    switch (data) {
      case ReservationTypes.AUTOMATIC_RESERVATION:
        this.showAddModal(null, true, '')
        break
      case ReservationTypes.MANUAL_RESERVATION:
        this.showManualDialog()
        break
      case ReservationTypes.DEPARTMENT_RESERVATION:
        this.showReserveDialog()
        break
      default:
        throw new Error('Reservation type not found')
    }
    this.uberDialog = false
  }

  toggleFullscreen (): void {
    // const calendarEl = this.$refs.calendar.el as HTMLElement
    const calendar = this.$refs.calendar as InstanceType<typeof FullCalendar>

    const body = document.body as HTMLElement

    const sideBarActive = Store.getters['app/getSideBarActivity']

    if (document.fullscreenElement) {
      // Exiting
      document.exitFullscreen()

      calendar.getApi().render()

      if (sideBarActive === false) {
        Store.dispatch('app/toggleSideBarActivity')
      }
    } else {
      // Entering
      body.requestFullscreen()

      calendar.getApi().render()

      if (sideBarActive === true) {
        Store.dispatch('app/toggleSideBarActivity')
      }
    }
  }

  async submit (): Promise<void> {
    this.close()
    this.initialRender = true
    await this.handleCalendarViewFetch()
  }

  close (): void {
    this.dialog = false
    this.autoDialog = false
    this.reserveDialog = false
  }

  calendarDateSelected (pickedDate: string): void {
    const date = new Date(pickedDate)

    const calendar = this.$refs.calendar as InstanceType<typeof FullCalendar>
    const calendarApi = calendar.getApi()
    calendarApi.gotoDate(date)

    this.calendarDialog = false
  }

  changeHourView (): void {
    if (!this.threeHourview) {
      const hour = new Date().getHours()

      this.threeHourview = true

      this.calendarOptions.customButtons.hourView.text = `${i18n.t(
        'page.calendar.buttons.24_hour_timer'
      )}`
      this.calendarOptions.views.hourView.slotMinTime = `${hour}:00:00`
      this.calendarOptions.views.hourView.slotMaxTime = `${hour + 3}:00:00`

      return
    }

    this.threeHourview = false

    this.calendarOptions.customButtons.hourView.text = `${i18n.t(
      'page.calendar.buttons.3_hour_timer'
    )}`
    this.calendarOptions.views.hourView.slotMinTime = '00:00:00'
    this.calendarOptions.views.hourView.slotMaxTime = '23:59:59'
  }

  // #region setters

  setCalendarEditability (): void {
    const userData = this.$store.state.auth.user
    if (userData) {
      this.userRole = userData.role
    }

    this.calendarOptions.editable =
      this.userRole === 'admin' || this.userRole === 'host'
  }

  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }

  formatEventTitle (
    title: string,
    isWholeDepartmentReservation = false
  ): string {
    if (!isWholeDepartmentReservation) return title

    return this.$t('common.reserved').toString().toUpperCase() + ' ' + title
  }

  get currentLanguage (): string {
    return this.$i18n.locale
  }
  // #endregion

  // #region Watchers

  @Watch('selectedDate')
  onSelectedDateChange (): void {
    this.calendarOptions.customButtons.customHeader.text = format(
      dummyDateConverter(this.selectedDate),
      'PPP',
      { locale: this.locale }
    )
  }

  @Watch('dialog')
  setSelectedIndex (): void {
    if (!this.dialog) {
      this.selectedIndex = null
    }
  }

  @Watch('autoDialog')
  setSelectedIndexAuto (): void {
    if (!this.autoDialog) {
      this.selectedIndex = null
    }
  }
  // #endregion

  // #region getters
  get userRoleComputed (): string {
    return this.$store.state.auth.user?.role as string
  }
  // #endregion
}
