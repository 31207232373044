
import { Component, Vue } from 'vue-property-decorator'
import PageOverview from '@/common/components/pages/PageOverview.vue'

@Component({ components: { PageOverview } })
export default class ThemeSettings extends Vue {
  themeColor = this.$store.state.app.globalTheme
  toolbarThemeColor = this.$store.state.app.toolbarTheme
  menuThemeColor = this.$store.state.app.menuTheme

  theme (theme: string): void {
    this.$store.commit('app/setGlobalTheme', theme)
  }

  toolbarTheme (theme: string): void {
    this.$store.commit('app/setToolbarTheme', theme)
  }

  menuTheme (theme: string): void {
    this.$store.commit('app/setMenuTheme', theme)
  }
}
