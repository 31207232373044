
// #region imports

// #region vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region components

// Components
import Table from '@/common/components/ui/table/Table.vue'
import ItemActions from '@/common/components/ui/table/ItemActions.vue'
import ItemAction from '@/common/components/ui/table/ItemAction.vue'

// Custom Components
import BookingsDate from '@/components/bookings/BookingsDate.vue'
import BookingClientName from '@/components/bookings/BookingClientName.vue'
import BookingClientPhoneNumber from '@/components/bookings/BookingClientPhoneNumber.vue'
import MoreThanOneTag from '@/components/bookings/MoreThanOneTag.vue'
import EditBookingRequest from '@/views/booking-requests/_EditBookingRequest.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'

// #endregion

// #region interfaces
import { AvailableRentingEntityForBookingRequest } from '@/types/interfaces/renting-entity'

import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'

import {
  BookingInterface,
  BookingTableItemInterface
} from '@/types/interfaces/booking'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region services
import bookingRequestService from '@/services/booking-request.service'
// #endregion

// #endregion

@Component({
  components: {
    Table,
    ItemActions,
    ItemAction,
    EditBookingRequest,
    DefaultTableActions
  },
  computed: { ...mapState('app', ['isLoading']), ...mapState('auth', ['user']) }
})
export default class PendingBookingRequests extends Vue {
  headers = [
    {
      text: i18n.t('table.col.client'),
      value: 'custom_render',
      render: BookingClientName
    },
    {
      text: i18n.t('table.col.phone_number'),
      value: 'custom_render',
      render: BookingClientPhoneNumber
    },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    { text: i18n.t('table.col.company'), value: 'company.name' },
    {
      text: i18n.t('table.col.interactors'),
      value: 'number_of_interactors',
      key: 'number_of_interactors'
    },
    {
      text: i18n.t('table.col.from'),
      value: 'custom_render',
      key: 'date_from',
      render: BookingsDate
    },
    {
      text: i18n.t('table.col.to'),
      value: 'custom_render',
      key: 'date_to',
      render: BookingsDate
    },
    {
      text: 'TAGS',
      align: 'start',
      sortable: false,
      value: 'custom_render',
      keys: 'properties',
      render: MoreThanOneTag
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  rules = rules
  bookings: Array<BookingInterface> = []
  pagination = {} as PaginationInterface

  booking: BookingInterface | null = null
  availableRentingEntities = [] as AvailableRentingEntityForBookingRequest[]
  selectedRentingEntitie = ''

  dialog = false

  async handleBookingRequestsFetch (): Promise<void> {
    const result = await bookingRequestService.getAll()
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleBookingRequestsFetch()
  }

  viewItem (row: BookingTableItemInterface): void {
    this.$router.push(`/booking-request/${row.item.id}`)
  }

  editItem (row: BookingTableItemInterface): void {
    this.booking = this.bookings[row.index]
    this.toggleDialog()
  }

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.booking = null
    }
  }

  @Watch('dialog')
  onDialogChange (): void {
    if (!this.dialog) {
      this.booking = null
    }
  }

  async fetchAvailableRentingEntities (
    row: BookingTableItemInterface
  ): Promise<void> {
    this.availableRentingEntities =
      await bookingRequestService.getAvailableRentingEntities(row.item.id)
  }

  async approve (row: BookingTableItemInterface): Promise<void> {
    await bookingRequestService.approve(
      row.item.id,
      this.selectedRentingEntitie
    )

    await this.handleBookingRequestsFetch()
  }

  async decline (row: BookingTableItemInterface): Promise<void> {
    await bookingRequestService.decline(row.item.id)
    await this.handleBookingRequestsFetch()
  }

  async prevPage (): Promise<void> {
    const result = await bookingRequestService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async nextPage (): Promise<void> {
    const result = await bookingRequestService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await bookingRequestService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.bookings = result.booking_requests.data

    delete result.booking_requests.data

    this.pagination = result.booking_requests
  }

  allowAction (row: BookingTableItemInterface, action = 'default'): boolean {
    if (action === 'edit') return true

    return new Date(row.item.date_from) >= new Date()
  }
}
