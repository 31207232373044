import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

// Views
import Home from '@/views/dashboard/Dashboard.vue'
import Settings from '@/views/settings/Settings.vue'
import Wizard from '@/views/wizard/Wizard.vue'
import Reports from '@/views/reports/Reports.vue'
import EnableAccount from '@/views/settings/EnableAccount.vue'

// Routes
import authRoutes from '@/router/auth.routes'
import errorRoutes from '@/router/error.routes'
import companyRoutes from '@/router/company.routes'
import staffRoutes from '@/router/staff.routes'
import departmentRoutes from '@/router/department.routes'
import rentingEntityRoutes from '@/router/renting-entities.routes'
import bookingRoutes from '@/router/booking.routes'
import bookingRequestsRoutes from '@/router/booking-requests.routes'

import clientRoutes from '@/router/client.routes'
import userRoutes from '@/router/user.routes'
import surveyRoutes from '@/router/survey.routes'
import store from '@/store/index'
import categoryRoutes from '@/router/category.routes'
import documentationRoutes from './documentation.routes'
import pricesRoutes from './prices.routes'
import printableRoutes from './printable.routes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/wizard',
    name: 'wizard',
    component: Wizard,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  {
    path: '/enable-account',
    name: 'enable-account',
    component: EnableAccount,
    meta: {
      auth: true,
      role: ['admin']
    }
  },
  ...companyRoutes,
  ...departmentRoutes,
  ...rentingEntityRoutes,
  ...categoryRoutes,
  ...bookingRoutes,
  ...bookingRequestsRoutes,
  ...staffRoutes,
  ...clientRoutes,
  ...userRoutes,
  ...surveyRoutes,
  ...authRoutes,
  ...errorRoutes,
  ...documentationRoutes,
  ...pricesRoutes,
  ...printableRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_TITLE
  const user = store.getters['auth/getUser']
  const usesAuth = to.meta && to.meta.auth ? to.meta.auth : false

  // logic to handle supser admin login:
  const path = to.path
  const query = to.query
  if (path.includes('/auth/signin') && Object.keys(query).length > 0) {
    if (localStorage.length !== 0) {
      localStorage.clear()
      window.location.reload()
    }
  }

  if (!usesAuth && user) {
    return next({
      path: '/'
    })
  }

  if (!usesAuth && !user) {
    return next()
  }

  // logged in
  if (usesAuth) {
    if (!user) {
      return next({
        path: '/login'
      })
    }

    const userData = store.getters['auth/getUserData']

    const routeMeta = to.meta

    if (
      routeMeta &&
      routeMeta.role &&
      !routeMeta.role.includes(userData.role)
    ) {
      return next({ name: 'error-page' })
    }

    if (
      userData.parent_domain !== 'demo' &&
      userData.role === 'admin' &&
      userData.full_domain.wizard_completed === false &&
      to.path !== '/wizard'
    ) {
      return next({ path: '/wizard' })
    }

    if (to.name === 'enable-account') {
      if (userData.is_demo && userData.parent_domain !== 'demo') {
        return next()
      }

      return next({ path: '/' })
    }
  }

  return next()
})
