
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class RadioOption extends Vue {
  @Prop({ required: true, default: 'name' }) readonly name!: string
  @Prop({ required: true }) readonly options!: Array<string>
  readonly optionLabel!: string
  @Prop({ required: false }) readonly answer!: string

  selected = ''

  update (val: string): void {
    this.$emit('input', val)
  }
}
