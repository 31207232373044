import { render, staticRenderFns } from "./ScheduleSystem.vue?vue&type=template&id=47db8574&"
import script from "./ScheduleSystem.vue?vue&type=script&lang=ts&"
export * from "./ScheduleSystem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports