
import { Component, Vue } from 'vue-property-decorator'
import NavMenu from '@/components/navigation/NavMenu.vue'
import { mapState } from 'vuex'
import store from '@/store'
import companyService from '@/services/company.service'

@Component({
  components: {
    NavMenu
  },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  computed: { ...mapState('auth', ['user']) }
})
export default class MainMenu extends Vue {
  accountState = store.getters['auth/getAccountState']

  goToSettings (): void {
    this.$router.push('/settings')
  }

  logout (): void {
    store.dispatch('auth/logout')
  }

  goLive (): void {
    this.$router.push('/enable-account')
  }

  async fetchAccountStatus (): Promise<void> {
    if (
      this.accountState &&
      this.accountState.isDemo &&
      this.accountState.parentDomain !== process.env.VUE_APP_DEMO_DEFAULT_DOMAIN
    ) {
      const state = await companyService.getAccountState()

      if (!state.isDemo) {
        this.accountState.isDemo = false
        store.commit('auth/setAccountState', this.accountState)
      }
    }
  }

  async created (): Promise<void> {
    await this.fetchAccountStatus()
  }
}
