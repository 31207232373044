
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import ItemActions from '@/common/components/ui/table/ItemActions.vue'
import ItemAction from '@/common/components/ui/table/ItemAction.vue'
import AddEditRentingEntity from '@/containers/renting-entities/AddEditRentingEntity.vue'
// #endregion

// #region interfaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  RentingEntityInterface,
  RentingEntityTableItemInterface
} from '@/types/interfaces/renting-entity'
// #endregion

// #region utils
import { copyLink } from '@/utils/helper.utils'
// #endregion

// #region services
import rentingEntityService from '@/services/renting-entity.service'
import departmentService from '@/services/department.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component({
  components: { Table, AddEditRentingEntity, ItemActions, ItemAction },
  methods: { copyLink }
})
export default class ActiveRentingEntities extends Vue {
  @Prop({ required: true }) readonly tab!: string

  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    {
      text: i18n.t('table.col.type'),
      value: 'custom_render',
      key: 'type',
      render: Vue.component('TypeName', {
        props: ['item'],
        template:
          '<v-chip label outlined color="red" small>{{item.name.toUpperCase()}}</v-chip>'
      })
    },
    { text: i18n.t('table.col.department'), value: 'department.name' },
    {
      text: 'Status',
      value: 'custom_render',
      render: Vue.component('Status', {
        template: '<v-chip label outlined color="green" small>ACTIVE</v-chip>'
      }),
      sortable: false
    },
    {
      text: i18n.t('input.label.number_of_interactors'),
      value: 'custom_render',
      key: 'number_of_interactors',
      render: Vue.component('NumberOfInteractors', {
        props: ['item'],
        template:
          '<v-chip label outlined color="blue" small class="tw-font-bold">{{item}}</v-chip>'
      }),
      sortable: false
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  rentingEntities: Array<RentingEntityInterface> = []
  pagination = {} as PaginationInterface
  departments = [] as Array<DepartmentInterface>
  selectedDepartments = []
  searchable = true
  clearFilterButtonColor = ''
  cleanState = true
  showCustomFilter = true

  searching = ''

  async handleRentingEntitiesFetch (): Promise<void> {
    const result = await rentingEntityService.getAll()
    this.setData(result)
    this.cleanState = true
  }

  async handlRentingEntitiesSearchFetch (
    searchString: string,
    page = 1
  ): Promise<void> {
    const result = await rentingEntityService.filter(searchString, page)
    this.setData(result)
  }

  async handleFilterByDepartments (page = 1): Promise<void> {
    if (this.selectedDepartments.length > 0) {
      const result = await rentingEntityService.filterByDepartments(
        this.selectedDepartments,
        page
      )
      this.setData(result)
      this.clearFilterButtonColor = 'tw-text-red-400'
      this.searchable = false
      this.cleanState = false
    }
  }

  async created (): Promise<void> {
    await this.handleRentingEntitiesFetch()
    await this.fetchDepartments()
  }

  dialog = false
  deleteDialog = false
  rentingEntity: RentingEntityInterface | null = null
  rentingEntityLink = ''

  editItem (row: RentingEntityTableItemInterface): void {
    this.rentingEntity = this.rentingEntities[row.index]
    this.toggleDialog()
  }

  viewItem (row: RentingEntityTableItemInterface): void {
    this.$router.push(`/renting-entity/${row.item.id}`)
  }

  async deleteItem (): Promise<void> {
    const id = this.rentingEntity !== null ? this.rentingEntity.id : ''

    await rentingEntityService.delete(id)

    this.$toast.success(
      i18n.t('notify.success.renting_entities_deleted').toString()
    )

    this.handleRentingEntitiesFetch()

    this.deleteDialog = false
    this.rentingEntity = null
  }

  initDeleteRentingEntity (row: RentingEntityTableItemInterface): void {
    this.deleteDialog = true
    this.rentingEntity = this.rentingEntities[row.index]
  }

  cancelDeleteDialog (): void {
    this.deleteDialog = false
    this.rentingEntity = null
  }

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.rentingEntity = null
    }
  }

  @Watch('dialog')
  onDialogChange (): void {
    if (!this.dialog) {
      this.rentingEntity = null
    }
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'activeRentingEntites') {
      this.handleRentingEntitiesFetch()
    }
  }

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleRentingEntitiesFetch()
      return
    }

    this.searching = query
    this.handlRentingEntitiesSearchFetch(query)
  }

  async fetchDepartments (): Promise<void> {
    const result = await departmentService.getActiveWithoutPagination()
    this.departments = result
  }

  async nextPage (): Promise<void> {
    if (!this.cleanState) {
      await this.handleFilterByDepartments(this.pagination.current_page + 1)
      return
    }

    if (this.searching) {
      await this.handlRentingEntitiesSearchFetch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }

    const result = await rentingEntityService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (!this.cleanState) {
      await this.handleFilterByDepartments(this.pagination.current_page - 1)
      return
    }

    if (this.searching) {
      await this.handlRentingEntitiesSearchFetch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }

    const result = await rentingEntityService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (!this.cleanState) {
      await this.handleFilterByDepartments(number)
      return
    }

    if (this.searching) {
      await this.handlRentingEntitiesSearchFetch(this.searching, number)
      return
    }

    const result = await rentingEntityService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.rentingEntities = result.entities.data
    delete result.entities.data
    this.pagination = result.entities
  }

  async clearDepartmentFilter (): Promise<void> {
    await this.handleRentingEntitiesFetch()
    this.selectedDepartments = []
    this.clearFilterButtonColor = ''
    this.searchable = true
    this.cleanState = true
  }

  link (row: RentingEntityTableItemInterface): void {
    const user = this.$store.getters['auth/getUserData']

    const domain = user.domain || 'demo'
    this.rentingEntityLink = `${window.location.origin}/${domain}/book?renting-entity=${row.item.id}`
  }
}
