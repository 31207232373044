// #region imports

// #region vue
import Store from '@/store'
// #endregion

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'

import {
  AutoBookingTransferDataInterface,
  BookingFilterBundle,
  BookingSearchBundle,
  CreateBookingInterface
} from '@/types/interfaces/booking'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #region utils & helpers
import { format } from 'date-fns'
// #endregion

// #endregion

class BookingService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url = type.length === 0 ? '/bookings' : `/bookings/${type}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async filter (data: BookingFilterBundle, type: string) {
    Store.dispatch('app/loading', true)

    const url =
      type.length > 0 ? `/bookings-filter/${type}` : '/bookings-filter/'

    Store.dispatch('app/loading', false)

    const result = await this.axios.post(`${url}?page=${data.filters.page}`, {
      data
    })

    return result.data
  }

  async search (data: BookingSearchBundle, type: string) {
    Store.dispatch('app/loading', true)

    const url =
      type.length > 0
        ? `/bookings-search/${type}?page=${data.search.page}`
        : `/bookings-search?page=${data.search.page}`

    const result = await this.axios.post(url, {
      data
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/booking/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.booking
  }

  async getCalendarViewRentingEntities (
    date: Date,
    departments = [],
    viewAll = true,
    scheduleSystem: string
  ) {
    Store.dispatch('app/loading', true)

    const url = '/calendar-renting-entities'

    const result = await this.axios(url, {
      headers: { 'Booking-Date': format(date, 'yyyy-MM-dd') },
      params: { departments, viewAll, scheduleSystem }
    })

    Store.dispatch('app/loading', false)

    return result.data.entities
  }

  async getCalendarViewEvents (date: Date, scheduleSystem: string) {
    Store.dispatch('app/loading', true)

    const url = '/calendar-bookings'

    const result = await this.axios(url, {
      headers: { 'Booking-Date': format(date, 'yyyy-MM-dd') },
      params: { scheduleSystem }
    })

    Store.dispatch('app/loading', false)

    return result.data.bookings
  }

  async getCalendarListEvents (
    date: Date,
    scheduleSystem: string,
    viewMode: string,
    phrase: string | null
  ) {
    Store.dispatch('app/loading', true)

    const url = '/calendar-list-bookings'

    const result = await this.axios(url, {
      params: {
        scheduleSystem,
        selectedDate: format(date, 'yyyy-MM-dd'),
        viewMode,
        phrase
      }
    })

    Store.dispatch('app/loading', false)

    return result.data.bookings
  }

  async getAvailableHours (
    departmentId: string,
    interactors: number,
    date = new Date()
  ) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(
      `/hours/${departmentId}/${interactors}/${format(date, 'yyyy-MM-dd')}`
    )

    Store.dispatch('app/loading', false)
    return result.data.available_hours
  }

  async getAvaialbleHoursForARentingEntity (
    rentingEntityId: string,
    dateFrom: string | Date,
    dateTo: string | Date | null = null
  ) {
    Store.dispatch('app/loading', true)

    const url = 'hours-for-renting-entity/'

    const result = await this.axios(url, {
      params: {
        renting_entity_id: rentingEntityId,
        date_from: dateFrom,
        date_to: dateTo
      }
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async getDisabledDatesForARentingEntity (rentingEntityId: string) {
    Store.dispatch('app/loading', true)

    const url = `/booked-dates-for-entity/${rentingEntityId}`

    const result = await this.axios.get(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  // eslint-disable-next-line
  async create(data: any) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/booking', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async createRequest(data: any, route: string = 'PRIVATE') {
    Store.dispatch('app/loading', true)

    const URL =
      route === 'PRIVATE' ? 'booking-request' : 'booking-request-public'

    const result = await this.axios(URL, {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async createDailyBooking(data: any) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/daily-booking-public', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async createPublic(data: any) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/booking-public', {
      data: { data },
      method: 'POST'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async updateEventDragOrResize(bookingId: string | null, data: any) {
    Store.dispatch('app/loading', true)

    const url = `booking-drag-resize/${bookingId}`

    const result = await this.axios.patch(url, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  // eslint-disable-next-line
  async getBookingCustom(id: string): Promise<CreateBookingInterface> {
    Store.dispatch('app/loading', true)

    const result = await this.axios.get(`/booking-custom/${id}`)

    Store.dispatch('app/loading', false)

    return result.data.booking
  }

  // eslint-disable-next-line
  async update(data: any, id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios.put(`/booking/${id}`, {
      data
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async delete (id: string) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/booking/${id}`, {
      method: 'DELETE'
    })

    Store.dispatch('app/loading', false)

    return result
  }

  async clientPresent (id: string): Promise<string> {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/client-present/${id}`, {
      method: 'PATCH'
    })

    Store.dispatch('app/loading', false)

    return result.data.arrived_at
  }

  async clientNotPresent (id: string): Promise<string> {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/client-not-present/${id}`, {
      method: 'PATCH'
    })

    Store.dispatch('app/loading', false)

    return result.data.arrived_at
  }

  async getRentingEntityRelatedData (
    rentingEntityId: string
  ): Promise<AutoBookingTransferDataInterface> {
    Store.dispatch('app/loading', true)

    const url = `calendar-get-auto-booking-data/${rentingEntityId}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  /**
   * Returns true if there are available slots and false otherwise
   */
  async checkAvaialbleBookingSlots ({
    dateFrom,
    dateTo,
    departmentId,
    numberOfInteractors,
    categoryId = null,
    rentingEntityId
  }: {
    dateFrom: string | Date
    dateTo: string | Date
    departmentId: string
    numberOfInteractors: number
    categoryId?: string | null
    rentingEntityId?: string
  }) {
    Store.dispatch('app/loading', true)

    const URL = 'get-available-booking-slots'

    const result = await this.axios(URL, {
      params: {
        date_from: dateFrom,
        date_to: dateTo,
        department_id: departmentId,
        number_of_interactors: numberOfInteractors,
        category_id: categoryId,
        renting_entity_id: rentingEntityId
      }
    })

    Store.dispatch('app/loading', false)

    return result.data.available_slots
  }

  async checkout (dateTo: string | Date, bookingId: string) {
    Store.dispatch('app/loading', true)

    const URL = '/booking/checkout'

    const result = await this.axios(URL, {
      method: 'PATCH',
      params: {
        date_to: dateTo,
        booking_id: bookingId
      }
    })

    Store.dispatch('app/loading', false)

    return result
  }
}

export default new BookingService()
