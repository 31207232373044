
// #region imports

// #region vue
import { Component, Vue, Prop } from 'vue-property-decorator'
// #endregion

// #region components
import TableModal from '@/common/components/ui/table/TableModal.vue'
import CustomTableRender from '@/common/components/ui/table/CustomTableRender.vue'
import EmptyTable from '@/common/components/ui/table/EmptyTable.vue'
import TableSearch from '@/common/components/ui/table/TableSearch.vue'
import TablePagination from '@/common/components/ui/table/TablePagination.vue'
import DeleteDialog from '@/common/components/modals/DeleteDialog.vue'
// #endregion

// #region interfaces
import { TableHeaderInterface } from '@/types/interfaces/ui'
// #endregion

// #endregion

@Component({
  components: {
    TableModal,
    EmptyTable,
    CustomTableRender,
    TableSearch,
    TablePagination,
    DeleteDialog
  }
})
export default class Table extends Vue {
  // Table Props
  @Prop({ required: true, default: '' }) readonly name!: string
  @Prop({ required: true, default: '' }) readonly title!: string
  @Prop({ required: false, default: '' }) readonly newButtonTitle!: string
  @Prop({ default: true }) readonly hasActions!: boolean
  @Prop({ required: true, default: [] })
  readonly headers!: Array<TableHeaderInterface>
  @Prop({ required: true, default: [] }) items!: Array<any> // eslint-disable-line
  @Prop({ default: false }) readonly loading!: boolean

  // Table Modal
  @Prop({ default: false }) readonly dialog!: boolean
  @Prop({ default: true }) readonly newItem!: boolean
  @Prop({ default: false }) readonly state!: boolean

  // Pagination
  @Prop({ default: false }) readonly hidePagination!: boolean
  @Prop({ default: false }) readonly currentPage!: number | boolean
  @Prop({ default: 1 }) readonly totalElements!: number
  @Prop({ default: 1 }) readonly perPage!: number

  // Search props
  @Prop({ default: false }) searchable!: boolean
  @Prop({ default: false }) debouncedSearch!: boolean
  @Prop({ default: false }) readonly showCustomFilter!: boolean

  // Delete dialog
  @Prop({ default: false }) readonly deleteDialog!: boolean
  @Prop() readonly confirmDeleteDialog!: () => void
  @Prop() readonly cancelDeleteDialog!: () => void

  // Looks
  @Prop({ required: false, default: false }) readonly dense!: boolean
}
