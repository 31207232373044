
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #endregion

@Component
export default class MoreThanOneRentingEntity extends Vue {
  @Prop({ required: true }) readonly items!: RentingEntityInterface[]
}
