
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EmptyTable extends Vue {
  @Prop({ required: false, default: '' }) readonly title!: string
  @Prop({ required: false }) readonly customTitle!: string
  @Prop({ required: false }) readonly titleClass!: string
  @Prop({ default: false }) readonly hideHint!: boolean
}
