
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
import AddEditDepartment from '@/containers/departments/AddEditDepartment.vue'
// #endregion

// #region intefaces
import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
import {
  DepartmentInterface,
  DepartmentTableItemInterface
} from '@/types/interfaces/department'
// #endregion

// #region services
import DepartmentService from '@/services/department.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion
@Component({
  components: { Table, AddEditDepartment, DefaultTableActions }
})
export default class ArchivedDepartments extends Vue {
  @Prop({ required: true }) readonly tab!: string

  dialog = false
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      value: 'custom_render',
      render: Vue.component('Name', {
        props: ['item'],
        template: `
        <h5 v-if="item.activity_system === 'REQUESTS_ONLY'">
          {{item.name}}
          <i class="mdi mdi-bookmark-off tw-text-md"></i>
        </h5>
        <h5 v-else>
          {{item.name}}
          <i class="mdi mdi-bookmark tw-text-md"></i>
        </h5>
          `
      })
    },
    {
      text: i18n.t('table.col.description'),
      value: 'description'
    },
    {
      text: i18n.t('table.col.organization_number'),
      value: 'organization_number'
    },
    {
      text: i18n.t('table.col.company'),
      value: 'company.name'
    },
    {
      text: i18n.t('table.col.address'),
      value: 'address.address'
    },
    {
      text: i18n.t('table.col.status'),
      value: 'custom_render',
      render: Vue.component('Status', {
        props: [],
        template: '<v-chip label outlined color="red">ARCHIVED</v-chip>'
      }),
      sortable: false
    },
    {
      text: i18n.t('table.col.actions'),
      value: 'actions',
      sortable: false
    }
  ]

  departments: Array<DepartmentInterface> = []
  pagination = {} as PaginationInterface

  async handleDepartmentsFetch (): Promise<void> {
    const result = await DepartmentService.getAll('archived')
    this.setData(result)
  }

  async created (): Promise<void> {
    await this.handleDepartmentsFetch()
  }

  viewItem (row: DepartmentTableItemInterface): void {
    this.$router.push(`department/${row.item.id}`)
  }

  async reactivateItem (row: DepartmentTableItemInterface): Promise<void> {
    const id = row.item.id

    const result = await DepartmentService.reactivate(id)

    this.$toast.success(
      i18n.t('notify.success.department_reactivated').toString()
    )

    this.handleDepartmentsFetch()

    return result
  }

  async nextPage (): Promise<void> {
    const result = await DepartmentService.goToPage(
      this.pagination.next_page_url
    )
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    const result = await DepartmentService.goToPage(
      this.pagination.prev_page_url
    )
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    const result = await DepartmentService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  @Watch('tab')
  async onTabChange (): Promise<void> {
    if (this.tab === 'archivedDepartments') {
      this.handleDepartmentsFetch()
    }
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.departments = result.departments.data
    delete result.departments.data
    this.pagination = result.departments
  }
}
