
// #region Imports

// vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import ManualBookingClientInformation from '@/containers/bookings/ManualBookingClientInformation.vue'
import SimpleChipWithTitle from '@/common/components/ui/chips/SimpleChipWithTitle.vue'
import DateFromTo from '@/components/bookings/DateFromTo.vue'

// interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import { DepartmentInterface } from '@/types/interfaces/department'
import {
  BookingPropertyInterface,
  ReservationInterface
} from '@/types/interfaces/booking'
import { ClientInterface } from '@/types/interfaces/client'
import {
  BlockedDatesSeriesInterface,
  DateRangeInterface,
  VCalendarAttributeInterface
} from '@/types/interfaces/calendar'
import {
  AvaialbleRentingEntitiesAtDateRangeResponseInterface,
  AvaialbleRentingEntityInterface,
  CreateManualBookingReservationInterface
} from '@/types/interfaces/manual-booking'

// helpers & utils
import rules from '@/utils/rules.utils'
import subMinutes from 'date-fns/subMinutes'
import addMinutes from 'date-fns/addMinutes'
import { clone } from 'lodash'
import { defaultOutgoing, dummyDateConverter } from '@/utils/date.utils'
import { addDays, isAfter, isBefore, isEqual } from 'date-fns'

// services
import _companyService from '@/services/company.service'
import _departmentService from '@/services/department.service'
import _manualBookingService from '@/services/manual-booking-service'

// configs
import { configuration } from '@/config'

// logics
import { getBlockedDatesAsync } from '@/common/logics/blocked-dates/'
import { enUS, nb } from 'date-fns/locale'

// #endregion

@Component({
  components: {
    ManualBookingClientInformation,
    SimpleChipWithTitle,
    DateFromTo
  },
  methods: {
    configuration
  }
})
export default class ManualBookingHourly extends Vue {
  @Prop() dialog!: boolean

  // data - start
  data = {
    companyId: '',
    departmentId: '',
    numberOfInteractors: 1,
    date: {
      date: new Date(),
      dateFrom: subMinutes(new Date(), 60),
      dateTo: addMinutes(new Date(), 60),
      datePickerKey: 0
    },
    availableSlots: {
      avaialble: false,
      checked: false
    }
  }

  reservation = {
    company: {
      id: '',
      name: '',
      logo_url: ''
    },
    department: {
      id: '',
      name: '',
      activity_system: '',
      schedule_system: '',
      checkin: '',
      checkout: ''
    },
    rentingEntity: {
      id: ''
    },
    client: {
      id: '',
      name: '',
      email: '',
      phone_number: '',
      country_code: ''
    },
    date_from: new Date(),
    date_to: new Date(),
    note: '',
    number_of_interactors: 0,
    category_id: ''
  } as ReservationInterface

  defaultDataDate = {
    date: new Date(),
    dateFrom: subMinutes(new Date(), 60),
    dateTo: addMinutes(new Date(), 60),
    datePickerKey: 0
  }

  companies = [] as Array<CompanyInterface>
  departments = [] as Array<DepartmentInterface>

  selectedCompany = {} as CompanyInterface
  selectedDepartment = {} as DepartmentInterface

  rules = rules
  loading = false

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  blockedDates = {
    blockedDatesAttributes: [] as VCalendarAttributeInterface[],
    disabledDates: [] as DateRangeInterface[]
  }

  avaialbleRentingEntitiesAtDateRange =
    {} as AvaialbleRentingEntitiesAtDateRangeResponseInterface

  avaialbleRentingEntities = {
    avaialble: false
  }

  selectedRentingEntities = {
    list: [] as AvaialbleRentingEntityInterface[],
    sum: 0
  }

  proceedClicked = false

  // data - end

  _config () {
    return configuration()
  }

  // hooks - start
  async created (): Promise<void> {
    this.toggleLoading()
    await this.fetchCompanies()
    this.toggleLoading()
  }
  // hooks - end

  // methods - start
  closeManualBookingHourly (): void {
    this.$emit('closeManualBookingHourly')
  }

  toggleLoading (): void {
    this.loading = !this.loading
  }

  setSelectedCompany (): void {
    if (this.companies.length > 0) {
      this.selectedCompany = this.companies.find(
        (x) => x.id === this.data.companyId
      ) as CompanyInterface
    }
  }

  setSelectedDepartment (): void {
    if (this.departments.length > 0) {
      this.selectedDepartment = this.departments.find(
        (x) => x.id === this.data.departmentId
      ) as DepartmentInterface
    }
  }

  onSelectedDateChange (): void {
    const year = this.data.date.date.getFullYear()
    const month = this.data.date.date.getMonth()
    const date = this.data.date.date.getDate()

    this.data.date.dateFrom.setFullYear(year)
    this.data.date.dateFrom.setMonth(month)
    this.data.date.dateFrom.setDate(date)

    this.data.date.dateTo.setFullYear(year)
    this.data.date.dateTo.setMonth(month)
    this.data.date.dateTo.setDate(date)

    this.data.date.datePickerKey++
  }

  ensureDefaultDateNotBlocked (): void {
    if (this.blockedDates.disabledDates.length === 0) {
      this.regulateDates()
      return
    }

    const disableDates = clone(this.blockedDates.disabledDates)

    disableDates.forEach((item) => {
      const tmpStart = dummyDateConverter(item.start)
      const tmpEnd = dummyDateConverter(item.end)
      // CHECKING THE FOLLOWING
      /*
        1. tmpStart-----dateFrom--------------------------------------tmpEnd-------dateTo
        2. dateFrom-----tmpStart---------------------------dateTo------------------tmpEnd
        3. dateFrom-----tmpStart---------------------------tmpEnd------------------dateTo
        4. tmpStart---------------------------dateFrom------dateTo-----------------tmpEnd
      */
      if (
        (this.data.date.dateFrom >= tmpStart &&
          this.data.date.dateFrom <= tmpEnd) ||
        (this.data.date.dateTo >= tmpStart &&
          this.data.date.dateTo <= tmpEnd) ||
        (this.data.date.dateFrom <= tmpStart &&
          this.data.date.dateTo >= tmpEnd) ||
        (this.data.date.dateFrom >= tmpStart && this.data.date.dateTo <= tmpEnd)
      ) {
        this.data.date.date = addDays(this.data.date.date, 1)
        this.data.date.dateFrom = addDays(this.data.date.dateFrom, 1)
        this.data.date.dateTo = addDays(this.data.date.dateTo, 1)
        this.ensureDefaultDateNotBlocked() // <-- recursively calling and adding one day until we find a date that is not blocked
      }
    })
  }

  regulateDates () {
    this.data.date = this.defaultDataDate
  }

  setRelevantData (): void {
    this.reservation.company.id = this.data.companyId
  }

  formatRentingEntityName (
    rentingEntity: AvaialbleRentingEntityInterface
  ): string {
    return `${rentingEntity.name} (${rentingEntity.number_of_interactors})`
  }

  resetAvailableRentingEntities (): void {
    this.avaialbleRentingEntitiesAtDateRange =
      {} as AvaialbleRentingEntitiesAtDateRangeResponseInterface
    this.selectedRentingEntities = {
      list: [] as AvaialbleRentingEntityInterface[],
      sum: 0
    }
    this.data.numberOfInteractors = 1
  }

  proceedToClientInformationClicked (): void {
    this.proceedClicked = true
    this.scrollToElement('clientInformationRef')
  }

  scrollToElement (elementRef: string): void {
    const checkRenderAndScroll = () => {
      this.$nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const el = this.$refs[elementRef] as any // this is a vue component element
        if (el) {
          el.$el.scrollIntoView({ behavior: 'smooth' })
        } else {
          setTimeout(checkRenderAndScroll, 100)
        }
      })
    }
    setTimeout(checkRenderAndScroll, 400)
  }
  // methods - end

  // fetchings from backend - start
  async fetchCompanies (): Promise<void> {
    this.toggleLoading()
    const result = await _companyService.getActiveWithoutPagination()
    this.companies = result
    this.toggleLoading()
  }

  async fetchDepartments (): Promise<void> {
    this.toggleLoading()
    const result = await _departmentService.getAllCompanyDepartments(
      this.data.companyId,
      'HOURLY'
    )
    this.departments = result
    this.toggleLoading()
  }

  async fetchBlockedDates (blockedDateId: string | null = null): Promise<void> {
    this.blockedDates.blockedDatesAttributes = []
    this.blockedDates.disabledDates = []
    const result = (await getBlockedDatesAsync(
      this.data.companyId,
      this.data.departmentId,
      blockedDateId,
      this.currentLanguage
    )) as BlockedDatesSeriesInterface

    this.blockedDates.blockedDatesAttributes = [
      ...this.blockedDates.blockedDatesAttributes,
      ...result.blockedDates
    ]

    this.blockedDates.disabledDates = [
      ...this.blockedDates.disabledDates,
      ...result.disabledDates
    ]
  }

  async fetchAvaialableRentingEntitiesAtDateRangeAndScroll (): Promise<void> {
    const result =
      await _manualBookingService.getAvaialableRentingEntitiesAtDateRange(
        this.selectedDepartment.id,
        this.selectedDepartment.schedule_system as string,
        defaultOutgoing(this.data.date.dateFrom),
        defaultOutgoing(this.data.date.dateTo)
      )

    this.avaialbleRentingEntitiesAtDateRange = result.result

    if (this.isAnyRentingEntitiesAvailable) {
      this.scrollToElement('rentingEntitiesInformationRef')
    }
  }

  async submit (data: ClientInterface): Promise<void> {
    const form = this.$refs.form as HTMLFormElement

    const isValid = form.validate()

    if (isValid) {
      const payload = {} as CreateManualBookingReservationInterface

      payload.booking = {
        company_id: this.data.companyId,
        department_id: this.data.departmentId,
        date_from: defaultOutgoing(this.data.date.dateFrom),
        date_to: defaultOutgoing(this.data.date.dateTo),
        number_of_interactors: this.data.numberOfInteractors as number,
        schedule_system: 'HOURLY',
        renting_entity_ids: this.selectedRentingEntities.list.map(
          (x) => x.id
        ) as string[],
        is_manual_booking: true
      }

      payload.client = {
        name: data.name,
        email: data.email as string,
        phone_number: data.phone_number,
        country_code: data.country_code
      }

      const bookingProperties = [] as BookingPropertyInterface[] | undefined

      if (data.notes) {
        bookingProperties?.push({
          key: 'notes',
          value: data.notes
        })
      }

      if (data.tags) {
        bookingProperties?.push({
          key: 'tags',
          value: data.tags.value
        })
      }

      payload.booking_properties =
        bookingProperties as BookingPropertyInterface[]

      _manualBookingService.create(payload)

      this.$emit('closeManualBookingHourly')
      this.$emit('submit')
    }
  }
  // fetchings from backend - end

  // #region watchers
  @Watch('data.companyId')
  async onCompanyIdChange (): Promise<void> {
    this.setSelectedCompany()
    this.data.departmentId = ''
    this.data.availableSlots.avaialble = false
    this.resetAvailableRentingEntities()
    this.proceedClicked = false
    await this.fetchDepartments()
  }

  @Watch('data.departmentId')
  async onDepartmentIdChange (): Promise<void> {
    this.toggleLoading()
    this.setSelectedDepartment()
    this.data.availableSlots.avaialble = false
    await this.fetchBlockedDates()
    this.ensureDefaultDateNotBlocked()
    this.resetAvailableRentingEntities()
    this.proceedClicked = false
    this.toggleLoading()
  }

  @Watch('data.date.dateFrom')
  onDateFromChange () {
    this.data.availableSlots.avaialble = false
    this.data.availableSlots.checked = false
    this.proceedClicked = false
    this.resetAvailableRentingEntities()
    if (isEqual(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }

    if (isAfter(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }
  }

  @Watch('data.date.dateTo')
  onDateToChange () {
    this.data.availableSlots.avaialble = false
    this.data.availableSlots.checked = false
    this.proceedClicked = false
    this.resetAvailableRentingEntities()
    if (isEqual(this.data.date.dateFrom, this.data.date.dateTo)) {
      this.data.date.dateTo = addMinutes(this.data.date.dateFrom, 30)
    }

    if (isBefore(this.data.date.dateTo, this.data.date.dateFrom)) {
      this.data.date.dateFrom = subMinutes(this.data.date.dateTo, 30)
    }
  }

  @Watch('selectedRentingEntities.list')
  onSelectedRentingEntitiesChanged () {
    const sum = this.selectedRentingEntities.list.reduce(
      (sum, item) => sum + item.number_of_interactors,
      0
    )
    this.selectedRentingEntities.sum = sum
  }

  @Watch('selectedRentingEntities.list')
  onSelectedRentingEntitiesSumChanged () {
    if (this.selectedRentingEntities.list.length === 0) {
      this.proceedClicked = false
    }
  }
  // #endregion watchers

  // #region getters
  get currentLanguage (): string {
    return this.$i18n.locale
  }

  get progressColorGradient (): [string, number] {
    const sum = this.data.numberOfInteractors
    const total = this.selectedRentingEntities.sum

    const percentage = (total * 100) / sum

    switch (true) {
      case percentage <= 0:
        return ['grey', percentage]
      case percentage > 0 && percentage < 20:
        return ['light-blue lighten-1', percentage]
      case percentage >= 20 && percentage < 40:
        return ['light-blue darken-1', percentage]
      case percentage >= 40 && percentage < 60:
        return ['light-blue darken-2', percentage]
      case percentage >= 60 && percentage < 80:
        return ['light-blue darken-3', percentage]
      case percentage >= 80 && percentage <= 100:
        return ['light-blue darken-4', percentage]
      default:
        return ['light-blue darken-4', percentage]
    }
  }

  get interactorRatio (): string {
    // return `${this.selectedRentingEntities.sum}/${this.data.numberOfInteractors}`
    return `${this.data.numberOfInteractors}/${this.selectedRentingEntities.sum}`
  }

  get totalAvaialableInteractors (): number {
    return this.avaialbleRentingEntitiesAtDateRange.total_number_of_interactors
  }

  get isAnyRentingEntitiesAvailable (): boolean {
    return (
      this.avaialbleRentingEntitiesAtDateRange.available_renting_entities
        ?.length > 0
    )
  }

  get isOverUnder (): [string, string] {
    const difference =
      this.selectedRentingEntities.sum - this.data.numberOfInteractors
    const _diffString =
      difference > 0 ? `+${difference}` : difference.toString()
    const completelyUtilized = this.$t(
      'page.bookings.manual_bookings.utilization.completely'
    ).toString()
    const overUtilized = this.$t(
      'page.bookings.manual_bookings.utilization.over'
    ).toString()
    const underUtilized = this.$t(
      'page.bookings.manual_bookings.utilization.under'
    ).toString()

    if (this.selectedRentingEntities.sum === 0) {
      return ['', '']
    }

    if (
      this.selectedRentingEntities.sum.toString() ===
      this.data.numberOfInteractors.toString()
    ) {
      return [
        `${completelyUtilized} (${_diffString})`,
        'light-blue--text text--darken-4'
      ]
    }

    return this.selectedRentingEntities.sum > this.data.numberOfInteractors
      ? [`${overUtilized} (${_diffString})`, 'brown--text text--darken-4']
      : [`${underUtilized} (${_diffString})`, 'brown--text text--darken-4']
  }

  get totalAvaialableInteractorsList (): number[] {
    return Array.from(Array(this.totalAvaialableInteractors).keys()).map(
      (x) => x + 1
    )
  }

  get isProceedDisabled (): boolean {
    return (
      this.selectedRentingEntities.sum < this.data.numberOfInteractors ||
      this.proceedClicked === true
    )
  }

  get canProceedToClientInformation (): boolean {
    return this.proceedClicked && this.selectedRentingEntities.list.length > 0
  }

  get isDeparmtentSelected (): boolean {
    // data.departmentId && !loading
    if (this.data.departmentId !== '' && !this.loading) {
      this.scrollToElement('dateRef')
      return true
    }
    return false
  }
  // #endregion getters

  // #region event listeners
  async clientInformationAddedEventListener (
    data: ClientInterface
  ): Promise<void> {
    this.submit(data)
  }
  // #endregion event listeners

  // #region event emitters

  // #endregion event emitters
}
