
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import PageHeader from '@/common/components/pages/PageHeader.vue'
import PageOverview from '@/common/components/pages/PageOverview.vue'
import PageOverviewItems from '@/common/components/pages/PageOverviewItems.vue'
import PageOverviewItem from '@/common/components/pages/PageOverviewItem.vue'
import Tags from '@/components/bookings/Tags.vue'
// #endregion

// #region interfaces
import { CategoryInterface } from '@/types/interfaces/category'
// #endregion

// #endregion

@Component({
  components: {
    PageHeader,
    PageOverview,
    PageOverviewItems,
    PageOverviewItem,
    Tags
  }
})
export default class Category extends Vue {
  category = {} as CategoryInterface

  async created (): Promise<void> {
    //
  }
}
