
// #region imports

// #region vue
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
// #endregion

// #region rules
import rules from '@/utils/rules.utils'
// #endregion

// #region services
import companyService from '@/services/company.service'
// #endregion

// #region interfaces
import { CompanySettingInterface } from '@/types/interfaces/setting'
// #endregion

// #endregion

@Component
export default class LogoSettings extends Vue {
  @Prop({ type: String, required: true }) companyId!: string
  @Prop({ required: true }) readonly settings!: CompanySettingInterface

  // fields
  previewImage: string | ArrayBuffer | null | undefined = ''
  logo: File | null = null
  saveDisabled = true
  deleteDialog = false
  hasUploaded = false

  // inititializers
  rules = rules

  toggleLogoSettings = false
  expandedLogo = false

  async created (): Promise<void> {
    await this.getCompanyLogo()
  }

  handleFileSelected (image: File): void {
    const reader = new FileReader()

    reader.readAsDataURL(image)
    reader.onload = (e) => {
      const img = new Image()

      img.src = e.target?.result as string
      img.onload = () => {
        if (img.width > 750 || img.height > 250) {
          this.$toast.error(this.$t('settings.logo.file_dimension_limit').toString())
          this.logo = null
          this.saveDisabled = true
        } else {
          this.previewImage = e.target?.result
        }
      }
    }

    this.logo = image

    this.saveDisabled = false
  }

  async getCompanyLogo (): Promise<void> {
    const logoResult = await companyService.getCompanyLogo(this.companyId)

    if (logoResult.filePath) {
      this.previewImage = logoResult.filePath
      this.hasUploaded = true
      this.disableElementOperations()
    }
  }

  async handleDeleteLogo (): Promise<void> {
    if (this.hasUploaded) {
      this.deleteDialog = true
    } else {
      this.deleteDialog = false
      this.previewImage = null
      this.logo = null
      this.saveDisabled = true
    }
  }

  disableElementOperations () {
    this.saveDisabled = true
    this.deleteDialog = false
  }

  toggleLogo (): void {
    this.expandedLogo = !this.expandedLogo
  }

  toggleDeleteDialog (): void {
    this.deleteDialog = !this.deleteDialog
  }

  async deleteLogo (): Promise<void> {
    const deleteResult = await companyService.deleteCompanyLogo(this.companyId)

    if (deleteResult) {
      this.$toast.success(this.$t('settings.logo.delete_success').toString())
      this.hasUploaded = false
      this.previewImage = null
      this.logo = null
      this.toggleDeleteDialog()
      window.location.reload()
    } else {
      this.$toast.error(this.$t('settings.logo.delete_failure').toString())
    }
  }

  async upload (): Promise<void> {
    if (!this.logo) return

    const form = this.$refs.form as HTMLFormElement

    const valid = form.validate()

    const formData = new FormData()

    if (valid) {
      formData.append('image', this.logo)
      formData.append('company_id', this.companyId)

      await new Promise((resolve, reject) => {
        const logoResult = companyService.uploadCompanyLogo(formData)

        logoResult
          .then((res) => {
            resolve(res)
            this.disableElementOperations()
            this.$toast.success(
              this.$t('settings.logo.upload_success').toString()
            )
            this.hasUploaded = true
            this.$store.dispatch('app/updateCompanyLogoPath', res.result.logo_path)
            this.$emit('logo-uploaded')
          })
          .catch((err) => {
            reject(err)
            this.$toast.error(
              this.$t('settings.logo.upload_failure').toString()
            )
          })
      })
    }
  }

  @Watch('settings')
  onSettingsChange (): void {
    this.previewImage = this.settings.logo_path
    this.logo = null
    this.saveDisabled = true
  }
}
