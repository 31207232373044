const themeSettings = JSON.parse(localStorage.getItem('theme_settings') || '{}')

export default {
  // global theme for the app
  globalTheme: themeSettings.global || 'light', // light | dark

  // side menu theme, use global theme or custom
  menuTheme: themeSettings.menu || 'global', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: themeSettings.toolbar || 'global', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    background: '#05090c',
    surface: '#111b27',
    primary: '#0096c7',
    secondary: '#829099',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    gMainBackgroundColor: '#05090c',
    gFontColor: '#ffffff',
    gCardBackgroundColor: '#111b27',
    gCardBorderColor: '#ffffff',
    gButtonBackgroundColor: '#111b27',
    gButtonFontColor: '#ffffff',
    gButtonBorderColor: '#ffffff',
    gDatesBackgroundColorPastDates: '#111b27',
    gDatesBackgroundColorPresent: '#111b27',
    gDatesBackgroundColorFutureDates: '#111b27',
    gGeneralElementsBackgroundColor: '#111b27'
  },

  // light theme colors
  light: {
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#0096c7',
    secondary: '#a0b9c8',
    accent: '#048ba8',
    error: '#ef476f',
    info: '#2196F3',
    success: '#06d6a0',
    warning: '#ffd166',
    gMainBackgroundColor: '#ffffff',
    gFontColor: '#000000',
    gCardBackgroundColor: '#ffffff',
    gCardBorderColor: '#123123',
    gButtonBackgroundColor: '#E3F2FD',
    gButtonFontColor: '#01579B',
    gButtonBorderColor: '#ffffff',
    gDatesBackgroundColorPastDates: '#111b27',
    gDatesBackgroundColorPresent: '#111b27',
    gDatesBackgroundColorFutureDates: '#111b27',
    gGeneralElementsBackgroundColor: '#111b27'
  }
}
