// #region Imports
import FilteredPrintableBookings from '@/views/printable/FilteredPrintableBookings.vue'
import FirelistPrintableInteractors from '@/views/printable/FirelistPrintableInteractors.vue'
// #endregion Imports

const ROUTE_PREFIX = '/printable'

const printableRoutes = [
  {
    path: `${ROUTE_PREFIX}/bookings`,
    name: 'printable-bookings',
    component: FilteredPrintableBookings,
    meta: {
      auth: true,
      layout: 'default'
    }
  },
  {
    path: `${ROUTE_PREFIX}/firelist`,
    name: 'printable-firelist',
    component: FirelistPrintableInteractors,
    meta: {
      auth: true,
      layout: 'default'
    }
  }
]

export default printableRoutes
