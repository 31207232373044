
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region interfaces
// import { RentingEntityInterface } from '@/types/interfaces/renting-entity'
// #endregion

// #region helpers & utils
import { defaultIncoming } from '@/utils/date.utils'
// #endregion

// #endregion

@Component(
  {
    methods: {
      defaultIncoming
    }
  }
)
export default class ReportTimeFrame extends Vue {
  @Prop({ required: true }) readonly items!: Array<string>

  get dateItems (): string[] {
    const dates = this.items
    return dates.map((date: string) => {
      return defaultIncoming(date, 'PP')
    })
  }
}
