// #region imports

// #region interfaces
import { ApiCallErrorInterface } from '@/types/interfaces/setting'
import {
  CreateReportInterface,
  FireListResponseInterface,
  FirelistRequestInterface,
  ReportInterface
} from '@/types/interfaces/report/'
// #endregion

// #region configs
import configs from '@/configs'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

const { baseUrl } = configs

class ReportService extends BaseService {
  public error?: ApiCallErrorInterface

  async getAll () {
    Store.dispatch('app/loading', true)

    const url = `${baseUrl}/reports`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)
    return result.data
  }

  async generate (data: CreateReportInterface) {
    Store.dispatch('app/loading', true)

    const url = `${baseUrl}/reports`

    await this.axios.post(url, { data })

    Store.dispatch('app/loading', false)
  }

  async downloadItem (reportContent: ReportInterface, type: string) {
    Store.dispatch('app/loading', true)

    const isExcel = type === 'excel'
    const file = {
      type: isExcel ? 'excel' : 'pdf',
      extension: isExcel ? 'xls' : 'pdf'
    }

    const url = `${baseUrl}/reports/${reportContent.id}/${file.type}`

    const response = await this.axios.get(url, { responseType: 'blob' })

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')

    link.href = downloadUrl

    const fileName = `${reportContent.id}__${
      reportContent.name
    }__${new Date().getTime()}.${file.extension}`

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    Store.dispatch('app/loading', false)
  }

  async delete (id: number) {
    Store.dispatch('app/loading', true)

    const url = `/reports/${id}`

    await this.axios.delete(url)

    Store.dispatch('app/loading', false)
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const URL = `/reports-search?page=${page}`

    const RESULT = await this.axios(URL, { params: { searchText } })

    Store.dispatch('app/loading', false)

    return RESULT.data
  }

  async getFirelistDocument (data: FirelistRequestInterface) {
    Store.dispatch('app/loading', true)

    const URL = '/reports/firelist-document'

    const result = await this.axios.post(
      URL,
      { data },
      { responseType: 'blob' }
    )

    const downloadUrl = window.URL.createObjectURL(new Blob([result.data]))
    const link = document.createElement('a')

    link.href = downloadUrl

    const fileName = 'FIRELIST DOCUMENT.pdf'

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    Store.dispatch('app/loading', false)

    return result.data.firelist as FireListResponseInterface[]
  }

  async getFirelistGuestsList (data: FirelistRequestInterface) {
    Store.dispatch('app/loading', true)

    const URL = '/reports/firelist-guests-list'

    const result = await this.axios.post(
      URL,
      { data },
      { responseType: 'blob' }
    )

    const downloadUrl = window.URL.createObjectURL(new Blob([result.data]))
    const link = document.createElement('a')

    link.href = downloadUrl

    const fileName = 'FIRELIST GUESTS LIST.pdf'

    link.setAttribute('download', fileName)

    document.body.appendChild(link)
    link.click()

    Store.dispatch('app/loading', false)

    return result.data.firelist as FireListResponseInterface[]
  }
}

export default new ReportService()
