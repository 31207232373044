
// #region imports

// #region vue
import { Component, Prop, Vue } from 'vue-property-decorator'
// #endregion

// #region utils & helpers
import { format } from 'date-fns'
import { parseJson } from '@/utils/helper.utils'
import { dummyDateConverter } from '@/utils/date.utils'
// #endregion

// #region interfaces
import {
  BookingPropertyInterface,
  BookingInterface
} from '@/types/interfaces/booking'
import { PublicBookingCustomVisualSettingsInterface } from '@/types/interfaces/setting'
// #endregion

// #region locales
import { enUS, nb } from 'date-fns/locale'
// #endregion

// #region constants
const DATE_FORMAT_WITH_HOUR = 'PP / H:mm'
const DATE_FORMAT = 'PP /'
// #endregion

// #endregion

@Component({
  methods: {
    format,
    parseJson
  }
})
export default class ThankYou extends Vue {
  @Prop({ required: true }) readonly bookingData!: BookingInterface
  @Prop({ default: false })
  readonly visualSettings!: PublicBookingCustomVisualSettingsInterface

  notes = {
    key: 'notes',
    value: ''
  } as BookingPropertyInterface

  tags = {
    key: 'tags',
    value: []
  } as BookingPropertyInterface

  locale = process.env.VUE_APP_LANGUAGE === 'en-us' ? enUS : nb

  created (): void {
    this.setLocale()
    this.notes.value = this.bookingData.properties?.find(
      (x) => x.key === 'notes'
    )?.value as string
    this.tags.value = this.bookingData.properties?.find((x) => x.key === 'tags')
      ?.value as string[]
  }

  // #region computed stuff
  get departmentAddress (): string {
    return this.bookingData.department.address
      ? `${this.bookingData.department.address.address},
          ${this.bookingData.department.address.city},
          ${this.bookingData.department.address.zip}`
      : ''
  }

  get clientPhoneNumber (): string {
    return `(${this.bookingData.client.country_code}) ${this.bookingData.client.phone_number}`
  }

  // eslint-disable max-len
  get dateRange (): string {
    // eslint-disable-next-line max-len
    if (this.bookingData.department.schedule_system === 'HOURLY') {
      if (this.bookingData.department.activity_system === 'BOOKING') {
        return format(
          dummyDateConverter(this.bookingData.date_from),
          DATE_FORMAT_WITH_HOUR,
          { locale: this.locale }
        )
      } else {
        return `${format(
          dummyDateConverter(this.bookingData.date_from),
          DATE_FORMAT_WITH_HOUR,
          { locale: this.locale }
        )} - 
        ${format(
          dummyDateConverter(this.bookingData.date_to),
          DATE_FORMAT_WITH_HOUR,
          { locale: this.locale }
        )}`
      }
    } else {
      return `${format(
        dummyDateConverter(this.bookingData.date_from),
        DATE_FORMAT,
        { locale: this.locale }
      )} 
      ${this.bookingData.department.checkin?.value} - 
      ${format(dummyDateConverter(this.bookingData.date_to), DATE_FORMAT, {
        locale: this.locale
      })} 
      ${this.bookingData.department.checkout?.value}`
    }
  }

  get isLoggedIn (): boolean {
    if (this.$store.state.auth.user.name) {
      return true
    }

    return false
  }

  get canIApplyVisualSettings (): boolean {
    return this.visualSettings.active && !this.isLoggedIn
  }
  // #endregion

  // #region setters
  setLocale (): void {
    if (this.$i18n.locale === 'en-us') {
      this.locale = enUS
      return
    }

    if (this.$i18n.locale === 'no') {
      this.locale = nb
    }
  }
  // #endregion
}
