
// #region imports

// #region vue
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
// #endregion

// #region services
import settingService from '@/services/setting.service'
// #endregion

// #region utils & helpers
import { parseJson } from '@/utils/helper.utils'
// #endregion

// #region interfaces
import {
  HourlyDurationSettingInteface,
  BookingDurationSettingInterface,
  CompanySettingInterface
} from '@/types/interfaces/setting'
import { HourlyBookingDurationInterface } from '@/types/interfaces/calendar'
// #endregion

// #region validations
import rules from '@/utils/rules.utils'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #endregion

@Component
export default class HourlyBookingDurationSettings extends Vue {
  @Prop({ required: true }) readonly companyId!: string
  @Prop({ required: true }) readonly companySettings!: CompanySettingInterface

  rules = rules

  hourlySettings: HourlyBookingDurationInterface[] = []
  settings = {} as HourlyDurationSettingInteface

  days = [
    i18n.t('day.monday'),
    i18n.t('day.tuesday'),
    i18n.t('day.wednesday'),
    i18n.t('day.thursday'),
    i18n.t('day.friday'),
    i18n.t('day.saturday'),
    i18n.t('day.sunday')
  ]

  hours = [
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00'
  ]

  addHourlySetting (): void {
    this.hourlySettings.push({
      day: '',
      hour: '',
      duration: 30,
      saved: false
    })
  }

  @Watch('companySettings')
  onCompanySettingsChange (): void {
    this.resetHourlySettings()
  }

  async submitDuration (index: number): Promise<void> {
    let hour = this.hourlySettings[index].hour
    hour = hour.split(':')[0]
    const data = { ...this.settings } as HourlyDurationSettingInteface
    const settingKey = `${this.hourlySettings[
      index
    ].day.toLowerCase()}_hourly_${hour}`
    const settingValue = +this.hourlySettings[index].duration

    data[settingKey] = settingValue
    this.hourlySettings[index].saved = true

    const settings = (await settingService.updateHourlyBookingDurations(
      data,
      this.companyId
    )) as CompanySettingInterface

    this.resetHourlySettings(settings)
  }

  changed (index: number): void {
    this.hourlySettings[index].saved = false
  }

  expandedHourlyBookingDurationSettings = false

  toggleHourlyBookingDurationSettings (): void {
    this.expandedHourlyBookingDurationSettings =
      !this.expandedHourlyBookingDurationSettings
  }

  async fetchHourlyBookingDurationSettings (): Promise<void> {
    const settings = await settingService.getCompanySettings(this.companyId)
    this.resetHourlySettings({ ...this.settings, ...settings })
  }

  async created (): Promise<void> {
    await this.fetchHourlyBookingDurationSettings()
  }

  resetHourlySettings (
    fetchedSettings: CompanySettingInterface | undefined = undefined
  ): void {
    const settingsToUse = fetchedSettings || this.companySettings
    const setting = settingsToUse.booking_duration
    const hourlySettings = []
    let settings = {} as HourlyDurationSettingInteface

    if (setting) {
      const data = parseJson(setting) as BookingDurationSettingInterface
      if (data) {
        for (const item in data.hourly) {
          const temp = item.split('_')
          hourlySettings.push({
            day: temp[0].toUpperCase(),
            hour: `${temp[2]}:00`,
            duration: data.hourly[item],
            saved: true
          })
        }
        settings = data.hourly
      }
    }

    this.settings = settings
    this.hourlySettings = hourlySettings
  }
}
