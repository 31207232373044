// #region imports

// #region interfaces
import { CompanyInterface } from '@/types/interfaces/company'
import {
  ApiCallErrorInterface,
  EnableAccountCountersInterface,
  AccountStateInterface
} from '@/types/interfaces/setting'
// #endregion

// #region vue
import Store from '@/store'
// #endregion

// #region services
import BaseService from '@/services/base.service'
// #endregion

// #endregion

class CompanyService extends BaseService {
  error?: ApiCallErrorInterface

  async getAll (type = '') {
    Store.dispatch('app/loading', true)

    const url = type.length === 0 ? '/companies' : '/companies/archived'
    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async filter (searchText: string, page = 1) {
    Store.dispatch('app/loading', true)

    const url = `/companies-search?page=${page}`

    const result = await this.axios(url, { params: { searchText } })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getSingle (id: string, archived = false) {
    Store.dispatch('app/loading', true)

    const url = archived ? `/company/${id}/archived` : `/company/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.company
  }

  async getWithAddress (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-with-address/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.company
  }

  async getAddresses () {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/addresses')

    Store.dispatch('app/loading', false)

    return result.data.addresses
  }

  async getActiveWithoutPagination () {
    Store.dispatch('app/loading', true)

    const url = '/companies-no-pagination'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data.companies
  }

  async getDisabledDates () {
    Store.dispatch('app/loading', true)

    const url = '/disabled-dates'

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getActiveWithoutPaginationPublic (domain: string) {
    Store.dispatch('app/loading', true)

    const url = '/companies-no-pagination-public'

    const result = await this.axios(url, {
      headers: {
        'COMPANY-DOMAIN-NAME': (domain || 'demo').toLowerCase()
      }
    })

    Store.dispatch('app/loading', false)

    return result.data.companies
  }

  async create (data: CompanyInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/company', {
      method: 'POST',
      data: { data }
    })

    Store.dispatch('app/loading', false)

    return result.data.company
  }

  async update (data: CompanyInterface) {
    Store.dispatch('app/loading', true)

    const result = await this.axios(`/company/${data.id}`, {
      method: 'PUT',
      data: { data }
    })

    Store.dispatch('app/loading', false)

    return result.data.company
  }

  async uploadCompanyLogo (payload: FormData) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/upload-company-logo', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: payload
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async uploadBackgroundImage (payload: FormData) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/upload-company-background-image', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: payload
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async uploadFirelistDoument (payload: FormData) {
    Store.dispatch('app/loading', true)

    const result = await this.axios('/upload-firelist-doc', {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: payload
    })

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getCompanyLogo (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-logo/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getBackgroundImage (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-background-image/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async getFirelistDoc (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/firelist-doc/${id}`

    const result = await this.axios(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async deleteCompanyLogo (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-logo/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async deleteBackgroundImage (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-background-image/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async deleteFirelistDoc (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/firelist-doc/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result.data
  }

  async reactivate (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company-reactivate/${id}`

    const result = await this.axios.patch(url)

    Store.dispatch('app/loading', false)

    return result.data.company
  }

  async delete (id: string) {
    Store.dispatch('app/loading', true)

    const url = `/company/${id}`

    const result = await this.axios.delete(url)

    Store.dispatch('app/loading', false)

    return result
  }

  async getEnableAccountCounters (): Promise<EnableAccountCountersInterface> {
    Store.dispatch('app/loading', true)

    const url = '/enable-real-account-counters'

    const result = await this.axios.get(url)

    Store.dispatch('app/loading', false)

    return result.data.counters
  }

  async enableAccount (domain: string): Promise<void> {
    Store.dispatch('app/loading', true)

    const url = '/demo-request-approve'

    await this.axios.put(url, { data: { domain } })

    Store.dispatch('app/loading', false)
  }

  async getAccountState (): Promise<AccountStateInterface> {
    Store.dispatch('app/loading', true)

    const url = '/account-state'

    const result = await this.axios.get(url)

    const data = result.data.account_state as AccountStateInterface

    Store.dispatch('app/loading', false)

    return data
  }

  async getCompaniesByUserId () {
    Store.dispatch('app/loading', true)

    const url = '/user-companies'

    const result = await this.axios.get(url)

    Store.dispatch('app/loading', false)

    return result.data.companies
  }
}

export default new CompanyService()
