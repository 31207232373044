
// #region imports

// #region vue
import { Component, Vue } from 'vue-property-decorator'
// #endregion

// #region components
import Table from '@/common/components/ui/table/Table.vue'
import AddEditStaffMember from '@/containers/staff/AddEditStaffMember.vue'
import DefaultTableActions from '@/common/components/ui/table/DefaultTableActions.vue'
// #endregion

// #region interfaces

import {
  StaffMemberInterface,
  StaffMemeberTableItemInterface
} from '@/types/interfaces/staff'

import {
  PaginatedApiResponseInterface,
  PaginationInterface
} from '@/types/interfaces/setting'
// #endregion

// #region services
import StaffService from '@/services/staff.service'
// #endregion

// #region locales
import i18n from '@/i18n'
// #endregion

// #region utils
import { getAppTitle } from '@/utils/helper.utils'
// #endregion

// #endregion

@Component({
  components: { Table, AddEditStaffMember, DefaultTableActions }
})
export default class StaffMembers extends Vue {
  headers = [
    {
      text: i18n.t('table.col.name'),
      align: 'start',
      sortable: false,
      value: 'name'
    },
    { text: i18n.t('common.email'), value: 'email' },
    {
      text: i18n.t('table.col.phone_number'),
      keys: ['country_code', 'phone_number'],
      value: 'custom_render',
      render: Vue.component('PhoneNumber', {
        props: ['items'],
        template:
          '<span class="tw-text-red-400">({{items[0]}}) {{items[1]}}</span>'
      })
    },
    {
      text: i18n.t('table.col.company'),
      value: 'custom_render',
      key: 'company.name',
      render: Vue.component('CompanyName', {
        props: ['item'],
        template:
          '<span :class="!item ? `tw-text-red-300 tw-text-xs` : ``">{{ item ? item : `N/A` }}</span>'
      })
    },
    {
      text: i18n.t('table.col.department'),
      value: 'custom_render',
      key: 'department.name',
      render: Vue.component('DepartmentName', {
        props: ['item'],
        template:
          '<span :class="!item ? `tw-text-red-300 tw-text-xs` : ``">{{ item ? item : `N/A` }}</span>'
      })
    },
    {
      text: i18n.t('common.role'),
      value: 'custom_render',
      key: 'role_name',
      render: Vue.component('Role', {
        props: ['item'],
        template: `
        <v-chip label outlined small color="red">{{item.toUpperCase()}}</v-chip>
      `
      })
    },
    { text: i18n.t('table.col.actions'), value: 'actions', sortable: false }
  ]

  members: Array<StaffMemberInterface> = []
  pagination = {} as PaginationInterface
  searching = ''

  async handleStaffMembersFetch (): Promise<void> {
    const result = await StaffService.getAll()
    this.setData(result)
  }

  async handleStaffMembersSearchFetch (
    searchText: string,
    page = 1
  ): Promise<void> {
    const result = await StaffService.filter(searchText, page)
    this.setData(result)
  }

  async created (): Promise<void> {
    document.title = getAppTitle('Staff Members')
    await this.handleStaffMembersFetch()
  }

  dialog = false
  deleteDialog = false
  member: StaffMemberInterface | null = null

  editItem (row: StaffMemeberTableItemInterface): void {
    this.member = this.members[row.index]
    this.toggleDialog()
  }

  viewItem (row: StaffMemeberTableItemInterface): void {
    this.$router.push(`staff-member/${row.item.id}`)
  }

  async deleteItem (): Promise<void> {
    const staffId = this.member !== null ? this.member.id : ''

    await StaffService.delete(staffId)

    this.$toast.success(i18n.t('notify.success.staf_member_deleted').toString())

    this.handleStaffMembersFetch()

    this.deleteDialog = false
  }

  initDeleteStaff (row: StaffMemeberTableItemInterface): void {
    this.deleteDialog = true
    this.member = this.members[row.index]
  }

  cancelDialog (): void {
    this.deleteDialog = false
    this.member = null
  }

  toggleDialog (): void {
    this.dialog = !this.dialog

    if (!this.dialog) {
      this.member = null
    }
  }

  async searchHandler (query: string): Promise<void> {
    if (!query) {
      this.searching = ''
      await this.handleStaffMembersFetch()
      return
    }

    this.searching = query
    await this.handleStaffMembersSearchFetch(query)
  }

  async nextPage (): Promise<void> {
    if (this.searching) {
      await this.handleStaffMembersSearchFetch(
        this.searching,
        this.pagination.current_page + 1
      )
      return
    }
    const result = await StaffService.goToPage(this.pagination.next_page_url)
    this.setData(result)
  }

  async prevPage (): Promise<void> {
    if (this.searching) {
      await this.handleStaffMembersSearchFetch(
        this.searching,
        this.pagination.current_page - 1
      )
      return
    }
    const result = await StaffService.goToPage(this.pagination.prev_page_url)
    this.setData(result)
  }

  async page (number: number): Promise<void> {
    if (this.searching) {
      await this.handleStaffMembersSearchFetch(this.searching, number)
      return
    }
    const result = await StaffService.goToPage(
      `${this.pagination.path}?page=${number}`
    )
    this.setData(result)
  }

  setData (result: PaginatedApiResponseInterface): void {
    this.members = result.users.data

    delete result.users.data

    this.pagination = result.users
  }
}
